import React from "react";
import { Box, FormControl, Select, MenuItem } from "@material-ui/core";

interface FilterDropDownProps {
  classes: any;
  title: string;
  options: any;
  value?: any;
  name?: any;
  onSelectValue: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const blankArr: any = [];
const FilterDropDown = (props: FilterDropDownProps) => {
  const { classes, onSelectValue, title, options, value, name } = props;

  const allowedNames = new Set(["filterBusinessCategories", "filterGroups"]);
  const getOptionLabel = (selectedValue: any) => {
    const selectedOption = options.find((option: any) =>
      allowedNames.has(name) ? option.id === selectedValue : option.name === selectedValue
    );
    return selectedOption ? selectedOption.name : selectedValue;
  };

  return (
    <Box className={"top-right"}>
      <FormControl className="select-control">
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            classes: { paper: classes.dropdownStyle },
          }}
          displayEmpty
          renderValue={(selected) => {
            const selectedArray = selected as (string | number)[];
            if (selectedArray.length === 0) {
              return title;
            } else if (selectedArray.length <= 2) {
              return selectedArray.map(getOptionLabel).join(", ");
            } else {
              return `${selectedArray.length} items selected`; 
            }
          }}
          defaultValue={blankArr}
          value={value || blankArr}
          name={name}
          onChange={onSelectValue}
          data-test-id={"dropdown-"}
          multiple
        >
          <MenuItem value="">{title}</MenuItem>
          {options &&
            options.length > 0 &&
            options.map((option: any) => {
              return (
                <MenuItem key={option.id} value={allowedNames.has(name) ? option.id : option.name}>
                  {option.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FilterDropDown;
