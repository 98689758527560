import React, { useEffect, useState } from "react";
import  { Box} from "@material-ui/core";

const TextFileViewer = ({ mediaFile }: { mediaFile: string }) => {
  const [fileContent, setFileContent] = useState<string>("");

  useEffect(() => {
    fetch(mediaFile)
      .then((response) => response.text())
      .then((text) => setFileContent(text))
      .catch((error) => console.error("Error fetching text file:", error));
  }, [mediaFile]);

  return (
    <Box className="text-file-preview" style={{ width: "186px", height: "158px", overflow: "auto", borderRadius: "12px", padding: "8px"}}>
      <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{fileContent}</pre>
    </Box>
  );
};

export default TextFileViewer;
