// Customizable Area Start
import React, { useState, useMemo } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Switch, ListSubheader, InputAdornment, Box } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import { checkBlack } from "../assets";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import i18n from "../../../../web/src/utilities/i18n";

const blankArr: any = [];

const containsText = (text: string, searchText: string) =>
  text?.toLowerCase()?.indexOf(searchText?.toLowerCase()) > -1;

export default function TeamMembersSort(props: any) {
  const {
    classes,
    hideDeactivateColumn,
    selectTeamOptions,
    selectJobOptions,
    onSelectedOptions,
    selectedTeam,
    selectedRole,
    selectedTitle,
    clearAction,
    deActivated,
    ondeActivatedChange,
  } = props;

  const teamNames = useMemo(
    () =>
      selectTeamOptions.map(
        (item: { attributes: { id: string; title: string } }) => ({
          id: item.attributes.id,
          title: item.attributes.title,
        })
      ),
    [selectTeamOptions]
  );

  const uniqueJobRoles = useMemo(
    () => [
      ...new Set(
        selectJobOptions.map(
          (item: { attributes: { job_role: string } }) =>
            item.attributes.job_role
        )
      ),
    ],
    [selectJobOptions]
  );

  const uniqueJobTypes = useMemo(
    () => [
      ...new Set(
        selectJobOptions.map(
          (item: { attributes: { job_type: string } }) =>
            item.attributes.job_type
        )
      ),
    ],
    [selectJobOptions]
  );

  const [searchText, setSearchText] = useState("");
  const [searchJobRoleText, setJobRoleText] = useState("");
  const [searchJobTypeText, setJobTypeText] = useState("");

  const displayTeamOptions = useMemo(
    () =>
      teamNames.filter((obj: { title: string }) =>
        containsText(obj.title, searchText)
      ),
    [teamNames, searchText]
  );

  const displayJobRoleOptions = useMemo(
    () =>
      uniqueJobRoles.filter((obj: any) => containsText(obj, searchJobRoleText)),
    [uniqueJobRoles, searchJobRoleText]
  );

  const displayJobTypesOptions = useMemo(
    () =>
      uniqueJobTypes.filter((obj: any) => containsText(obj, searchJobTypeText)),
    [uniqueJobTypes, searchJobTypeText]
  );
 
  const t = (key:any, variables?: Record<string, any>) => {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }

  const getLabel = (value: string[], def: string = "") => {
    const selectedItemCount = value.length;

    const hasUndefinedOrNull = value.some(item => item === undefined || item === null);
   
    if (selectedItemCount === 0) {
      return def || "Choose Option";
    } else if (selectedItemCount === 1 && def === "Team") {
      const result = displayTeamOptions.find((obj: any) => obj.id === value[0]);
      const myres = result ? result.title : "Title Not Found";
      if(myres === "Title Not Found"){
        return;
      }
      return   result.title;
    } else if (selectedItemCount === 1 && def === "Choose Role") {
     return value;
    } else if (selectedItemCount === 1) {
      return value;
    } else if (selectedItemCount > 1 && def === "Team") {
      if(hasUndefinedOrNull){
        return <>{selectedItemCount - 1 } teams selected</>;
      }
      return <>{selectedItemCount} teams selected</>;
    }
    else if (selectedItemCount > 1) {
      if(hasUndefinedOrNull){
        return <>{selectedItemCount - 1 } items selected</>;
      }
      return <>{selectedItemCount} items selected</>;
    }
  };

  const mediumScreen = useMediaQuery('(max-width:599px)');

  return (
    <Box className="top-middle">
      <Box className="table-selection">
        <FormControl className="select-control multi-select" variant={mediumScreen ? 'outlined' : 'standard'}>
          {selectedTeam.length > 1 && (
            <ClearIcon onClick={()=>clearAction( "selectedTeamVal")} className="multiple-clear" />
          )}
          <InputLabel className="multiselect-label" id="team-multiple-checkbox">{t("Team")}</InputLabel>
          <Select
            labelId="team-multiple-checkbox-label"
            id="team-multiple-checkbox"
            multiple
            value={selectedTeam}
            defaultValue={blankArr}
            onChange={(event, type) =>
              onSelectedOptions(event, "selectedTeamVal")
            }
            onClose={() => setSearchText("")}
            renderValue={() => getLabel(selectedTeam, "Team")}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: { paper: `${classes.dropdownStyle} multiSelect` },
            }}
            displayEmpty
            data-testid="memberTeamFilter"
          >
            <ListSubheader>
              <CustomInputWeb
                placeholder={t("Search here")}
                fullWidth={true}
                autoFocus={true}
                onChange={(e) => setSearchText(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>

            {displayTeamOptions.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                <ListItemText primary={option.title} />
                <Box className="multiselect-check">
                  <img src={checkBlack} alt="sort-group" />
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="select-control multi-select" variant={mediumScreen ? 'outlined' : 'standard'}>
          {selectedTitle.length > 1 && (
            <ClearIcon onClick={()=>clearAction( "selectedTitleVal")} className="multiple-clear" />
          )}
          <InputLabel className="multiselect-label" id="jobtitle-multiple-checkbox">{t("Choose Role")}</InputLabel>
          <Select
            labelId="jobtitle-multiple-checkbox-label"
            id="jobtitle-multiple-checkbox"
            multiple
            value={selectedTitle}
            defaultValue={blankArr}
            onChange={(event, type) =>
              onSelectedOptions(event, "selectedTitleVal")
            }
            onClose={() => setJobTypeText("")}
            renderValue={(selected) => getLabel(selectedTitle, t("Choose Role"))}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: { paper: `${classes.dropdownStyle} multiSelect` },
            }}
            displayEmpty
            data-testid="memberTitleFilter"
          >
            <ListSubheader>
              <CustomInputWeb
                placeholder={t("Search here")}
                fullWidth={true}
                autoFocus={true}
                onChange={(e) => setJobTypeText(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {displayJobTypesOptions.map((jobTitle: any) => (
              <MenuItem key={jobTitle} value={jobTitle}>
                <ListItemText primary={jobTitle} />
                <Box className="multiselect-check">
                  <img src={checkBlack} alt="sort-group" />
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className="select-control multi-select" variant={mediumScreen ? 'outlined' : 'standard'}>
          {selectedRole.length > 1 && (
            <ClearIcon onClick={()=>clearAction( "selectedRoleVal")} className="multiple-clear" />
          )}
          <InputLabel className="multiselect-label" id="jobrole-mutiple-checkbox">{t("Job Title")}</InputLabel>
          <Select
            labelId="jobrole-mutiple-checkbox-label"
            id="jobrole-mutiple-checkbox"
            multiple
            value={selectedRole}
            defaultValue={blankArr}
            onChange={(event, type) =>
              onSelectedOptions(event, "selectedRoleVal")
            }
            onClose={() => setJobRoleText("")}
            renderValue={(selected) => getLabel(selectedRole, t("Job Title"))}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: { paper: `${classes.dropdownStyle} multiSelect` },
            }}
            displayEmpty
            data-testid="memberRoleFilter"
          >
            <ListSubheader>
              <CustomInputWeb
                placeholder={t("Search here")}
                fullWidth={true}
                autoFocus={true}
                onChange={(e) => setJobRoleText(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {displayJobRoleOptions.map((jobRole: any) => (
              <MenuItem key={jobRole} value={jobRole}>
                <ListItemText primary={jobRole} />
                <Box className="multiselect-check">
                  <img src={checkBlack} alt="sort-group" />
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        
        {!hideDeactivateColumn && (
          <Box className={classes.switchWrapper}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="start"
                  checked={deActivated}
                  control={<Switch data-testid="deactivatedSwitch" onClick={ondeActivatedChange} />}
                  label={t("Deactivated only")}
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
          </Box>
        )}
      </Box>
    </Box>
  );
}
// Customizable Area End
