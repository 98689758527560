// Customizable Area Start
import React from "react";
import { Box, InputAdornment } from "@material-ui/core";
import { attachment, close, sendIcon } from "../../blocks/LiveChat2/src/assets";
import CustomInputContent from "./CustomInput.web";
import { configJSON } from "../../blocks/LiveChat2/src/LiveChat2Controller.web";
import { FileDrop } from "react-file-drop";

interface CustomInputProps {
  filesUploaded: File[] | null;
  previews: string[];
  handleCloseAttachment: () => void;
  translateChat: any;
  chatTxtMessage: string;
  handleAllInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBrowse: () => void;
  formRef: React.RefObject<HTMLFormElement>;
  fileRef: React.RefObject<HTMLInputElement>;
  handleSubmitMessage: () => void;
  handleOnDrop: (event: React.ChangeEvent<HTMLInputElement>) => void;
  messageId: string;
  createExternalAndInternalChatMessgaeEvent?: () => void;
  chatType?: string;
  handleKeyDown: (event: React.KeyboardEvent<Element>) => void;
}

export const ChatImagePreview: React.FC<CustomInputProps> = (
  props: CustomInputProps
) => {
  const {
    previews,
    filesUploaded,
    handleCloseAttachment,
    formRef,
    fileRef,
    handleOnDrop,
    handleSubmitMessage,
    translateChat,
    chatTxtMessage,
    handleAllInputChange,
    onBrowse,
    handleKeyDown,
    messageId,
    chatType,
    createExternalAndInternalChatMessgaeEvent,
  } = props;
  const file = filesUploaded && filesUploaded[0];
  const fileType = file && file.type.split("/")[0];
  return (
    <>
      {filesUploaded &&
        previews.map((preview: string, index: number) => {
          switch (fileType) {
            case "image":
              return (
                <Box key={preview} className="image-video-audio-preview">
                  <Box className="close-icon">
                    <img
                      src={close}
                      alt="close"
                      onClick={handleCloseAttachment}
                    />
                  </Box>
                  <img
                    src={preview}
                    alt="file preview"
                    className="preview-image"
                  />
                </Box>
              );
            case "audio":
              return (
                <Box key={preview} className="image-video-audio-preview">
                  <Box className="close-icon">
                    <img
                      src={close}
                      alt="close"
                      onClick={handleCloseAttachment}
                    />
                  </Box>
                  <audio
                    key={index}
                    src={preview}
                    controls
                    className="preview-image"
                  />
                </Box>
              );
            case "video":
              return (
                <Box key={preview} className="image-video-audio-preview">
                  <Box className="close-icon">
                    <img
                      src={close}
                      alt="close"
                      onClick={handleCloseAttachment}
                    />
                  </Box>
                  <video
                    key={index}
                    src={preview}
                    controls
                    className="preview-image"
                  />
                </Box>
              );
            default:
              return (
                <Box key={preview} className="image-video-audio-preview">
                  <Box className="close-icon">
                    <img
                      src={close}
                      alt="close"
                      onClick={handleCloseAttachment}
                    />
                  </Box>
                  <img
                    src={preview}
                    alt="No Preview"
                    className="preview-image"
                  />
                </Box>
              );
          }
        })}
      {messageId && (
        <CustomInputContent
          type="text"
          placeholder={translateChat(`${configJSON.typeMessage}`)}
          value={chatTxtMessage}
          onChange={handleAllInputChange}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          name="chatTxtMessage"
          data-test-id="chatTxtMessage"
          endAdornment={
            <>
              <FileDrop>
                <InputAdornment position="end" data-test-id="attachment-img">
                  <img
                    className="attachment-img"
                    src={attachment}
                    alt="attachmenticon"
                    onClick={onBrowse}
                  />
                  <form ref={formRef}>
                    <input
                      type="file"
                      onChange={(event) => {
                        if (event.preventDefault) {
                          event.preventDefault();
                        }
                        handleOnDrop(event);
                      }}
                      hidden
                      ref={fileRef}
                      data-test-id="fileInput"
                    />
                  </form>
                </InputAdornment>
              </FileDrop>
              <InputAdornment
                position="end"
                data-test-id={`handleSubmitMessage`}
                onClick={
                  chatType === "etoh_shop_support"
                    ? handleSubmitMessage
                    : createExternalAndInternalChatMessgaeEvent
                }
              >
                <img className="send-img" src={sendIcon} alt="attachmenticon" />
              </InputAdornment>
            </>
          }
        />
      )}
    </>
  );
};

export default ChatImagePreview;
// Customizable Area End
