import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import i18n from "../../../web/src/utilities/i18n";
import { toast } from "react-toastify";
import React from "react";
import { shoppingCartIcon } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  viewDetails: any;
  isImageView: boolean;
  currentIndex: any;
  isCartOpen: boolean;
  quantity: any;
  undoId: any;
  subscriptionDuration: any;
  subscriptionPrices: any;
  subscriptionDurations: any;
  isCart: boolean;
  currentId: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrdermanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionData: any;
  userToken: any;
  getSingleEtohProductRequestId: string = "";
  addToCartRequestApiID: string = "";
  deleteCartItemRequestID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      isLoading: false,
      viewDetails: {
        attributes: {},
      },
      isImageView: false,
      currentIndex: 0,
      isCartOpen: false,
      quantity: 1,
      undoId: "",
      subscriptionDuration: "weekly",
      subscriptionPrices: {
        weekly: {
          price: 0,
          salePrice: 0,
        },
        monthly: {
          price: 0,
          salePrice: 0,
        },
        yearly: {
          price: 0,
          salePrice: 0,
        },
      },
      subscriptionDurations: {
        weekly: "Weekly",
        monthly: "Monthly",
        yearly: "Yearly",
      },
      isCart: false,
      currentId:0,
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    if (this.userSessionData) {
      this.userToken = JSON.parse(this.userSessionData);
    }

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.error) {
        this.setState({ isLoading: false });
        return;
      } else {
        switch (apiRequestCallId) {
          case this.getSingleEtohProductRequestId:
            this.setState({ isLoading: false });
            this.setState({ viewDetails: responseJson.data });
            const {
              converted_price_weekly,
              converted_sale_price_weekly,
              converted_price_monthly,
              converted_sale_price_monthly,
              converted_price_yearly,
              converted_sale_price_yearly
            } = responseJson.data.attributes;

            this.setState({
              subscriptionPrices: {
                weekly: {
                  price: converted_price_weekly,
                  salePrice: converted_sale_price_weekly,
                },
                monthly: {
                  price: converted_price_monthly,
                  salePrice: converted_sale_price_monthly,
                },
                yearly: {
                  price: converted_price_yearly,
                  salePrice: converted_sale_price_yearly,
                },
              },
            });
            break;
          case this.addToCartRequestApiID:
            this.setState({ isLoading: false });
            if (responseJson.data) {
              this.setState({ undoId: responseJson.data.id });
              this.createToastNotificationSuccess(
                "Added to Cart",
                shoppingCartIcon
              );
            } else {
              this.createToastNotification(responseJson.message);
            }
            break;
          case this.deleteCartItemRequestID:
            this.setState({ isLoading: false });          
            this.setState({ undoId: "" });
            break;
          default:
            this.parseApiCatchErrorResponse(this.t(errorResponse));
            break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    const pageLink = window.location.pathname;
    const id = pageLink.split("/").pop();
    this.getSingleEtohProduct(id);
    const lang = localStorage.getItem("lang") ?? "en";
    await (i18n as any).changeLanguage(lang);
  }

  t(key: any) {
    return (i18n as any).t(key, { ns: "translation" });
  }

  createToastNotification = (toastMessage: string) => {
    toast.success(this.t(`${toastMessage}`), {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  handleBackBtn = () => {
    window.history.back();
  };

  handleSubscriptionChange = (event: any) => {
    this.setState({ subscriptionDuration: event.target.value as string });
  };

  getSingleEtohProduct = (id: any) => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const endpoint = `${configJSON.singleDetailsApi}/${id}`;

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleEtohProductRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  viewImageModal = (itemId: number) => {
    const index = this.state.viewDetails.attributes.thumbnails.findIndex((thumbnail: {
      id: number;
      filename: string;
      url: string;
    }) => thumbnail.id === itemId);
    this.setState({ currentIndex: index, currentId: itemId, isImageView: true });
  };
  handleAfterChange = (currentSlide: number) => {
    const {viewDetails} = this.state;
    const currentImage = viewDetails.attributes.thumbnails[currentSlide];
    this.setState({ currentIndex: currentSlide, currentId: currentImage?.id });
  };
  viewImageModalClose = () => {
    this.setState({ isImageView: false });
  };
  handleOpenCart = () => {
    this.setState({ isCartOpen: true });
  };
  handleCloseCart = () => {
    this.setState({ isCartOpen: false });
  };
  handleremoveCartbutton = (deleteID: string) => {
    if (this.state.undoId == deleteID) {
      this.setState({ undoId: "" });
    }
  };  
  handleAdd = () => {
    this.setState((prevState) => ({
      quantity: prevState.quantity + 1,
    }));
  };

  handleSubtract = () => {
    if (this.state.quantity > 1) {
      this.setState((prevState) => ({
        quantity: prevState.quantity - 1,
      }));
    }
  };

  createToastNotificationSuccess = (
    toastMesssage: string,
    toastIcon: string
  ) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{this.t(`${toastMesssage}`)}</div>
        <div          
          data-test-id="undoApiAction"
          className="toast-link"
          onClick={() => this.removeCartItem(this.state.undoId)}
        >
          {this.t("undo")}
        </div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        icon: () => <img src={toastIcon} alt="emptydata" />,
      }
    );
  };
  addToCart = (id: any) => {
    const { subscriptionPrices, subscriptionDuration, viewDetails } =
      this.state;
    const selectedPrice = subscriptionPrices[subscriptionDuration].price;
    const selectedSalePrice =
      subscriptionPrices[subscriptionDuration].salePrice;
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json",
    };
    const body = {
      bx_block_catalogue_service_id: id,
      quantity: this.state.quantity,
      price:
        viewDetails.attributes.service_type === "Subscription"
          ? selectedPrice
          : viewDetails.attributes.converted_price,
      sale_price:
        viewDetails.attributes.service_type === "Subscription"
          ? selectedSalePrice
          : viewDetails.attributes.converted_sale_price,
      duration: viewDetails.attributes.service_type === "Subscription" ? subscriptionDuration : null,
      is_cart: true,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addToCartRequestApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToCartApiId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  removeCartItem = (itemId: any) => {
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json",
    };
    const body = {
      cart_id: [itemId],
      // isCart: this.state.isCart,
    };
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCartItemRequestID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteCartApi}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
