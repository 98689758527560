import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  popoveAnchorEl:any;
  popoverClose:() => void;
  getAllCampaingsList:() => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isMenuDrawerOpened: boolean;
  anchorEl: any;
  popoveAnchorEl: any;
  createCampaignEl: any;
  createEmailCampaignForm: boolean;
  activeStep: number;
  floatEmailCampaign: boolean;
  stepChangeValue: number;
  gAEnabled: boolean;
  EmbedImagesEnable: boolean;
  attachmentEnable: boolean;
  mirrorLinkEnable: boolean;
  isContentVisible: boolean;
  campaignScheduledate: any;
  campaignScheduleTime: any;
  campaignTitle: string;
  fromEmailAddress: string;
  campaignSubjectTxt: string;
  campaignPreviewTxt: string;
  campaignErrors: {
    campaignTitle: string;
    fromEmailError: string;
    fromNameError: string;
    subjectTextError: string;
    previewTextError: string;

  };
  scheduleDateError: string;
  scheduleTimeError: string;
  sentCampaignList: any;
  isLoading: boolean;
  openViewCampaignModal: boolean;
  emailCampaignType: string;
  showStepperTimeline: boolean;
  previewTemplate: boolean;
  showTimePicker: boolean;
  hours: string;
  minutes: string;
  seconds: string;
  sortCampgnVal: string;
  isSorting: boolean;
  brevoContactLists: any;
  campaignListIds: any;
  emailTemplatesList: any;
  campaignId: string;
  campaignListCount: string;
  utmTrackingValue: string;
  fromName: string;
  sendersList: any[];
  searchListValue: string;
  isSearchLists:boolean;
  sortListDirection: string;
  isSortingLists: boolean;
  campaignTemplateId: string;
  totalRecipientsCount: number;
  attachmentsUploaded: any;
  isScheduling: boolean;
  showEmailCampaignForm: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateEmailCampaignController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCampaignsRequestId: string = "";
  createEmailCampaignRequestId: string = "";
  getContactListsRequestId: string = "";
  validateBrevoAPIRequestId: string = "";
  getEmailTemplateListAPIRequestId: string = "";
  getSenderListAPIRequestId: string = "";
  popoverRef: React.RefObject<any>;
  userSessionData: any;
  userToken: any;
  fileRef: React.RefObject<HTMLInputElement>;
  formRef: React.RefObject<HTMLFormElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.popoverRef = React.createRef();
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isMenuDrawerOpened: false,
      anchorEl: null,
      createCampaignEl: false,
      popoveAnchorEl: null,
      createEmailCampaignForm: false,
      activeStep: 0,
      floatEmailCampaign: false,
      stepChangeValue: 1,
      gAEnabled: false,
      EmbedImagesEnable: false,
      attachmentEnable: false,
      mirrorLinkEnable: false,
      isContentVisible: false,
      campaignScheduledate: "",
      campaignScheduleTime: "",
      campaignTitle: "",
      fromEmailAddress: "",
      campaignSubjectTxt: "",
      campaignPreviewTxt: "",
      campaignErrors: {
        campaignTitle: "",
        fromEmailError: "",
        fromNameError: "",
        subjectTextError: "",
        previewTextError: "",
      },
      scheduleDateError: "",
      scheduleTimeError: "",
      sentCampaignList: [],
      campaignListCount: "",
      isLoading: false,
      openViewCampaignModal: false,
      emailCampaignType: "sent",
      showStepperTimeline: false,
      previewTemplate: false,
      showTimePicker: false,
      hours: "HH",
      minutes: "MM",
      seconds: "SS",
      sortCampgnVal: "",
      isSorting: false,
      brevoContactLists: [],
      
      campaignListIds: [],
      emailTemplatesList: [],
      campaignId: "",
      utmTrackingValue: "",
      fromName: "",
      sendersList: [],
      searchListValue: "",
      isSearchLists: false,
      sortListDirection: "",
      isSortingLists: false,
      campaignTemplateId: '',
      totalRecipientsCount: 0,
      attachmentsUploaded: [],
      isScheduling: false,
      showEmailCampaignForm: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.fileRef = React.createRef();
    this.formRef = React.createRef();

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse || !responseJson || !apiRequestCallId) {
        return;
      }
      switch (apiRequestCallId) {
        case this.createEmailCampaignRequestId:
          this.setState({ isLoading: false });
          if (responseJson.error) {
            this.createToastNotificationError(responseJson.error);
          } else {
            this.setState({
              campaignTitle: "",
              fromEmailAddress: "",
              campaignListIds: "",
              campaignSubjectTxt: "",
              utmTrackingValue: "",
              fromName: "",
              campaignScheduledate: "",
              campaignScheduleTime: "",
              campaignPreviewTxt: "",
              scheduleDateError: "",
              scheduleTimeError: "",
              activeStep:0,
              stepChangeValue:1,
            });
            this.createToastNotificationSuccess("New email campaign created");
            this.props.getAllCampaingsList();
            this.props.popoverClose();
          }

          break;
        case this.getContactListsRequestId:
          this.setState({ brevoContactLists: responseJson.data.lists });
          this.setState({ isLoading: false });
          break;
        
        case this.getEmailTemplateListAPIRequestId:
          this.handleTemplateResponse(responseJson)
          
          break;
        case this.getSenderListAPIRequestId:
          this.setState({ sendersList: responseJson.data.senders });

          break;
        
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const langT = localStorage.getItem("lang") || "en";
    await (i18n as any).changeLanguage(langT);
    this.getAllListsfromBrevo();
    this.getEmailTemplateLists();
    this.getSendersList();
  }
  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.campaignTitle !== this.state.campaignTitle) {
      this.setState({
        campaignErrors: {
          ...this.state.campaignErrors,
          campaignTitle: "",
        },
      });
    }
    if (prevState.fromEmailAddress !== this.state.fromEmailAddress) {
      this.setState({
        campaignErrors: {
          ...this.state.campaignErrors,
          fromEmailError: "",
        },
      });
    }
    if (prevState.fromName !== this.state.fromName) {
      this.setState({
        campaignErrors: {
          ...this.state.campaignErrors,
          fromNameError: "",
        },
      });
    }
    if (prevState.campaignSubjectTxt !== this.state.campaignSubjectTxt) {
      this.setState({
        campaignErrors: {
          ...this.state.campaignErrors,
          subjectTextError: "",
        },
      });
    }
    if (prevState.campaignPreviewTxt !== this.state.campaignPreviewTxt) {
      this.setState({
        campaignErrors: {
          ...this.state.campaignErrors,
          previewTextError: "",
        },
      });
    }
  }
  t(key: any, variable?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation", ...variable });
  }

  
  previewCampaignTemplate = () => {
    this.setState({ previewTemplate: true });
  };
  useEmailCampaign = (templateId:string) => {
    this.setState({
      campaignTemplateId : templateId
    })
  }
  closePreviewTemplate = () => {
    this.setState({ previewTemplate: false });
  };
  hoursChange = (event: any) => {
    let newValue = event.target.value;
    // Validate input: Allow only numbers and limit the value to 0-999
    newValue = newValue.replace(/\D/g, "");
    if (Number(newValue) > 24) {
      newValue = this.state.hours;
    }
    newValue = String(Number(newValue));
    if (Number(newValue < 10)) {
      newValue = "0" + newValue;
    }
    this.setState({ hours: newValue });
  };
  minutesChange = (event: any) => {
    let newValue = event.target.value;
    // Validate input: Allow only numbers and limit the value to 0-999
    newValue = newValue.replace(/\D/g, "");
    if (Number(newValue) > 60) {
      newValue = this.state.minutes;
    }
    newValue = String(Number(newValue));
    if (Number(newValue < 10)) {
      newValue = "0" + newValue;
    }
    this.setState({ minutes: newValue });
  };
  secondsChange = (event: any) => {
    let newValue = event.target.value;
    // Validate input: Allow only numbers and limit the value to 0-999
    newValue = newValue.replace(/\D/g, "");
    if (Number(newValue) > 60) {
      newValue = this.state.seconds;
    }
    newValue = String(Number(newValue));
    if (Number(newValue < 10)) {
      newValue = "0" + newValue;
    }
    this.setState({ seconds: newValue });
  };
  setHours = () => {
    let totalHours = "";
    if (
      this.state.hours !== "" ||
      this.state.minutes !== "" ||
      this.state.seconds !== ""
    ) {
      totalHours = this.state.hours == "" ? "00" : this.state.hours;
      totalHours += ":";
      totalHours += this.state.minutes == "" ? "00" : this.state.minutes;
      totalHours += ":";
      totalHours += this.state.seconds == "" ? "00" : this.state.seconds;
    }

    this.setState({
      campaignScheduleTime: totalHours,
      showTimePicker: false,
    });
  };
  

  handleNext = () => {
    const { campaignErrors, campaignTitle, fromName, campaignSubjectTxt, campaignPreviewTxt, activeStep, stepChangeValue } = this.state;
  
    let errorFlag = false;
    let titleError = "";
    let emailError = "";
    let nameError = "";
    let subjectTextError = "";
    let previewTextError = "";
    switch (activeStep) {
      case 0:
        if (campaignTitle.trim() === "") {
          titleError = "Please enter campaign title";
          errorFlag = true;
        }
        if (campaignTitle.length > 128) {
          titleError = "Title cannot be more than 128 characters";
          errorFlag = true;
        }
        break;
  
      case 1:
        if (fromName.trim() === "") {
          nameError = "Please enter sender name";
          errorFlag = true;
        }
        break;
  
      case 3:
        if (campaignSubjectTxt.trim() === "") {
          subjectTextError = "Please enter Subject line";
          errorFlag = true;
        }
        if (campaignSubjectTxt.length > 200) {
          subjectTextError = "Subject line cannot be more than 200 characters";
          errorFlag = true;
        }
        if (campaignPreviewTxt.length > 200) {
          previewTextError = "Preview text cannot be more than 200 characters";
          errorFlag = true;
        }
        break;
      default:
        break;
    }
    if (errorFlag) {
      this.setState({
        campaignErrors: {
          ...campaignErrors,
          campaignTitle: titleError,
          fromEmailError: emailError,
          fromNameError: nameError,
          subjectTextError: subjectTextError,
          previewTextError: previewTextError,
        },
      });
      return true;
    }
  
    this.setState({
      activeStep: activeStep + 1,
      stepChangeValue: stepChangeValue + 1,
    });
  };
  
  

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };
  openFloatCampaign = () => {
    this.setState({ floatEmailCampaign: true, showEmailCampaignForm: false });
  };
  openCreateEmailCampaignForm = () => {
    this.setState({ showEmailCampaignForm: true, floatEmailCampaign: false });
  };
  handleEscCampaign = (event: any) => {
    event.stopPropagation();
    if (event.key === "Escape") {
      this.openFloatCampaign();
    }
  }
  closeFloatEmailCampaign = () => {
    this.setState({ floatEmailCampaign: false });
  };
  handleStepChange = (event: { target: { value: any } }) => {
    this.setState({ showStepperTimeline: true });
    this.setState({ stepChangeValue: event.target.value });
    this.setState({ activeStep: event.target.value - 1 });
  };
  handleAdvancedCampaignSettings = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name = event.target.name;
    const checked = event.target.checked;
    switch (name) {
      case "GoogleAnalytics":
        this.setState({ gAEnabled: checked });
        break;
      case "EmbedImages":
        this.setState({ EmbedImagesEnable: checked });
        break;
      case "addAttachment":
        this.setState({ attachmentEnable: checked });
        break;
      case "mirrolLink":
        this.setState({ mirrorLinkEnable: checked });
        break;
    }
  };
  scheduleCampaignDate = (date: any) => {
    this.setState({
      campaignScheduledate: date?.isValid ? date.format("YYYY-MM-DD") : "",
    });
  };

  showTimePickerModal = (time: any) => {
    this.setState({
      showTimePicker: true,
    });
  };
  closeTimePickerModal = (time: any) => {
    this.setState({
      showTimePicker: false,
    });
  };



  handleCampaignFormInputs = (name: any, value: string) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };
  handleSenderEmailValue = (event: { target: { value: any } }) => {
    if (event.target.value != undefined) {
      this.setState({ fromEmailAddress: event.target.value }, () =>
        this.preFillSenderName()
      );
    }
  };
  preFillSenderName = () => {
    const { sendersList, fromEmailAddress } = this.state;
    const senderNAme = sendersList.find(
      (senderNAme) => senderNAme.email === fromEmailAddress
    );

    this.setState({
      fromName: senderNAme.name,
    });
  };
  

 

  
  
  handleBackButton = () => {
    const {activeStep, stepChangeValue} = this.state;
      this.setState({
        activeStep: activeStep - 1,
        stepChangeValue: stepChangeValue - 1,
      });
  }
  
  sendEmailCampaign = (campaignType: string) => {
    const {
      campaignTitle,
      fromEmailAddress,
      campaignListIds,
      campaignSubjectTxt,
      utmTrackingValue,
      fromName,
      campaignScheduledate,
      campaignScheduleTime,
      campaignTemplateId,
    } = this.state;

    let errorFlag = false;
    let scheduleDateError = this.state.scheduleDateError;
    let scheduleTimeError =  this.state.scheduleTimeError;
  
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    let body;

    if (campaignType === "Schedule Campaign") {
      if(campaignScheduledate.trim() === ""){
        scheduleDateError = "Please select the date";
        errorFlag = true;
      }
      if(campaignScheduleTime.trim() === ""){
        scheduleTimeError = "Please choose time";
        errorFlag = true;
      }
      
      if (errorFlag) {
        this.setState({
          scheduleDateError: scheduleDateError,
          scheduleTimeError: scheduleTimeError,
        });
        return true;
      }

      const timezoneOffset = "+05:30"; // Your desired timezone offset
      const scheduledAt = `${campaignScheduledate}T${campaignScheduleTime}${timezoneOffset}`;

      body = {
        campaign: {
          name: campaignTitle,
          subject: campaignSubjectTxt,
          sender: {
            name: fromName,
            email: fromEmailAddress,
          },
          template_id: campaignTemplateId,
          scheduled_at: scheduledAt,
          replyTo: "replyto@domain.com",
          toField: "{{contact.FIRSTNAME}} {{contact.LASTNAME}}",
          recipients: {
            list_ids: campaignListIds,
            //exclusion_list_ids: [2],
          },
          attachment_url: "",
          inline_image_activation: false,
          mirror_active: false,
          recurring: false,
          brevo_email_type: "classic",
          tag: "My Tag",
          header: "test header",
          footer: "test footer",
          utm_campaign: utmTrackingValue,
          params: {
            PARAMETER: "My param value",
            ADDRESS: "Seattle, WA",
            SUBJECT: "New Subject",
          },
          html_content: "",
          status: "queued",
        },
      };
    } else {
      body = {
        campaign: {
          name: campaignTitle,
          subject: campaignSubjectTxt,
          sender: {
            name: fromName,
            email: fromEmailAddress,
          },
          template_id: campaignTemplateId,
          status: "sent",
          replyTo: "replyto@domain.com",
          toField: "{{contact.FIRSTNAME}} {{contact.LASTNAME}}",
          recipients: {
            list_ids: campaignListIds,
            //exclusion_list_ids: [2],
          },
          attachment_url: "",
          inline_image_activation: false,
          mirror_active: false,
          recurring: false,
          brevo_email_type: "classic",
          tag: "My Tag",
          header: "test header",
          footer: "test footer",
          utm_campaign: utmTrackingValue,
          params: {
            PARAMETER: "My param value",
            ADDRESS: "Seattle, WA",
            SUBJECT: "New Subject",
          },
          html_content: "",
        },
      };
    }

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createEmailCampaignRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createEmailCampaign
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllListsfromBrevo = () => {
    const {isSearchLists,searchListValue,isSortingLists,sortListDirection} = this.state;
    let endPoint;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    let baseURL =  configJSON.getContactLists;
    if(isSearchLists){
      endPoint = baseURL + `?search_key=${searchListValue}`
    } else if(isSortingLists && sortListDirection !=="") {
      endPoint = baseURL + `?sort=name&sort_direction=${sortListDirection}`
    }else{
      endPoint = baseURL
    }
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactListsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

   

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  
  createToastNotificationSuccess = (toastMessage: string) => {
    toast.success(toastMessage, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  createToastNotificationError = (toastMessage: string) => {
    toast.error(toastMessage, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
 

  handleListsChange = (listId: string, recipientCount: number) => {
    this.setState((prevState) => {
      let updatedCampaignListIds = [...prevState.campaignListIds];
      const index = updatedCampaignListIds.indexOf(listId);
      if (index === -1) {
        updatedCampaignListIds.push(listId);
        const newTotalRecipientsCount = prevState.totalRecipientsCount + recipientCount;
        return {
          campaignListIds: updatedCampaignListIds,
          totalRecipientsCount: newTotalRecipientsCount,
        };
      }
      return {
        campaignListIds: updatedCampaignListIds,
        totalRecipientsCount: prevState.totalRecipientsCount,
      };
    });
  };
  
  
  getEmailTemplateLists = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEmailTemplateListAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTemplates
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSendersList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSenderListAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSenderList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  getSearchListValue = (searchValue:string) => {
    this.setState({isSearchLists : true, searchListValue : searchValue}, () => this.getAllListsfromBrevo())
  }
  handleSortByLists = (value:any) => {
    if(value === "A-Z"){
      this.setState({
        sortListDirection : 'asc'
      })
    }else if(value === "Z-A"){
      this.setState({
        sortListDirection : 'desc'
      })
    }else {
      this.setState({
        sortListDirection : ''
      })
    }
    this.setState({isSortingLists: true}, () => this.getAllListsfromBrevo())
  }
  onBrowse = () => {
    if (this.fileRef.current !== null) {
      this.fileRef.current.click();
    }
  }
  handleOnDropAttachments = (e:React.ChangeEvent<HTMLInputElement>) => { 
    let fileObject:FileList;
  if(e.target.files != null){
      fileObject= e.target.files;
        this.setState({attachmentsUploaded: [...this.state.attachmentsUploaded,...Object.values(fileObject)]});
  }
  };
  removeAttachment = (index:any) => {
    const updatedArray = [...this.state.attachmentsUploaded.slice(0, index), ...this.state.attachmentsUploaded.slice(index + 1)];
    this.setState({ attachmentsUploaded: updatedArray });
  }
  handleTemplateResponse = (responseJson:any) => {
    if(responseJson.data?.templates.length > 0){
      this.setState({ emailTemplatesList: responseJson.data.templates });
      }
  }

  // Customizable Area End
}
