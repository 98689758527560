import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./MetaStyles.web";

export const configJSON = require('./config')
const globalJSON = require('../../../framework/src/config')

export interface Props extends WithStyles<typeof customStyles> {
  navigation: any;
  id: string;
  classes: any;
  backToStartIntegration: any;
}

interface S {
  pageInsideMetrics: boolean;
  pageInsidePC: boolean;
  pageInsidePP: boolean;
  pageInsideCTA: boolean;
  pageInsidePI: boolean;
  pageInsidePPI: boolean;
  pageInsidePPE: boolean
}

interface SS {
  id: any;
}

export default class ChooseMetricsController extends BlockComponent<
  Props,
  S,
  SS
> {

  addMetricsRequestId: string = "";
  userSessionData: any;
  userToken: any;


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
    ];


    this.userSessionData = sessionStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);

    this.state = {
      pageInsideMetrics: true,
      pageInsidePC: false,
      pageInsidePP: false,
      pageInsideCTA: false,
      pageInsidePPI: true,
      pageInsidePI: false,
      pageInsidePPE: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage))

      if (apiRequestCallId == this.addMetricsRequestId) {
        const url = responseJson?.url;
        // window.open(url, '_blank', 'noopener,noreferrer');
        // return
      }
    }

  }

  // web events
  handleChangePIM = (event: any) => {
    this.setState({ pageInsideMetrics: event.target.checked });
  };

  handleChangPIPC = (event: any) => {
    this.setState({ pageInsidePC: event.target.checked });
  };

  handleChangPIPP = (event: any) => {
    this.setState({ pageInsidePP: event.target.checked });
  };

  handleChangeCTA = (event: any) => {
    this.setState({ pageInsideCTA: event.target.checked });
  };

  handleChangPPE = (event: any) => {
    this.setState({ pageInsidePPE: event.target.checked });
  };

  handleChangPI = (event: any) => {
    this.setState({ pageInsidePI: event.target.checked });
  };

  handleChangPPI = (event: any) => {
    this.setState({ pageInsidePPI: event.target.checked });
  };

  cancel = () => {
  };

  addMetrics = () => {
    // window.location.href="/choose-metrics"
    // console.log('goToMetrics Clicked')

    // this.props.navigation.navigate('ChooseMetrics');

    // const message = new Message(getName(MessageEnum.NavigationMessage));
    // message.addData(getName(MessageEnum.NavigationTargetMessage), "ChooseMetrics");
    // message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    // this.send(message);

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const trueKeys = Object.keys(this.state).filter((key) => {
      const value = this.state[key as keyof S];
      return value;
    });

    const metricsList = trueKeys.join(',');

    let endPoint = `${globalJSON.baseURL}${configJSON.addMetricsEndPoint}${metricsList}?code=AQAPW`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.addMetricsRequestId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint.toString()
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  };

}
