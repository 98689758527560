// Customizable Area Start
import React from "react";
import {
  Chip,
  Box,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Select,
  MenuItem,
  Modal,
  Button,
  Typography,
  Menu,
  CardMedia,
  Popover,
  InputAdornment,
  InputLabel
} from "@material-ui/core/";


const ViewContact = () => {
  return (
    <>
      {/* view contact modal start */}

     <>Test component</>
      

      
    </>
  );
};

export default ViewContact;