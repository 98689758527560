import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./CreateAccount.Web";
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id?: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  companyName: string;
  country: string;
  countryData: any[];
  countryName: any;
  stateName: any;
  stateData: any[];
  cityname: any;
  cityData: any[];
  city: string;
  postalcode: any;
  postalCodeError:string;
  companyAddress: string;
  otpAuthToken: string;
  registerToken: string;
  data: any[];
  countryCodeSelected: string;
  phone: string;
  errorMessage: any;
  error: {
    errorFirstnameNotValid: {
      message: string;
    };
    errorlastNameNotValid: {
      message: string;
    };
    errorcompanyNameNotValid: {
      message: string;
    };
    errorcountryNotValid: {
      message: string;
    };
    errorstateNotValid: {
      message: string;
    };
    errorcodeNotValid: {
      message: string;
    };
    errorcityNotValid: {
      message: string;
    };
    errorcompanyaddressNotValid: {
      message: string;
    };
    errorPostalCode:{
      message: string;
    };
  };
  companyaddress: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CreateAccountControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  countryAPICallId: any;
  stateCodeApiCallId: any;
  cityCodeApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  labelLastName: string;
  labelCompanyName: string;
  labelCountry: string;
  labelState: string;
  labelCity: string;
  labelPostalCode: string;
  labelCompanyAddress: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  createAccountlabelHeader: string;
  createAccountlabelHeaderHelperText: string;
  btnTextSignUp: string;
  createAccountSubmitBtn: string;

  currentCountryCode: any;
  selectedCountry: any;
  selectedState: any;
  userSessionData: any;
  userToken: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.userSessionData = sessionStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);

    this.state = {
      // Customizable Area Start
      firstName: this.userToken.data.attributes.first_name ?? "",
      lastName: this.userToken.data.attributes.last_name ?? "",
      companyName: "",
      country: "",
      countryData: [],
      countryName: "",
      stateName: "",
      stateData: [],
      cityname: "",
      cityData: [],
      city: "",
      postalcode: "",
      postalCodeError:"",
      companyAddress: "",
      otpAuthToken: "",
      registerToken: "",
      data: [],
      countryCodeSelected: "",
      phone: "",
      errorMessage: "",
      error: {
        errorFirstnameNotValid: {
          message: "",
        },
        errorlastNameNotValid: {
          message: "",
        },
        errorcompanyNameNotValid: {
          message: "",
        },
        errorcountryNotValid: {
          message: "",
        },
        errorstateNotValid: {
          message: "",
        },
        errorcodeNotValid: {
          message: "",
        },
        errorcityNotValid: {
          message: "",
        },
        errorcompanyaddressNotValid: {
          message: "",
        },
        errorPostalCode: {
          message: "",
        },
      },
      companyaddress: "",
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = /\w+/;
    this.emailReg = /\w+/;

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.labelLastName = configJSON.lastName;
    this.labelCompanyName = configJSON.labelCompanyName;
    this.labelCountry = configJSON.labelCountry;
    this.labelState = configJSON.labelState;
    this.labelCity = configJSON.labelCity;
    this.labelPostalCode = configJSON.labelPostalCode;
    this.labelCompanyAddress = configJSON.labelCompanyAddress;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.createAccountlabelHeader = configJSON.createAccountlabelHeader;
    this.createAccountlabelHeaderHelperText =
      configJSON.createAccountlabelHeaderHelperText;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.createAccountSubmitBtn = configJSON.createAccountSubmitBtn;
    this.selectedCountry = this.state.countryName;
    this.selectedState = 1;
    // if (
    //   this.userToken.data.attributes.original_owner &&
    //   this.userToken.data.attributes.profile_created===false
    // ) {
    //   this.props.navigation.navigate("CreateProfile");
    // } else {
    //   this.props.navigation.navigate("CustomisableUserProfiles");
    // }
    // Customizable Area End
  }
  btnLogout = () => {
    sessionStorage.removeItem("userData");
    this.props.navigation.navigate("EmailAccountLogin");
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );
            localStorage.setItem(
              "attributes",
              JSON.stringify(responseJson?.data?.attributes)
            );
            sessionStorage.removeItem("userData");
            sessionStorage.setItem(
              "userData",
              JSON.stringify({ ...responseJson, meta: this.userToken.meta })
            );
            this.props.navigation.navigate("OwnerOnboardingBlock");

            this.send(msg);
          } else {
            //Check Error Response
            this.setState({
              errorMessage:
                responseJson.errors[0].account ||
                responseJson.errors[0].message ||
                responseJson.errors
            });
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.countryAPICallId) {
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );

          this.setState({
            countryData: this.countryCodesToDropDown(responseJson.data),
          });
        } else if (apiRequestCallId === this.stateCodeApiCallId) {
          this.setState({ stateName: "" });
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (responseJson && !responseJson.errors) {
            this.setState({
              stateData: this.stateCodesToDropDown(responseJson.data),
            });
          } else {
            let errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            this.parseApiCatchErrorResponse(errorReponse);
          }
        } else if (apiRequestCallId === this.cityCodeApiCallId) {
          this.setState({ cityname: "" });
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (responseJson && !responseJson.errors) {
            this.setState({
              cityData: this.cityCodesToDropDown(responseJson.data),
            });
          } else {
            let errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            this.parseApiCatchErrorResponse(errorReponse);
          }
        }
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      let selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected: selectedCode,
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {  
    this.fetchCountries();
    const lang = localStorage.getItem("lang") ?? "en"; 
    await (i18n as any).changeLanguage(lang);   
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.countryName !== this.state.countryName) {
      // Now fetch the new data here.
      this.fetchStatesByCountry();
    }

    if (prevState.stateName !== this.state.stateName) {
      // Now fetch the new data here.
      this.fetchCityByStates(this.state.stateName);
    }
    if (prevState.firstName !== this.state.firstName) {
      this.setState({
        error: {
          ...this.state.error,
          errorFirstnameNotValid: {
            message: "",
          },
        },
      });
    }
    if (prevState.lastName !== this.state.lastName) {
      this.setState({
        error: {
          ...this.state.error,
          errorlastNameNotValid: {
            message: "",
          },
        },
      });
    }
    if (prevState.companyName !== this.state.companyName) {
      this.setState({
        error: {
          ...this.state.error,
          errorcompanyNameNotValid: {
            message: "",
          },
        },
      });
    }
    if (prevState.countryName !== this.state.countryName) {
      this.setState({
        error: {
          ...this.state.error,
          errorcountryNotValid: {
            message: "",
          },
        },
      });
    }
    if (prevState.stateName !== this.state.stateName) {
      this.setState({
        error: {
          ...this.state.error,
          errorstateNotValid: {
            message: "",
          },
        },
      });
    }
    if (prevState.cityname !== this.state.cityname) {
      this.setState({
        error: {
          ...this.state.error,
          errorcityNotValid: {
            message: "",
          },
        },
      });
    }
    if (prevState.companyAddress !== this.state.companyAddress) {
      this.setState({
        error: {
          ...this.state.error,
          errorcompanyaddressNotValid: {
            message: "",
          },
        },
      });
    }
  }

  countryCodesToDropDown = (data: any) => {
    return data.map((item: any) => ({
      label: `${item.country_name}`,
      value: item.id,
    }));
  };

  stateCodesToDropDown = (data: any) => {
    return data.map((item: any) => ({
      label: `${item.state_name}`,
      value: item.id,
    }));
  };
  cityCodesToDropDown = (data: any) => {
    return data.map((item: any) => ({
      label: `${item.city_name}`,
      value: item.id,
    }));
  };
  countriesHandler = (e: any) => {
    this.setState({ countryName: e.target.value });
  };
  statesHandler = (e: any) => {
    this.setState({ stateName: e.target.value });
  };
  citiesHandler = (e: any) => {
    this.setState({ cityname: e.target.value });
  };

  createAccount = () => {
    let errorFlag = false;
    let firstNameError = this.state.error.errorFirstnameNotValid;
    let lastNameError = this.state.error.errorlastNameNotValid;
    let companyNameError = this.state.error.errorcompanyNameNotValid;
    let countryNameError = this.state.error.errorcountryNotValid;
    let stateNameError = this.state.error.errorstateNotValid;
    let cityNameError = this.state.error.errorcityNotValid;
    let companyAddressError = this.state.error.errorcompanyaddressNotValid;
    let postalCodeError = this.state.error.errorPostalCode

    if (this.state.firstName.trim() === "") {
      firstNameError = { message: configJSON.errorFirstnameNotValid };
      errorFlag = true;
    }
    if (this.state.lastName.trim() === "") {
      lastNameError = { message: configJSON.errorlastNameNotValid };
      errorFlag = true;
    }
    if (this.state.companyName.trim() === "") {
      companyNameError = { message: configJSON.errorcompanyNameNotValid };
      errorFlag = true;
    }

    
    if (this.state.countryName.trim() === "") {
      countryNameError = { message: configJSON.errorcountryNotValid };
      errorFlag = true;
    }
    // if (this.state.stateName == "" || isNaN(this.state.stateName)) {
    //   stateNameError = { message: configJSON.errorstateNotValid };
    //   errorFlag = true;
    // }
    if (this.state.stateName.trim() === "" && this.state.stateData.length > 0) {
      stateNameError = { message: configJSON.errorstateNotValid };
      errorFlag = true;
    }

    if (this.state.companyAddress.trim() === "") {
      companyAddressError = { message: configJSON.errorcompanyaddressNotValid };
      errorFlag = true;
    }
    if (errorFlag) {
      this.setState({
        error: {
          errorFirstnameNotValid: firstNameError,
          errorlastNameNotValid: lastNameError,
          errorcompanyNameNotValid: companyNameError,
          errorcountryNotValid: countryNameError,
          errorstateNotValid: stateNameError,
          errorcityNotValid: cityNameError,
          errorcodeNotValid: { message: "" },
          errorcompanyaddressNotValid: companyAddressError,
          errorPostalCode:postalCodeError,
        },
      });
      return;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.userToken.meta.token,
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      company_place_address: this.state.companyAddress,
      country: this.state.countryName,
      city: this.state.cityname,
      state: this.state.stateName,
      postal_code: this.state.postalcode,
      company_name: this.state.companyName,
      activated: true,
    };

    // Convert the object to a JSON string
    const attrsJSON = JSON.stringify(attrs);

    // Store the JSON string in localStorage
    localStorage.setItem("userProfileDetails", attrsJSON);

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAccountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  fetchCountries = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryAPIEndPoint
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.userToken.meta.token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchStatesByCountry() {
    let country = this.state.countryData.find(
      (x: any) => x.label == this.state.countryName
    );
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.stateCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.statesAPIEndPoint + country.value
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.userToken.meta.token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  fetchCityByStates(value: any) {
    let state = this.state.stateData.find(
      (x: any) => x.label == this.state.stateName
    );
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cityCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cityAPIEndpoint + state.value
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.userToken.meta.token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  postalCodeHandler = (event:any) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 10) {
      this.setState({
        postalcode: event.target.value,
      })
    }

  }
  // Customizable Area End
}
