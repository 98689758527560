// Customizable Area Start
export const imgAppIcon1 = require("./assets/app-1.png")
export const imgAppIcon2 = require("./assets/app-2.png")
export const imgTranslate = require("./assets/translate.png")
export const imgSlider1 = require("./assets/slider-1.jpeg");
export const imgSlider2 = require("./assets/slider-2x4.png");
export const imgSlider3 = require("./assets/slider-3x4.png");
export const imgLogo = require("./assets/logo.png");
export const  logo = require("./assets/images/logo.png");
export const  logoSvg = require("./assets/images/etoh-logo.svg");
export const  goggle = require("./assets/images/google.png");
export const  eyeGray = require("./assets/images/eye-gray.png");
export const  appIcon1 = require("./assets/images/app-1.png");
export const  appIcon2 = require("./assets/images/app-2.png");
export const  translate = require("./assets/images/translate.png");
export const  imageIphone = require("./assets/images/image-iphone.png");
export const  sliderImg1 = require("./assets/images/slider-1.jpg");
export const  sliderImg2 = require("./assets/images/slider-2.png");
export const  sliderImg3 = require("./assets/images/slider-3.png");
export const  passwordReset = require("./assets/images/password-reset.png");
export const  modalImg = require("./assets/images/modal-img.png");
export const  appStore = require("./assets/images/app-store.png");
export const playStore = require("./assets/images/play-store.png");
export const lifeBuoyBlack = require("./assets/images/life-buoy-black.png");
export const notificationsBlack = require("./assets/images/notification-black.png");
export const settingBlack = require("./assets/images/setting-black.png");
export const checkBlack = require("./assets/images/check-black.png");
export const msgBlack = require("./assets/images/msg-black.png");
export const lockblack = require("./assets/images/lock-black.png");
export const calendarBlack = require("./assets/calendar-black.png");
export const connectBrevo = require("./assets/connect-account.png");
export const checkBoxEmpty = require("./assets/check-empty.png");
export const checkBoxFilled = require("./assets/check-filled.png");
export const arrowDown = require("./assets/arrow-down.svg");
export const warningIcon = require("./assets/warning-red.png");
export const wineImg = require("./assets/wine-img.png");

export const one = require("./home.png");
export const two = require("./man.png");
export const three = require("./grp.png");
export const four = require("./tool.png");
export const five = require("./tv.png");
export const six = require("./circular.png");


// Customizable Area End