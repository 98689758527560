import React from "react";
// Customizable Area Start
import OrderDetailsController, {
  Props,
  configJSON,
} from "./OrderDetailsController.web";
import { etohShopStyle } from "../../../components/src/EtohShopStyleHelper.web";
import WebLoader from "../../../components/src/WebLoader.web";
import { withStyles } from "@material-ui/core/styles";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import {
  Typography,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  Box
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { ShoppingCartOrders } from "../../shoppingcart/src/ShoppingCartOrders.web";
import ImageModal from "../../../components/src/OrderDetailsImageView.web";

const images = require("./assets")
// Customizable Area End

export class OrderDetails extends OrderDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      viewDetails,
      isImageView,
      currentIndex,
      isCartOpen,
      quantity,
      subscriptionDuration,
      subscriptionPrices,
      subscriptionDurations,
    } = this.state;
    const selectedPrice = subscriptionPrices[subscriptionDuration].price;
    const selectedSalePrice =
      subscriptionPrices[subscriptionDuration].salePrice;

    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.isLoading && <WebLoader />}
        <Box className={classes.orderDetailsRoot}>
          <Box className="header-content">
            <Box
              className="back-btn-content"
              data-test-id="handleBackBtn"
              onClick={this.handleBackBtn}
            >
              <img src={images.back} />
              <Typography className="back-txt">
                {this.t(`${configJSON.backText}`)}
              </Typography>
            </Box>
            <Box
              className="live-chat-box"
              data-test-id="handleOpenCart"
              onClick={this.handleOpenCart}
            >
              <ShoppingCartOutlinedIcon className="chat-icon" />
              <Typography className="live-btn-txt">
                {this.t(`${configJSON.cartTxt}`)}
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={3} className="main-container">
            <Grid item xs={6}>
              <Box className="image-wrapper">
              {viewDetails.attributes.thumbnails && viewDetails.attributes.thumbnails.length > 0 ? (
                  viewDetails.attributes.thumbnails.map((item: any) => (
                    <img
                      src={item.url}
                      alt="Images"
                      key={item.id}
                      className="thumbnail"
                      onClick={() => this.viewImageModal(item.id)}
                      data-test-id={`img-thumbnail-${item.id}`}
                    />
                  ))
                ) : (
                  <img
                    src={images.defaultServiceImage}
                    alt="Default Image"
                    className="thumbnail"
                    data-test-id="thumbnail-default"
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6} className="second-grid">
              <Typography className="main-heading">
                {viewDetails.attributes.title}
              </Typography>
              <Typography className="short-description">
                {viewDetails.attributes.short_description}
              </Typography>
              <Typography className="price-txt">
                {this.t(`${configJSON.priceTxt}`)}
              </Typography>
              <Box className="price-container">
                {viewDetails.attributes.service_type === "Subscription" ? (
                  <>
                    <Typography className="cart-price-text">
                      {selectedSalePrice}
                    </Typography>
                    <Typography className="price-dash-txt">
                      {selectedPrice}
                    </Typography>
                    <FormControl className="price-control">
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.dropdownStyle,
                          },
                        }}
                        IconComponent={(props) => (
                          <KeyboardArrowDown
                            style={{ color: "#94A3B8" }}
                            {...props}
                          />
                        )}
                        displayEmpty
                        disableUnderline
                        defaultValue={"Weekly"}
                        data-test-id="subscription-dropdown"
                        value={subscriptionDuration}
                        onChange={this.handleSubscriptionChange}
                        renderValue={(selected: unknown) => subscriptionDurations[selected as number]}                   
                      >
                        {Object.entries(subscriptionDurations).map(
                          ([key, value]: [any, any]) => (
                            <MenuItem value={key} key={key}>
                              {subscriptionPrices[key].salePrice} {value}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <>
                    <Typography className="cart-price-text">
                      {viewDetails.attributes.converted_sale_price}
                    </Typography>
                    <Typography className="price-dash-txt">
                      {viewDetails.attributes.converted_price}
                    </Typography>
                  </>
                )}
              </Box>
              {viewDetails.attributes.service_type === "Subscription" && (
                <Box className="card-quantity-container">
                  <Button
                    className={`secondary-btn ${classes.secondaryButtonn}`}
                    onClick={this.handleSubtract}
                    data-test-id="handleSubtract"
                  >
                    -
                  </Button>
                  <Typography className="cart-quantity">{quantity}</Typography>
                  <Button
                    className={`secondary-btn ${classes.secondaryButtonn}`}
                    onClick={this.handleAdd}
                    data-test-id="handleAdd"
                  >
                    +
                  </Button>
                </Box>
              )}
              {this.state.undoId ? (
                <Button
                  className={`secondary-btn-2 ${classes.secondaryButton}`}
                  onClick={() => this.removeCartItem(this.state.undoId)}
                  data-test-id="remove-from-cart-btn"
                >
                  {this.t(`${configJSON.removeFromCartTxt}`)}
                </Button>
              ) : (
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  onClick={() => this.addToCart(viewDetails.id)}
                  data-test-id="add-to-cart-btn"
                >
                  {this.t(`${configJSON.addToCartTxt}`)}
                </Button>
              )}

              <Typography className="cart-description">
                {this.t(`${configJSON.descriptionTxt}`)}
              </Typography>
              <Typography className="cart-description-txt">
                {viewDetails.attributes.description}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {isImageView && <ImageModal
          isOpen={isImageView}
          viewDetails={viewDetails}
          currentIndex={currentIndex}
          currentId={this.state.currentId}
          onClose={this.viewImageModalClose} 
          classes={this.props.classes}
          handleAfterChange={this.handleAfterChange}
          data-test-id="ImageModal"     
          /> }
        {isCartOpen && (
          <ShoppingCartOrders
            navigation={undefined}
            classes={this.props.classes}
            id="cart-id"
            handleCloseCartEvent={this.handleCloseCart}
            isCartOpen={this.state.isCartOpen}
            setCartState={(deleteID) => this.handleremoveCartbutton(deleteID)}
            data-test-id="shopping-cart"
          />
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(etohShopStyle, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(OrderDetails)
);
// Customizable Area End
