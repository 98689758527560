// Customizable Area Start
import React from "react";
import Box from "@material-ui/core/Box";
import { Typography,Modal } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  close,
  pencil
} from "../../blocks/ContactsList/src/assets";
import { configJSON } from "../../blocks/ContactsList/src/GroupListController.web";
import { getImageForSubCategory } from "./ReusableFunctions";

interface CustomInputProps {
  modalOpen: any;
  modalClose: any;
  classes: any;
  selectedProductsData: any;
  openEdit?:any;
  t:any;
}

const ProductViewModal = (props: CustomInputProps) => {
  const { modalOpen, classes, modalClose, selectedProductsData,openEdit , t } = props;
  return (
    <Modal
      open={modalOpen}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalBoxCenter}
    >
      <Box className={classes.productListViewModal}>
        <Box className="modal-heading">
          <Box className="heading-left">
            <Typography className="modal-title" component="h2">
             {selectedProductsData.length}&nbsp;
             {selectedProductsData.length>1? t("Products"): t("Product")}
            </Typography>
            <Box component="p" className="sub-txt">
              {t(`${configJSON.selectedItemModelTxt}`)}
            </Box>
          </Box>
          <Box className="heading-right">
            <Link to="#" className="heading-icon" onClick={openEdit}>
              <img src={pencil} alt="pencil-icon" />
            </Link>
            <Link to="#" className="heading-icon" onClick={modalClose}>
              <img src={close} alt="close-icon" />
            </Link>
          </Box>
        </Box>
        {selectedProductsData.map((product:any)=>{
          console.log(product)
         return <Box className="product-card" key={product.id}>
          {getImageForSubCategory(product.attributes.sub_category.id, product.attributes)}
         <Box className="card-content">
           <Typography className="headding">{product.attributes.name}</Typography>
           <Box className="retail-content">
             <Typography className="item-quantity">
               1 {t(`${configJSON.itemText}`)} @ ${(product?.attributes?.prices[0]?.amount || 0).toFixed(2)}
             </Typography>
             <Typography className="retail-price-txt">{t(`${configJSON.ShowAsOption1}`)}</Typography>
           </Box>
           <Box className="table-content">
             <table className="table-content-2">
               <thead>
                 <tr>
                   <th>{t(`${configJSON.ProducerTxt}`)}</th>
                   <th>{t(`${configJSON.brandTxt}`)}</th>
                 </tr>
               </thead>
               <tbody>
                 <tr>
                   <td>{product.attributes.meta_data.producer || ""}</td>
                   <td>{product.attributes.meta_data.brand || ""}</td>
                 </tr>
               </tbody>
             </table>
             <table className="table-content-2">
               <thead>
                 <tr>
                   <th>{t(`${configJSON.colorTxt}`)}</th>
                   <th>{t(`${configJSON.AppellationTxt}`)}</th>
                   <th>{t(`${configJSON.GrapeVarietyTxt}`)}</th>
                   <th>{t(`${configJSON.VintageTxt}`)}</th>
                 </tr>
               </thead>
               <tbody>
                 <tr>
                   <td>{product.attributes.meta_data.color || ""}</td>
                   <td>{product.attributes.location.appellation || ""}</td>
                   <td>{product.attributes.meta_data.grape_variety || ""}</td>
                   <td>1920</td>
                 </tr>
               </tbody>
             </table>
           </Box>
         </Box>
         <Box className="price-txt">
           <Typography className="price">${(product?.attributes?.prices[0]?.amount || 0).toFixed(2)}</Typography>
         </Box>
       </Box>
        })}
       
      </Box>
    </Modal>
  );
};

export default ProductViewModal;
// Customizable Area End
