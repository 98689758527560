// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Button,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  InputAdornment,
  ListItemText,
  Popover,
} from "@material-ui/core";
import {
  checkBoxEmpty,
  checkBoxFilled,
  checked,
  close,
  new_page,
} from "./assets";
import ProductViewModal from "../../../components/src/ProductViewModal.web";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import CreateContactController, {
  Props,
  configJSON,
} from "./CreateContactController.web";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import { customStyles } from "./ContactsListStyles.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProductList from "./ProductList.web";
import FreeSoloCreateOption from "../../../components/src/FreeSoloCreateOption.web";
import ConfirmActionModal from "../../email-account-registration/src/teams-web/ConfirmActionModal.web";
import { restrictKeys } from "../../../components/src/ReusableFunctions";
import { CustomSearchDropdown } from "../../../components/src/CustomSearchDropdown.web";

class CreateContact extends CreateContactController {
  constructor(props: Props) {
    super(props);
  }
  renderGroup() {
    const { classes } = this.props;
    let groupsData;

    if (this.state.isSearching) {
      groupsData = this.state.searchedGroup;
    } else {
      groupsData = this.state.groups;
    }
    return (
      <Box className="info-group">
        <Typography className="group-title">
          {this.t(`${configJSON.addToGroupTxt}`)}
        </Typography>
        <Box className="group-form" component="form">
          <Box className="form-info-wrapper">
            <Box className="form-row">
              <Box className="form-col">
                <FormControl className="select-outer" variant="outlined">
                  <InputLabel>{this.t(`${configJSON.chooseGroup}`)}</InputLabel>
                  <Select
                    labelId="mutiple-checkbox-label"
                    id="mutiple-checkbox"
                    data-test-id="add-to-group"
                    multiple
                    value={this.state.selectedOptions}
                    label="Choose Group"
                    defaultValue={[]}
                    onChange={(event: any) =>
                      this.saveSelectedOption(event, "selectedOptions")
                    }
                    renderValue={() =>
                      this.getLabel(
                        this.state.selectedOptions ?? [],
                        "Choose Group"
                      )
                    }
                    inputProps={{ IconComponent: () => null }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: {
                        paper: `${classes.dropdownStyle} multiSelect`,
                      },
                    }}
                    displayEmpty
                    endAdornment={<SearchIcon />}
                  >
                    <ListSubheader>
                      <CustomInputWeb
                        placeholder={this.t(`${configJSON.searchTxt}`)}
                        fullWidth={true}
                        autoFocus={true}
                        value={this.state.searchTerm}
                        onChange={(e: any) => this.handleGroupSearch(e.target.value)}
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                        onKeyDown={(e: any) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                        data-test-id="filterSearchInput"
                      />
                      {this.state.isNewGroup && (
                        <div className={classes.addOptionWrapper}>
                          <CustomInputWeb
                            type="text"
                            value={this.state.newGroup}
                            name="newGroup"
                            onChange={this.handleAllInputChange}
                            errors={this.state.error.newGroup}
                            data-test-id="new-group"
                            placeholder={this.t(`${configJSON.newGroupTxt}`)}
                            onKeyDown={(e: any) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                          <Box className="button-wrapper">
                            <Button
                              className="add-link secondary-link"
                              onClick={this.handleCloseNewGroup}
                            >
                              {this.t(`${configJSON.cancel}`)}
                            </Button>
                            <Button
                              className="add-link"
                              data-testid="saveTitle"
                              onClick={this.createGroup}
                            >
                              {this.t(`${configJSON.create}`)}
                            </Button>
                          </Box>
                        </div>
                      )}
                    </ListSubheader>
                    {groupsData?.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        <ListItemText>{item.attributes.name}</ListItemText>
                        <Box className="multiselect-squareCheck">
                          <img
                            className="normal-img"
                            src={checkBoxEmpty}
                            alt="checkBoxEmpty"
                          />
                          <img
                            className="selected-img"
                            src={checkBoxFilled}
                            alt="checkBoxFilled"
                          />
                        </Box>
                      </MenuItem>
                    ))}

                    <Button
                      className="new-group"
                      data-test-id="handleNewGroup"
                      onClick={this.handleNewGroup}
                    >
                      {this.t(`${configJSON.newGroupTxt}`)}
                    </Button>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  renderFavoritesProduct() {
    return (
      <Box className="info-group favorite-product">
        <ProductList
          openProductsModal={this.state.isFavProduct}
          closeProductsModal={this.onCloseFavProduct}
          classes={this.props.classes}
          selectedDealProducts={this.selectedProducts}
          buttonTxt={this.t("Add to Contact")}
          data-test-id="is-fav-product"
        />
      </Box>
    );
  }

  render() {
    const { classes,isCreatingFromDeal } = this.props;
    let customClass = "";
    if(isCreatingFromDeal){
      customClass = "deal-create"
    }
    return (
      <Popover
      open={Boolean(this.props.anchorElState)}
      anchorEl={this.props.anchorElState}
      onClose={ this.props.handleCloseDialog}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}               
      classes={{ paper: `${classes.createContactBox}` }}
      style={{ bottom: "85px" }}
      onKeyDown={(event) => {
        this.props.handleEscCreateContact(event)
        this.saveAsDraftContact()
      }}
      data-test-id="contact-popover"
    >
      <Box className={`${classes.createContactBox} ${customClass}`}>
        <Box className="contact-heading">
          <Box className="heading-left">
            <Typography className="modal-title" component="h2">
              {this.t(`${configJSON.createContact}`)}
            </Typography>
            <Box component="p" className="sub-txt">
              {this.t(`${configJSON.createContactSubTxt}`)}
            </Box>
          </Box>
          <Box className="heading-right">
            <Link
              to="#"
              className="heading-icon"
              onClick={() => {
                this.props.handleMinimizeCreateContact()
                this.saveAsDraftContact()
              }}
              data-test-id="saveAsDraftContact"
            >
              <img src={new_page} alt="minimizeGray" />
            </Link>
            <Link
              to="#"
              className="heading-icon"
              onClick={()=>{
                this.props.handleCloseDialog()
                localStorage.removeItem("draftContact")
              }}
              data-test-id="draftContact"
            >
              <img src={close} alt="closeGray" />
            </Link>
          </Box>
        </Box>

        <Box className="contact-content">      
        <Box className="info-group">
          <Typography className="group-title">
            {this.t(`${configJSON.personalInfoTxt}`)}
          </Typography>
          <Box className="group-form" component="form">
            <Box className="form-info-wrapper">
              <Box className="form-row">
                <Box className="form-col col6">
                  <Box className="form-control right-8">
                    <CustomInputWeb
                      type="text"
                      label={this.t(`${configJSON.firstName}`)}
                      value={this.state.firstName}
                      isRequired={true}
                      name="firstName"
                      onChange={this.handleAllInputChange}
                      errors={this.state.error.firstName}
                      data-test-id="firstName"
                    />
                  </Box>
                </Box>
                <Box className="form-col col6">
                  <Box className="form-control left-8">
                    <CustomInputWeb
                      type="text"
                      label={this.t(`${configJSON.lastName}`)}
                      value={this.state.lastName}
                      isRequired={true}
                      name="lastName"
                      onChange={this.handleAllInputChange}
                      errors={this.state.error.lastName}
                      data-test-id="lastName"
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="form-row">
                <Box className="form-col">
                  <CustomInputWeb
                    type="email"
                    label={this.t(`${configJSON.email}`)}
                    value={this.state.email}
                    name="email"
                    onChange={this.handleAllInputChange}
                    errors={this.state.error.email}
                    rows={4}
                    data-test-id="email"
                  />
                </Box>
              </Box>
              <Box className="form-row">
                <Box className="form-col">
                  <CustomInputWeb
                    type="number"
                    label={this.t(`${configJSON.phone}`)}
                    value={this.state.phone}
                    name="phone"
                    onChange={this.handleAllInputChange}
                    errors={this.state.error.phone}
                    onKeyDown={(e) => restrictKeys(e)}
                    data-test-id="Phone"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {this.state.showBussinessInfo && (
          <>
            <Box className="info-group">
              <Typography className="group-title">
                {this.t(`${configJSON.businessInformation}`)}
              </Typography>
              <Box className="group-form" component="form">
                <Box className="form-info-wrapper">
                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomInputWeb
                        type="text"
                        label={this.t(`${configJSON.companyName}`)}
                        value={this.state.companyName}
                        name="companyName"
                        onChange={this.handleAllInputChange}
                        errors={this.state.error.companyName}
                        rows={4}
                        data-test-id="CompanyName"
                      />
                    </Box>
                  </Box>
                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomInputWeb
                        type="number"
                        label={this.t(`${configJSON.vatNumber}`)}
                        value={this.state.vatNumber}
                        name="vatNumber"
                        onChange={this.handleAllInputChange}
                        errors={this.state.error.vatNumber}                        
                        data-test-id="VatNumber"                        
                        onKeyDown={(e) => restrictKeys(e)}
                      />
                    </Box>
                  </Box>
                  <Box className="form-row">
                    <Box className="form-col">
                      <FreeSoloCreateOption
                        options={this.state.businessCategorys.map(
                          (val: any) => {
                            return {
                              id: val.id,
                              title: val.name,
                            };
                          }
                        )}
                        addEditCallback={this.addBCategory}
                        addLinkTitle={this.t(`${configJSON.addNewCategory}`)}
                        noOptionTitle={this.t(`${configJSON.noCategoryFound}`)}
                        label={this.t(`${configJSON.businessCategorytxt}`)}
                        id="b-id"
                        onChange={this.categoryChangeHandler}
                        value={this.state.businessCategory}
                        errors={this.state.error.errorbusinessCategory}
                        deleteCallback={this.deleteBCategoryEvent}
                        errorMsgNoRecord={this.t(
                          `${configJSON.noCategoryCreated}`
                        )}
                        notRequired
                        data-test-id="bussines-category"
                        placeHolder={this.t(`${configJSON.defaultPlacehoder}`)}
                      />
                      {this.state.isDeleting && (
                        <ConfirmActionModal
                          isOpen={this.state.isDeleting}
                          handleClose={this.handleCloseTitle}
                          modalConfirmAction={this.deleteBCategoryFromList}
                          deleteTeamId={this.state.deleteBCategoryId}
                          modalMessage= {this.t(configJSON.deleteBusinessCategory, { categoryName: this.state.deleteTitle })}
                          confirmBtnTxt={this.t(`${configJSON.delete}`)}
                          modalHeading={this.t(`${configJSON.deleteTitle}`)}
                          cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
                          data-testid="confirmDeleteCategory"
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="info-group">
              <Typography className="group-title shipping-address-txt">
                {this.t(`${configJSON.billingAddressTxt}`)}
              </Typography>
              <Box component="form" className="group-form">
                <Box className="form-info-wrapper">
                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomSearchDropdown
                      classes={classes}
                      label={this.t(`${configJSON.country}`)}
                      value={this.state.billingAddressCountry}
                      changeHandler={this.handleAllInputChange}
                      searchChangeHAndler={this.multiSelectSearchChange}
                      searchValue={this.state.countrySearchVal}
                      dropdownListing={this.displayCountries}
                      selectName="billingAddressCountry"
                      searchValueName="countrySearchVal"
                      itemValueKey="country_name"
                      itemLabelKey="country_name"
                      data-test-id="CountryComponent"
                      />
                    </Box>
                  </Box>

                  <Box className="form-row">
                    <Box className="form-col col6">
                      <Box className="form-control right-8">
                      <CustomSearchDropdown
                      classes={classes}
                      label={this.t(`${configJSON.state}`)}
                      value={this.state.billingAddressState}
                      changeHandler={this.handleAllInputChange}
                      searchChangeHAndler={this.multiSelectSStateChange}
                      searchValue={this.state.stateSearchValue}
                      dropdownListing={this.displayStates}
                      selectName="billingAddressState"
                      searchValueName="stateSearchValue"
                      itemValueKey="state_name"
                      itemLabelKey="state_name"
                      data-test-id="StateComponent"
                      />
                       
                      </Box>
                    </Box>
                    <Box className="form-col col6">
                      <Box className="form-control left-8">
                      <CustomSearchDropdown
                      classes={classes}
                      label={this.t(`${configJSON.city}`)}
                      value={this.state.billingAddressCity}
                      changeHandler={this.handleAllInputChange}
                      searchChangeHAndler={this.multiSelectCityChange}
                      searchValue={this.state.citySearchValue}
                      dropdownListing={this.displayCities}
                      selectName="billingAddressCity"
                      searchValueName="citySearchValue"
                      itemValueKey="city_name"
                      itemLabelKey="city_name"
                      data-test-id="CityComponent"
                      />
                      
                      </Box>
                    </Box>
                  </Box>
                  <Box className="form-row">
                    <Box className="form-col">
                      <Box className="form-control">
                        <CustomInputWeb
                          label={this.t(`${configJSON.pinCode}`)}
                          type="number"
                          value={this.state.billingAddressPinCode}
                          name="billingAddressPinCode"
                          onChange={this.handleAllInputChange}
                          errors={""}
                          data-test-id="PinCode"
                          onKeyDown={(e: any) => {
                            if (["e", "E", "-"].includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomInputWeb
                        type="text"
                        label={this.t(`${configJSON.address1}`)}
                        value={this.state.billingAddress1}
                        name="billingAddress1"
                        onChange={this.handleAllInputChange}
                        errors={""}
                        rows={4}
                        data-test-id="shippingAddress1"
                      />
                    </Box>
                  </Box>
                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomInputWeb
                        type="text"
                        label={this.t(`${configJSON.address2}`)}
                        value={this.state.billingAddress2}
                        name="billingAddress2"
                        onChange={this.handleAllInputChange}
                        errors={""}
                        rows={4}
                        data-test-id="shippingAddress2"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="info-group">
              <Typography className="group-title shipping-address-txt">
                {this.t(`${configJSON.shippingAddress}`)}
              </Typography>
              <Typography className="group-sub-title">
                {this.t(`${configJSON.sameAddressTxt}`)}

                <Checkbox
                  className="shipping-address-chkbox"
                  checked={this.state.checked}
                  onChange={this.handleChecked}
                  data-test-id="checkbox"
                  name="checked"
                  checkedIcon={<img src={checked} alt="checkbox" />}
                />
              </Typography>
              {this.state.checked === false && (
                <Box className="group-form" component="form">
                  <Box className="form-info-wrapper">
                    <Box className="form-row">
                      <Box className="form-col">
                      <CustomSearchDropdown
                      classes={classes}
                      label={this.t(`${configJSON.country}`)}
                      value={this.state.shippingAddressCountry}
                      changeHandler={this.handleAllInputChange}
                      searchChangeHAndler={this.multiSelectSearchChange}
                      searchValue={this.state.countrySearchVal}
                      dropdownListing={this.displayCountries}
                      selectName="shippingAddressCountry"
                      searchValueName="countrySearchVal"
                      itemValueKey="country_name"
                      itemLabelKey="country_name"
                      data-test-id="Country2"
                      />
                        
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col col6">
                        <Box className="form-control right-8">
                        <CustomSearchDropdown
                      classes={classes}
                      label={this.t(`${configJSON.state}`)}
                      value={this.state.shippingAddressState}
                      changeHandler={this.handleAllInputChange}
                      searchChangeHAndler={this.shippingStateSelect}
                      searchValue={this.state.stateSearchValue}
                      dropdownListing={this.displayStatesShipping}
                      selectName="shippingAddressState"
                      searchValueName="stateSearchValue"
                      itemValueKey="state_name"
                      itemLabelKey="state_name"
                      data-test-id="State2"
                      />
                        </Box>
                      </Box>
                      <Box className="form-col col6">
                        <Box className="form-control right-8">
                        <CustomSearchDropdown
                      classes={classes}
                      label={this.t(`${configJSON.city}`)}
                      value={this.state.shippingAddressCity}
                      changeHandler={this.handleAllInputChange}
                      searchChangeHAndler={this.multiSelectCityShipping}
                      searchValue={this.state.citySearchValue}
                      dropdownListing={this.displayCitiesShipping}
                      selectName="shippingAddressCity"
                      searchValueName="citySearchValue"
                      itemValueKey="city_name"
                      itemLabelKey="city_name"
                      data-test-id="City2"
                      />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <Box className="form-control">
                          <CustomInputWeb
                            label={this.t(`${configJSON.pinCode}`)}
                            type="number"
                            name="shippingAddressPinCode"
                            value={this.state.shippingAddressPinCode}
                            onChange={this.handleAllInputChange}
                            errors={""}
                            data-test-id="PinCode2"
                            onKeyDown={(e: any) => {
                              if (["e", "E", "-"].includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <CustomInputWeb
                          type="text"
                          label={this.t(`${configJSON.address1}`)}
                          value={this.state.shippingAddress1}
                          name="shippingAddress1"
                          onChange={this.handleAllInputChange}
                          errors={""}
                          rows={4}
                          data-test-id="shippingAddress11"
                        />
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <CustomInputWeb
                          type="text"
                          label={this.t(`${configJSON.address2}`)}
                          value={this.state.shippingAddress2}
                          name="shippingAddress2"
                          onChange={this.handleAllInputChange}
                          errors={""}
                          rows={4}
                          data-test-id="shippingAddress12"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              <Typography className="group-caption">
                {this.t(`${configJSON.contactAgreesMarketingTxt}`)}
              </Typography>
            </Box>
          </>
        )}
        {this.state.showNotes && (
          <Box className="info-group">
            <Typography className="group-title">
              {this.t(`${configJSON.notes}`)}
            </Typography>
            <Box className="group-form" component="form">
              <Box className="form-info-wrapper">
                <Box className="form-row">
                  <Box className="form-col">
                    <CustomInputWeb
                      type="text"
                      label=""
                      value={this.state.notes}
                      name="notes"
                      placeholder={this.t(`${configJSON.typeHere}`)}
                      onChange={this.handleAllInputChange}
                      errors={""}
                      rows={4}
                      isMultiline
                      data-test-id="Notes"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {this.state.selectedProductsId.length > 0 && (
          <Box className="info-group favorite-product">
            <Typography className="group-title">
              {this.t(`${configJSON.favProduct}`)}
            </Typography>
            <Box className="group-form favorite-box" component="form">
              <Box className="category-left-box">
                <Typography className="left-title">
                  {this.t(`${configJSON.favProducts}`)}
                </Typography>
                <Typography className="left-sub-txt">
                  {this.state.selectedProductsId.length}{" "}
                  {this.state.selectedProductsId.length > 1 ? "Items" : "Item"}
                </Typography>
              </Box>
              <Box className="category-right-box">
                <Typography className="total-amount-txt">
                  {this.t(`${configJSON.totalAmmount}`)}
                </Typography>
                <Typography className="amount-txt">
                  ${this.state.totalAmount}.00
                </Typography>
                <Button
                  className="arrow-btn"
                  data-test-id="open-fav-product"
                  onClick={this.handleShowingFavoritesProduct}
                >
                  <ArrowForwardIosIcon className="btn-icon" />
                </Button>
              </Box>
            </Box>
          </Box>
        )}

        {this.state.isFavProduct && this.renderFavoritesProduct()}
        {this.state.showGroup && this.renderGroup()}

        <Box className="wrap-option">
          {!this.state.showBussinessInfo && (
            <Button
              className="btn-add-option"
              data-test-id="handleShowBussinessInfo"
              onClick={this.handleShowBussinessInfo}
            >
              + {this.t(`${configJSON.businessShippingInfoTxt}`)}
            </Button>
          )}
          {!this.state.showNotes && (
            <Button
              className="btn-add-option"
              data-test-id="handleShowNotes"
              onClick={this.handleShowNotes}
            >
              + {this.t(`${configJSON.addNotes}`)}
            </Button>
          )}
          {!this.state.isFavProduct &&
            this.state.selectedProductsId.length === 0 && (
              <Button
                className="btn-add-option"
                data-test-id="handleShowingFavoritesProduct"
                onClick={this.onOpenFavProduct}
              >
                + {this.t(`${configJSON.addFavProductTxt}`)}
              </Button>
            )}
          {this.state.showFavoritesProduct && (
            <ProductViewModal
              modalOpen={this.state.showFavoritesProduct}
              modalClose={this.handleShowingFavoritesProductClose}
              selectedProductsData={this.state.selectedProductsData}
              openEdit={this.onOpenFavProduct}
              classes={classes}
              t={this.t}
            />
          )}
          {!this.state.showGroup && (
            <Button
              className="btn-add-option"
              data-test-id="handleShowingGroup"
              onClick={this.handleShowingGroup}
            >
              + {this.t(`${configJSON.addToGroupTxt}`)}
            </Button>
          )}
        </Box>
        </Box>
        <Box className="create-bottom">
         <Box className="full-btn">
         <Button
          data-test-id="createContact"
          onClick={this.createContact}
          className={`create-contact-btn ${classes.primaryButton}`}
        >
          + {this.t(`${configJSON.createContact}`)}
        </Button>
        </Box>
        </Box>
      
      </Box>
      </Popover>
    );
  }
}

export default withStyles(customStyles, { withTheme: true })(CreateContact);
// Customizable Area End
