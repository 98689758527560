import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./EmailAccountRegistration.web";
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  isEmailDisabled: boolean;
  lastName: string;
  companyName: string;
  country: string;
  state: string;
  city: string;
  postalcode: string;
  companyAddress: string;
  email: string;
  password: string;
  checkError: string;
  checkFlag: boolean;
  isChecked: boolean;
  isCreatingAccount: boolean;
  otpAuthToken: string;
  registerToken: string;
  reTypePassword: string;
  isPasswordVisible: boolean;
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  isPasswordValid: boolean;
  showModal: boolean;
  errorMessage: string;
  googleLogin: string;
  error: {
    emailError: {
      message: string;
      visible: boolean;
    };
    passwordError: {
      message: string;
      visible: boolean;
    };
  };
  checkboxError: {
    message: string;
    visible: boolean;
  };
  isLoading: boolean;
  termsPrivacyData: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  getTermsAndPrivacyApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    const user = sessionStorage.getItem("userEmail");
    this.state = {
      // Customizable Area Start
      firstName: "",
      isEmailDisabled: true,
      lastName: "",
      companyName: "",
      country: "",
      state: "",
      city: "",
      postalcode: "",
      companyAddress: "",
      email: user || "",
      password: "",
      reTypePassword: "",
      isChecked: false,
      checkError: "",
      checkFlag: false,
      isCreatingAccount: false,
      isPasswordVisible: false,
      otpAuthToken: "",
      registerToken: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      isPasswordValid: false,
      showModal: false,
      errorMessage: "",
      googleLogin: "",
      error: {
        emailError: {
          message: "",
          visible: false,
        },
        passwordError: {
          message: "",
          visible: false,
        },
      },
      checkboxError: {
        message: "",
        visible: false,
      },
      isLoading: false,
      termsPrivacyData: "",
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = /\w+/;
    this.emailReg = /\w+/;

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.email !== this.state.email) {
      this.setState({
        error: {
          ...this.state.error,
          emailError: {
            message : "",
            visible: false,
          } ,
        },
      });
    }

    if (prevState.password !== this.state.password) {
      this.setState({
        error: {
          ...this.state.error,
          passwordError: {
            message : "",
            visible: false,
          } ,
        },
      });
    }

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.setState({isLoading: false})
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.errors) {
          this.setState({
            error: {
              ...this.state.error,
              emailError: {
                message: responseJson.errors[0].message,
                visible: true,
              },
            },
          });
        }
        if (apiRequestCallId === this.createAccountApiCallId) {
          this.handleCreateAccountResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }
        else if(apiRequestCallId === this.getTermsAndPrivacyApiCallId) {
          this.handleTermsAndPrivacyResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const langT = localStorage.getItem("lang") ?? "en"; 
    await (i18n as any).changeLanguage(langT);  
  }
  t(key:any, variable?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variable } )
  }

  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  togglePasswordVisibility = () => {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
  };

  isValidPassword = (isValid: boolean) => {
    this.setState({ isPasswordValid: isValid });
  };

  validateEmail = (inputEmail: string) => {
    return this.state.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  isMatched = (inputPassword: string) => {
    let isContainsUpperCase = () => /[A-Z]/.test(inputPassword);
    let isContainsLowerCase = () => /[a-z]/.test(inputPassword);
    let isContainsNumber = () => /\d/.test(inputPassword);
    let isMinLength = () => /.{8,}$/.test(inputPassword);
    if (
      isContainsLowerCase() &&
      isContainsUpperCase() &&
      isContainsNumber() &&
      isMinLength() &&
      inputPassword !== ""
    ) {
      return true;
    }
  };

  handleModalOpen = () => {
    this.setState({ showModal: true });
  };

  handleClose = () => {
    this.setState({ showModal: false, isEmailDisabled: false });
    this.setState({ isChecked: false });
  };
  handleNotyouclick = () => {
    this.setState({ showModal: false})
    this.setState({email : ""});
    this.setState({password : ""});
  }
  validateAccount = () => {
    let errorFlag = false;
    let emailErr = this.state.error.emailError;
    let passwordErr = this.state.error.passwordError;

    if (
      this.state.email === "" ||
      this.state.email.length === 0 ||
      !this.state.email.trim()
    ) {
      emailErr = { message: configJSON.errorEmail, visible: true };
      errorFlag = true;
    } else if (
      this.state.email !== "" &&
      !this.validateEmail(this.state.email)
    ) {
      emailErr = { message: configJSON.errorEmailNotValid, visible: true };
      errorFlag = true;
    } else if (
      this.state.password === "" ||
      this.state.password.length === 0 ||
      !this.state.password.trim()
    ) {
      passwordErr = { message: configJSON.errorPassword, visible: true };
      errorFlag = true;
    } else if (
      this.state.password !== "" &&
      !this.isMatched(this.state.password)
    ) {
      passwordErr = {
        message: configJSON.errorPasswordNotValid,
        visible: true,
      };
      errorFlag = true;
    }

    if (errorFlag) {
      this.setState({
        error: {
          emailError: emailErr,
          passwordError: passwordErr,
        },
      });
    } else {
      this.handleModalOpen();
    }
    return false;
  };
  handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const checkboxError = isChecked
      ? { message: "", visible: true }
      : this.state.checkboxError;
    this.setState({
      isChecked,
      checkboxError,
    });
  };

  createAccount = () => {
    let checkboxerr = this.state.checkboxError;
    if (!this.state.isChecked) {
      checkboxerr = { message: configJSON.errorLegalCheckbox, visible: true };
      this.setState({
        checkboxError: checkboxerr,
      });
      return;
    } else {
      this.setState({isLoading: true})
      checkboxerr = { message: "", visible: true };
      this.setState({
        checkboxError: checkboxerr,
        checkFlag: false,
        isCreatingAccount: true,
      });
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
      };

      const attrs = {
        first_name: "",
        last_name: "",
        email: this.state.email,
        password: this.state.password,
        full_phone_number: "",
        activated: true,
      };

      const data = {
        type: "email_account",
        attributes: attrs,
      };

      const httpBody = {
        data: data,
        token: this.state.otpAuthToken,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountsAPiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleBack = () => {
    this.props.navigation.navigate("EmailAccountLogin");
  };

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleCreateAccountResponse = (responseJson: {
    errors: { account: string; message: string }[];
    meta: { token: string };
  }) => {
    this.setState({ isCreatingAccount: false });
    if (!responseJson.errors) {
      let meta = responseJson.meta.token;
      localStorage.setItem("token", meta);
      sessionStorage.setItem("token", meta);
      sessionStorage.setItem("userData", JSON.stringify(responseJson));
      this.props.navigation.navigate("TutorialScreen");
      const msg: Message = new Message(
        getName(MessageEnum.AccoutResgistrationSuccess)
      );

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      this.send(msg);
    } else {
      //Check Error Response
      this.setState({
        errorMessage:
          responseJson.errors[0].account || responseJson.errors[0].message,
      });
      this.parseApiErrorResponse(responseJson);
    }
  };


  getTermsAndPrivacy = async (isPrivacyModal:boolean) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTermsAndPrivacyApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
    );
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    isPrivacyModal ?  configJSON.privacyPolicyEndPoints : configJSON.termsAndConditionsEndPoints
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleTermsAndPrivacyResponse = (responseJson: {data:Array<{attributes : {content : string}}>}) => {
    this.setState({
      termsPrivacyData: responseJson.data[0].attributes.content,
    });
  };

  setPrivacyNull = () => {
    this.setState({ termsPrivacyData: "" });
  };
  // Customizable Area End
}
