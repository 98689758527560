//Customizable Area Start
import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { StepIconProps } from "@material-ui/core/StepIcon";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import {
  Typography,
  Box,
  List,
  ListItem,
  Tab,
  Modal,
  CardMedia,
  Tabs,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { FileDrop } from "react-file-drop";
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import { sortIcon, filterSmIcon, uploadImg, handshake,emailFast,fileEdit,bottle, editContactAvatarRound, } from "./assets";
import BulkUploadContactController, { configJSON } from "./BulkUploadContactController.web";
import Loader from "../../../components/src/Loader.web";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import Check from "@material-ui/icons/Check";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import CreateIcon from "@material-ui/icons/Create";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import TabPanel from "@material-ui/lab/TabPanel";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TabContext, TabList } from "@material-ui/lab";
import ContactsList from "./ContactsTable.web";
import GroupContactsSort from "./GroupContactsSort.web";
import TeamSort from "../../email-account-registration/src/teams-web/TeamSort.web";
import ConfirmActionModal from "../../email-account-registration/src/teams-web/ConfirmActionModal.web";
import ContactBulkGroupTable from "../../../components/src/ContactBulkGroupTable.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    stepperRoot: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      "& .MuiStepConnector-line": {
        borderColor: "transparent",
      },
      "& .stepper-top": {
        padding: "16px 24px",
        [theme.breakpoints.down("xs")]: {
          padding: "21px 24px",
        },
        "& .stepper-row": {
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          margin: "0 -10px",
          [theme.breakpoints.down("sm")]: {
            margin: "0 -5px",
          },
          [theme.breakpoints.down("xs")]: {
            margin: "0",
            justifyContent: "space-between",
          },
          "& .stepper-col": {
            flex: "0 0 33.33%",
            maxWidth: "33.33%",
            padding: "0 10px",
            [theme.breakpoints.down("md")]: {
              flex: "0 0 27%",
              maxWidth: "27%",
            },
            [theme.breakpoints.down("sm")]: {
              padding: "0 5px",
              flex: "unset",
              maxWidth: "unset",
            },
            [theme.breakpoints.down("xs")]: {
              padding: "0",
            },
            "&.stepper-middle": {
              [theme.breakpoints.down("md")]: {
                flex: "0 0 46%",
                maxWidth: "46%",
              },
              [theme.breakpoints.down("sm")]: {
                flex: "unset",
                maxWidth: "unset",
              },
              [theme.breakpoints.down("xs")]: {
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              },
            },
            "&.stepper-action": {
              [theme.breakpoints.down("sm")]: {
                marginLeft: "auto",
              },
              [theme.breakpoints.down("xs")]: {
                display: "none",
              },
            },
            "& .MuiMobileStepper-root": {
              [theme.breakpoints.down("xs")]: {
                padding: "0",
                background: "transparent",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "16px",
                color: "#94A3B8",
              },
            },
            "& .back-btn-wrap": {
              "& i": {
                display: "block",
              },
            },
            "& .auth-logo": {
              maxWidth: "117px",
              width: "100%",
              "& img": {
                maxWidth: "100%",
              },
            },
            "& .button-wrapper": {
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              "& .secondary-btn": {
                minWidth: "116px",
                marginRight: "14px",
                [theme.breakpoints.down("md")]: {
                  minWidth: "90px",
                  marginRight: "10px",
                  padding: "12px 7px",
                },
                [theme.breakpoints.down("sm")]: {
                  minWidth: "unset",
                  marginRight: "5px",
                  padding: "9px 3px",
                  fontSize: "12px",
                  minHeight: "38px",
                },
              },
              "& .primary-btn": {
                minWidth: "142px",
                [theme.breakpoints.down("md")]: {
                  minWidth: "130px",
                  padding: "12px 7px",
                },
                [theme.breakpoints.down("sm")]: {
                  minWidth: "unset",
                  padding: "9px 3px",
                  fontSize: "12px",
                  minHeight: "38px",
                },
              },
            },
          },
        },
      },
      "& .sm-visible": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          display: "block",
        },
      },
      "& .stepIcon": {
        backgroundColor: "#fafcfe",
        zIndex: 1,
        color: "#94a3b8",
        width: 48,
        height: 48,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid transparent",
        [theme.breakpoints.down("sm")]: {
          width: 32,
          height: 32,
        },
        "& .MuiSvgIcon-root": {
          width: 20,
          height: 20,
        },
        "&.active": {
          backgroundColor: "#4ba3b7",
          color: "#ffffff",
        },
        "&.completed": {
          backgroundColor: "transparent",
          borderColor: "#00ba88",
          color: "#00ba88",
        },
      },
      "& .MuiStepper-root": {
        padding: "0",
        maxWidth: "490px",
        width: "100%",
        margin: "0 auto",
        [theme.breakpoints.down("xs")]: {
          maxWidth: "unset",
          width: "auto",
        },
        "& .step-root": {
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
          "&.active-step-root": {
            [theme.breakpoints.down("xs")]: {
              display: "block",
              padding: "0",
            },
          },
        },
        "& .MuiStepConnector-root": {
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
        },
        "& .MuiStepLabel-iconContainer": {
          paddingRight: "13px",
          [theme.breakpoints.down("sm")]: {
            paddingRight: "5px",
          },
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
        },
      },
      "& .MuiStepLabel-label": {
        "&.MuiStepLabel-active": {
          "& .step-no": {
            color: "#4ba3b7",
          },
          "& .step-label": {
            color: "#2b2b2b",
            [theme.breakpoints.down("xs")]: {
              color: "#4BA3B7",
            },
          },
        },
        "&.MuiStepLabel-completed": {
          "& .step-no": {
            color: "#00ba88",
          },
          "& .step-label": {
            color: "#00ba88",
          },
        },
        "& .step-no": {
          fontSize: "12px",
          lineHeight: "16px",
          color: "#94a3b8",
          fontWeight: 400,
          fontFamily: "Roboto",
          letterSpacing: "0",
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
        },
        "& .step-label": {
          fontSize: "16px",
          lineHeight: "24px",
          color: "#94a3b8",
          fontWeight: 400,
          fontFamily: "Expletus Sans",
          letterSpacing: "0",
          [theme.breakpoints.down("sm")]: {
            fontSize: "13px",
            lineHeight: "17px",
          },
          [theme.breakpoints.down("xs")]: {
            background: "rgba(75, 163, 183, 0.08)",
            borderRadius: "6px",
            padding: "4px 12px",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.0075em",
            color: "#4BA3B7",
            fontFamily: "Roboto",
            marginRight: "12px",
          },
        },
      },
      "& .step-content-root": {
        display: "flex",
        padding: "37px 24px",
        width: "100%",
        flex: "1",
        [theme.breakpoints.down("xs")]: {
          padding: "18px 0 24px",
        },
      },
      "& .step-content": {
        width: "100%",
        display: "flex",
        "& .member-upload-content": {
          maxWidth: "100%",
          width: "812px",
          margin: "auto",
          [theme.breakpoints.down("xs")]: {
            padding: "0 24px",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
          },
          "& .file-drop": {
            [theme.breakpoints.down("xs")]: {
              marginBottom: "8px",
            },
          },
          "& .heading": {
            fontSize: "24px",
            lineHeight: "30px",
            margin: "0 0 9px",
            color: "#000000",
            letterSpacing: "0",
            fontFamily: "Expletus Sans",
            fontWeight: 400,
            [theme.breakpoints.down("xs")]: {
              fontSize: "22px",
              lineHeight: "26px",
              margin: "0 0 9px",
              color: "#2B2B2B",
            },
          },
          "& .sub-txt": {
            fontSize: "14px",
            lineHeight: "24px",
            margin: "0 0 26px",
            color: "#505050",
            fontFamily: "Roboto",
            fontWeight: 400,
            [theme.breakpoints.down("xs")]: {
              fontSize: "12px",
              lineHeight: "20px",
              margin: "0 0 78px",
              color: "#2B2B2B",
            },
          },
          "& .button-wrapper": {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginTop: "25px",
            [theme.breakpoints.down("xs")]: {
              marginTop: "auto",
            },
            "& .secondary-btn": {
              [theme.breakpoints.down("xs")]: {
                width: "100%",
                marginBottom: "24px",
              },
            },
            "& .MuiButton-root": {
              [theme.breakpoints.down("xs")]: {
                width: "100%",
                marginBottom: "24px",
              },
              "&:last-child": {
                [theme.breakpoints.down("xs")]: {
                  marginBottom: "0",
                },
              },
            },
          },
        },
      },
    },
    radioChoose: {
      width: "100%",
      marginBottom: "24px",
      "&.error-show": {
        "& .MuiRadio-colorSecondary ~ span": {
          border: "1px solid #C7263E",
          color: "#C7263E",
        },
      },
      "& .radio-group": {
        flexDirection: "row",
        margin: "0 -5px",
        [theme.breakpoints.down("xs")]: {
          margin: "0",
        },
        "& .MuiFormControlLabel-root": {
          margin: "0",
          flex: "0 0 33.33%",
          maxWidth: "33.33%",
          padding: "0 5px",
          [theme.breakpoints.down("xs")]: {
            flex: "1",
            maxWidth: "33.33%",
            marginRight: "5px",
            padding: "0",
          },
          "&:last-child": {
            marginRight: "0",
          },
          "&:nth-child(3n)": {
            [theme.breakpoints.down("xs")]: {
              marginRight: "0",
            },
          },
        },
      },
      "& .MuiRadio-root": {
        display: "none",
      },
      "& .MuiRadio-colorSecondary ~ span": {
        padding: "10px 15px",
        width: "100%",
        borderRadius: "6px",
        backgroundColor: "#ffffff",
        border: "1px solid #e8ecf2",
        fontSize: "14px",
        letterSpacing: "0",
        lineHeight: "19px",
        color: "#2B2B2B",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "57px",
        transition: "all 0.3s ease-in-out",
      },
      "& .MuiRadio-colorSecondary.Mui-checked ~ span": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "12px",
        letterSpacing: "0.0075em",
        // color: "#C7263E",
        color: "#00BA88",
        // fontFamily: "Expletus Sans",
        fontFamily: "Roboto",
        fontWeight: "400",
        marginLeft: "14px",
        marginRight: "14px",
        "&.Mui-error": {
          color: "#C7263E",
        },
        "&.Mui-default": {
          color: "#2B2B2B",
        },
      },
    },
    fileUploadWrapper: {
      border: "1px dashed #e8ecf2",
      padding: "62px 30px 70px",
      borderRadius: "12px",
      textAlign: "center",
      transition: "all 0.5s ease-in-out",
      cursor: "pointer",
      "&:hover": {
        filter: "drop-shadow(0px 16px 10px rgba(0,0,0,0.05))",
        backgroundColor: "#fafcfe",
        borderColor: "transparent",
      },
      "& .upload-icon": {
        marginBottom: "30px",
      },
      "& .file-heading": {
        fontSize: "16px",
        lineHeight: "21px",
        margin: "0 0 7px",
        color: "rgba(0,0,0,0.87)",
        letterSpacing: "0.15px",
        fontFamily: "Expletus Sans",
        fontWeight: 600,
      },
      "& .file-subtxt": {
        fontSize: "12px",
        lineHeight: "16px",
        color: "rgba(0,0,0,0.87)",
        letterSpacing: "0.4px",
        fontFamily: "Roboto",
        fontWeight: 600,
        "& .browse-link": {
          color: "#4ba3b7",
          cursor: "pointer",
          margin: "0 5px",
        },
      },
    },
    fileDropDrag: {
      filter: "drop-shadow(0px 16px 10px rgba(0,0,0,0.05))",
      backgroundColor: "#fafcfe",
      borderColor: "transparent",
    },
    uploadProgress: {
      borderRadius: "12px",
      border: "1px solid #e8ecf2",
      marginTop: "24px",
      position: "relative",
      overflow: "hidden",
      [theme.breakpoints.down("xs")]: {
        marginTop: "0",
        marginBottom: "46px",
      },
      "& .progress-status": {
        width: "56%",
        position: "absolute",
        height: "100%",
        opacity: "0.169",
        backgroundImage: "linear-gradient(270deg, #82d0dc 0%, #08748c 100%)",
      },
      "& .progress-row": {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "29px 24px",
        [theme.breakpoints.down("xs")]: {
          padding: "15px 16px",
        },
        "& .progress-heading": {
          fontSize: "16px",
          lineHeight: "24px",
          margin: "0 0 8px",
          color: "#000000",
          letterSpacing: "0",
          fontFamily: "Roboto",
          fontWeight: 400,
          [theme.breakpoints.down("xs")]: {
            color: "rgba(0, 0, 0, 0.87)",
            letterSpacing: "0.15px",
          },
        },
        "& .progress-subtxt": {
          fontSize: "12px",
          lineHeight: "16px",
          color: "#000000",
          letterSpacing: "0",
          fontFamily: "Roboto",
          fontWeight: 400,
          [theme.breakpoints.down("xs")]: {
            color: "rgba(0, 0, 0, 0.87)",
            letterSpacing: "0.4px",
          },
          "& .remaining": {
            position: "relative",
            marginLeft: "9px",
            paddingLeft: "14px",
            "&:before": {
              content: "''",
              width: "6px",
              height: "6px",
              borderRadius: "3px",
              backgroundColor: "#2b2b2b",
              position: "absolute",
              left: "0",
              top: "4px",
            },
          },
        },
        "& .right-col": {
          marginLeft: "15px",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          "& >a": {
            marginRight: "23px",
            "&:last-child": {
              marginRight: "0",
            },
            "&.play-pause": {
              color: "#000000",
            },
            "&.close": {
              color: "#bf3e50",
            },
          },
        },
      },
    },
    uploadFileList: {
      marginTop: "24px",
      padding: "0",
      [theme.breakpoints.down("xs")]: {
        marginTop: "0px",
        marginBottom: "10px",
      },
      "& .MuiListItem-root": {
        marginBottom: "24px",
        borderRadius: "12px",
        backgroundColor: "#ffffff",
        border: "1px solid #e8ecf2",
        padding: "15px 24px",
        [theme.breakpoints.down("xs")]: {
          padding: "14px 24px",
        },
        "&:last-child": {
          marginBottom: "0",
        },
        "& .file-info": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          "& .file-name": {
            fontSize: "16px",
            lineHeight: "24px",
            color: "rgba(0, 0, 0, 0.87)",
            letterSpacing: "0.15px",
            fontFamily: "Roboto",
            fontWeight: 400,
          },
          "& .close": {
            color: "#959595",
          },
        },
      },
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "6px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#f2f8fc",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      maxWidth: "100%",
      minWidth: "170px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    secondaryButton: {
      background: "transparent",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 600,
      textAlign: "center",
      textTransform: "unset",
      fontFamily: "Expletus Sans",
      maxWidth: "100%",
      minWidth: "198px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    dropdownStyle: {
      marginTop: "21px",
      maxWidth: "190px",
      width: "100%",
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "24px",
            paddingRight: "24px",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          color: "#2B2B2B",
          padding: "14px 24px",
          "& .MuiListItemText-root": {
            margin: "0",
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
          },
          "& .multiselect-check": {
            display: "none",
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        whiteSpace: "normal",
        "& .MuiTypography-root": {
          font: "inherit",
          letterSpacing: "0",
        },
      },
      "& .MuiList-padding": {
        paddingTop: "4px",
        paddingBottom: "8px",
      },
    },
    teamModuleWrapper: {
      padding: "0",
      textAlign: "left",
      width: "100%",
      "& .stepper-action": {
        [theme.breakpoints.down("xs")]: {
          padding: "46px 24px 0",
        },
        "& .button-wrapper": {
          "& .MuiButton-root": {
            [theme.breakpoints.down("xs")]: {
              width: "100%",
              marginBottom: "16px",
            },
            "&:last-child": {
              [theme.breakpoints.down("xs")]: {
                marginBottom: "0",
              },
            },
          },
        },
      },
      "& .heading-wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexWrap: "wrap",
        marginBottom: "23px",
        padding: "0 0 0 7px",
        [theme.breakpoints.down("md")]: {
          marginBottom: "16px",
        },
        [theme.breakpoints.down("xs")]: {
          marginBottom: "24px",
          padding: "0 24px",
        },
        "& .heading-right": {
          marginLeft: "10px",
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down("xs")]: {
            marginLeft: "0",
            width: "100%",
            marginTop: "10px",
          },
        },
        "& .members-count": {
          fontSize: "14px",
          lineHeight: "20px",
          color: "#2b2b2b",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: "500",
          marginRight: "14px",
        },
        "& .heading": {
          fontSize: "24px",
          lineHeight: "28px",
          margin: "0 0 11px",
          color: "#000000",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: "400",
          [theme.breakpoints.down("xs")]: {
            fontSize: "22px",
            lineHeight: "26px",
            margin: "0 0 8px",
            color: "#2B2B2B",
          },
        },
        "& .sub-txt": {
          fontSize: "14px",
          lineHeight: "24px",
          color: "#505050",
          fontFamily: "Roboto",
          fontWeight: "400",
          margin: "0",
          letterSpacing: "0.25px",
          [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
            lineHeight: "17px",
            color: "#2B2B2B",
            letterSpacing: "0",
            opacity: "0.67",
          },
        },
      },
      "& .search-filter-sm": {
        display: "none",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          display: "flex",
          padding: "0 24px",
          marginBottom: "3px",
        },
        "& .search-wrapper": {
          flex: "1",
          "& .form-control": {
            marginBottom: "0",
            "& .MuiOutlinedInput-input": {
              fontSize: "13px",
              lineHeight: "17px",
              paddingTop: "16px",
              paddingBottom: "16px",
              "&::-webkit-input-placeholder": {
                color: "#94A3B8",
              },
              "&:-ms-input-placeholder": {
                color: "#94A3B8",
              },
              "&::placeholder": {
                color: "#94A3B8",
              },
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              "& .MuiSvgIcon-root": {
                fill: "#94A3B8",
                width: "20px",
                height: "20px",
              },
            },
          },
        },
        "& .sort-group": {
          marginLeft: "16px",
          cursor: "pointer",
        },
        "& .filter-group": {
          marginLeft: "16px",
          cursor: "pointer",
        },
      },
      "& .no-member": {
        color: "rgba(0,0,0,0.87)",
        fontSize: "24px",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        lineHeight: "32px",
        letterSpacing: "0",
        margin: "50px 0",
        textAlign: "center",
      },
      "& .table-top": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 0 8px 24px",
        borderTop: "1px solid #e8ecf2",
        borderBottom: "1px solid #e8ecf2",
        minHeight: "55px",

        [theme.breakpoints.down("sm")]: {
          padding: "7px 0 8px 24px",
        },
        [theme.breakpoints.down("xs")]: {
          display: "none",
          flexWrap: "wrap",
        },
        "& .top-left": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          [theme.breakpoints.down("xs")]: {
            width: "100%",
          },
        },
        "& .top-right": {
          marginLeft: "10px",
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginLeft: "0",
            marginTop: "10px",
          },
          "& .select-control": {
            [theme.breakpoints.down("xs")]: {
              width: "100%",
            },
          },
        },
        "& .top-middle": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginLeft: "10px",
        },
        "& .table-selection": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginRight: "35px",
          [theme.breakpoints.down("md")]: {
            marginRight: "37px",
          },
          [theme.breakpoints.down("sm")]: {
            marginRight: "17px",
          },
          [theme.breakpoints.down("xs")]: {
            marginRight: "0",
            width: "100%",
            marginBottom: "10px",
          },
          "& .select-control": {
            marginRight: "52px",
            [theme.breakpoints.down("md")]: {
              marginRight: "50px",
            },
            [theme.breakpoints.down("sm")]: {
              marginRight: "30px",
            },
            [theme.breakpoints.down("xs")]: {
              marginRight: "0",
              width: "100%",
              marginBottom: "15px",
            },
            "&:last-child": {
              marginRight: "0",
              [theme.breakpoints.down("xs")]: {
                marginBottom: "0",
              },
            },
          },
        },
        "& .search-wrapper": {
          [theme.breakpoints.down("xs")]: {
            width: "100%",
          },
          "& .MuiInput-root": {
            width: "413px",
            maxWidth: "100%",
            [theme.breakpoints.down("md")]: {
              width: "308px",
            },
            [theme.breakpoints.down("sm")]: {
              width: "234px",
            },
            [theme.breakpoints.down("xs")]: {
              width: "100%",
            },
          },
          "& .MuiInputAdornment-positionStart": {
            marginRight: "13px",
          },
          "& .MuiInput-underline": {
            "&:before, &:after": {
              display: "none",
            },
          },
          "& .MuiSvgIcon-root": {
            color: "#2b2b2b",
            opacity: "0.671",
            fontSize: "22px",
            [theme.breakpoints.down("sm")]: {
              fontSize: "20px",
            },
          },
          "& .MuiInputBase-input": {
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0",
            color: "#000000",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            "&::-webkit-input-placeholder": {
              opacity: "0.369",
              color: "#000000",
            },
            "&:-ms-input-placeholder": {
              opacity: "0.369",
              color: "#000000",
            },
            "&::placeholder": {
              opacity: "0.369",
              color: "#000000",
            },
          },
        },
        "& .select-control": {
          "&.multi-select": {
            "& .multiselect-label": {
              display: "none",
            },
            "& .MuiInput-formControl": {
              marginTop: "0",
            },
            "& .MuiSelect-select": {
              paddingRight: "40px",
            },
            "& .multiple-clear": {
              position: "absolute",
              fontSize: "15px",
              right: "18px",
              top: "3px",
              zIndex: 1,
              color: "#94A3B8",
            },
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0",
            color: "#000000",
            opacity: "0.671",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            paddingBottom: "0",
            paddingTop: "0",
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiInput-underline:before": {
            display: "none",
          },
          "& .MuiInput-underline:after": {
            display: "none",
          },
        },
      },
    },
    switchWrapper: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& .MuiFormControlLabel-root": {
        opacity: "0.671",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0px",
        color: "#000000",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          width: "100%",
          justifyContent: "space-between",
          opacity: "1",
          color: "#2B2B2B",
        },
        "& .MuiFormControlLabel-label": {
          font: "inherit",
        },
      },
      "& .MuiSwitch-track": {
        backgroundColor: "#d3d9e1",
        opacity: 1,
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "#94A3B8",
        },
      },
      "& .MuiSwitch-root": {
        [theme.breakpoints.down("xs")]: {
          width: "33px",
          height: "20px",
          padding: "5px",
        },
        "& .MuiIconButton-root": {
          [theme.breakpoints.down("xs")]: {
            padding: "6px",
          },
        },
      },
      "& .MuiSwitch-switchBase": {
        color: "#fafafa",
        [theme.breakpoints.down("xs")]: {
          color: "#ffffff",
        },
        "&.Mui-checked": {
          color: "#3691a6",
          [theme.breakpoints.down("xs")]: {
            transform: "translateX(12px)",
          },
          "&+ .MuiSwitch-track": {
            backgroundColor: "#3691a6",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.2))",
        [theme.breakpoints.down("xs")]: {
          width: "8px",
          height: "8px",
        },
      },
    },
    emptyDataBox: {
      minHeight: "54vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .emptydata-row": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "830px",
        margin: "25px auto",
        [theme.breakpoints.down("md")]: {
          maxWidth: "661px",
        },
        [theme.breakpoints.down("xs")]: {
          flexWrap: "wrap",
          justifyContent: "center",
          textAlign: "center",
          maxWidth: "100%",
          padding: "0 24px",
          margin: "18px auto",
        },
        "& .emptydata-img": {
          maxWidth: "389px",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            maxWidth: "281px",
          },
          [theme.breakpoints.down("xs")]: {
            maxWidth: "327px",
          },
        },
      },
      "& .emptydata-content": {
        marginLeft: "98px",
        [theme.breakpoints.down("md")]: {
          marginLeft: "60px",
        },
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          marginTop: "50px",
          width: "100%",
        },
      },
      "& .heading": {
        fontSize: "20px",
        lineHeight: "24px",
        margin: "0 0 14px",
        color: "#000000",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: "400",
        [theme.breakpoints.down("md")]: {
          fontSize: "18px",
          lineHeight: "24px",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "18px",
          lineHeight: "21px",
          letterSpacing: "0.03em",
          color: "#363636",
          fontFamily: "Roboto",
          fontWeight: 700,
          margin: "0 0 16px",
        },
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "20px",
        margin: "0 0 48px",
        color: "#2b2b2b",
        opacity: "0.671",
        fontFamily: "Expletus Sans",
        fontWeight: "400",
        [theme.breakpoints.down("md")]: {
          margin: "0 0 32px",
        },
        [theme.breakpoints.down("xs")]: {
          letterSpacing: "0.03em",
          color: "#7A7A7A",
          margin: "0 0 24px",
        },
      },
    },
    tabWrapper: {
      "& .MuiTable-root": {
        "&.bulk-upload-confirm": {
          "& .MuiTableCell-head": {
            "&.MuiTableCell-paddingNone": {
              paddingLeft: "26px",
            },
          },
          "& .MuiTableCell-body": {
            "&.MuiTableCell-paddingNone": {
              paddingLeft: "26px",
            },
            "&.name-cell": {
              width: "26%",
              [theme.breakpoints.down("xs")]: {
                width: "40%",
              },
            },
            "&.email-cell": {
              width: "74%",
              [theme.breakpoints.down("xs")]: {
                width: "60%",
              },
            },
          },
        },
        "& .MuiTableCell-body": {
          "&.name-cell": {
            width: "15.3%",
            // [theme.breakpoints.down("md")]: {
            //   width: "15.4%",
            // },
          },
          "&.email-cell": {
            width: "15%",
            // [theme.breakpoints.down("md")]: {
            //   width: "21%",
            // },
            "& .table-link": {
              marginLeft: "0",
            },
          },
          "&.phone-cell": {
            width: "13%",
            // [theme.breakpoints.down("md")]: {
            //   width: "19.9%",
            // },
          },
          "&.group-cell": {
            width: "23.5%",
            // [theme.breakpoints.down("md")]: {
            //   width: "10.5%",
            // },
          },
          "&.company-cell": {
            width: "13%",
            // [theme.breakpoints.down("md")]: {
            //   width: "10.5%",
            // },
          },
          "&.business-cell": {
            width: "11%",
            // [theme.breakpoints.down("md")]: {
            //   width: "10.5%",
            // },
          },
          "&:last-child": {
            width: "auto",
          },
          "&.action-link": {
            width: "8.5%",
            // [theme.breakpoints.down("md")]: {
            //   width: "18.3%",
            // },
          },
        },
      },
      "& .MuiTabs-flexContainer": {
        margin: "0 33px",
        [theme.breakpoints.down("xs")]: {
          margin: "0 24px",
        },
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "#3691a6",
      },
      "& .MuiTabs-root": {
        borderBottom: "0",
        [theme.breakpoints.down("xs")]: {
          borderBottom: "1px solid #E8ECF2",
        },
      },
      "& .MuiTab-root": {
        fontSize: "14px",
        lineHeight: "20px",
        color: "#2b2b2b",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        textTransform: "unset",
        letterSpacing: 0,
        opacity: "0.671",
        [theme.breakpoints.up("sm")]: {
          minWidth: "166px",
        },
        [theme.breakpoints.down("xs")]: {
          width: "50%",
        },
        "&.Mui-selected": {
          opacity: "1",
        },
      },
      "& .MuiTabPanel-root": {
        padding: "0",
      },
      "& .button-wrapper": {
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down("xs")]: {
          justifyContent: "space-between",
        },
        "& .MuiButton-root": {
          marginRight: "15px",
          [theme.breakpoints.down("xs")]: {
            marginRight: "0",
            width: "48%",
            minWidth: "unset",
          },
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
    },
    selectOutline: {
      width: "100%",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(25,25,25,0.32)",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& .MuiFormLabel-root": {
        fontSize: "14px",
        lineHeight: "18px",
        color: "#94a3b8",
        letterSpacing: "0.0075em",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        display: "flex",
        width: "calc(100% - 28px)",
        "& .MuiInputLabel-asterisk": {
          marginLeft: "auto",
          fontSize: "18px",
          lineHeight: "23px",
          color: "#e86577",
        },
        "&.Mui-error": {
          color: "rgba(43, 43, 43, 0.87)",
        },
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "6px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "&.MuiOutlinedInput-adornedEnd": {
          paddingRight: "23px",
        },
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#b5b5b5",
        borderWidth: "1px",
      },
      "& .MuiOutlinedInput-input": {
        fontSize: "14px",
        lineHeight: "18px",
        color: "#2b2b2b",
        fontFamily: "Roboto",
        fontWeight: 400,
        letterSpacing: "0.0075em",
        padding: "20px 16px",
        "&:focus": {
          background: "transparent",
        },
        "&.MuiOutlinedInput-inputAdornedStart": {
          paddingLeft: "0",
        },
      },
      "& .MuiInputLabel-outlined": {
        color: "#94a3b8",
      },
      "& .MuiInputLabel-outlined.Mui-focused, & .MuiFormLabel-filled": {
        fontSize: "12px",
        lineHeight: "16px",
        color: "rgba(43,43,43,0.87)",
        letterSpacing: "0.0075em",
        fontFamily: "Roboto",
        fontWeight: "400",
        width: "auto",
        "& ~.MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
        },
        "&.Mui-error": {
          color: "rgba(43,43,43,0.87)",
        },
      },
      "& .MuiInputLabel-outlined.Mui-focused ": {
        "& ~.MuiOutlinedInput-root.Mui-error": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#C7263E",
          },
        },
      },
      "& .MuiOutlinedInput-root.Mui-error": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#C7263E",
        },
      },
      "& .MuiFormLabel-filled ~.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#C7263E",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "12px",
        letterSpacing: "0.0075em",
        color: "#00BA88",
        fontFamily: "Roboto",
        fontWeight: "400",
        "&.Mui-error": {
          color: "#C7263E",
        },
        "&.Mui-default": {
          color: "#2B2B2B",
        },
      },
    },
    modalDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      padding: "82px 60px",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("md")]: {
        width: 548,
        padding: "45px 60px",
      },
      [theme.breakpoints.down("sm")]: {
        width: 520,
        padding: "50px 40px",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "90%",
        padding: "30px 25px",
      },
      "&.filter-dialogbox": {
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
          padding: "24px",
          width: "100%",
        },
        "& .modal-heading": {
          marginBottom: "40px",
          "& .filter-heading": {
            display: "flex",
            alignItems: "center",
            "& .auth-back": {
              cursor: "pointer",
            },
            "& .modal-title ": {
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "Expletus Sans",
              marginLeft: "24px",
            },
          },
          "& .filter-link": {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "19px",
            color: "#4BA3B7",
            fontFamily: "Expletus Sans",
            textDecoration: "none",
          },
        },
        "& .modal-form": {
          marginTop: "0",
          "& .form-info-wrapper": {
            "& .form-heading": {
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              marginBottom: "16px",
              letterSpacing: "0",
            },
            "& .form-control": {
              "& .MuiInputBase-input": {
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-root": {
                "&.MuiOutlinedInput-adornedEnd": {
                  paddingRight: "27px",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  width: "20px",
                  height: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                padding: "20px 24px",
                "& .MuiOutlinedInput-inputAdornedEnd": {
                  paddingRight: "0",
                },
              },
            },
            "& .select-control": {
              "&.multi-select": {
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                  marginBottom: "32px",
                },
                "& .multiselect-label": {
                  display: "none",
                  [theme.breakpoints.down("sm")]: {
                    display: "block",
                    position: "static",
                    transform: "none",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "19px",
                    color: "#2B2B2B",
                    fontFamily: "Expletus Sans",
                    marginBottom: "16px",
                    letterSpacing: "0",
                  },
                },
                "& .MuiSelect-select": {
                  paddingRight: "40px",
                  [theme.breakpoints.down("sm")]: {
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    color: "#2B2B2B",
                    padding: "18px 40px 18px 24px",
                    lineHeight: "20px",
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  [theme.breakpoints.down("sm")]: {
                    borderColor: "#e8ecf2",
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      [theme.breakpoints.down("sm")]: {
                        borderWidth: "1px",
                      },
                    },
                  },
                },
                "& .multiple-clear": {
                  position: "absolute",
                  fontSize: "15px",
                  right: "18px",
                  top: "3px",
                  zIndex: 1,
                  color: "#94A3B8",
                  [theme.breakpoints.down("sm")]: {
                    top: "calc(50% + 17px)",
                    right: "27px",
                    transform: "translateY(-50%)",
                  },
                },
              },
              "& .MuiSelect-select": {
                fontSize: "14px",
                letterSpacing: "0",
                color: "#000000",
                opacity: "0.671",
                fontWeight: "500",
                fontFamily: "Expletus Sans",
                paddingBottom: "0",
                paddingTop: "0",
                "&:focus": {
                  backgroundColor: "transparent",
                },
              },
              "& .MuiInput-underline:before": {
                display: "none",
              },
              "& .MuiInput-underline:after": {
                display: "none",
              },
            },
          },
        },
        "& .modal-description": {
          [theme.breakpoints.down("xs")]: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
          },
        },
        "& .modal-footer": {
          [theme.breakpoints.down("xs")]: {
            marginTop: "auto",
          },
        },
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "8px",
      },
      "& .modal-title": {
        fontSize: "24px",
        lineHeight: "28px",
        color: "#000000",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        color: "#2b2b2b",
        letterSpacing: "0",
        fontWeight: 400,
        fontFamily: "Roboto",
        marginTop: "0",
        marginBottom: "27px",
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#2B2B2B",
        fontWeight: "500",
        "& .modal-link": {
          marginLeft: "3px",
        },
      },
      "& .modal-form": {
        marginTop: "47px",
      },
      "& .modal-footer": {
        marginTop: "44px",
      },
      "& .primary-btn": {
        minHeight: "56px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "17px",
        width: "100%",
      },
      "& .secondary-btn": {
        minHeight: "56px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "17px",
        width: "100%",
      },
    },
    
    viewContactModalDialog: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "#ffffff",
      padding: "0",
      width: 680,
      overflowY: "auto",
      borderRadius: "0",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        width: 570,
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "90%",
      },
      "&.merge-contact-modal": {
        width: 640,
        [theme.breakpoints.down("sm")]: {
          width: 570,
        },
        [theme.breakpoints.down("xs")]: {
          maxWidth: "90%",
        },
        "& .modal-heading": {
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          borderBottom: "1px solid #E8ECF2",
          padding: "24px 40px",
          marginBottom: "0",
          [theme.breakpoints.down("xs")]: {
            padding: "16px 24px",
          },
          "& .heading-links": {
            display: "flex",
            alignItems: "center",
            "& .heading-link": {
              marginRight: "24px",
              [theme.breakpoints.down("xs")]: {
                marginRight: "16px",
              },
              "&:last-child": {
                marginRight: "0",
              },
            },
          },
          "& .close-icon": {
            lineHeight: "0",
            color: "#94A3B8",
            "& .MuiSvgIcon-root": {
              height: "20px",
              width: "20px",
              fontSize: "20px",
            },
          },
        },
        "& .modal-title": {
          color: "#2B2B2B",
          fontSize: "22px",
          lineHeight: "26px",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          marginBottom: "16px",
          "& .priority-status": {
            marginRight: "5px",
            color: "#C7263E",
          },
        },
        "& .modal-description": {
          padding: "44px 60px 24px",
          [theme.breakpoints.down("xs")]: {
            padding: "16px 24px",
          },
          "& .profile-block-wrapper": {
            marginBottom: "32px",
            "& .profile-info": {
              "& .profile-outer": {
                margin: "0 auto 24px",
              },
              "& .profile-name": {
                justifyContent: "center",
              },
            },
          },
          "& .button-wrapper": {
            marginTop: "34px",
            "& .primary-btn": {
              marginBottom: "0",
            },
          },
        },
      },
      "&.view-modal-dialog": {
        width: 640,
        [theme.breakpoints.down("sm")]: {
          width: 570,
        },
        [theme.breakpoints.down("xs")]: {
          maxWidth: "90%",
        },
        "& .modal-heading": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "0",
          borderBottom: "1px solid #E8ECF2",
          padding: "24px 40px",
          [theme.breakpoints.down("xs")]: {
            padding: "16px 24px",
          },
          "& .heading-links": {
            display: "flex",
            alignItems: "center",
            "& .heading-link": {
              marginRight: "24px",
              [theme.breakpoints.down("xs")]: {
                marginRight: "16px",
              },
              "&:last-child": {
                marginRight: "0",
              },
            },
          },
          "& .close-icon": {
            lineHeight: "0",
            color: "#94A3B8",
            "& .MuiSvgIcon-root": {
              width: "20px",
              height: "20px",
              fontSize: "20px",
            },
          },
        },
        "& .modal-title": {
          fontSize: "22px",
          lineHeight: "26px",
          color: "#2B2B2B",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          marginBottom: "16px",
          "& .priority-status": {
            color: "#C7263E",
            marginRight: "5px",
          },
        },
        "& .modal-description": {
          padding: "44px 40px",
          [theme.breakpoints.down("xs")]: {
            padding: "16px 24px",
          },
        },
      },
      "&.filter-dialogbox": {
        width: 640,
        [theme.breakpoints.down("sm")]: {
          width: 570,
        },
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          maxWidth: "100%",
        },
        "& .modal-heading": {
          marginBottom: "40px",
          "& .filter-heading": {
            alignItems: "center",
            display: "flex",
            "& .auth-back": {
              cursor: "pointer",
              "& .MuiSvgIcon-root": {
                color: "rgba(43, 43, 43, 0.67)",
              },
            },
            "& .modal-title ": {
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "19px",
              marginLeft: "8px",
              fontFamily: "Expletus Sans",
              letterSpacing: "0",
              color: "#2B2B2B",
            },
          },
          "& .right-col": {
            alignItems: "center",
            display: "flex",
            "& .right-icon": {
              lineHeight: "0",
              color: "#94A3B8",
              marginRight: "24px",
              "&:last-child": {
                marginRight: "0",
              },
            },
          },
          "& .filter-link": {
            fontWeight: 500,
            lineHeight: "19px",
            fontSize: "14px",
            color: "#4BA3B7",
            textDecoration: "none",
            fontFamily: "Expletus Sans",
          },
        },
        "& .modal-dialoginner": {
          padding: "80px 40px",
          [theme.breakpoints.down("xs")]: {
            padding: "16px 24px",
          },
        },
        "& .modal-description": {
          "& .heading-block": {
            "& .primary-txt": {
              fontSize: "22px",
            },
            "& .sub-txt": {
              lineHeight: "16px",
              fontSize: "12px",
            },
          },
        },
      },
      "& .modal-dialoginner": {
        padding: "80px 60px",
      },
      "& .modal-heading": {
        marginBottom: "20px",
        justifyContent: "space-between",
        display: "flex",
        "& .modal-title ": {
          fontWeight: 400,
          lineHeight: "26px",
          fontSize: "22px",
          color: "#2b2b2b",
          fontFamily: "Expletus Sans",
        },
        "& .heading-left": {
          alignItems: "center",
          display: "flex",
          "& .auth-back": {
            marginRight: "8px",
            cursor: "pointer",
            color: "#2B2B2B",
            opacity: "0.67",
          },
          "& .modal-title ": {
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "19px",
            color: "#2b2b2b",
            fontFamily: "Expletus Sans",
          },
        },
        "& .close-icon": {
          lineHeight: "0",
          color: "#94A3B8",
        },
      },
      "& .modal-description": {
        "& .select-outer": {
          width: "100%",
          "& .MuiInputLabel-formControl": {
            fontWeight: 500,
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            letterSpacing: "0.1px",
            lineHeight: "19px",
            color: "#94A3B8",
            width: "calc(100% - 46px)",
            display: "flex",
            "&.MuiFormLabel-filled": {
              width: "auto",
            },
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            color: "#000000",
            letterSpacing: "0",
            opacity: "1",
            fontFamily: "Expletus Sans",
            fontWeight: "500",
            alignItems: "center",
            display: "flex",
            "&:focus": {
              backgroundColor: "transparent",
            },
            "& .status-name": {
              color: "#2B2B2B",
              fontWeight: 400,
              fontSize: "14px",
              letterSpacing: "0.105px",
              lineHeight: "26px",
            },
            "& .status": {
              width: "12px",
              background: "#94A3B8",
              height: "12px",
              marginLeft: "10px",
              borderRadius: "100%",
              "&.open": {
                background: "#4BA3B7",
              },
              "&.cancelled": {
                background: "#F7E223",
              },
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            },
          },
          "& .MuiFormLabel-root": {
            "&.Mui-focused": {
              width: "auto",
            },
            "& .MuiInputLabel-asterisk": {
              color: "#e86577",
              lineHeight: "23px",
              fontSize: "18px",
              marginLeft: "auto",
            },
          },
        },
        "& .taskview-list": {
          marginBottom: "24px",
          "& .MuiListItem-root": {
            padding: "16px 0",
            display: "flex",
            borderBottom: "1px solid #E8ECF2",
            alignItems: "center",
            "&:last-child": {
              borderBottom: "0",
            },
            "& .list-label": {
              width: "40%",
              lineHeight: "26px",
              fontSize: "14px",
              fontFamily: "Roboto",
              color: "#94A3B8",
              letterSpacing: "0.105px",
              fontWeight: 400,
            },
            "& .list-description": {
              width: "60%",
              fontSize: "14px",
              paddingLeft: "15px",
              lineHeight: "26px",
              fontFamily: "Roboto",
              color: "#2B2B2B",
              letterSpacing: "0.105px",
              fontWeight: 400,
              "& .list-subtitle": {
                lineHeight: "14px",
                fontSize: "10px",
                letterSpacing: "normal",
                color: "#94A3B8",
              },
              "& .assignee-list": {
                alignItems: "center",
                display: "flex",
                "& .assignee-bubble": {
                  alignItems: "center",
                  display: "flex",
                  background: "#ECF1F4",
                  justifyContent: "center",
                  height: "24px",
                  width: "24px",
                  borderRadius: "100%",
                  color: "rgba(43, 43, 43, 0.87)",
                  border: "2px solid #FFF",
                  fontSize: "9.586px",
                  fontWeight: 600,
                  fontFamily: "Roboto",
                  lineHeight: "13.42px",
                  position: "relative",
                  letterSpacing: "-0.244px",
                  marginLeft: "-4px",
                  "&:first-child": {
                    marginLeft: "0",
                  },
                },
              },
              "& .date-info": {
                display: "flex",
                marginBottom: "16px",
                alignItems: "center",

                "& .label": {
                  color: "#94A3B8",
                },
                "& .value": {
                  display: "flex",
                  marginLeft: "10px",
                  alignItems: "center",
                  "& .icon": {
                    marginLeft: "8px",
                  },
                },
              },
            },
          },
        },
        "& .tasklist-tabwrapper": {
          "& .assignee-tabs": {
            "& .MuiTabs-root": {
              marginBottom: "0",
            },
          },
          "& .MuiTabs-root": {
            marginBottom: "24px",
            borderBottom: "1px solid #E8ECF2",
            "& .MuiTabs-flexContainer": {
              padding: "0 24px",
            },
            "& .MuiTab-root": {
              fontSize: "14px",
              color: "#2B2B2B",
              fontWeight: 500,
              lineHeight: "20px",
              fontFamily: "Expletus Sans",
              textTransform: "capitalize",
              padding: "16px 24px",
              opacity: "0.67",
              letterSpacing: "0",
              minWidth: "unset",
              "&.Mui-selected": {
                opacity: "1",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            },
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#4BA3B7",
          },
          "& .description-box": {
            background: "#F2F8FC",
            borderRadius: "12px",
            padding: "16px",
            fontSize: "14px",
            color: "#2B2B2B",
            fontWeight: 400,
            letterSpacing: "0.105px",
            lineHeight: "26px",
            fontFamily: "Roboto",
          },
          "& .attachments-list": {
            "& .subheader-title": {
              display: "flex",
              position: "static",
              padding: "0",
              justifyContent: "space-between",
              borderBottom: "1px solid #E8ECF2",
              paddingBottom: "16px",
              "& .title": {
                color: "#2B2B2B",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                opacity: "0.67",
              },
              "& .tasklist-link": {
                fontSize: "14px",
                color: "#4BA3B7",
                fontFamily: "Expletus Sans",
                lineHeight: "19px",
                fontWeight: 500,
                textDecoration: "none",
                opacity: "0.67",
              },
            },
            "& .list-item": {
              paddingTop: "16px",
              borderBottom: "1px solid #E8ECF2",
              paddingLeft: "0",
              paddingBottom: "16px",
              "& .list-img": {
                marginRight: "8px",
                width: "28px",
              },
              "& .list-name": {
                color: "#2B2B2B",
                lineHeight: "16px",
                fontSize: "12px",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
              },
            },
            "& .list-secondary": {
              right: "0",
              "& .MuiIconButton-root": {
                margin: "0",
                padding: "0",
                color: "#828282",
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                },
              },
            },
          },
          "& .tab-heading": {
            justifyContent: "space-between",
            display: "flex",
            marginBottom: "24px",
            alignItems: "center",
            "& .heading-right": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              "& .heading-link": {
                marginRight: "24px",
                "&:last-child": {
                  marginRight: "0",
                },
              },
            },
            "& .primary-txt": {
              color: "#2B2B2B",
              fontWeight: 500,
              fontSize: "16px",
              letterSpacing: "0",
              lineHeight: "21px",
              fontFamily: "Expletus Sans",
              marginBottom: "5px",
            },
            "& .sub-txt": {
              color: "#94A3B8",
              fontWeight: 400,
              fontSize: "13px",
              lineHeight: "17px",
              fontFamily: "Roboto",
              letterSpacing: "0",
              marginBottom: "0",
            },
          },
        },
        "& .activitylist-ul": {
          padding: "0",
          "& .activity-li": {
            display: "flex",
            padding: "0",
            marginBottom: "32px",
            justifyContent: "space-between",
            position: "relative",
            background: "transparent",
            "&:last-child": {
              marginBottom: "0",
              "&:before": {
                display: "none",
              },
            },
            "&:before": {
              content: "''",
              position: "absolute",
              borderRight: "1px solid #E8ECF2",
              top: "100%",
              height: "100%",
              left: "16px",
            },
            "& .activity-details": {
              margin: "0",
              "& .activity-info": {
                fontSize: "12px",
                color: "#2B2B2B",
                lineHeight: "16px",
                fontWeight: 400,
                fontFamily: "Roboto",
                letterSpacing: "0.09px",
                marginBottom: "2px",
                "& .name": {
                  marginRight: "5px",
                  color: "#4BA3B7",
                },
              },
              "& .activity-date": {
                color: "#94A3B8",
                lineHeight: "17px",
                fontSize: "10px",
                fontFamily: "Roboto",
                letterSpacing: "0.125px",
                fontWeight: 400,
              },
            },
            "& .activity-icon": {
              minWidth: "unset",
              height: "32px",
              width: "32px",
              marginRight: "16px",
            },
          },
        },
        "& .heading-block": {
          marginBottom: "46px",
          "& .primary-txt": {
            fontSize: "24px",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Expletus Sans",
            lineHeight: "32px",
            fontWeight: 400,
            marginBottom: "6px",
          },
          "& .sub-txt": {
            fontSize: "14px",
            color: "#94A3B8",
            lineHeight: "26px",
            marginBottom: "0",
            letterSpacing: "0.105px",
          },
        },
        "& .tasklist-member": {
          padding: "0",
          "& .tasklist-li": {
            display: "flex",
            background: "transparent",
            justifyContent: "space-between",
            position: "relative",
            padding: "26px 24px 18px",
            borderBottom: "1px solid #ECF1F4",
            "& .tasklist-left": {
              display: "flex",
              alignItems: "center",
              "& .assignee-info": {
                marginLeft: "12px",
              },
              "& .assignee-profile": {
                display: "flex",
                width: "38px",
                height: "38px",
                background: "#ECF1F4",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Roboto",
                borderRadius: "100%",
                color: "rgba(43, 43, 43, 0.87)",
                fontSize: "15.485px",
                fontWeight: 600,
                letterSpacing: "-0.395px",
                lineHeight: "21.679px",
              },
              "& .assignee-name": {
                fontSize: "14px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
              },
              "& .assignee-email": {
                color: "#94A3B8",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                fontFamily: "Expletus Sans",
              },
            },
            "& .tasklist-icon": {
              minWidth: "unset",
            },
          },
        },
        "& .assignee-multiSelect-list": {
          paddingTop: "0",
          "& .MuiListSubheader-gutters": {
            position: "static",
            paddingLeft: "0",
            paddingRight: "0",
            "&.multiselect-subheader": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: "500",
              borderBottom: "1px solid #ECF1F4",
              lineHeight: "19px",
              marginBottom: "5px",
              padding: "15px 20px",
              "& .tasklist-link": {
                marginLeft: "10px",
                fontSize: "14px",
                color: "#C7263E",
                fontWeight: "500",
                fontFamily: "Expletus Sans",
                whiteSpace: "nowrap",
                lineHeight: "19px",
                textDecoration: "none",
              },
            },
            "&.multiselect-subtxt": {
              fontFamily: "Roboto",
              color: "#94A3B8",
              fontWeight: 400,
              fontSize: "10px",
              lineHeight: "normal",
              padding: "10px 20px",
              textTransform: "uppercase",
              borderBottom: "1px solid #ECF1F4",
            },
            "& .form-control": {
              marginBottom: "20px",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  fill: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-input": {
                lineHeight: "17px",
                fontSize: "13px",
                padding: "16px",
                letterSpacing: "0",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
          "& .MuiListItem-root": {
            cursor: "pointer",
            padding: "15px 20px",
          },
          "& .multiselect-membres": {
            alignItems: "center",
            display: "flex",
            width: "100%",
            padding: "15px 20px",
            justifyContent: "space-between",
            borderBottom: "1px solid #ECF1F4",
            "& .total-member": {
              color: "#2B2B2B",
              fontSize: "12px",
              fontFamily: "Expletus Sans",
              lineHeight: "19px",
              fontWeight: 500,
            },
            "& .select-control": {
              "& .MuiSelect-select": {
                fontSize: "12px",
                color: "#2b2b2b",
                opacity: "1",
                letterSpacing: "0",
              },
            },
          },
          "& .assignee-detail": {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& .assignee-left": {
              display: "flex",
              "& .assignee-info": {
                marginLeft: "10px",
              },
              "& .assignee-profile": {
                width: "39px",
                height: "39px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#ECF1F4",
                borderRadius: "100%",
                color: "rgba(43, 43, 43, 0.87)",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontWeight: 600,
                letterSpacing: "-0.395px",
                lineHeight: "21.679px",
              },
              "& .assignee-name": {
                lineHeight: "19px",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                color: "#2b2b2b",
                fontWeight: 500,
              },
              "& .assignee-email": {
                fontSize: "12px",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
              },
            },
            "& .assignee-right": {
              marginLeft: "10px",
              "& .assinee-selected": {
                width: "20px",
                height: "20px",
                "& .normal-img": {
                  display: "none",
                },
                "& .selected-img": {
                  display: "block",
                },
              },
              "& .assinee-unselected": {
                width: "20px",
                height: "20px",
                "& .normal-img": {
                  display: "block",
                },
                "& .selected-img": {
                  display: "none",
                },
              },
            },
          },
        },
        "& .button-wrapper": {
          marginTop: "48px",
          [theme.breakpoints.down("xs")]: {
            marginTop: "32px",
          },
          "&.full-btn": {
            "& .MuiButton-root": {
              width: "100%",
            },
          },
        },
        "& .profile-block-wrapper": {
          marginBottom: "17px",
          "& .profile-info": {
            "& .profile-row": {
              flexWrap: "wrap",
              justifyContent: "space-between",
              marginBottom: "8px",
              display: "flex",
              "& .right-col": {
                marginLeft: "15px",
                marginTop: "4px",
              },
            },
            "& .heading-avatar": {
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "32px",
              "& .wrap-avatar": {
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                marginBottom:"16px",
                flexDirection: "row",
                "& .shortcut-name": {
                  fontFamily: "Expletus Sans",
                  position: "absolute",
                  color: "var(--White, #FFF)",
                  fontSize: "32px",
                  fontWeight: "600",
                  fontStyle: "normal",
                  lineHeight: "42px",
                },
              },
            },
            "& .profile-outer": {
              height: "160px",
              width: "160px",
              padding: "10px",
              border: "1px dashed rgba(43, 43, 43, 0.87)",
              borderRadius: "100%",
              margin: "0 auto 16px",
              [theme.breakpoints.down("xs")]: {
                height: "140px",
                width: "140px",
              },
              [theme.breakpoints.down("sm")]: {
                height: "120px",
                width: "120px",
              },
              "& .profile-icon": {
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                width: "100%",
                background: "#4BA3B7",
                height: "100%",
                fontWeight: 600,
                borderRadius: "inherit",
                fontSize: "43px",
                color: "#FFFFFF",
                lineHeight: "56px",
                overflow: "hidden",
                fontFamily: "Expletus Sans",
                textTransform: "uppercase",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "38px",
                  lineHeight: "48px",
                },
                [theme.breakpoints.down("xs")]: {
                  fontSize: "32px",
                  lineHeight: "42px",
                },
              },
            },
            "& .profile-name": {
              fontSize: "18px",
              fontWeight: 400,
              color: "#000000",
              lineHeight: "24px",
              fontFamily: "Expletus Sans",
              marginBottom: "8px",
              alignItems: "center",
              display: "flex",
              "& .edit-icon": {
                marginLeft: "8px",
                height: "20px",
                width: "20px",
                cursor:"pointer",
              },
            },
            "& .job-info": {
              fontSize: "13px",
              fontWeight: 400,
              letterSpacing: "0",
              lineHeight: "17px",
              color: "rgba(43, 43, 43, 0.67)",
              marginBottom: "14px",
              fontFamily: "Roboto",
            },
          },
          "& .contact-statistics": {
            "& .statistics-col": {
              justifyContent: "flex-end",
              display: "flex",
              marginBottom: "24px",
              [theme.breakpoints.down("xs")]: {
                marginBottom: "16px",
              },
              "& .content": {
                fontFamily: "Expletus Sans",
                color: "rgba(43, 43, 43, 0.87)",
                fontSize: "14px",
                lineHeight: "19px",
                fontWeight: 500,
                marginRight: "6px",
                opacity: "0.67",
                [theme.breakpoints.down("xs")]: {
                  fontWeight: "400",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  letterSpacing: "0.09px",
                  lineHeight: "16px",
                },
              },
              "& .value": {
                color: "rgba(43, 43, 43, 0.87)",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                marginRight: "6px",
                lineHeight: "19px",
              },
            },
          },
        },
        "& .contact-info-wrapper": {
          "& .info-block": {
            marginBottom: "24px",
            paddingBottom: "7px",
            borderBottom: "1px solid #E8ECF2",
            "&:last-child": {
              paddingBottom: "0",
              marginBottom: "0",
              borderBottom: "0",
            },
            "& .info-heading": {
              color: "#000000",
              fontSize: "16px",
              fontFamily: "Roboto",
              lineHeight: "22px",
              fontWeight: 400,
              marginBottom: "16px",
            },
            "& .about-info-row": {
              flexWrap: "wrap",
              display: "flex",
              margin: "0 -24px",
              [theme.breakpoints.down("sm")]: {
                margin: "0 -12px",
              },
              "& .about-col": {
                width: "33.33%",
                marginBottom: "16px",
                padding: "0 24px",
                [theme.breakpoints.down("sm")]: {
                  padding: "0 12px",
                },
                [theme.breakpoints.down("xs")]: {
                  width: "100%",
                },
                "& .label": {
                  color: "#94A3B8",
                  fontSize: "10px",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  letterSpacing: "0.125px",
                  lineHeight: "17px",
                  marginBottom: "6px",
                },
                "& .value": {
                  color: "#000000",
                  fontSize: "13px",
                  fontFamily: "Roboto",
                  lineHeight: "17px",
                  fontWeight: 400,
                },
              },
            },
          },
        },
        "& .contact-accodion": {
          "& .select-control": {
            "& .MuiSelect-select": {
              paddingRight: "0",
              "& .down-arrow": {
                position:"absoulute",
                color: "rgba(43, 43, 43, 0.67)",
              },
            },
          },
          "& .MuiAccordion-root": {
            boxShadow: "none",
            borderRadius: "0",
            margin: "0 0 10px",
            "&:before": {
              display: "none",
            },
            "& .MuiAccordionSummary-root": {
              padding: "0",
              marginBottom: "0px",
              minHeight: "unset",
              [theme.breakpoints.down("sm")]: {
                marginBottom: "16px",
              },
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
              "& .MuiIconButton-root": {
                padding: "0",
                marginRight: "0",
                "& .MuiSvgIcon-root": {
                  height: "20px",
                  width: "20px",
                  color: "#94A3B8",
                  fontSize: "20px",
                },
              },
            },
            "& .MuiAccordionDetails-root": {
              padding: "0",
              flexDirection: "column",
              display: "flex",
            },
          },
          "& .content-heading": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "16px",
            borderBottom: "1px solid #E8ECF2",
            marginBottom: "16px",
            "& .content-icon":{
              cursor:"pointer",
            },
            "& .title": {
              color: "rgba(43, 43, 43, 0.67)",
              fontSize: "12px",
              fontFamily: "Expletus Sans",
              lineHeight: "16px",
              fontWeight: 500,
            },
            "& .content-filter": {
              alignItems: "center",
              display: "flex",
              "& .select-control": {
                "& .MuiSelect-select": {
                  fontSize: "12px",
                  position: "relative",
                  zIndex: 9,
                  paddingRight: "30px", // this is important
                },
                "&.border-hr": {
                  paddingRight: "10px",
                  marginRight: "10px",
                },
                "& .down-arrow": {
                  width: "20px",
                  fontSize: "20px",
                  color: "rgba(43, 43, 43, 0.67)",
                  height: "20px",
                  position:"absolute", // this is important
                  right:"0",
                  zIndex: 0,
                },
              },
            },
          },

          "& .wrap-add-note-btn": {
            flexDirection: "row",
            display: "flex",
            justifyContent: "flex-end",
            "& .cancel-add-note-btn": {
              textDecoration: "none",
              display: "flex",
              textTransform: "none",
              width: "156px",
              height: "48px",
              justifyContent: "center",
              padding: "8px 14px",
              alignItems: "center",
              background: "#fff",
              borderRadius: "6px",
              color: "#000",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              fontSize: "14px",
              marginRight: "16px",
              border: "1px solid #E8ECF2",
            },
            "& .add-note-btn": {
              display: "flex",
              padding: "8px 24px",
              height: "48px",
              justifyContent: "center",
              borderRadius: "6px",
              alignItems: "center",
              background: "#4BA3B7",
              fontFamily: "Expletus Sans",
              color: "#FAFCFE",
              fontSize: "14px",
              fontWeight: "500",
              fontStyle: "normal",
              marginBottom: "24px",
              lineHeight: "normal",
              textDecoration: "none",
              textTransform: "none",
              width: "156px",
            },
            "& .add-note-btn-disable": {
              height: "48px",
              display: "flex",
              padding: "8px 24px",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "6px",
              color: "#FAFCFE",
              background: "#92C7D3",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontStyle: "normal",
              lineHeight: "normal",
              fontWeight: "500",
              marginBottom: "24px",
              textDecoration: "none",
              textTransform: "none",
              width: "156px",
            },
          },
          "& .content-details": {
            width: "100%",
            "& .content-inner": {
              width: "100%",
              display: "flex",
              marginBottom: "16px",
              borderBottom: "1px solid #E8ECF2",
              paddingBottom: "22px",
              wordBreak: "break-word",
              "&.no-opacity":{
                opacity : 1,
              },
              "&.grey-color":{
                opacity : 0.5,
              },
              "& .assignee-profile": {
                width: "24px",
                display: "flex",
                height: "24px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "100%",
                background: "#ECF1F4",
                color: "rgba(43, 43, 43, 0.87)",
                fontSize: "10px",
                fontFamily: "Roboto",
                lineHeight: "14px",
                fontWeight: 600,
                letterSpacing: "-0.395px",
              },
              "& .assignee-info": {
                flex: 1,
                marginLeft: "8px",
                "& .top-info": {
                  justifyContent: "space-between",
                  marginBottom: "4px",
                  display: "flex",
                  [theme.breakpoints.down("xs")]: {
                    flexDirection: "column",
                  },
                  "& .info-left": {
                    display: "flex",
                    "& .select-modal-field": {
                      "& .form-control": {
                        marginLeft: "6px",
                        marginBottom: "0",
                        "& .MuiOutlinedInput-input": {
                          width: "0",
                          padding: "0",
                        },
                        "& .MuiInputAdornment-positionEnd": {
                          marginLeft: "0",
                      },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "0",
                          padding: "0",
                          justifyContent: "flex-start",
                          display: "flex",
                          "& .MuiOutlinedInput-notchedOutline": {
                            padding: "0",
                            borderColor: "transparent",
                          }
                        }
                      },      
                      "& .MuiInputAdornment-positionEnd": {
                        "& .MuiSvgIcon-root": {
                          color: "#94A3B8",
                          fontSize: "20px",
                          marginLeft:"-8px",
                        },
                      },      
                    },
                    "& .status-item": {
                      alignItems: "center",
                      display: "flex",
                      "& .status-name": {
                        textTransform: "capitalize",
                        marginRight: "4px",
                        color:"#2B2B2B",
                        fontSize:"10px",
                      },
                      "& .status": {
                        width: "8px",
                        height: "8px",
                        background: "#94A3B8",
                        borderRadius: "100%",
                        marginLeft: "auto",
                        "&.open": {
                          background: "#4BA3B7",
                        },
                        "&.cancelled": {
                          background: "#F7E223",
                        },
                        "&.yellow": {
                          background: "#F7E223",
                        },
                        "&.sky-blue": {
                          background: "#12AEF1",
                        },
                        "&.green": {
                          background: "#00BA88",
                        },
                        "&.orange": {
                          background: "#F6A318",
                        },
                        "&.black": {
                          background: "#2B2B2B",
                        },
                      },
                    },
                    "& .name": {
                      color: "#4F4F4F",
                      fontSize: "10px",
                      fontFamily: "Roboto",
                      lineHeight: "17px",
                      fontWeight: 400,
                      letterSpacing: "0.125px",
                    },
                    "& .stage-control": {
                      marginLeft: "6px",
                    },
                  },
                  "& .date": {
                    display: "flex",
                    marginLeft: "10px",
                    alignItems: "center",
                    color: "#94A3B8",
                    fontSize: "10px",
                    fontFamily: "Roboto",
                    lineHeight: "17px",
                    fontWeight: 400,
                    letterSpacing: "0.125px",
                    [theme.breakpoints.down("xs")]: {
                      marginTop: "2px",
                      marginLeft: "0",
                    },
                    "& .attach-icon": {
                      marginRight: "8px",
                    },
                  },
                },
                "& .primary-txt": {
                  lineHeight: "19px",
                  fontSize: "14px",
                  color: "#2b2b2b",
                  fontWeight: 600,
                  fontFamily: "Expletus Sans",
                  marginBottom: "8px",
                },
                "& .assignee-desc": {
                  fontSize: "12px",
                  color: "#4F4F4F",
                  lineHeight: "17px",
                  fontFamily: "Roboto",
                  maxWidth: "282px",
                  fontWeight: 400,
                  width: "100%",
                },
                "& .assignee-desc-dark": {
                  fontSize: "12px",
                  color: "#2b2b2b",
                  fontFamily: "Roboto",
                  lineHeight: "17px",
                  maxWidth: "282px",
                  fontWeight: 400,
                  width: "100%",
                },
                "& .edit-note-wrapper": {
                  flexDirection: "column",
                  display: "flex",
                  marginTop: "8px",

                  "& .txt-edit-note": {
                    width: "100%",
                  },
                  "& .form-control": {
                    marginBottom: "12px", 
                  },
                  "& .wrap-button": {
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "flex-end",
                    "& .btn-cancel": {
                      textTransform: "none",
                      textDecoration: "none",
                      width: "156px",
                      display: "flex",
                      height: "48px",
                      justifyContent: "center",
                      padding: "8px 14px",
                      alignItems: "center",
                      borderRadius: "6px",
                      color: "#000",
                      background: "#fff",
                      fontSize: "14px",
                      fontFamily: "Expletus Sans",
                      border: "1px solid #E8ECF2",
                      fontWeight: "500",
                      marginRight: "16px",
                    },
                    "& .btn-save": {
                      textDecoration: "none",
                      display: "flex",
                      textTransform: "none",
                      height: "48px",
                      width: "156px",
                      justifyContent: "center",
                      padding: "8px 14px",
                      alignItems: "center",
                      background: "#4BA3B7",
                      borderRadius: "6px",
                      color: "#F2F8FC",
                      fontSize: "14px",
                      fontFamily: "Expletus Sans",
                      fontWeight: "500",
                    },
                  },
                },
              },
            },
          },
          "& .product-list-row": {
            flexWrap: "wrap",
            display: "flex",
            margin: "0 -12px",
            [theme.breakpoints.down("sm")]: {
              margin: "0 -8px",
            },
            "& .product-col": {
              padding: "0 12px",
              marginBottom: "24px",
              [theme.breakpoints.down("sm")]: {
                marginBottom: "16px",
                padding: "0 8px",
              },
              "&.col4": {
                flex: "0 0 33.33%",
                maxWidth: "33.33%",
                [theme.breakpoints.down("xs")]: {
                  maxWidth: "50%",
                  flex: "0 0 50%",
                },
              },
            },
            "& .product-inner": {
              borderRadius: "12px",
              padding: "24px 24px 15px",
              border: "1px solid #E8ECF2",
              position: "relative",
              height: "231px",
              [theme.breakpoints.down("sm")]: {
                padding: "24px 16px 16px",
              },
              "& .product-img": {
                textAlign: "center",
                height: "72px",
                width: "72px",
                margin: "0 auto 24px",
                maxWidth: "100%",
              },
              "& .product-details": {
                display: "flex",
                flexDirection: "column",
                justifyContent:" space-between",
                "& .product-info": {
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  fontFamily: "Roboto",
                  color: "#94A3B8",
                  fontSize: "10px",
                  lineHeight: "17px",
                  fontWeight: 400,
                  marginBottom: "4px",
                  letterSpacing: "0.125px",
                  "& .rating": {
                    display: "flex",
                    marginLeft: "10px",
                    alignItems: "center",
                    position: "relative",
                    paddingLeft: "14px",
                    [theme.breakpoints.down("sm")]: {
                      marginLeft: "6px",
                      paddingLeft: "5px",
                    },
                    "&:before": {
                      content: "''",
                      height: "4px",
                      width: "4px",
                      background: "#E0E0E0",
                      borderRadius: "100%",
                      left: 0,
                      position: "absolute",
                    },
                    "& .star-icon": {
                      color: "#F6A318",
                      height: "10px",
                      width: "10px",
                      marginLeft: "2px",
                      fontSize: "10px",
                    },
                  },
                },
                "& .product-name": {
                  color: "#2B2B2B",
                  fontSize: "14px",
                  fontFamily: "Expletus Sans",
                  lineHeight: "19px",
                  fontWeight: 500,
                  marginBottom: "4px",
                },
                "& .product-desc": {
                  color: "#94A3B8",
                  fontSize: "10px",
                  fontFamily: "Roboto",
                  lineHeight: "17px",
                  fontWeight: 400,
                  letterSpacing: "0.125px",
                  display:"flex",
                  alignItems:"center",
                  marginTop:"6px",
                  "& .arrow-icon":{
                    width:"20px",
                  }
                },
              },
            },
            "& .close-icon": {
              color: "rgba(43, 43, 43, 0.67)",
              right: "16px",
              position: "absolute",
              width: "20px",
              top: "16px",
              fontSize: "20px",
              height: "20px",
              "& .MuiSvgIcon-root": {
                height: "inherit",
                width: "inherit",
                fontSize: "inherit",
                color: "inherit",
              },
            },
          },
          "& .accodion-heading": {
            color: "#000000",
            fontSize: "16px",
            fontFamily: "Roboto",
            lineHeight: "22px",
            fontWeight: 400,
          },
        },
        "& .mergecontact-wrapper": {
          "& .contact-heading": {
            fontSize: "16px",
            color: "#000",
            fontWeight: 400,
            fontFamily: "Expletus Sans",
            lineHeight: "24px",
            marginBottom: "16px",
          },
          "& .merge-list": {
            padding: "0",
            "& .list-item": {
              marginBottom: "16px",
              padding: "0 0 16px",
              borderBottom: "1px solid #ECF1F4",
              "& .MuiListItemText-root": {
                margin: "0",
              },
              "& .list-label": {
                fontSize: "12px",
                color: "#94A3B8",
                fontFamily: "Roboto",
                fontWeight: 400,
                letterSpacing: "0.09px",
                lineHeight: "16px",
                marginBottom: "8px",
              },
              "& .MuiFormControl-root": {
                width: "100%",
                "& .MuiFormControlLabel-root": {
                  justifyContent: "space-between",
                  margin: "0",
                  marginBottom: "4px",
                  "&:last-child": {
                    marginBottom: "0",
                  },
                  "& .MuiFormControlLabel-label": {
                    fontFamily: "Roboto",
                    color: "rgba(43, 43, 43, 0.87)",
                    fontWeight: 400,
                    fontSize: "14px",
                    letterSpacing: "0.105px",
                    lineHeight: "26px",
                  },
                },
                "& .MuiRadio-root": {
                  padding: "0",
                },
              },
            },
          },
        },
        "& .contact-group-list": {
          padding: "0",
          "& .contact-li": {
            justifyContent: "space-between",
            display: "flex",
            position: "relative",
            background: "transparent",
            padding: "16px 20px",
            [theme.breakpoints.down("xs")]: {
              padding: "16px 0",
            },
            "& .contact-left": {
              "& .name": {
                fontSize: "14px",
                color: "#2b2b2b",
                lineHeight: "19px",
                fontWeight: 400,
                fontFamily: "Expletus Sans",
                marginBottom: "4px",
              },
              "& .sub-txt": {
                fontSize: "10px",
                color: "#94A3B8",
                lineHeight: "17px",
                fontFamily: "Roboto",
                letterSpacing: "0.125px",
                fontWeight: 400,
                marginBottom: "0",
              },
            },
            "& .contact-icon": {
              minWidth: "unset",
              "& .MuiCheckbox-root": {
                padding: "0",
              },
              "& .MuiIconButton-colorSecondary": {
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            },
          },
        },
        "& .multiselect-membres": {
          display: "flex",
          width: "auto",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: "unset",
          padding: "15px 60px",
          margin: "0 -40px",
          borderBottom: "1px solid #ECF1F4",
          [theme.breakpoints.down("xs")]: {
            margin: "0",
            padding: "15px 0",
          },
          "& .total-member": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "19px",
          },
          "& .select-control": {
            "& .MuiSelect-select": {
              fontSize: "12px",
              color: "#2b2b2b",
              opacity: "1",
              paddingRight: "0",
              letterSpacing: "0",
            },
            "& .down-arrow": {
              marginLeft: "6px",
              fontSize: "20px",
            },
          },
        },
      },
      "& .sub-txt": {
        fontSize: "14px",
        color: "#2b2b2b",
        letterSpacing: "0",
        lineHeight: "24px",
        fontFamily: "Roboto",
        marginBottom: "27px",
        fontWeight: 400,
        marginTop: "0",
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .form-info-wrapper": {
        margin: "0 0 28px",
        "&:last-child": {
          margin: "0",
        },
        "& .form-control": {
          "& .MuiInputAdornment-root": {
            "& .MuiSvgIcon-root": {
              fill: "#959595",
              fontSize: "20px",
            },
          },
        },
      },
      "& .form-heading": {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#2B2B2B",
        fontFamily: "Roboto",
        margin: "0 0 16px",
        letterSpacing: "0",
      },
      "& .profile-team": {
        "& .team-label": {
          fontWeight: 400,
          fontSize: "10px",
          textTransform: "uppercase",
          lineHeight: "12px",
          color: "#94A3B8",
          marginBottom: "8px",
          fontFamily: "Roboto",
        },
        "& .chip-wrapper": {
          "& .MuiChip-root": {
            padding: "5px",
            minHeight: "24px",
          },
        },
      },
      "& .bottom-link": {
        lineHeight: "20px",
        textAlign: "center",
        fontSize: "14px",
        letterSpacing: "0",
        color: "#2B2B2B",
        fontWeight: "500",
        "& .modal-link": {
          marginLeft: "3px",
        },
      },
      "& .modal-form": {
        marginTop: "47px",
      },
      "& .current-team": {
        color: "#94A3B8",
        fontSize: "14px",
        fontFamily: "Expletus Sans",
        padding: "17px 16px",
        lineHeight: "20px",
        fontWeight: 500,
        letterSpacing: "0.1px",
        border: "1px solid #e8ecf2",
        borderRadius: "6px",
      },
      "& .select-control": {
        "&.multi-select": {
          "& .multiselect-label": {
            display: "none",
          },
          "& .MuiInput-formControl": {
            marginTop: "0",
          },
          "& .MuiSelect-select": {
            alignItems: "center",
            paddingRight: "40px",
            display: "flex",
            "& .select-icon": {
              marginRight: "6px",
            },
          },
          "& .multiple-clear": {
            right: "18px",
            fontSize: "15px",
            position: "absolute",
            top: "3px",
            zIndex: 1,
            color: "#94A3B8",
          },
        },
        "&.status-control": {
          padding: "6px 12px",
          background: "rgba(18, 174, 241, 0.07)",
          borderRadius: "12px",
          "& .down-arrow": {
            height: "16px",
            width: "16px",
          },
          "& .MuiSelect-select": {
            fontSize: "12px",
            fontFamily: "Roboto",
            color: "#12AEF1",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.09px",
            opacity: "1",
            paddingRight: "5px",
          },
        },
        "&.stage-control": {
          "& .MuiSelect-select": {
            fontSize: "10px",
            color: "#2B2B2B",
            fontFamily: "Roboto",
            letterSpacing: "0.125px",
            fontWeight: 400,
            lineHeight: "17px",
            display: "flex",
            alignItems: "center",
          },
          "& .down-arrow": {
            height: "16px",
            fontSize: "16px",
            color: "rgba(43, 43, 43, 0.67)",
            width: "16px",
          },
          "& .status": {
            background: "#94A3B8",
            width: "8px",
            height: "8px",
            marginLeft: "6px",
            borderRadius: "100%",
            "&.open": {
              background: "#4BA3B7",
            },
            "&.cancelled": {
              background: "#F7E223",
            },
          },
        },
        "& .select-icon": {
          marginRight: "6px",
          position: "relative",
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          color: "rgba(43, 43, 43, 0.87)",
          opacity: "0.671",
          fontWeight: "500",
          paddingTop: "0",
          fontFamily: "Expletus Sans",
          paddingBottom: "0",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .clear-icon": {
            width: "50px",
            height: "50px",
            background: "red",
          },
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
        "&.border-hr": {
          marginRight: "24px",
          paddingRight: "24px",
          borderRight: "1px solid #E8ECF2",
        },
      },
      "& .modal-footer": {
        marginTop: "44px",
        "& .half-btn": {
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          flexWrap: "wrap",
          "& .MuiButton-root": {
            width: "auto",
            marginRight: "10px",
            marginBottom: "0",
            "&:last-child": {
              marginRight: "0",
            },
            "&.primary-btn": {
              padding: "12px 38px",
            },
            "&.secondary-btn": {
              minWidth: "140px",
            },
          },
        },
      },
      "& .primary-btn": {
        minHeight: "56px",
        lineHeight: "19px",
        fontSize: "14px",
        width: "100%",
        marginBottom: "17px",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px",
        },
      },
      "& .secondary-btn": {
        fontSize: "14px",
        minHeight: "56px",
        marginBottom: "17px",
        lineHeight: "19px",
        width: "100%",
      },
    },
    modalDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
    },
    modalLink: {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0",
      color: "#4ba3b7",
      fontWeight: 500,
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
    },
    chipButton: {
      background: "#ECF1F4",
      fontWeight: 400,
      borderRadius: "6px",
      lineHeight: "12px",
      fontSize: "10px",
      padding: "5px 6px",
      color: "#2B2B2B",
      cursor: "pointer",
      marginBottom: "8px",
      marginRight: "8px",
      height: "auto",
      minHeight: "26px",
      border: "1px solid transparent",
      fontFamily: "Roboto",
      "&.last-child": {
        marginRight: "0",
      },
      "&.secondary-chip": {
        borderColor: "#4BA3B7",
        color: "#4BA3B7",
        backgroundColor: "transparent",
      },
      "&.primary-chip": {
        color: "#FFFFFF",
        backgroundColor: "#4BA3B7",
      },
      "& .MuiChip-avatar": {
        width: "14px",
        height: "14px",
        marginLeft: "0",
        marginRight: "5px",
      },
      "& .MuiChip-label": {
        padding: "0",
      },
    }
  });

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <PermMediaIcon />,
    2: <CreateIcon />,
    3: <CreateIcon />,
  };

  let className: string = "";

  if (completed) {
    className = "completed";
  } else if (active) {
    className = "active";
  }

  return (
    <Box
      className={`stepIcon ${className}`}
    >
      {completed ? (
        <Check className="completed" />
      ) : (
        <>{icons[String(props.icon)]}</>
      )}
    </Box>
  );
}

function getSteps(activeStep: number,t:any) {
  return [
    <>
      <Box className="step-no">{activeStep > 0 ? "Complete" : " Step 1"} </Box>
      <Box className="step-label">{t(`${configJSON.bulkUploadTxt}`)}</Box>
    </>,
    <>
      <Box className="step-no">{activeStep > 1 ? "Complete" : " Step 2"} </Box>
      <Box className="step-label">{t(`${configJSON.preview}`)}</Box>
    </>,
    <>
      <Box className="step-no">Step 3</Box>
      <Box className="step-label">{t(`${configJSON.finish}`)}</Box>
    </>,
  ];
}

function viewBulkTabProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export function BulkTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
const displayAddress = (address: any) => {
  if (!address) return "";
  const { address1, address2, state, city, pin_code, country } = address;
  return `${address1 ?? ""} ${address2 ?? ""} ${state ?? ""} ${city ?? ""} ${pin_code ?? ""} ${country ?? ""}`.trim();
}
export class BulkUploadContact extends BulkUploadContactController {
  listTeamsData = () => {
    if (this.state.isFilteringTeam) {
      if (this.state.filteredDataTeam.length > 0) {
        return (
          <ContactBulkGroupTable
            renderTeams={this.state.filteredDataTeam}
            getCount={this.getCountEvent}
            tableType="groups"
            data-test-id="groups-event"
            showDifferentLabel={true}
            multipleDelete={this.multipleDelete}
            getSelectedRecords={this.getSelectedRecords}
          />
        );
      } else {
        return <Box className="no-member">{this.t(`${configJSON.noGroupFound}`)}</Box>;
      }
    } else if (this.state.teamsList.length > 0) {
      return (
        <ContactBulkGroupTable
          renderTeams={this.state.teamsList}
          getCount={this.getCountEvent}
          tableType="groups"
          data-test-id="groups-event"
          showDifferentLabel={true}
          multipleDelete={this.multipleDelete}
          getSelectedRecords={this.getSelectedRecords}
        />
      );
    } else {
      return <Box className="no-member">{this.t(`${configJSON.noGroupFound}`)}</Box>;
    }
  };
  contactGroupData = () => {
    if (this.state.isFiltering) {
      if (this.state.filteredData.length > 0) {
        return (
          <ContactsList
            renderMembers={this.state.filteredData}
            showDeleteInMembers={true}
            deleteMember={this.deleteMemberHandler}
            getSelectedRecords={this.getSelectedRecords}
            multipleDelete={this.multipleDelete}
            showDifferentLabel={true}
          />
        );
      } else {
        return <Box className="no-member">{this.t(`${configJSON.notFoundTxt}`)}</Box>;
      }
    } else if (this.state.membersList.length > 0) {
      return (
        <ContactsList
          renderMembers={this.state.membersList}
          showDeleteInMembers={true}
          deleteMember={this.deleteMemberHandler}
          getSelectedRecords={this.getSelectedRecords}
          multipleDelete={this.multipleDelete}
          showDifferentLabel={true}
          data-test-id="contact-tab"
          openViewContact={this.ViewContactDetail}
        />
      );
    } else {
      return <Box className="no-member">{this.t(`${configJSON.notFoundTxt}`)}</Box>;
    }
  };
  getMyGroups = (contactGroups:any) => {
    const {classes} = this.props;
    const userGroups = contactGroups;
    const filteredGroups = userGroups.filter((group: any) => group !== null);
    const defGroupLength: number = 2;
    const displayGroups = this.state.showMoreGroups
      ? filteredGroups
      : filteredGroups.slice(0, defGroupLength);
    return (
      <>
      {displayGroups.length > 0 ? <>
        {displayGroups.map((group: any) => {
          return (
            <Chip
              key={group?.title}
              className={classes.chipButton}
              label={group?.title}
            />
          );
        })}
      </>
        : <Box className="team-label">No groups</Box>}
      </>
    );
  };
  getStepContent = (step: any) => {
    const { classes } = this.props;
    const steps = getSteps(this.state.activeStep,this.t);
    const { activeStep } = this.state;

    this.listTeamsData();
    this.contactGroupData();

    const getPluralizedLabel = (count:any, singularLabel:any, pluralLabel:any) =>
    `${count} ${count !== 1 ? pluralLabel : singularLabel}`;
  
    const label = this.state.isTeamsTab
    ? this.t(configJSON.groupText)
    : this.t(configJSON.contactText);
  
  const pluralizedLabel = this.state.isTeamsTab
    ? this.t(configJSON.groupsText)
    : this.t(configJSON.contactsText);
  
  const teamCountText = getPluralizedLabel(
    this.state.isTeamsTab ? this.state.teamsList.length : this.state.membersList.length,
    label,
    pluralizedLabel
  );
  const label1 = this.state.groupCount >1
  ? this.t(configJSON.groupsText)
  : this.t(configJSON.groupText);
  const pluralizedLabel1 = this.state.groupCount >1
  ? this.t(configJSON.groupsText)
  : this.t(configJSON.groupText);
  const removeCountText = getPluralizedLabel(
    this.state.groupCount,
    label1,
    pluralizedLabel1
  );
      const message = activeStep === steps.length - 1
      ? this.t(configJSON.finish)
      : this.t(configJSON.continue);
    switch (step) {
      case 0:
        return (
            <Box className="member-upload-content">
              <Typography className="heading" variant="h1">
               {this.t(`${configJSON.bulkUploadTxt}`)}
              </Typography>
              <Box component="p" className="sub-txt">
                {this.t(`${configJSON.bulkUploadSubText}`)}                
              </Box>
              <FileDrop
                onDrop={this.handleOnDrop}
                onDragOver={this.dragEnter}
                onDragLeave={this.dragLeave}
              >
                <Box
                  className={`${classes.fileUploadWrapper} ${this.state.fileDrop && classes.fileDropDrag
                    } `}
                  onClick={this.onBrowse}
                  data-testid="onBrowse"
                >
                  <Box className="upload-icon">
                    <img src={uploadImg} alt="uploadImg" />
                  </Box>
                  <form ref={this.formRef}>
                    <input
                      name="upload-photo"
                      accept=".csv"
                      type="file"
                      onChange={(e) => {
                        if (e.preventDefault) {
                          e.preventDefault();
                        }
                        this.handleOnDrop(e.target.files);
                      }}
                      hidden
                      ref={this.fileRef}
                      data-testid="fileInput"
                    />
                  </form>
                  <Box className="file-upload-block">
                    <Typography className="file-heading" variant="h2">
                     {this.t(`${configJSON.fileDrop}`)}   
                    </Typography>
                    <Box component="p" className="file-subtxt">
                    {this.t(`${configJSON.or}`)} 
                      <Box component="span" className="browse-link">
                      {this.t(`${configJSON.browseFiles}`)}
                      </Box>
                      {this.t(`${configJSON.chooseFile}`)}                      
                    </Box>
                  </Box>
                </Box>
              </FileDrop>
              {this.state.isLoading ? (
                <Box className={classes.uploadProgress}>
                  <Box
                    className="progress-status"
                    style={{ width: `${this.state.progress}%` }}
                  ></Box>
                  <Box className="progress-row">
                    <Box className="left-col">
                      <Typography className="progress-heading" variant="h2">
                      {this.t(`${configJSON.uploading}`)}
                      </Typography>
                      <Box component="p" className="progress-subtxt">
                        <Box component="span" className="completed">
                          {this.state.progress}%
                        </Box>
                        <Box component="span" className="remaining">
                          {this.state.timeRemain} {this.t(`${configJSON.secondsRemaining}`)}
                        </Box>
                      </Box>
                    </Box>
                    <Box className="right-col">
                      <Link
                        to="#"
                        className="play-pause"
                        onClick={this.pauseResumeUpload}
                        data-test-id="pauseResumeUpload"
                      >
                        {this.state.isPause ? (
                          <PlayCircleOutlineIcon />
                        ) : (
                          <PauseCircleOutlineIcon />
                        )}
                      </Link>
                      <Link
                        to="#"
                        className="close"
                        onClick={this.cancelUpload}
                        data-test-id="cancelUpload"
                      >
                        <CloseIcon />
                      </Link>
                    </Box>
                  </Box>
                </Box>
              ) : (
                ""
              )}
              {!this.state.isLoading && this.state.filesUploaded && (
                <List className={classes.uploadFileList}>
                  <ListItem>
                    <Box className="file-info">
                      <Box className="file-name">
                        {" "}
                        {this.state.filesUploaded?.name}
                      </Box>
                      <Link
                        className="close"
                        to="#"
                        onClick={this.cancelParsing}
                        data-testid="removeFileBtn"
                      >
                        <CloseIcon />
                      </Link>
                    </Box>
                  </ListItem>
                </List>
              )}
              <Box className="button-wrapper">
                <Button
                  onClick={this.downloadCsvTemplate}
                  className={`secondary-btn ${classes.secondaryButton}`}
                  data-testid="downloadTemplate"
                >
                  {this.t(`${configJSON.downloadTemplet}`)}
                </Button>
                <Button
                  onClick={this.handleNext}
                  className={`primary-btn ${classes.primaryButton}`}
                  data-testid="nextBtn"
                >
                  {message}
                </Button>
              </Box>
            </Box>
        );
      case 1:
        return (
            <Box className={classes.teamModuleWrapper}>
              <Box className="heading-wrapper">
                <Box className="heading-left">
                  <Typography className="heading" variant="h1">
                   {this.state.showCount?removeCountText:teamCountText}
                  </Typography>
                  <Box component="p" className="sub-txt">
                  {this.t(`${configJSON.toMakeChangeTxt}`)}                    
                  </Box>
                </Box>
              </Box>

              <Box className="search-filter-sm">
                <Box className="search-wrapper">
                  <CustomInputWeb
                    type="search"
                    value={this.state.searchValue}
                    label=""
                    placeholder="Search here1"
                    onChange={this.searchHandler}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </Box>
                <Box className="sort-group">
                  <img src={sortIcon} alt="sort-group" data-testid="changeSortByResponsive" onClick={this.changeSortByResponsive} />
                </Box>
                <Box className="filter-group" data-testid="filterTeamHandler" onClick={this.filterTeamHandler}>
                  <img src={filterSmIcon} alt="filter-group" />
                </Box>
              </Box>

              {/* mobile filter */}
              {this.state.isFilter && (
                <Modal
                  open={this.state.modalOpen}
                  onClose={this.handle_modalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className={classes.modalDialog}
                >
                  <Box className={`${classes.modalDialogBox} filter-dialogbox`}>
                    <Box className="modal-heading">
                      <Box className="filter-heading">
                        <Box
                          className="auth-back"
                          onClick={this.handle_modalClose}
                          data-test-id="handle_modalClose"
                        >
                          <i>
                            <svg
                              className="MuiSvgIcon-root"
                              focusable="false"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                            >
                              <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                            </svg>
                          </i>
                        </Box>
                        <Typography className="modal-title" component="h2">
                        {this.t(`${configJSON.filters}`)}
                        </Typography>
                      </Box>
                      <Link to="#" className="filter-link" data-testid="resetFilters" onClick={this.resetFilters}>
                      {this.t(`${configJSON.reset}`)}
                      </Link>
                    </Box>
                    <Box className="modal-description">
                      <Box className="modal-form" component="form">
                        <Box className="form-info-wrapper">
                          {this.state.isTeamsTab ?
                            <TeamSort
                              teamStateName={"selectedTeamFilter"}
                              selectTeamOptions={this.state.teamsList}
                              classes={classes}
                              onSelectedOptions={this.saveSelectedTeamsOption}
                              clearAction={this.handleClearSelectionTeam}
                              selectedTeam={this.state.selectedTeamFilter}
                              t={this.t}
                              data-test-id="Team-Sort"
                            /> :
                            <GroupContactsSort
                              selectGroupOptions={this.state.teamsList}
                              selectOptions={this.state.membersList}
                              classes={classes}
                              onSelectedOptions={this.saveSelectedOption}
                              hideDeactivateColumn={true}
                              clearAction={this.handleClearSelection}
                              selectedGroup={this.state.selectedGroupVal}
                              selectedCompany={this.state.selectedCompanyVal}
                              selectedTitle={this.state.selectedTitleVal} 
                            />
                          }
                        </Box>

                      </Box>
                      <Box className="modal-footer">
                        <Button
                          className={`primary-btn ${classes.primaryButton}`}
                          title="Apply Filters"
                          onClick={this.handle_modalClose}
                        >                          
                          {this.t(`${configJSON.applyFilters}`)}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Modal>
              )}

              <Box className={classes.tabWrapper}>
                <Box sx={{ width: "100%" }}>
                  <TabContext value={this.state.tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={this.tabsChangeHandler}
                        aria-label="Tab Change"
                        data-testid="tabChange"
                      >
                        <Tab label={this.t(`${configJSON.groupsTxt}`)} value="1" />
                        <Tab label={this.t(`${configJSON.contacts}`)} value="2" />
                      </TabList>
                    </Box>

                    {/* Groups tab */}
                    <TabPanel value="1">
                      <Box className="table-top">
                        <Box className="top-left">
                          <Box className="search-wrapper">
                            <Input
                              placeholder={this.t(`${configJSON.searchTxtType}`)}
                              type="search"
                              onChange={this.handleTeamSearch}
                              value={this.state.searchGroupValue}
                              startAdornment={
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              }
                              data-testid="teamSearch"
                            />
                          </Box>
                        </Box>
                        <Box className="top-right">
                          <FormControl className="select-control">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                classes: { paper: classes.dropdownStyle },
                              }}
                              onChange={this.changeSortByGroups}
                              displayEmpty
                              defaultValue=""
                              data-testid="sortTeams"
                            >
                              <MenuItem value="">Sort by</MenuItem>
                              <MenuItem value={1}>A to Z</MenuItem>
                              <MenuItem value={2}>Z to A</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                      {this.listTeamsData()}
                    </TabPanel>

                    {/* member tab */}
                    <TabPanel value="2">
                      <Box className="table-top">
                        <Box className="top-left">
                          <Box className="search-wrapper">
                            <Input
                              placeholder={this.t(`${configJSON.searchTxtType}`)}
                              type="search"
                              onChange={this.handleMemberSearch}
                              value={this.state.searchValue}
                              startAdornment={
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              }
                              data-testid="memberSearch"
                            />
                          </Box>
                          {/* <TeamMembersSearch /> */}
                          <GroupContactsSort
                            selectGroupOptions={this.state.teamsList}
                            selectOptions={this.state.membersList}
                            classes={classes}
                            onSelectedOptions={this.saveSelectedOption}
                            hideDeactivateColumn={true}
                            clearAction={this.handleClearSelection}
                            selectedGroup={this.state.selectedGroupVal}
                            selectedCompany={this.state.selectedCompanyVal}
                            selectedTitle={this.state.selectedTitleVal}
                          />
                        </Box>
                        <Box className="top-right">
                          <FormControl className="select-control">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                classes: { paper: classes.dropdownStyle },
                              }}
                              onChange={this.changeSortByMembers}
                              displayEmpty
                              defaultValue=""
                              data-testid="sortMembers"
                            >
                              <MenuItem value="">Sort by</MenuItem>
                              <MenuItem value={1}>A to Z</MenuItem>
                              <MenuItem value={2}>Z to A</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                      {this.contactGroupData()}
                    </TabPanel>
                  </TabContext>
                </Box>
              </Box>

              <Box className="sm-visible stepper-action">
                <Box className="button-wrapper">
                  <Button
                    className={`secondary-btn ${classes.secondaryButton}`}
                    title="Reupload"
                    onClick={this.reUploadFile}
                  >
                    {this.t(`${configJSON.reupload}`)}
                  </Button>
                  <Button
                    className={`primary-btn ${classes.primaryButton}`}
                    title="Confirm and Pay"
                    onClick={this.confirmClick}
                    data-testid="confirmButton"
                  >
                     {this.t(`${configJSON.confirm}`)}
                  </Button>
                </Box>
              </Box>
            </Box>
        );
      case 2:
        return "step 2";
      default:
        return "Unknown step";
    }
  };

  render() {
    const { classes } = this.props;
    const steps = getSteps(this.state.activeStep,this.t);
    const { activeStep, deleteType, viewCurrentContact, viewBulkTabsValue } = this.state;
    const maxSteps = steps.length;
    return (
      <>
        <Loader loading={this.state.showLoader} />
        <ConfirmActionModal
          isOpen={this.state.isDeleteMembers}
          handleClose={this.handleClose}
          modalConfirmAction={deleteType === "contacts" ? this.membersDelete : this.groupsDelete}
          deleteTeamId=""
          modalMessage="Are you sure you want to delete members?"
          confirmBtnTxt="Continue"
          modalHeading="Delete member"
          data-testid="confirmRemoveMember"
        />
        <ConfirmActionModal
          isOpen={this.state.isDeleteFile}
          handleClose={this.handleClose}
          modalConfirmAction={this.removeFile}
          deleteTeamId=""
          modalMessage={`Selecting a new file will remove the current file ${this.state.filesUploaded?.name}. Continue ?`}
          confirmBtnTxt="Continue"
          modalHeading="Delete File"
          data-testid="confirmRemoveFile"
        />
        <Box className={classes.stepperRoot}>
          <Box className="stepper-top">
            <Box className="stepper-row">
              <Box className="stepper-col">
                {/* <Box className="auth-logo">
                <img src={imgLogo} alt="logo" />
              </Box> */}
                <Box className="back-btn-wrap"  data-test-id="handleBack" onClick={this.handleBack}>
                  <i>
                    <svg
                      className="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"></path>
                    </svg>
                  </i>
                </Box>
              </Box>
              <Box className="stepper-col stepper-middle">
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const props = { completed: false };
                    const labelProps = {};
                    if (this.isStepCompleted(index)) {
                      props.completed = true;
                    }
                    return (
                      <Step
                        key={`step-${index.toString()}`}
                        {...props}
                        className={`step-root ${activeStep == index ? "active-step-root" : ""
                          }`}
                      >
                        <StepLabel
                          {...labelProps}
                          StepIconComponent={ColorlibStepIcon} 
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  variant="text"
                  activeStep={activeStep}
                  nextButton={<></>}
                  backButton={<></>}
                  className="sm-visible"
                />
              </Box>
              {this.state.activeStep > 0 && (
                <Box className="stepper-col stepper-action">
                  <Box className="button-wrapper">
                    <Button
                      className={`secondary-btn ${classes.secondaryButton}`}
                      title="Reupload"
                      onClick={this.reUploadFile}
                      data-testid="reUploadFile"
                    >
                       {this.t(`${configJSON.reupload}`)}
                    </Button>
                    <Button
                      className={`primary-btn ${classes.primaryButton}`}
                      title="Confirm"
                      onClick={this.confirmClick}
                      data-testid="confirmButton1"
                    >
                      {this.t(`${configJSON.confirm}`)}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box className="step-content-root">
            {activeStep === steps.length ? (
              <Box className="step-content">
                <Typography className="heading">                 
                  {this.t(`${configJSON.allStepsCompleted}`)}
                </Typography>
              </Box>
            ) : (
              <Box className="step-content">
                {this.getStepContent(activeStep)}
              </Box>
            )}
          </Box>

          {this.state.isViewingContact && (
             <Modal
             open={this.state.isViewingContact}
             onClose={this.handleCloseViewContactModal}
             aria-labelledby="modal-modal-title"
             aria-describedby="modal-modal-description"
             className={classes.modalDialog}
           >
             <Box className={`view-modal-dialog ${classes.viewContactModalDialog}`}>
               <Box className="modal-heading">
               <Box className="heading-links"></Box>
                 <Link
                   to="#"
                   className="close-icon"
                   onClick={this.handleCloseViewContactModal}
                   data-test-id="viewContactsClose"
                 >
                   <CloseIcon />
                 </Link>
               </Box>
               <Box className="modal-description">
               <Box className="profile-block-wrapper">
                 <Box className="profile-info">
                   <Box className="profile-row">
                     <Box className="left-col">
                       <Box className="heading-avatar">
                         <Box className="wrap-avatar">
                           <CardMedia
                             component="img"
                             className="avatar-bg"
                             src={editContactAvatarRound}
                           />
                           <Typography className="shortcut-name">
                             {viewCurrentContact?.attributes?.first_name.charAt(0).toUpperCase()}
                             {viewCurrentContact?.attributes?.last_name.charAt(0).toUpperCase()}
                           </Typography>
                         </Box>
                       </Box>
                     </Box>
                     <Box className="right-col">
                       <Box className="contact-statistics">
                         <Box className="statistics-col">
                           <Box className="content">{this.t(`${configJSON.deals}`)}</Box>
                           <Box className="value">0</Box>
                           <Box className="icon">
                             <img src={handshake} alt="handshake" />
                           </Box>
                         </Box>
                         <Box className="statistics-col">
                           <Box className="content">{this.t(`${configJSON.outreach}`)}</Box>
                           <Box className="value">0</Box>
                           <Box className="icon">
                             <img src={emailFast} alt="emailFast" />
                           </Box>
                         </Box>
                         <Box className="statistics-col">
                           <Box className="content">{this.t(`${configJSON.notes}`)}</Box>
                          
                           <Box className="icon">
                             <img src={fileEdit} alt="fileEdit" />
                           </Box>
                         </Box>
                         <Box className="statistics-col">
                           <Box className="content">{this.t(`${configJSON.favProducts}`)}</Box>
                           <Box className="value">0</Box>
                           <Box className="icon">
                             <img src={bottle} alt="bottle" />
                           </Box>
                         </Box>
                       </Box>
                     </Box>
                   </Box>
                   <Box className="profile-name">
                     {`${viewCurrentContact?.attributes?.first_name} ${viewCurrentContact?.attributes?.last_name}`}
                    
                   </Box>
                   <Box className="job-info">
                     {viewCurrentContact?.attributes?.company_name == "" ? <Box className="team-label">No company</Box> : viewCurrentContact?.attributes?.company_name}
                   </Box>
                   <Box className="profile-team">
                     <Box className="team-label">{this.t(`${configJSON.groupsTxt}`)}</Box>
                     <Box className="chip-wrapper" data-test-id="groupChips">
                       {this.getMyGroups(viewCurrentContact?.attributes?.group)}
                     </Box>
                   </Box>
                 </Box>
                 <Box className="tasklist-tabwrapper">
                 <Box>
                   <Tabs
                     aria-label="simple tabs example"
                     value={this.state.viewBulkTabsValue}
                     onChange={this.setBulkTabsValue}
                     data-test-id="bulkTabs"
                   >
                     <Tab label={this.t(`${configJSON.aboutTxt}`)} {...viewBulkTabProps(0)} />
                     <Tab label={this.t(`${configJSON.interactions}`)} {...viewBulkTabProps(1)} />
                     <Tab label={this.t(`${configJSON.notes}`)} {...viewBulkTabProps(2)} />
                     <Tab label={this.t(`${configJSON.favProducts}`)} {...viewBulkTabProps(3)} />
                   </Tabs>
                 </Box>
                 <BulkTabPanel value={viewBulkTabsValue} index={0}>
                   <Box className="contact-info-wrapper">
                     <Box className="info-block">
                       <Box className="info-heading">{this.t(`${configJSON.personalInfoTxt}`)}</Box>
                       <Box className="about-info-row">
                         <Box className="about-col">
                           <Box className="label">{this.t(`${configJSON.phone}`)}</Box>
                           <Box className="value">{viewCurrentContact?.attributes.phone}</Box>
                         </Box>
                         <Box className="about-col">
                           <Box className="label">{this.t(`${configJSON.email}`)}</Box>
                           <Box className="value">{viewCurrentContact?.attributes.email}</Box>
                         </Box>
                         <Box className="about-col">
                           <Box className="label">{this.t(`${configJSON.createdDate}`)}</Box>
                           <Box className="value">
                             {this.formatCreatedDate(viewCurrentContact?.attributes?.created_at)}
                           </Box>
                         </Box>
                       </Box>
                     </Box>
                     <Box className="info-block">
                       <Box className="info-heading">{this.t(`${configJSON.businessInformation}`)}</Box>
                       <Box className="about-info-row">
                         <Box className="about-col">
                           <Box className="label">{this.t(`${configJSON.companyName}`)}</Box>
                           <Box className="value">
                             {
                               viewCurrentContact?.attributes?.company_name ?? ""
                             }
                           </Box>
                         </Box>
                         <Box className="about-col">
                           <Box className="label">{this.t(`${configJSON.vatNumberTxt}`)}</Box>
                           <Box className="value">
                             {
                               viewCurrentContact?.attributes?.VAT_number ?? ""
                             }
                           </Box>
                         </Box>
                         <Box className="about-col">
                           <Box className="label">{this.t(`${configJSON.bCategoryinSmall}`)}</Box>
                           <Box className="value">
                             {
                               viewCurrentContact?.attributes?.business_category ?? ""
                             }
                           </Box>
                         </Box>
                         <Box className="about-col">
                           <Box className="label">{this.t(`${configJSON.billingAddressTxt}`)}</Box>
                           <Box className="value">
                             {displayAddress(viewCurrentContact?.attributes?.billing_address)}
                           </Box>
                         </Box>
                         <Box className="about-col">
                           <Box className="label">{this.t(`${configJSON.shippingAddress}`)}</Box>
                           <Box className="value">
                             {displayAddress(viewCurrentContact?.attributes?.shipping_address)}
                           </Box>
                         </Box>
                       </Box>
                     </Box>
                   </Box>
                 </BulkTabPanel>
                 <BulkTabPanel value={viewBulkTabsValue} index={1} data-test-id="interactions-tab">
                <Box className="contact-accodion">
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box className="accodion-heading">{this.t(`${configJSON.deals}`)}</Box>
                    </AccordionSummary>
                    <AccordionDetails className="accodion-content">
                    <Box className="content-heading">
                        <Box className="title">0 deal</Box>
                      </Box>
                       
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Box className="accodion-heading">{this.t(`${configJSON.outreach}`)}</Box>
                    </AccordionSummary>
                    <AccordionDetails className="accodion-content">
                    <Box className="content-heading">
                        <Box className="title">0 {this.t(`${configJSON.outreach}`)}</Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </BulkTabPanel>
              <BulkTabPanel value={viewBulkTabsValue} index={2}>
                <Box className="contact-accodion">
                  <Accordion defaultExpanded={true} data-test-id="accordian">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      id="panel1a-header"
                      aria-controls="panel1a-content"
                    >
                      <Box className="accodion-heading">{this.t(`${configJSON.notes}`)}</Box>
                    </AccordionSummary>
                    <AccordionDetails className="accodion-content" data-test-id="accordionDetails">
                    <Box className="content-heading">
                        <Box className="title">0 {this.t(`${configJSON.notes}`)}</Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </BulkTabPanel>
              <BulkTabPanel value={viewBulkTabsValue} index={3}>
                <Box className="contact-accodion">
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box className="accodion-heading">
                        {this.t(`${configJSON.favoriteProducts}`)}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails className="accodion-content">
                    <Box className="content-heading">
                        <Box className="title">0  {this.t(`${configJSON.favoriteProducts}`)}</Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </BulkTabPanel>
                
               </Box>
   
               </Box>
               </Box>
             </Box>
           </Modal>
          )}
        </Box>
      </>
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(BulkUploadContact);
// Customizable Area End