// Customizable Area Start
import React, { ChangeEvent } from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { setStorageData, removeStorageData, getStorageData } from "../../../../framework/src/Utilities";
import { WithStyles } from "@material-ui/core/styles";
import { etohShopStyle } from "../../../../components/src/EtohShopStyleHelper.web";
import { toast } from "react-toastify";
import i18n from "../../../../web/src/utilities/i18n";
import { checkedIcon } from "../assets";

export const configJSON = require("../config");

export interface Props extends WithStyles<typeof etohShopStyle> {
    navigation: any;
    id: string;
    isCartOpen: boolean;
    type?: string;
    handleCloseCartEvent: () => void;
    invitedMember?: object[];
    responseMember?: object[];
}

interface UserData {
    id: string;
    type: string;
    attributes: UserAttributes;
}

interface UserAttributes {
    first_name: string;
    last_name: string;
    email: string;
    full_phone_number: string;
    user_name: string | null;
    country: string;
    city: string;
    state: string;
    country_code: string | null;
    phone_number: string | null;
    choose_team: string;
    job_type: string;
    job_role: string;
    hourly_pay: number;
    activated: boolean;
    is_new: boolean;
    company_name: string;
    company_place_address: string;
    unassigned: string | null;
    created_by: string;
    owner_onboard: boolean;
    original_owner: boolean;
    profile_created: boolean;
    invite_accepted: boolean;
    password_set: boolean;
    accepted_terms_and_conditions: boolean;
    preferred_locale: string;
    can_skip: boolean;
    stripe_subscription_id: string | null;
    device_tokens: DeviceToken[];  
    last_device_token: string | null;
    brevo_verified: boolean;
}

interface DeviceToken {
    id: number;
    account_id: number;
    device_token: string;
    created_at: string;
    updated_at: string;
}

interface MetaData {
    token: string;
    refresh_token: string;
    message: string;
}

interface MainUser {
    data: UserData;
    meta: MetaData;
}

export interface SubscriptionItem {
    id: number;
    name: string;
    details: string;
    price: string;
    interval: string;
    stripe_price_id: string;
}

export interface SubscriptionPlansProps {
    subscriptiondata: SubscriptionItem[];
}

export interface S {
    isLoading: boolean;
    subscriptiondata: SubscriptionItem[];
    userdetail: UserData;
    selectedPlan: number | null;
    sessionID: string;
    isModalopen: boolean;
    isErrorModalopen: boolean;
    currencySymbol: string;
}

export interface SS {
    id: any;
}

export default class SubscriptionScreenController extends BlockComponent<
    Props,
    S,
    SS
> {
    getAllSubscriptionPlanRequestId: string = "";
    GetUserDetailsRequestId: string = "";
    buySubscriptionRequestId: string = "";
    createSubscriptionRequestId: string = "";
    SubscriptionpaymentStatusApiCallId: string = "";
    userSessionData: any;
    userToken: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage),];

        this.state = {
            isLoading: false,
            subscriptiondata: [] as SubscriptionItem[],
            selectedPlan: null,
            sessionID: "",
            isModalopen: false,
            isErrorModalopen: false,
            userdetail: {} as UserData,
            currencySymbol: "",
        };
        this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
        this.userToken = JSON.parse(this.userSessionData);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    getCurrencySymbol(langC : string) {
        switch (langC) {
          case "en-GB":
            return "£";
          case "en-US":
            return "$"; 
          case "fr":
            return "€"; 
          case "it":
            return "€"; 
          case "es":
            return "€"; 
          default:
            return "$"; 
        }
    }      
    async componentDidMount() {
        this.GetUserDetails();
        this.getAllSubscriptions();
        if (window.location.pathname === "/subscription-member-success" || window.location.pathname === "/subscription-success") {
            this.checkPaymentSuccess();
        }
        const langC = localStorage.getItem("lang") ?? "en";
        await (i18n as any).changeLanguage(langC);
    }
    t(key: any, variable?: Record<string, any>) {
        return (i18n as any).t(key, { ns: "translation", ...variable })
    }
    createToastNotification = (
        Messsage: string,
        Error: boolean = false
    ) => {        
        Error
            ? toast.error(
                <div className="toast-notification">
                    <div className="notification-txt">{Messsage}</div>
                </div>,
                {
                    position: toast.POSITION.TOP_CENTER,
                }
            )
            : toast.success(
                <div className="toast-notification">
                    <div className="notification-txt">{Messsage}</div>
                </div>,
                {
                    position: toast.POSITION.TOP_CENTER,
                    icon: () => <img src={checkedIcon} alt="emptydata" />,
                }
            );
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
    
        this.setState({ isLoading: false });
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    
        if (!apiRequestCallId || !responseJson) {
            this.parseApiCatchErrorResponse(errorReponse);
            return;
        }
    
        if (responseJson.error) {
            await this.handleErrorResponse(responseJson.error);
        } else {
            await this.handleSuccessResponse(apiRequestCallId, responseJson);
        }
    }

    async handleErrorResponse(error: string) {
        this.createToastNotification(error, true);
        this.handleCloseModal();
        if (this.props.handleCloseCartEvent) {
            this.props.handleCloseCartEvent();
        }
    
        if (error === "You already have an active subscription.") {
            window.location.href = '/dashboard';
        }
    }
    
    async handleSuccessResponse(apiRequestCallId: string, responseJson: any) {
        switch (apiRequestCallId) {
            case this.GetUserDetailsRequestId:
                const currencySymbol = this.getCurrencySymbol(responseJson.data.attributes.preferred_locale);
                this.setState({
                    userdetail: responseJson.data,
                    currencySymbol: currencySymbol
                })
                break;    
            case this.getAllSubscriptionPlanRequestId:
                this.setState({
                    subscriptiondata: responseJson.Subscription_plans,
                    selectedPlan: responseJson.Subscription_plans[0]?.id || ''
                });
                break;
   
            case this.buySubscriptionRequestId:
                await this.handleBuySubscription(responseJson);
                break;
    
            case this.SubscriptionpaymentStatusApiCallId:
                await this.handlePaymentStatus(responseJson.payment_status);
                break;
    
            case this.createSubscriptionRequestId:
                await this.handleCreateSubscription();
                break;
    
            default:
                this.parseApiCatchErrorResponse(responseJson);
        }
    }
    
    async handleBuySubscription(responseJson: any) {
        this.setState({
            isLoading: false,
            sessionID: responseJson.session_data.id
        });
    
        if (responseJson.session_data.url) {
            window.location.href = responseJson.session_data.url;
            await setStorageData('stripeSubscriptionData', JSON.stringify({
                session_id: responseJson.session_data.id
            }));
            setTimeout(() => {
                this.checkPaymentSuccess();
            }, 40 * 1000);
        }
    
        await setStorageData('Subscription_session_id', responseJson.session_data.id);
    }
    
    async handlePaymentStatus(paymentStatus: string) {
        this.setState({ isLoading: false });
    
        if (paymentStatus === "paid") {
            this.createSubscriptionAfterPayment();
        } else if (paymentStatus === "unpaid") {
            this.handleCloseModal();
            this.handleErrorOpenModal();
        }
    }
    
    async handleCreateSubscription() {
        this.setState({ isLoading: false });
        this.createToastNotification("Subscription created successfully", false);
        await removeStorageData('Subscription_session_id');
        await removeStorageData('stripeSubscriptionData');
    }

    handleRadioChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            selectedPlan: Number(event.target.value),
        });
    };
    getAllSubscriptions = async() => {
        let token=  this.userToken.meta.token;        
        const header = {
            "Content-Type": "application/json",
            token: await token,
        };

        const requestMessage: Message = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllSubscriptionPlanRequestId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.GetallsubcriptionApiPath
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.requestMethodGet
        );
        this.setState({ isLoading: true });

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    GetUserDetails = async() => {
        let token=  this.userToken.meta.token;        
        const header = {
            "Content-Type": "application/json",
            token: await token,
        };

        const requestMessage: Message = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.GetUserDetailsRequestId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.GetUserDetails
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.requestMethodGet
        );
        this.setState({ isLoading: true });

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    buySubscription = async() => {
        let token= await this.userToken.meta.token;   
        const selectedplan = this.state.selectedPlan;
        const ResponseInvitedMember = this.props.responseMember;
        const convertResponseInvitedMember = JSON.stringify(ResponseInvitedMember);
        this.handleCloseModal();
        this.handleErrorCloseModal();
        const body: { plan_id: number | null; member_ids?: string } = {
            plan_id: selectedplan,
        };
        if (ResponseInvitedMember && Array.isArray(ResponseInvitedMember) && ResponseInvitedMember.length > 0) {
            body.member_ids = convertResponseInvitedMember;
        }

        const headers = {
            "Content-Type": "application/json",
            token: token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.buySubscriptionRequestId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.BuysubscriptionApiPath
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.requestMethodPost
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        this.setState({ isLoading: true });

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    createSubscriptionAfterPayment = async () => {
        let token = await this.userToken.meta.token;   
        const sessionID = await getStorageData("Subscription_session_id");

        const body = {
            "session_id": sessionID
        }
        const headers = {
            "Content-Type": "application/json",
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createSubscriptionRequestId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createscriptionApiPath
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.requestMethodPost
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        this.setState({ isLoading: true });
        this.handleCloseModal();
        if (this.props.handleCloseCartEvent) {
            this.props.handleCloseCartEvent();
        }    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    checkPaymentSuccess = async () => {
        let token = await this.userToken.meta.token;   

        const sessionID = await getStorageData("Subscription_session_id");
        const headers = {
            "Content-Type": "application/json",
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.SubscriptionpaymentStatusApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.subscriptionpaymentStatus}?session_id=${sessionID}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.requestMethodGet
        );

        this.setState({ isLoading: true });

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    handleOpenModal = () => {
        this.setState({ isModalopen: true });
    };
    handleCloseModal = () => {
        this.setState({ isModalopen: false });
    };
    handleErrorOpenModal = () => {
        this.setState({ isErrorModalopen: true });
    };
    handleErrorCloseModal = () => {
        this.setState({ isErrorModalopen: false });
    };
    renderPlanSubtitle(item: SubscriptionItem, Totalinvitedmember: number | string | null) {
        const IncludeOwner = this.state.userdetail.attributes?.stripe_subscription_id === null;
        const prefix = IncludeOwner ? "Me + " : "";
        return this.props.type === "skip"
            ? `Me @ ${item.price} per member per ${item.interval}`
            : `${prefix}${Totalinvitedmember} Member @ ${item.price} per member`;
    }    
    calculateTotalPrice(item: SubscriptionItem, Totalinvitedmember: number): number {
        let price: number;
        const stripeSubscriptionId = this.state.userdetail.attributes?.stripe_subscription_id;

        if (typeof item.price === 'string') {
            const cleanedPrice = item.price.replace('€', '').trim().replace(/[^0-9.-]+/g, '');
            price = parseFloat(cleanedPrice);
        } else {
            price = item.price;
        }
        let effectiveTotalMembers: number;
        if (this.props.type === "skip") {
            effectiveTotalMembers = 1;
        } else {
            effectiveTotalMembers = stripeSubscriptionId === null
                ? Totalinvitedmember + 1
                : Totalinvitedmember;
        }

        return price * effectiveTotalMembers;
    }
    calculateTotalInvitedMembers = (): number => {
        if (this.props.type === "skip") {
            return 1;
        } else {
            return this.props.invitedMember && this.props.invitedMember.length ? this.props.invitedMember.length : 1;
        }
    };
    returnTeam = () => {
        this.props.navigation.navigate("AccountCreation");
    };
    addAnotherMember = () => {
        this.props.navigation.navigate("ConnectAccountIntegrationsScreen");
    };
}
// Customizable Area End
