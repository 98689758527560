import React, { useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    Button,
    Container,
    makeStyles,
    createTheme,
    ThemeProvider
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
    screenOne,
    screenTwo,
    logoPng,
    one,
    two,
    three,
    four,
    five,
    six
} from "../assets";

// Custom theme
const theme = createTheme({
    palette: {
        primary: {
            main: '#5FBCD3',
        },
    },
    typography: {
        fontFamily: 'Arial, sans-serif',
    },
});

// Styles
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
    },
    checkIcon: {
        position: 'absolute',
        top: 5,
        right: 5,
        color: '#4BA3B7',
        backgroundColor: 'white',
        borderRadius: '50%',
    },
    logo: {
        marginBottom: theme.spacing(4),
        float: "left"
    },
    title: {
        marginBottom: theme.spacing(1),
        fontWeight: 600,
        fontSize: "32px",
        color: '#2B2B2B',
        margin: '0 0 6px',
        fontFamily: '"Expletus Sans", sans-serif',
    },
    subtitle: {
        marginBottom: theme.spacing(4),
        fontWeight: 400,
        fontSize: "16px",
        color: '#2B2B2B',
        margin: '0 0 6px',
        fontFamily: '"Expletus Sans", sans-serif',
    },
    roleName: {
        marginTop: theme.spacing(4),
        textAlign: "center",
        fontWeight: 400,
        fontSize: "16px",
        color: '#2B2B2B',
        margin: '0 0 6px',
        fontFamily: '"Expletus Sans", sans-serif',
    },
    name: {
        marginBottom: theme.spacing(4),
        fontWeight: 500,
        fontSize: "14px",
        color: '#2B2B2B',
        margin: '0 0 6px',
        fontFamily: '"Expletus Sans", sans-serif',
    },
    roleButton: {
        width: 120,
        height: 120,
        borderRadius: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "#f2f8fc",
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: "#f2f8fc",
            boxShadow: 'none',
        },
    },
    roleIcon: {
        fontSize: 40,
        marginBottom: theme.spacing(1),
    },
    continueButton: {
        backgroundColor: "#4BA3B7",
        borderRadius: "7px",
        filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
        fontSize: "14px",
        lineHeight: "18px",
        color: "#ffffff",
        fontWeight: 500,
        fontFamily: "Expletus Sans",
        textAlign: "center",
        textTransform: "unset",
        maxWidth: "100%",
        width: "156px",
        padding: "12px 10px",
        minHeight: "48px",
        marginBottom: "0",
        letterSpacing: "0",
        textDecoration: "none",
        marginTop: "50px",
        "&:hover": {
            backgroundColor: "#92C7D3",
            color: "#ffffff",
        },
        '&:disabled': {
            backgroundColor: '#92C7D3',
            color: '#ffffff',
        },
    },
    image: {
        width: "150px",
        height: "30px",
        margin: "30px 0px 0px 30px",
        bottom: "65px",
    },
    screenImage: {
        maxWidth: '100%',
        height: 'auto',
        marginBottom: theme.spacing(4),
    },
}));

interface Role {
    name: string;
    icon: string;
}

interface RoleButtonProps {
    role: Role;
    isSelected: boolean;
    onClick: () => void;
    disabled: boolean;
}

// Role data
const roles = [
    { name: 'Owner', icon: one },
    { name: 'Manager', icon: two },
    { name: 'Project Lead', icon: three },
    { name: 'Task Owner', icon: four },
    { name: 'Marketing', icon: five },
    { name: 'Sales', icon: six },
];

const RoleButton: React.FC<RoleButtonProps> = ({ role, isSelected, onClick, disabled }) => {
    const classes = useStyles();

    return (
        <Box position="relative">
            <Button
                className={classes.roleButton}
                onClick={onClick}
                disabled={disabled}
            >
                <Box className={classes.roleIcon}>
                    <img src={role.icon} />
                </Box>

            </Button>
            {isSelected && <CheckCircleIcon className={classes.checkIcon} />}

            <Typography variant="subtitle1" className={classes.roleName}>
                {role.name}
            </Typography>
        </Box>
    );
};

const AddOwner = (props:any) => {
    const classes = useStyles();
    const [selectedRole, setSelectedRole] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);

    const handleContinue = () => {
        setCurrentStep(prevStep => prevStep + 1);
    };

    const renderContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                        <Typography variant="h4" className={classes.title}>
                            Welcome to EtOH Suite Dashboards
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                            To create your home dashboard, select your main focus (you can change it later)
                        </Typography>

                        <Box justifyContent="space-between" display={"flex"} width={"70%"}>
                            {roles.map((role: any) => (
                                <RoleButton
                                    key={role.name}
                                    role={role}
                                    isSelected={selectedRole === role.name}
                                    onClick={() => setSelectedRole(role.name)}
                                    disabled={false}
                                />
                            ))}
                        </Box>
                    </>
                );
            case 1:
                return <img src={screenOne} alt="Screen One" className={classes.screenImage} />;
            case 2:
                return <img src={screenTwo} alt="Screen Two" className={classes.screenImage} />;
            default:
                return null;
        }
    };

    return (
        <ThemeProvider theme={theme}>
            {currentStep === 0 && (
                <img className={classes.image} src={logoPng} alt="logo" />
            )}

            <Box className={classes.root}>
                {renderContent()}
                <Button
                    variant="contained"
                    className={classes.continueButton}
                    disabled={currentStep === 0 && !selectedRole}
                    onClick={currentStep < 2 ? handleContinue : () => props.navigation.navigate('OwnerDashboard')}
                >
                    {currentStep === 2 ? "Continue" : "Continue"}
                </Button>
            </Box>
        </ThemeProvider>
    );
};

export default AddOwner;