import React from "react";

import {
  // Customizable Area Start
  Box,
  Grid,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme
} from "@material-ui/core/styles";
import CustomInput from "../../../components/src/CustomInput.web";
import { customStyles } from "../../../components/src/AddItemStyles.web";

// Customizable Area End

import LocationController, {
  Props,
  configJSON,
} from "./LocationController.web";

export class Location extends LocationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {classes, seletedItem} = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
        <Box className={classes.basicInfoSection}>
            <Box className="heading-box">
                <Box>
                    <Typography className={classes.heading} variant="h1">
                    {this.t(`${configJSON.locationTitle}`)}
                    </Typography>
                    <Typography className={classes.subtitleText}>
                    {this.t(`${configJSON.locationSubtext}`)} {" "}{seletedItem.toLowerCase()}{" "}{this.t(`${configJSON.originText}`)}
                    </Typography>
                </Box>
                <Box className="item-box">
                    {seletedItem}
                </Box>
            </Box>
            <Grid className={classes.formSection} container alignItems="center" justifyContent="center">
                {seletedItem === configJSON.beerText ?
                <>
                  <Grid item md={12} lg={12} sm={12} xs={12}>
                  <CustomInput
                    data-testId={"beerStyleFeild"}
                    value={this.state.style}
                    onChange={(event)=>this.handleStyle(event)}
                    placeholder={this.t(`${configJSON.beerLocationFeild1}`)}
                  />
                  </Grid>
                  <Grid item md={12} lg={12} sm={12} xs={12}>
                    <FormControl className="select-outer" variant="outlined">
                      <InputLabel>
                      {this.t(`${configJSON.countryLabel}`)}
                      </InputLabel>
                      <Select 
                      label={this.t(`${configJSON.countryLabel}`)}
                      value={this.state.country}
                      onChange={(event)=>this.handleCountry(event)}
                      data-testId={'countryFeild'}
                      IconComponent={(props) => (
                          <ExpandMoreIcon {...props} />
                      )}
                      MenuProps={{
                          anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                          },
                          transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: { paper: classes.dropdownStyle },
                      }}
                      >
                      {this.state.countryData.map((data, index:number)=>{
                          return <MenuItem value={data} key={index}
                          data-testId={`countryBeer-${index}`}
                          >
                              {this.t(`${data}`)}
                          </MenuItem>
                      })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={12} lg={12} sm={12} xs={12}>
                    <FormControl className="select-outer" variant="outlined">
                      <InputLabel>{this.t(`${configJSON.regionLabel}`)}</InputLabel>
                      <Select
                      data-testId={"regionFeild"}
                      value={this.state.region}
                      onChange={(event)=>this.handleRegion(event)}
                      label={this.t(`${configJSON.regionLabel}`)}
                      IconComponent={(props) => (
                          <ExpandMoreIcon {...props} />
                      )}
                      MenuProps={{
                          transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                          },
                          anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                          },
                          classes: { paper: classes.dropdownStyle },
                          getContentAnchorEl: null,
                      }}
                      >
                      {this.state.regionData.map((data, index:number)=>{
                          return <MenuItem value={data} data-testId={`region-${index}`}
                            key={index}>
                              {this.t(`${data}`)}
                          </MenuItem>
                      })}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
                :
                <>
                  <Grid item md={12} lg={12} sm={12} xs={12}>
                    <FormControl className="select-outer" variant="outlined">
                    <InputLabel>
                    {this.t(`${configJSON.appellationTypeLabel}`)}
                    </InputLabel>
                    <Select
                    data-testId={"appelationtypeFeild"}
                    value={this.state.appellationType}
                    onChange={(event)=>this.handleAppelationType(event)}
                    label={this.t(`${configJSON.appellationTypeLabel}`)}
                    IconComponent={(props) => (
                        <ExpandMoreIcon {...props} />
                    )}
                    MenuProps={{
                        anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                        },
                        transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        classes: { paper: classes.dropdownStyle },
                    }}
                    >
                    {this.state.appellationTypeData.map((data)=>{
                        return <MenuItem data-testId={`appell-type-${data.id}`} key={data.id} value={data.name}>
                            {data.name}
                        </MenuItem>
                    })}
                    </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={12} lg={12} sm={12} xs={12}>
                    <FormControl className="select-outer" variant="outlined">
                    <InputLabel >
                    {this.t(`${configJSON.appellationLabel}`)}
                    </InputLabel>
                    <Select
                    data-testId={"appelationFeild"}
                    value={this.state.appellation}
                    onChange={(event)=>this.handleAppelation(event)}
                    label={this.t(`${configJSON.appellationLabel}`)}
                    IconComponent={(props) => (
                        <ExpandMoreIcon {...props} />
                    )}
                    MenuProps={{
                        anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                        },
                        transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        classes: { paper: classes.dropdownStyle },
                    }}
                    >
                    {this.state.appellationData.map((data)=>{
                        return <MenuItem value={data.name} data-testId={`appellation-${data.id}`}
                        key={data.id}>
                            {data.name}
                        </MenuItem>
                    })}
                    </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={12} lg={12} sm={12} xs={12}>
                  <FormControl className="select-outer" variant="outlined">
                      <InputLabel>
                      {this.t(`${configJSON.countryLabel}`)}
                      </InputLabel>
                      <Select label={this.t(`${configJSON.countryLabel}`)}
                      data-testId={'countryFeild'}
                      value={this.state.country}
                      onChange={(event)=>this.handleCountry(event)}
                      MenuProps={{
                          anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                          },
                          transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: { paper: classes.dropdownStyle },
                      }}
                      IconComponent={(props) => (
                        <ExpandMoreIcon {...props} />
                      )}
                      >
                      {this.state.countryData.map((data,index:number)=>{
                        return <MenuItem value={data} key={index}data-testId={`country-${index}`}>
                         {this.t(`${data}`)}
                          </MenuItem>
                      })}
                      </Select>
                  </FormControl>
                  </Grid>
                  <Grid item md={12} lg={12} sm={12} xs={12}>
                    <FormControl className="select-outer" variant="outlined">
                      <InputLabel>
                      {this.t(`${configJSON.regionLabel}`)}
                      </InputLabel>
                      <Select
                      data-testId={"regionFeild"}
                      value={this.state.region}
                      onChange={(event)=>this.handleRegion(event)}
                      label={this.t(`${configJSON.regionLabel}`)}
                      IconComponent={(props) => (
                          <ExpandMoreIcon {...props} />
                      )}
                      MenuProps={{
                          transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                          },
                          anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: { paper: classes.dropdownStyle },
                      }}
                      >
                      {this.state.regionData.map((data, index:number)=>{
                          return <MenuItem value={data} data-testId={`region-${index}`}
                            key={index}>
                            {this.t(`${data}`)}
                          </MenuItem>
                      })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={12} lg={12} sm={12} xs={12}>
                  <FormControl className="select-outer" variant="outlined">
                      <InputLabel>
                      {this.t(`${configJSON.subRegionLabel}`)}
                      </InputLabel>
                      <Select
                      data-testId={"subregionFeild"}
                      value={this.state.subRegion}
                      onChange={(event)=>this.handleSubRegion(event)}
                      label={this.t(`${configJSON.subRegionLabel}`)}
                      IconComponent={(props) => (
                          <ExpandMoreIcon {...props} />
                      )}
                      MenuProps={{
                          anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                          },
                          transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: { paper: classes.dropdownStyle },
                      }}
                      >
                      {this.state.subRegionData.map((data,ind:number)=>{
                          return <MenuItem data-testId={`sub-region-${ind}`}
                          value={data} key={ind}
                          >
                            {this.t(`${data}`)}
                          </MenuItem>
                      })}
                      </Select>
                  </FormControl>
                  </Grid>
                </>
              }
            </Grid>
            <Box className={classes.formButtons}>
              <Button className="backBtn" onClick={this.backToBasicInfo} data-testId={"back-btn"}>
                  {this.t(`${configJSON.backButton}`)}
              </Button>
              <Button className="next-step-btn" onClick={this.handleLocationData}
              data-testId={"nextstep-btn"}>
                  {this.t(`${configJSON.nextButton}`)}
              </Button>
            </Box>
        </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start


export default withStyles(customStyles, { withTheme: true })(Location);
// Customizable Area End
