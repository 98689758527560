import React, { useEffect, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Paper,
  makeStyles,
  Button,
  Popover
} from '@material-ui/core';
import { starIcon } from "../../blocks/customisableuserprofiles/src/assets";
import ConfirmActionModal from "../../blocks/email-account-registration/src/teams-web/ConfirmActionModal.web";
import { createCommonToastNotification } from './ReusableFunctions';
export const connectBrevo = require("./assets/connect-account.png");
export const checkBoxEmpty = require("./assets/check-empty.png");
export const checkBoxFilled = require("./assets/check-filled.png");
export const arrowDown = require("./assets/arrow-down.svg");
export const warningIcon = require("./assets/warning-red.png");
export const wineImg = require("./assets/wine-img.png");
const SELECTED_ROLE_KEY = 'selectedRole';

import {one, two, three, four, five, six} from './assets'

const iconUrls = {
  User: one,
  Home: two,
  Users: three,
  GitBranch: four,
  BarChart: five,
  Target: six,
  HelpCircle: 'https://fonts.gstatic.com/s/i/materialicons/help_outline/v12/24px.svg',
  ChevronDown: 'https://fonts.gstatic.com/s/i/materialicons/expand_more/v14/24px.svg'
};

interface IconComponentProps {
  iconName: any;
  size?: number;
  color?: string;
  style?: React.CSSProperties;
}

const IconComponent: React.FC<IconComponentProps> = ({ iconName, size = 24, color = 'currentColor', style }) => (
  <img
    src={iconName}
    alt={iconName}
    width={size}
    height={size}
    style={{ fill: color, ...style }}
  />
);

const useStyles = makeStyles((theme) => ({

  button: {
    textTransform: 'none',
    justifyContent: 'space-between',
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popoverPaper: {
    width: 300,
    borderRadius: theme.shape.borderRadius * 2,
    marginTop: theme.spacing(1),
  },
  listItem: {
    '&:last-child': {
      borderBottom: 'none',
    },
    '&.Mui-selected': {
      backgroundColor: '#f0f7ff',
    },
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    "& .header-t1": {
      fontSize: '16px',
      color: "#2B2B2B",
      fontWeight: 400,
      "& .MuiListItemText-secondary ": {
        fontSize: '10px',
        color: '#94A3B8',
        fontWeight: 400,
      },
    },
    borderRadius: "12px"
  },

  helpSection: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
    color: "#4BA3B7 !important",
    textDecoration: 'underline'
  },
  helpText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  helpTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: "12px"
  }
}));


const roles = [
  { name: 'Task Owner', description: 'Setting a productive day', icon: iconUrls['User'], link: '/task-owner-dashboard' },
  { name: 'Owner', description: 'Global view of the company', icon: iconUrls['Home'], link: null },
  { name: 'Manager', description: 'Lead your team to success', icon: iconUrls['Users'], link: null },
  { name: 'Project Lead', description: 'Guide the vision and turn plans into reality', icon: iconUrls['GitBranch'], link: null },
  { name: 'Marketing', description: 'Campaign performance', icon: iconUrls["BarChart"], link: '/marketing-dashboard' },
  { name: 'Sales', description: 'Sales excellence through deals and contacts', icon: iconUrls['Target'], link: '/sales-dashboard' },
];

const RoleSelectionDropdown = (props: any) => {
  const classes = useStyles();
  const [selectedRole, setSelectedRole] = useState(roles[0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [roleBefore, setRoleBefore] = useState(roles[0]);
  const [help, setHelp] = useState(false);
  const [tempRole, setTempRole] = useState({ name: "", description: "", icon: iconUrls["BarChart"], link: "" });
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  // Load saved role on initial render
  useEffect(() => {
    const savedRole = localStorage.getItem(SELECTED_ROLE_KEY);
    if (savedRole) {
      const parsedRole = JSON.parse(savedRole);
      const matchingRole = roles.find(role => role.name === parsedRole.name);
      if (matchingRole) {
        setSelectedRole(matchingRole);
        setRoleBefore(matchingRole);
      }
    } else {
      // If no saved role, check URL path
      const path = window.location.pathname;
      const matchingRole = roles.find(role => role.link === path);
      if (matchingRole) {
        setSelectedRole(matchingRole);
        setRoleBefore(matchingRole);
        localStorage.setItem(SELECTED_ROLE_KEY, JSON.stringify(matchingRole));
      }
    }
  }, []);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    props.navigation.push(roleBefore?.link);
  };

  const handleRoleSelect = (role: any) => {
    setTempRole(role);
    setShowConfirmDialog(true);
  };

  const confirmRoleChange = () => {
    if (tempRole) {
      setSelectedRole(tempRole);
      setRoleBefore(tempRole);
      // Save to localStorage
      localStorage.setItem(SELECTED_ROLE_KEY, JSON.stringify(tempRole));
      createCommonToastNotification(
        `Main Focus Updated`
      );
      setAnchorEl(null);
      setShowConfirmDialog(false);
      props.navigation.push(tempRole?.link);
    }
    setShowConfirmDialog(false);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ fontFamily: 'Expletus Sans' }}>
    <Button
      className={classes.button}
      variant="outlined"
      onClick={handleClick}
      endIcon={<IconComponent iconName={iconUrls.ChevronDown} size={20} />}
    >
      {selectedRole.name}
    </Button>
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        className: classes.popoverPaper,
      }}
    >
      <Box
        className={classes.helpTitle}
      >
        <Typography
        >
          Switch Focus?
        </Typography>
        <img
          onClick={() => setHelp(true)}
          src={require('./help-circle.png')} />
      </Box>
      <Paper elevation={0} style={{ position: 'relative', zIndex: 0 }}>
        <List style={{
          padding: "16px",
        }}>
          {roles.map((role) => (
            <ListItem
              key={role.name}
              button
              selected={role.name === selectedRole.name}
              onClick={() => handleRoleSelect(role)}
              className={classes.listItem}
            >
              <ListItemIcon>
                <IconComponent
                  iconName={role.icon}
                  size={24}
                  color={role.name === selectedRole.name ? '#1976d2' : '#757575'}
                />
              </ListItemIcon>
              <ListItemText
                primary={role.name}
                secondary={role.description}
                className="header-t1"
              />
              {role.name === selectedRole.name && (
                <IconComponent iconName={starIcon} size={16} color="#4BA3B7" style={{ marginLeft: 8 }} />
              )}
            </ListItem>
          ))}
        </List>
      </Paper>
      {help &&
        <Box><img src={require('./blur-bg.png')}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 999,
            width: '100%',
            height: '100%',
          }} />
          <img src={require('./child-box.png')}
            onClick={() => setHelp(false)}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1000,
              width: "86%"
            }}
            alt="Overlay image"
          />
          <Button
            size='medium'
            style={{
              padding: '8px 24px',
              backgroundColor: "#ffffff",
              color: "#4BA3B7",
              textTransform: "none",
              zIndex: 1000,
              width: "260px",
              borderRadius: '6px',
              margin: "15px 10px 15px 20px"
            }}
            onClick={() => setHelp(false)}
          >
            Got it!
          </Button>
        </Box>
      }
    </Popover>

    <ConfirmActionModal
      isOpen={showConfirmDialog}
      handleClose={() => {
        setAnchorEl(null);
        setShowConfirmDialog(false)
      }
      }
      modalConfirmAction={() => {
        confirmRoleChange()
      }}
      modalMessage={`Would you like to change your main focus to the "${tempRole?.name}" dashboard?`}
      modalHeading="Change Main Focus"
      confirmBtnTxt="Change"
      cancelBtnTxt="Cancel"
      data-testid="confirmOption"
    />
  </Box>
  );
};

export default RoleSelectionDropdown;