// Customizable Area Start
import React from "react";
import { customStyles } from "./ContactsListStyles.web";
import { withStyles } from "@material-ui/core/styles";
import ViewContactController, { Props, configJSON } from "./ViewContactController.web";
import WebLoader from "../../../components/src/WebLoader.web";
import { Link } from "react-router-dom";
import {
  Chip,
  Box,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Select,
  MenuItem,
  Modal,
  Button,
  Typography,
  Menu,
  CardMedia,
  Popover,
  InputAdornment,
  InputLabel,
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  deleteGray,
  callMerge,
  accountMultiplePlus,
  handshake,
  emailFast,
  fileEdit,
  bottle,
  pencil,
  plus,
  paperClip,
  editContactAvatarRound,
} from "./assets";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import EditContactWeb from "./EditContact.web";
import DeleteContact from "./DeleteContact.web";
import AddIcon from "@material-ui/icons/Add";
import CreateDealWeb from "./CreateDeal.web";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { ProductList } from "./ProductList.web";
import {
  getImageForSubCategory,
  extractItemDetails,
  showLettersFromName,
} from "../../../components/src/ReusableFunctions";
import CreateEmailCampaign from "../../cfsendinblueapi/src/CreateEmailCampaign.web"

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
function viewContactProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const displayAddress = (address: any) => {
  if (!address?.data?.attributes) return "";
  const { address1, address2, state, city, pin_code, country } = address.data.attributes;
  return `${address1 ?? ""} ${address2 ?? ""} ${state ?? ""} ${city ?? ""} ${pin_code ?? ""} ${country ?? ""}`.trim();
}
export class ViewContactNew extends ViewContactController {
  constructor(props: Props) {
    super(props);
  }

 sortDealsData = (dealsData:any) => {
    return dealsData.slice().sort((a: any, b: any) => {
      if (this.state.sortValueDeals === "A-Z") {
        return a.attributes.name.localeCompare(b.attributes.name);
      } else if (this.state.sortValueDeals === "Z-A") {
        return b.attributes.name.localeCompare(a.attributes.name);
      }
      return 0;
    });
  };

  sortNotesData = () => {
    return this.state.notesData.slice().sort((a: any, b: any) => {
      if (this.state.sortValueNotes === "Newest to oldest") {
        return new Date(b.attributes.created_at).getTime() - new Date(a.attributes.created_at).getTime();
      }  else if (this.state.sortValueNotes === "Oldest to newest") {
        return new Date(a.attributes.created_at).getTime() - new Date(b.attributes.created_at).getTime();
      }
      return 0;
    });
  };

  sortFavProductData = (products:any) => {
    return products.slice().sort((a: any, b: any) => {
      if (this.state.sortValueFavPRoduct === "A-Z") {
        return  a.product.data.attributes.name.localeCompare(b.product.data.attributes.name);
      } else if (this.state.sortValueFavPRoduct === "Z-A") {
        return b.product.data.attributes.name.localeCompare( a.product.data.attributes.name);
      }
      return 0;
    });
  };

  renderStatusItems = () => {
    const statusItemss = [
      { value: 0, name: "Initiated", className: "sky-blue" },
      { value: 1, name: "Sent Proposal", className: "orange" },
      { value: 2, name: "Follow Up", className: "yellow" },
      { value: 3, name: "Won", className: "green" },
      { value: 4, name: "Lost", className: "black" },
    ];

    return statusItemss.map((it) => (
      <MenuItem key={it.value} value={it.value} className="status-item">
        <Box className="status-name">{this.t(`${it.name}`)}</Box>
        <Box className={`status ${it.className}`}></Box>
      </MenuItem>
    ));
  };

   getMyGroups = (contactGroups:any) => {
    const {classes} = this.props;
    const groupLength = contactGroups.length;
    const userGroups = contactGroups;
    const filteredGroups = userGroups.filter((group: any) => group !== null);
    const defGroupLength: number = 2;
    const displayGroups = this.state.showMoreGroups
      ? filteredGroups
      : filteredGroups.slice(0, defGroupLength);

    return (
      <>
        {displayGroups.map((group: any) => {
          return (
            <Chip
              key={group?.name}
              className={classes.chipButton}
              label={group?.name}
            />
          );
        })}
        {filteredGroups.length > defGroupLength && (
          <>
            {this.state.showMoreGroups ? (
              <Chip
                className={`secondary-chip ${classes.chipButton}`}
                label="Show Less"
                onClick={this.toggleShowGroups}
                data-test-id="showLessBtn"
              />
            ) : (
              <Chip
                className={`more-btn ${classes.chipButton}`}
                label={`+${groupLength - displayGroups.length} more`}
                onClick={this.toggleShowGroups}
                data-test-id="showMoreBtn"
              />
            )}
          </>
        )}
      </>
    );
  };

  showWebLoader = () => {
    return <>{this.state.isLoading && <WebLoader/>}</>
  }

  displayNotes = (sortedNotes:any) => {
    return <>
    {sortedNotes?.map((note: any) => {
      const {
        first_name,
        last_name,
        created_by_id,
      } = note.attributes;
      const {noteUpdateId} = this.state;
      const {classes} = this.props;
      
      const profileInitials = `${first_name.charAt(0).toUpperCase()}${last_name.charAt(0).toUpperCase()}`;
      const noteContent = note.attributes.content;
      const noteId = note.id;

      const contentClassName = noteUpdateId
        ? noteUpdateId === noteId
          ? "no-opacity"
          : "grey-color"
        : "no-opacity";
      return (
        <>
          {noteContent !== "" && (
            <Box key={noteId}>
              {this.state.isShowEditNote && noteUpdateId === noteId ? (
                // Edit Mode
                <Box className="content-inner">
                  <Box className="assignee-profile">
                    {profileInitials}
                  </Box>
                  <Box className="assignee-info">
                    <Box className="top-info">
                      <Box className="info-left">
                        <Box className="name">
                          {first_name} {last_name}
                        </Box>
                      </Box>
                      <Box className="date">
                        {this.formatNoteDate(note.attributes.created_at)}
                      </Box>
                    </Box>
                    <Box className="edit-note-wrapper">
                      <CustomInputWeb
                        type="text"
                        label=""
                        value={this.state.selectedNote}
                        name=""
                        onChange={(event: any) =>
                          this.onUpdateNote(event.target.value)
                        }
                        errors={""}
                        isMultiline
                        data-test-id="edit-note-textfield"
                      />
                      <Box className="wrap-button">
                        <Button
                          className="btn-cancel"
                          onClick={this.onHideEditNote}
                        >
                          {this.t(`${configJSON.cancel}`)}
                        </Button>
                        <Button className="btn-save" onClick={this.updateNoteActionn}>
                          {this.t(`${configJSON.save}`)}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : (
                // View Mode
                <Box className={`content-inner ${contentClassName}`} key={noteId}>
                  <Box className="assignee-profile">
                    {profileInitials}
                  </Box>
                  <Box className="assignee-info">
                    <Box className="top-info">
                      <Box className="info-left">
                        <Box className="name">
                          {first_name} {last_name}
                        </Box>
                      </Box>
                      <Box className="date">
                        {this.formatNoteDate(note.attributes.created_at)}
                        {this.userId == created_by_id && 
                        <>
                        <MoreVertIcon
                          onClick={(event: any) =>
                            this.handleOpenNoteMenu(event, noteId, noteContent)
                          }
                          data-test-id="handleMenuOpen"
                        /> 
                        <Menu
                          id="note-update-menu"
                          anchorEl={this.state.noteMenuAnchor}
                          onClose={this.handleCloseMenuAnchor}
                          open={Boolean(this.state.noteMenuAnchor)}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "left",
                            vertical: "bottom",
                          }}
                          className={`${classes.dropDropdownMenuNote}`}
                        >
                          <MenuItem
                            onClick={() => this.onShowEditNote(noteUpdateId, this.state.selectedNote)}
                            data-test-id="onEditOpen"
                          >
                            {this.t(`${configJSON.edit}`)}
                          </MenuItem>
                          <MenuItem
                            onClick={this.onShowDeleteNoteConfirmation}
                            data-test-id="onDeleteOpen"
                          >
                            {this.t(`${configJSON.delete}`)}
                          </MenuItem>
                        </Menu>
                        </>
                        }
                      </Box>
                    </Box>
                    <Box className={noteUpdateId === noteId ? "assignee-desc-dark" : "assignee-desc"}>
                      {noteContent}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </>
      );
    })}
    </>
  }
  render() {
    // Customizable Area Start
    const { classes,contactDetails } = this.props;
    const {viewContactTabsValue, noteUpdateId, notes, isActiveChooseStage} = this.state;
    if (!contactDetails || !contactDetails.attributes) {
      return <>{this.state.isLoading && <WebLoader/>}</>; // Show loading or a placeholder
    }
   
    let dealsMessage;
  const dealMessageText = contactDetails.attributes.contact_interactions.data.length >= 2 ? this.t(`${configJSON.dealsTxt}`) : this.t(`${configJSON.dealTxt}`);
  dealsMessage = dealMessageText?.replace('{{count}}', contactDetails.attributes.contact_interactions.data.length);
    const sortedDeals = this.sortDealsData(contactDetails.attributes.contact_interactions.data);

    let noteMessage;
    const filteredNotes = this.state.notesData?.filter((note: any) => note.attributes.content !== "");
    const filteredNotesLength = filteredNotes ? filteredNotes.length : 0;
    const noteText = filteredNotesLength >= 2 ? this.t(`${configJSON.notesTxt}`) : this.t(`${configJSON.noteTxt}`);
    noteMessage = noteText?.replace('{{count}}', filteredNotesLength);

    let favProductMessage;
  const favProductMessageText = contactDetails.attributes.contact_products.length >= 2 ? this.t(`${configJSON.favProsuctsTxt}`) : this.t(`${configJSON.favProsuctTxt}`);
  favProductMessage = favProductMessageText?.replace('{{count}}', contactDetails.attributes.contact_products.length);

    const sortedNotes = this.sortNotesData();

    const sortedProducts = this.sortFavProductData(contactDetails.attributes.contact_products);

    
    return (
      <>
        {this.showWebLoader()}
        <Modal
          open={this.props.isShowViewContactModal}
          onClose={this.props.handleCloseViewContactModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalDialog}
        >
          <Box className={`view-modal-dialog ${classes.modalDialogBox}`}>
            <Box className="modal-heading">
              <Box className="heading-links">
                {this.userRole !== "employee" && 
                <>
                  <Box className="heading-link">
                    <Link
                      to="#"
                      className="icon"
                      onClick={this.props.handleMergeContactOpen}
                      data-test-id="handle-merge"
                    >
                      <img src={callMerge} alt="callMerge" />
                    </Link>
                  </Box>
                  <Box className="heading-link">
                    <Link
                      to="#"
                      className="icon"
                      data-test-id="deleteContactEvent"
                      onClick={this.deleteContactEventAction}
                    >
                      <img src={deleteGray} alt="deleteGray" />
                    </Link>
                  </Box>
                </>
                 }
                <Box className="heading-link">
                  <Link
                    to="#"
                    className="icon"
                    data-test-id="handleOpenAddGroupToContact"
                    onClick={this.props.handleOpenAddGroupToContact}
                  >
                    <img src={accountMultiplePlus} alt="accountMultiplePlus" />
                  </Link>
                </Box>
              </Box>
              <Link
                to="#"
                className="close-icon"
                onClick={this.props.handleCloseViewContactModal}
                data-test-id="viewContactsClose"
              >
                <CloseIcon />
              </Link>
            </Box>
            <Box className="modal-description">
            <Box className="profile-block-wrapper">
              <Box className="profile-info">
                <Box className="profile-row">
                  <Box className="left-col">
                    <Box className="heading-avatar">
                      <Box className="wrap-avatar">
                        <CardMedia
                          component="img"
                          className="avatar-bg"
                          src={editContactAvatarRound}
                        />
                        <Typography className="shortcut-name">
                          {contactDetails.attributes.first_name.charAt(0).toUpperCase()}
                          {contactDetails.attributes.last_name.charAt(0).toUpperCase()}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="right-col">
                    <Box className="contact-statistics">
                      <Box className="statistics-col">
                        <Box className="content">{this.t(`${configJSON.deals}`)}</Box>
                        <Box className="value">{contactDetails.attributes.contact_deals_count}</Box>
                        <Box className="icon">
                          <img src={handshake} alt="handshake" />
                        </Box>
                      </Box>
                      <Box className="statistics-col">
                        <Box className="content">{this.t(`${configJSON.outreach}`)}</Box>
                        <Box className="value">1</Box>
                        <Box className="icon">
                          <img src={emailFast} alt="emailFast" />
                        </Box>
                      </Box>
                      <Box className="statistics-col">
                        <Box className="content">{this.t(`${configJSON.notes}`)}</Box>
                        <Box className="value">{this.state.notesData?.filter((note: any) => note.attributes.content !== "").length}</Box>
                        <Box className="icon">
                          <img src={fileEdit} alt="fileEdit" />
                        </Box>
                      </Box>
                      <Box className="statistics-col">
                        <Box className="content">{this.t(`${configJSON.favProducts}`)}</Box>
                        <Box className="value">{contactDetails.attributes.contact_products.length}</Box>
                        <Box className="icon">
                          <img src={bottle} alt="bottle" />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="profile-name">
                  {`${contactDetails.attributes.first_name} ${contactDetails.attributes.last_name}`}
                  <CardMedia
                    component="img"
                    className="edit-icon"
                    src={pencil}
                    alt="pencil"
                    onClick={this.onEditContact}
                    data-test-id="oncontacteditclick"
                  />
                </Box>
                <Box className="job-info">
                  {contactDetails?.attributes?.business_info?.data?.attributes?.company_name ?? ""}
                </Box>
                <Box className="profile-team">
                  <Box className="team-label">{this.t(`${configJSON.groupsTxt}`)}</Box>
                  <Box className="chip-wrapper" data-test-id="groupChips">
                    {this.getMyGroups(contactDetails.attributes.contact_groups)}
                  </Box>
                </Box>
              </Box>
              <Box className="tasklist-tabwrapper">
              <Box>
                <Tabs
                  aria-label="simple tabs example"
                  value={this.state.viewContactTabsValue}
                  onChange={this.setViewContactTabsValue}
                  data-test-id="viewContactTabs"
                >
                  <Tab label={this.t(`${configJSON.aboutTxt}`)} {...viewContactProps(0)} />
                  <Tab label={this.t(`${configJSON.interactions}`)} {...viewContactProps(1)} />
                  <Tab label={this.t(`${configJSON.notes}`)} {...viewContactProps(2)} />
                  <Tab label={this.t(`${configJSON.favProducts}`)} {...viewContactProps(3)} />
                </Tabs>
              </Box>
              <TabPanel value={viewContactTabsValue} index={0}>
                <Box className="contact-info-wrapper">
                  <Box className="info-block">
                    <Box className="info-heading">{this.t(`${configJSON.personalInfoTxt}`)}</Box>
                    <Box className="about-info-row">
                      <Box className="about-col">
                        <Box className="label">{this.t(`${configJSON.phone}`)}</Box>
                        <Box className="value">{contactDetails.attributes.phone}</Box>
                      </Box>
                      <Box className="about-col">
                        <Box className="label">{this.t(`${configJSON.email}`)}</Box>
                        <Box className="value">{contactDetails.attributes.email}</Box>
                      </Box>
                      <Box className="about-col">
                        <Box className="label">{this.t(`${configJSON.createdDate}`)}</Box>
                        <Box className="value">
                          {this.formatCreatedDate(contactDetails.attributes.created_at)}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="info-block">
                    <Box className="info-heading">{this.t(`${configJSON.businessInformation}`)}</Box>
                    <Box className="about-info-row">
                      <Box className="about-col">
                        <Box className="label">{this.t(`${configJSON.companyName}`)}</Box>
                        <Box className="value">
                          {
                            contactDetails?.attributes.business_info?.data?.attributes?.company_name ?? ""
                          }
                        </Box>
                      </Box>
                      <Box className="about-col">
                        <Box className="label">{this.t(`${configJSON.vatNumberTxt}`)}</Box>
                        <Box className="value">
                          {
                            contactDetails?.attributes.business_info?.data?.attributes?.VAT_number ?? ""
                          }
                        </Box>
                      </Box>
                      <Box className="about-col">
                        <Box className="label">{this.t(`${configJSON.bCategoryinSmall}`)}</Box>
                        <Box className="value">
                          {
                            contactDetails?.attributes.business_info?.data?.attributes?.business_category_name ?? ""
                          }
                        </Box>
                      </Box>
                      <Box className="about-col">
                        <Box className="label">{this.t(`${configJSON.billingAddressTxt}`)}</Box>
                        <Box className="value">
                          {displayAddress(contactDetails?.attributes?.billing_address)}
                        </Box>
                      </Box>
                      <Box className="about-col">
                        <Box className="label">{this.t(`${configJSON.shippingAddress}`)}</Box>
                        <Box className="value">
                          {displayAddress(contactDetails?.attributes?.shipping_address)}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={viewContactTabsValue} index={1} data-test-id="interactions-tab">
                <Box className="contact-accodion">
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box className="accodion-heading">{this.t(`${configJSON.deals}`)}</Box>
                    </AccordionSummary>
                    <AccordionDetails className="accodion-content">
                      <Box className="content-heading">
                        <Box className="title">{dealsMessage}</Box>
                        <Box className="content-filter">
                          <FormControl className="select-control sortby-control border-hr">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  horizontal: "left",
                                  vertical: "bottom",
                                },
                                transformOrigin: {
                                  horizontal: "left",
                                  vertical: "top",
                                },
                                classes: { paper: classes.dropdownStyle },
                                getContentAnchorEl: null,
                              }}
                              //onChange={(e: any) => handleSortChangeDeals(e)}
                              displayEmpty
                              defaultValue={this.t(`${configJSON.sortByTxt}`)}
                              data-test-id="handleSortChangeDeals"
                              renderValue={(value: any) => {
                                return <Box>{value}</Box>;
                              }}
                              IconComponent={() => (
                                <ExpandMoreIcon className="down-arrow" />
                              )}
                            >
                              <MenuItem value="A-Z">A-Z</MenuItem>
                              <MenuItem value="Z-A">Z-A</MenuItem>
                            </Select>
                          </FormControl>
                          <Box className="content-icon"
                            onClick={(event: any) => this.handleOpenDeal(event)}
                            data-test-id="handleOpenDeal"
                          >
                            <img src={plus} alt="plus" />
                            {this.state.createDealPopoverShown && (
                              <Popover
                                open={Boolean(this.state.anchorElDeal)}
                                anchorEl={this.state.anchorElDeal}
                                onClose={this.handleCloseDeal}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                classes={{ paper: `${classes.createDealsBox}` }}
                              >
                                <CreateDealWeb
                                  handleCloseDialog={this.handleCloseDeal}
                                  classes={classes}
                                  handleSelectContactsOpen={this.props.handleSelectContactsOpen}
                                  data-test-id="create-deal-web"
                                  contactDetails={this.state.contactDetails}
                                />
                              </Popover>
                            )}
                          </Box>
                        </Box>
                      </Box>
                      {sortedDeals?.map((deal: any) => {
                        const first_name = deal.attributes.contact[0].first_name;
                        const last_name = deal.attributes.contact[0].last_name;
                        const fullName = `${first_name} ${last_name}`;
                        return <Box className="content-details" key={deal.id}>
                          <Box className="content-inner">
                            <Box className="assignee-profile">
                            {showLettersFromName(fullName)}
                            </Box>
                            <Box className="assignee-info">
                              <Box className="top-info">
                                <Box className="info-left">
                                  <Box className="name">{fullName}</Box>
                                  <Box className="form-col select-modal-field">
                                    <CustomInputWeb
                                      label=""
                                      disabled={true}
                                      startAdornment={
                                        <InputAdornment position="start">
                                          <Box className="status-item">
                                            <Box className="status-name">
                                              {this.t(`${deal.attributes.stage}`)}
                                            </Box>
                                            {this.stageStatusClassMaping(
                                              deal.attributes.stage
                                            )}
                                          </Box>
                                        </InputAdornment>
                                      }
                                      endAdornment={
                                        <InputAdornment
                                          position="end"
                                          onClick={() =>
                                            this.chooseStageHandlerEvent(
                                              deal.id,
                                              deal.attributes.stage,
                                              deal.attributes.note
                                            )
                                          }
                                          data-test-id={`chooseStageModal-${deal.id}`}
                                        >
                                          <ExpandMoreIcon />
                                        </InputAdornment>
                                      }
                                      data-test-id={`stageInput-${deal.id}`}
                                      isRequired={true}
                                    />
                                  </Box>
                                </Box>
                                <Box className="date">
                                  <img
                                    alt="paperClip"
                                    src={paperClip}
                                    className="attach-icon"
                                  />
                                  {this.formatCreatedDate(deal.attributes.close_date)}
                                </Box>
                              </Box>
                              <Box className="primary-txt">
                                {(deal.attributes.name)}
                              </Box>
                              <Box className="assignee-desc">
                                {deal.attributes.description}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      })}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Box className="accodion-heading">{this.t(`${configJSON.outreach}`)}</Box>
                    </AccordionSummary>
                    <AccordionDetails className="accodion-content">
                      <Box className="content-heading">
                        <Box className="title">1 {this.t(`${configJSON.outreaches}`)}</Box>
                        <Box className="content-filter">
                          <FormControl className="select-control sortby-control border-hr">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  horizontal: "left",
                                  vertical: "bottom",
                                },
                                transformOrigin: {
                                  horizontal: "left",
                                  vertical: "top",
                                },
                                getContentAnchorEl: null,
                                classes: { paper: classes.dropdownStyle },
                              }}
                              displayEmpty
                              // onChange={(event, type) =>
                              //   onSelectedOptions(event, "selectedSortByVal")
                              // }
                              defaultValue={this.t(`${configJSON.sortByTxt}`)}
                              data-testid="sortTasks"
                              renderValue={(value: any) => {
                                return <Box>{value}</Box>;
                              }}
                              //value={selectedSortBy}
                              IconComponent={() => (
                                <ExpandMoreIcon className="down-arrow" />
                              )}
                            >
                              <MenuItem value="A-Z">A-Z</MenuItem>
                              <MenuItem value="Z-A">Z-A</MenuItem>
                            </Select>
                          </FormControl>
                          <Box className="content-icon" onClick={(event: any) => this.openCreateCampaigns(event)} data-test-id="openCampaignForms">
                            <img src={plus} alt="plus" />
                          </Box>
                        </Box>
                      </Box>
                      {this.state.createCampaignPopover && (
                         <CreateEmailCampaign
                         popoveAnchorEl={this.state.anchorElCampaign}
                         popoverClose={this.closeCreateCampaigns}
                         getAllCampaingsList={this.getContactDetails}
                         data-test-id="email=campaign-form"
                         />
                      )}
                     
                      <Box className="content-details">
                        <Box className="content-inner">
                          <Box className="assignee-profile">AC</Box>
                          <Box className="assignee-info">
                            <Box className="top-info">
                              <Box className="info-left">
                                <Box className="name">Andrew Ceno</Box>
                              </Box>
                              <Box className="date">
                                <img
                                  src={paperClip}
                                  className="attach-icon"
                                  alt="paperClip"
                                />
                                10:32 AM
                              </Box>
                            </Box>
                            <Box className="primary-txt">
                              Report - Chatto project
                            </Box>
                            <Box className="assignee-desc">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has...
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </TabPanel>
              <TabPanel value={viewContactTabsValue} index={2}>
                <Box className="contact-accodion">
                  <Accordion defaultExpanded={true} data-test-id="accordian">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      id="panel1a-header"
                      aria-controls="panel1a-content"
                    >
                      <Box className="accodion-heading">{this.t(`${configJSON.notes}`)}</Box>
                    </AccordionSummary>
                    <AccordionDetails className="accodion-content" data-test-id="accordionDetails">
                      <Box className="content-heading">
                        <Box className="title">{noteMessage}</Box>
                        <Box className="content-filter">
                          <FormControl className="select-control sortby-control border-hr">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                classes: { paper: classes.dropdownStyle },
                              }}
                             onChange={(e: any) => this.handleSortChangeNotes(e)}
                              defaultValue={this.t(`${configJSON.sortByTxt}`)}
                              displayEmpty
                              data-test-id="handleSortChangeNotes"
                              renderValue={(value: any) => {
                                return <Box>{value}</Box>;
                              }}
                              IconComponent={() => (
                                <ExpandMoreIcon className="down-arrow" />
                              )}
                            >
                              <MenuItem value={this.t(`${configJSON.newestToOldest}`)}>{this.t(`${configJSON.newestToOldest}`)}</MenuItem>
                              <MenuItem value={this.t(`${configJSON.oldestToNewest}`)}>{this.t(`${configJSON.oldestToNewest}`)}</MenuItem>
                            </Select>
                          </FormControl>
                          <Box
                            className="content-icon"
                            onClick={this.handleShowAddNote}
                            data-test-id="add-note-plus-icon"
                          >
                            <AddIcon fontSize="small" style={{ color: "#727171" }} />
                          </Box>
                        </Box>
                      </Box>
                      {this.state.isShowAddNote && (
                        <>
                          <CustomInputWeb
                            type="text"
                            label=""
                            value={notes}
                            name="notes"
                            placeholder={this.t(`${configJSON.typeHere}`)}
                            onChange={this.handleInputChange}
                            errors={""}
                            rows={4}
                            isMultiline
                            data-test-id="notes"
                          />
                          <Box className="wrap-add-note-btn">
                            <Button
                              className="cancel-add-note-btn"
                              onClick={this.handleHideAddNote}
                              data-test-id="handleHideAddNote"
                            >
                              {this.t(`${configJSON.cancel}`)}
                            </Button>
                            <Button
                              data-test-id="add-note-btn-create"
                              onClick={this.createNotess}
                              className={notes.trim() === "" ? "add-note-btn-disable" : "add-note-btn"}
                              disabled={notes.trim() === ""}
                            >{this.t(`${configJSON.addNote}`)}</Button>
                          </Box>
                        </>
                      )}

                      <Box className="content-details">
                        {this.displayNotes(sortedNotes)}
                     

                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </TabPanel>
              <TabPanel value={viewContactTabsValue} index={3}>
                <Box className="contact-accodion">
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box className="accodion-heading">
                        {this.t(`${configJSON.favoriteProducts}`)}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails className="accodion-content">
                      <Box className="content-heading">
                        <Box className="title">{favProductMessage}</Box>
                        <Box className="content-filter">
                          <FormControl className="select-control sortby-control border-hr">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  horizontal: "left",
                                  vertical: "top",
                                },
                                classes: { paper: classes.dropdownStyle },
                                getContentAnchorEl: null,
                              }}
                              defaultValue={this.t(`${configJSON.sortByTxt}`)}
                              displayEmpty
                              //onChange={(e: any) => handleSortChange(e)}
                              data-test-id="handleSortChange"
                              renderValue={(value: any) => {
                                return <Box>{value}</Box>;
                              }}
                              IconComponent={() => (
                                <ExpandMoreIcon className="down-arrow" />
                              )}
                            >
                              <MenuItem value="A-Z">A-Z</MenuItem>
                              <MenuItem value="Z-A">Z-A</MenuItem>
                            </Select>
                          </FormControl>
                          <Box className="content-icon" onClick={this.handleOpenFavProduct} data-test-id="add-fav-prdct">
                            <img src={plus} alt="plus" />
                          </Box>
                        </Box>
                      </Box>
                      <Box className="content-details">
                        <Box className="product-list-row">
                          {sortedProducts?.map((item: any) => {  
                            const {
                              targetName,
                              brandName,
                              materialName,
                              colorName,
                              style,
                              appellation,
                              grapeVariety,
                              vintage,
                              itemName, 
                              price
                            } = extractItemDetails(item.product.data);                         
                            return <Box className="product-col col4" key={item.product.data.id}>
                              <Box className="product-inner">
                                <Link to="#" className="close-icon" data-test-id="handleRemoveProduct" onClick={() => this.handleRemoveProduct(item.product.data.id)}>
                                  <CloseIcon />
                                </Link>
                                <Box className="product-img">
                                {getImageForSubCategory(item.product.data.attributes.sub_category.id, item.product.data.attributes)}
                                </Box>
                                <Box className="product-details">
                                  <Box className="product-info">
                                    <Box className="year">2024</Box>
                                    <Box className="rating">
                                    {vintage} ml
                                    </Box>
                                  </Box>
                                  <Box className="product-name">
                                  {itemName}
                                  </Box>
                                  <Box className="product-desc">
                                    {brandName}
                                    {style}
                                    {targetName !== "" && brandName !== ""
                                      ? `, ${targetName}`
                                      : targetName}
                                    {materialName !== "" && brandName !== ""
                                      ? `, ${materialName}`
                                      : materialName}
                                    {colorName !== "" && (brandName !== "" || style !== "")
                                      ? `, ${colorName}`
                                      : colorName}
                                    {appellation}
                                    {grapeVariety !== "" && appellation !== ""
                                      ? `, ${grapeVariety}`
                                      : grapeVariety}
                                  </Box>
                                  <Typography className="price-txt">
                                  ${((price))?.toFixed(2)}
                                </Typography>
                                </Box>
                              </Box>
                            </Box>
                          })}
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </TabPanel>
            </Box>

            </Box>
            </Box>
          </Box>
        </Modal>
        {this.state.isShowDeleteNoteConfirmation && (
        <Modal
          open={this.state.isShowDeleteNoteConfirmation}
          onClose={this.onHideDeleteNoteConfirmation}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalDialog}
        >
          <Box className={classes.deleteNoteModal}>
            <Box className="heading">
              <Typography className="delete-note-txt">{this.t(`${configJSON.deleteNote}`)}</Typography>
              <CloseIcon
                className="close-icon"
                onClick={this.onHideDeleteNoteConfirmation}
              />
            </Box>
            <Typography className="confirm-txt">
              {this.t(`${configJSON.deleteNoteConfirmationTxt}`)}
            </Typography>
            <Box className="wrap-button">
              <Button
                className="cancel-btn"
                onClick={this.onHideDeleteNoteConfirmation}
              >
                {this.t(`${configJSON.cancel}`)}
              </Button>
              <Button
                className="delete-btn"
                onClick={this.deleteNoteActionn}
                data-test-id="deleteAction"
              >
                {this.t(`${configJSON.delete}`)}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
      {this.state.isDeleteContact && (
        <DeleteContact
          modalOpen={this.state.delModalOpen}
          modalClose={this.closeDeleteModalAction}
          delContactDetails={this.state.delContactDetails}
          deleteContactAction={this.deleteContactAction}
          headding={this.t(`${configJSON.deleteContactTxt}`)}
          cancleBtntxt={this.t(`${configJSON.cancel}`)}
          deleteBtntxt={this.t(`${configJSON.delete}`)}
          confirmationMsg={this.t(`${configJSON.deleteTxtMsgContact}`)}
          data-test-id="deleteContact"
        />
      )}
      {/* edit contact modal*/}
      {this.state.isShowEditContact && (
        <EditContactWeb
          navigation={this.props.navigation}
          isShowEditContact={this.state.isShowEditContact}
          handleOpenEditContact={this.onEditContact}
          hancleCloseEditContact={this.onCloseEditContact}
          viewSingleDetails={this.state.contactDetails}
          undoEditAction={this.undoEditAction}
          data-test-id="editContactWeb"
        />
      )}
       {isActiveChooseStage && (
        <Modal
          open={isActiveChooseStage}
          onClose={this.chooseStageModalCloseEvent}
          aria-describedby="modal-modal-description"
          aria-labelledby="modal-modal-title"
          BackdropProps={{ className: "backdrop-root" }}
          className={`${classes.modalDialog}`}
          data-test-id="activeStageModal"
        >
          <Box
            className={`${classes.modalCenterDialogBox} modalCenterDialogBox`}
          >
            <Box className="modal-heading">
              <Typography className="modal-title" component="h2">
                {this.t(`${configJSON.changeStatus}`)}
              </Typography>
              <Link
                to="#"
                data-test-id="stage-modal-close"
                className="close-icon"
                onClick={this.chooseStageModalCloseEvent}
              >
                <CloseOutlinedIcon />
              </Link>
            </Box>
            <Box className="modal-description-stage">
              <Box className="modal-form-stage" component="form">
                <Box className="form-info-wrapper">
                  <Box className="form-row">
                    <Box className="form-col">
                      <FormControl
                        variant="outlined"
                        className="select-outer"
                      >
                        <InputLabel id="stage">{this.t(`${configJSON.stagetxt}`)}</InputLabel>
                        <Select
                          labelId="stage"
                          value={this.state.newStatus ?? 0}
                          defaultValue={
                            this.state.currentDealStage
                          }
                          name="newStage"
                          onChange={this.handleStageChangeEvent}
                          label="Current Stage"
                          MenuProps={{
                            anchorOrigin: {
                              horizontal: "left",
                              vertical: "bottom",
                            },
                            transformOrigin: {
                              horizontal: "left",
                              vertical: "top",
                            },
                            classes: {
                              paper: `${classes.dropdownStyleDeals} modal-dropdown`,
                            },
                            getContentAnchorEl: null,
                          }}
                          IconComponent={ExpandMoreIcon}
                          data-test-id="modalChangeStatus"
                        >
                          {this.renderStatusItems()}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomInputWeb
                        type="text"
                        value={this.state.addDealNote}
                        label={this.t(`${configJSON.addANoteTxt}`)}
                        name="addNote"
                        onChange={(event) =>
                          this.dealNoteHandlerEvent(event.target.value)
                        }
                        data-test-id="dealNote-input"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="modal-footer">
                <Button
                  className={`secondary-btn ${classes.secondaryButton}`}
                  title="Cancel"
                  onClick={this.chooseStageModalCloseEvent}
                >
                  {this.t(`${configJSON.cancel}`)}
                </Button>
                <Button
                  title="Update"
                  className={`primary-btn ${classes.primaryButton}`}
                  data-test-id="updateDealStage"
                  onClick={this.updateDealStageEvent}
                >
                  {this.t(`${configJSON.update}`)}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
      {this.state.favProduct && (
        //@ts-ignore
        <ProductList
          openProductsModal={this.state.favProduct}
          closeProductsModal={this.handleCloseFavPRoduct}
          classes={classes}
          buttonTxt={this.t("Add as Favorite")}
          data-test-id="is-fav-product"
          addAsFavProduct={this.addAsFavProduct}
        />
      )

      }
      </>
    );
  }
}

export default withStyles(customStyles, { withTheme: true })(ViewContactNew);
// Customizable Area End
