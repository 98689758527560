// Customizable Area Start
import React from 'react';
import { Card, CardContent, Typography, Box, Button } from '@material-ui/core';
import {
    createStyles,
    withStyles,
    Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import TutorialScreenControllerWeb, { Props } from './TutorialScreenController.web';
import { tutorialLogo, TUTORIAL_BG } from './assets';
import { vectorPng } from '../../cfescore/src/assets';
import Footer from '../../../components/src/Footer.web';
// Customizable Area End

export const customStyles = (theme: AugmentedTheme) =>
    createStyles({
        container: {
            "& .footer-wrapper": {
                 "& .downlaod-app": {
                    color: '#FFFFFF'
                },
                "& .copyright-txt": {
                    color: '#FFFFFF',
                "& span": {
                    color: '#FFFFFF',
                    "& a": {
                        color: '#FFFFFF',
                    }
                }
            },
             "& .translate-language":{
                "& .translate-control":{
                    "& .MuiSelect-select": {
                color: "#FFFFFF"
            },
                   "& .MuiSelect-icon": {
                     color: "#FFFFFF"
                   }
                }
             }
            },
        },
        boxContainer: {
            backgroundImage: `url(${TUTORIAL_BG})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            "& .MuiBox-root": {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '100px',
                    paddingBottom: '100px',
                    backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)), linear-gradient(180.04deg, rgba(0, 0, 0, 0) 54.18%, rgba(0, 0, 0, 0.8) 99.97%)',
            },
            "& .MuiButton-label" : {
                textTransform: "initial"
            },   
        },
        tutorialImg: {
            position: "absolute",
            top: "40px",
            left: "40px",
            width: "150x",
            height: "30px",
        },
        tutorialBox: {
            background: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '750px',
            borderRadius: '15px',
            "& .MuiCardContent-root": {
                position: 'relative',
                padding: '20px'
            }
        },
        crossIcon: {
            position: 'absolute',
            top: '5px',
            right: '3px',
            borderRadius: "100%",
            border: "2px solid #E8ECF2",
            padding: '6px'
        },
        tutorialTitle: {
            textAlign: 'center',
            fontFamily: 'Expletus Sans',
            fontSize: '24px',
            fontWeight: 400
        },
        tutorialDescription: {
            textAlign: 'center',
            fontFamily: 'Expletus Sans',
            fontSize: '14px',
            color: '#94A3B8',
            fontWeight: 500
        },
        tutorialFooter1: {
           display: 'flex',
           alignItems: 'center',
           justifyContent: 'space-between',
           position: 'relative',
        },
        tutorialFooter2: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            position: 'relative',
            marginTop: '15px'
         },
        tutorialDot: {
            display: 'flex',
            alignItems: 'center',
            justifySelf: 'center',
            gap: '5px',
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%)',
        },
        activeDot: {
            width: '7px',
            height: '7px',
            borderRadius: '7px',
            backgroundColor: 'black'
        },
        inactiveDot: {
            width: '5px',
            height: '5px',
            borderRadius: '5px',
            backgroundColor: 'gray'
        }
    })

export class  TutorialScreen extends TutorialScreenControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    
    render() {
        // Customizable Area Start
        console.log(this.state, 'state')
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <img className={classes.tutorialImg} src={tutorialLogo} />
                <div className={classes.boxContainer}>
                    <Box>
                        <Card className={classes.tutorialBox}>
                            <CardContent>
                                <div>
                                    <img src={this.state.tutorialData[this.state.activeTab]?.attributes?.image?.image_url} />
                                    <div className={classes.crossIcon} onClick={this.onRedirectCreateAccount}>
                                        <img style={{width:'8px', height:'8px'}} src={vectorPng}/>
                                    </div>
                                </div>

                                <Typography className={classes.tutorialTitle} variant="h5">
                                    {this.state.tutorialData[this.state.activeTab]?.attributes?.title}
                                </Typography>
                                <Typography className={classes.tutorialDescription} variant="body2">
                                    {this.state.tutorialData[this.state.activeTab]?.attributes?.description}
                                </Typography>

                                <div className={this.state.activeTab !== 0 ? classes.tutorialFooter1 : classes.tutorialFooter2}>
                                    {this.state.activeTab !== 0 &&
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            style={{ borderColor:'#4BA3B7', color:'#4BA3B7',borderRadius: '6px'}}
                                            onClick={this.previousTutorialSlider}
                                        >
                                            Prev Step
                                        </Button>
                                    }

                                    <div className={classes.tutorialDot}>
                                        {[0, 1, 2].map(data => {
                                            return (
                                                <div className={this.state.activeTab === data ? classes.activeDot : classes.inactiveDot}></div>
                                            )
                                        })}
                                    </div>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ backgroundColor:' #4BA3B7', color:'white', borderRadius: '6px'}}
                                        onClick={this.state.activeTab === 2 ? this.onRedirectCreateAccount : this.tutorialSlider}
                                    >
                                        {this.state.activeTab === 2 ? "Get Started" : "Next Step"}
                                    </Button>
                                </div>
                            </CardContent>
                        </Card>
                    </Box>
                </div>
                 <Footer/>
            </div>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(TutorialScreen);
// Customizable Area End

