import { ChatMessagesResponse } from "./ReusableEnums";

export const productData = [
  {
    id: "374",
    type: "catalogue",
    attributes: {
      category: {
        id: 1,
        name: "K12",
        created_at: "2021-11-26T10:55:56.911Z",
        updated_at: "2021-11-26T10:55:56.911Z",
        admin_user_id: null,
        rank: null,
        light_icon: {
          url: null,
        },
        light_icon_active: {
          url: null,
        },
        light_icon_inactive: {
          url: null,
        },
        dark_icon: {
          url: null,
        },
        dark_icon_active: {
          url: null,
        },
        dark_icon_inactive: {
          url: null,
        },
        identifier: 0,
        inventory_id: null,
        static_id: null,
      },
      sub_category: {
        id: 1,
        name: "Pre Primary (kg)",
        created_at: "2021-11-26T10:55:56.978Z",
        updated_at: "2021-11-26T10:55:56.978Z",
        parent_id: null,
        rank: null,
        static_id: null,
      },
      brand: null,
      target: null,
      tags: [],
      reviews: [],
      name: "abc hahaha 123",
      sku: null,
      description: null,
      manufacture_date: null,
      length: null,
      breadth: null,
      height: null,
      stock_qty: 10,
      availability: null,
      weight: null,
      price: null,
      recommended: null,
      on_sale: null,
      sale_price: null,
      discount: null,
      meta_data: {
        age: "1",
        sku: "123",
        gtin: "1234567890123",
        name: "abc hahaha 123",
        brand: "qbc",
        color: "Red",
        dosage: "Brut Nature",
        alcohol: "12",
        vintage: "1",
        producer: "abc",
        quantity: "10",
        allergens: "12",
        aging_process: "1334",
        grape_variety: "Abbuoto",
        aging_potential: "1234",
        name_complement: "q3",
        short_description: "abc",
        production_process: "Barrel",
        container_with_volume: "Balthazar",
        grape_variety_percent: "12",
      },
      tasting_notes: {
        eye: "Red",
        nose: "Fine",
        mouth: "Balanced",
        comment: "w",
        main_aromas: "Candy",
        temperature: "12",
        food_pairing: "Hamburgers",
        opening_time: "12",
      },
      images: [
        {
          id: 235,
          filename: "IMG_20231219_143748_631.jpg",
          url:
            "https://etohapp-123437-ruby.b123437.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZXM9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2ec1df15abd09b75c2231a59e8184bd7aa958249/IMG_20231219_143748_631.jpg",
        },
        {
          id: 252,
          filename: "IMG_20231218_174528_631.jpg",
          url:
            "https://etohapp-123437-ruby.b123437.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZnc9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--70d37d39f108eaa6af4768c55de8bb04a353f6e9/IMG_20231218_174528_631.jpg",
        },
      ],
      is_available: true,
      average_rating: 0,
      catalogue_variants: [],
      allergens: [],
      location: {
        id: 132,
        appellation_type: "",
        appellation: "",
        country: "Afghanistan",
        region: "Andkhoy",
        sub_region: "",
        style: null,
        era: null,
        string: null,
        region_of_origin: null,
        catalogue_id: 374,
        created_at: "2023-12-21T13:22:41.485Z",
        updated_at: "2023-12-21T13:22:41.485Z",
      },
      certification: null,
      trophies: [
        {
          id: 73,
          name: "ABSA Top 10 Pinotage",
          year: "1234",
          comment: "134",
          result: "Gold",
          image:
            "https://etohapp-123437-ruby.b123437.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZW89IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--e9e2b3b6fcdbb9629f2d0b583cfab29010da8cca/Screenshot_2023-12-19-14-37-42-771_com.EtOHapp.jpg",
        },
      ],
      prices: [
        {
          id: 60,
          title: "test0",
          amount: 123,
          tax_included: false,
        },
      ],
    },
  },
  {
    id: "369",
    type: "catalogue",
    attributes: {
      category: {
        id: 1,
        name: "K12",
        created_at: "2021-11-26T10:55:56.911Z",
        updated_at: "2021-11-26T10:55:56.911Z",
        admin_user_id: null,
        rank: null,
        light_icon: {
          url: null,
        },
        light_icon_active: {
          url: null,
        },
        light_icon_inactive: {
          url: null,
        },
        dark_icon: {
          url: null,
        },
        dark_icon_active: {
          url: null,
        },
        dark_icon_inactive: {
          url: null,
        },
        identifier: 0,
        inventory_id: null,
        static_id: null,
      },
      sub_category: {
        id: 4,
        name: "Secondary (9 & 10)",
        created_at: "2021-11-26T10:55:57.006Z",
        updated_at: "2021-11-26T10:55:57.006Z",
        parent_id: null,
        rank: null,
        static_id: null,
      },
      brand: null,
      target: null,
      tags: [],
      reviews: [],
      name: "Hari ",
      sku: null,
      description: null,
      manufacture_date: null,
      length: null,
      breadth: null,
      height: null,
      stock_qty: null,
      availability: null,
      weight: null,
      price: null,
      recommended: null,
      on_sale: null,
      sale_price: null,
      discount: null,
      meta_data: {
        sku: "relif",
        gtin: "1234567890987",
        name: "Hari ",
        brand: "old monk ",
        comment: "mind relief ",
        producer: "krishna ",
        allergens: "Refresh ",
        raw_material: "grapes",
        aging_process: "super ",
        name_complement: "excellent ",
        short_description: "good product ",
        production_process: "Barrel",
        raw_material_percent: "25",
        container_with_volume: "Nebuchadnezzar",
      },
      tasting_notes: {},
      images: [
        {
          id: 232,
          filename: "images (19).jpeg",
          url:
            "https://etohapp-123437-ruby.b123437.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZWc9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0abe62799b71e132527cbfdd0f0ddec98d1e4404/images%20(19).jpeg",
        },
      ],
      is_available: null,
      average_rating: 0,
      catalogue_variants: [],
      allergens: [],
      location: {
        id: 104,
        appellation_type: "",
        appellation: "",
        country: "Afghanistan",
        region: "Andkhoy",
        sub_region: "",
        style: null,
        era: null,
        string: null,
        region_of_origin: null,
        catalogue_id: 369,
        created_at: "2023-12-20T06:19:02.526Z",
        updated_at: "2023-12-20T06:19:02.526Z",
      },
      certification: null,
      trophies: [],
      prices: [],
    },
  },
  {
    id: "373",
    type: "catalogue",
    attributes: {
      category: {
        id: 1,
        name: "K12",
        created_at: "2021-11-26T10:55:56.911Z",
        updated_at: "2021-11-26T10:55:56.911Z",
        admin_user_id: null,
        rank: null,
        light_icon: {
          url: null,
        },
        light_icon_active: {
          url: null,
        },
        light_icon_inactive: {
          url: null,
        },
        dark_icon: {
          url: null,
        },
        dark_icon_active: {
          url: null,
        },
        dark_icon_inactive: {
          url: null,
        },
        identifier: 0,
        inventory_id: null,
        static_id: null,
      },
      sub_category: {
        id: 1,
        name: "Pre Primary (kg)",
        created_at: "2021-11-26T10:55:56.978Z",
        updated_at: "2021-11-26T10:55:56.978Z",
        parent_id: null,
        rank: null,
        static_id: null,
      },
      brand: null,
      target: null,
      tags: [],
      reviews: [],
      name: "bxbxb",
      sku: null,
      description: null,
      manufacture_date: null,
      length: null,
      breadth: null,
      height: null,
      stock_qty: 1,
      availability: null,
      weight: null,
      price: null,
      recommended: null,
      on_sale: null,
      sale_price: null,
      discount: null,
      meta_data: {
        age: "1",
        sku: "stst",
        gtin: "1234567890123",
        name: "bxbxb",
        brand: "hdueh",
        color: "Rose",
        dosage: "Brut Nature",
        alcohol: "12",
        vintage: "1",
        producer: "hehe",
        quantity: "1",
        allergens: "123",
        aging_process: "144",
        grape_variety: "Abouriou",
        aging_potential: "1234",
        name_complement: "sgsg",
        short_description: "eheh",
        production_process: "Barrel",
        container_with_volume: "Nebuchadnezzar",
        grape_variety_percent: "12",
      },
      tasting_notes: {
        eye: "Red",
        nose: "Fine",
        mouth: "Balanced",
        comment: "qr",
        main_aromas: "Candy",
        temperature: "12",
        food_pairing: "Hamburgers",
        opening_time: "12",
      },
      images: null,
      is_available: true,
      average_rating: 0,
      catalogue_variants: [],
      allergens: [],
      location: {
        id: 108,
        appellation_type: "",
        appellation: "",
        country: "Afghanistan",
        region: "Andkhoy",
        sub_region: "",
        style: null,
        era: null,
        string: null,
        region_of_origin: null,
        catalogue_id: 373,
        created_at: "2023-12-20T07:01:27.476Z",
        updated_at: "2023-12-20T07:01:27.476Z",
      },
      certification: null,
      trophies: [
        {
          id: 72,
          name: "ABSA Top 10 Pinotage",
          year: "1234",
          comment: "1234",
          result: "Gold",
          image:
            "https://etohapp-123437-ruby.b123437.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZWs9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1191b76152dd8e7d3fe6dd0c9f555fd933c14d3c/Screenshot_2023-12-19-21-58-06-107_com.EtOHapp.jpg",
        },
      ],
      prices: [
        {
          id: 59,
          title: "test0",
          amount: 12,
          tax_included: false,
        },
      ],
    },
  },
  {
    id: "376",
    type: "catalogue",
    attributes: {
      category: {
        id: 1,
        name: "K12",
        created_at: "2021-11-26T10:55:56.911Z",
        updated_at: "2021-11-26T10:55:56.911Z",
        admin_user_id: null,
        rank: null,
        light_icon: {
          url: null,
        },
        light_icon_active: {
          url: null,
        },
        light_icon_inactive: {
          url: null,
        },
        dark_icon: {
          url: null,
        },
        dark_icon_active: {
          url: null,
        },
        dark_icon_inactive: {
          url: null,
        },
        identifier: 0,
        inventory_id: null,
        static_id: null,
      },
      sub_category: {
        id: 4,
        name: "Secondary (9 & 10)",
        created_at: "2021-11-26T10:55:57.006Z",
        updated_at: "2021-11-26T10:55:57.006Z",
        parent_id: null,
        rank: null,
        static_id: null,
      },
      brand: null,
      target: null,
      tags: [],
      reviews: [],
      name: "pro q",
      sku: null,
      description: null,
      manufacture_date: null,
      length: null,
      breadth: null,
      height: null,
      stock_qty: null,
      availability: null,
      weight: null,
      price: null,
      recommended: null,
      on_sale: null,
      sale_price: null,
      discount: null,
      meta_data: {
        sku: "sfg",
        gtin: "1456697987576",
        name: "pro q",
        brand: "fhj",
        comment: "fah",
        producer: "csb",
        allergens: "vh",
        raw_material: "fruit",
        aging_process: "fah",
        name_complement: "vhh",
        short_description: "vsj",
        production_process: "Stainless steel tank",
        raw_material_percent: "576",
        container_with_volume: "Nebuchadnezzar",
      },
      tasting_notes: {},
      images: null,
      is_available: null,
      average_rating: 0,
      catalogue_variants: [],
      allergens: [],
      location: {
        id: 111,
        appellation_type: "",
        appellation: "",
        country: "Afghanistan",
        region: "Andkhoy",
        sub_region: "",
        style: null,
        era: null,
        string: null,
        region_of_origin: null,
        catalogue_id: 376,
        created_at: "2023-12-20T07:15:31.740Z",
        updated_at: "2023-12-20T07:15:31.740Z",
      },
      certification: null,
      trophies: [],
      prices: [
        {
          id: 61,
          title: "test0",
          amount: 57,
          tax_included: false,
        },
      ],
    },
  },
];

export const producerData = [
  {
    id: "34",
    type: "brand",
    attributes: {
      id: 34,
      name: "Producer A",
      created_at: "2023-12-05T05:33:11.268Z",
      updated_at: "2023-12-05T05:33:11.268Z",
    },
  },
  {
    id: "35",
    type: "brand",
    attributes: {
      id: 35,
      name: "Producer A",
      created_at: "2023-12-05T10:09:15.106Z",
      updated_at: "2023-12-05T10:09:15.106Z",
    },
  },
  {
    id: "36",
    type: "brand",
    attributes: {
      id: 36,
      name: "wine brand",
      created_at: "2023-12-05T11:05:57.326Z",
      updated_at: "2023-12-05T11:05:57.326Z",
    },
  },

  {
    id: "87",
    type: "brand",
    attributes: {
      id: 87,
      name: "Producer A",
      created_at: "2023-12-07T10:01:35.988Z",
      updated_at: "2023-12-07T10:01:35.988Z",
    },
  },
  {
    id: "88",
    type: "brand",
    attributes: {
      id: 88,
      name: "Producer A",
      created_at: "2023-12-07T14:40:47.448Z",
      updated_at: "2023-12-07T14:40:47.448Z",
    },
  },
  {
    id: "89",
    type: "brand",
    attributes: {
      id: 89,
      name: "Producer A",
      created_at: "2023-12-07T15:17:32.157Z",
      updated_at: "2023-12-07T15:17:32.157Z",
    },
  },

  {
    id: "112",
    type: "brand",
    attributes: {
      id: 112,
      name: "machinery",
      created_at: "2023-12-18T05:47:12.538Z",
      updated_at: "2023-12-18T05:47:12.538Z",
    },
  },
  {
    id: "113",
    type: "brand",
    attributes: {
      id: 113,
      name: "test machinery",
      created_at: "2023-12-19T10:37:55.189Z",
      updated_at: "2023-12-19T10:37:55.189Z",
    },
  },
  {
    id: "124",
    type: "brand",
    attributes: {
      id: 124,
      name: "machine brand",
      created_at: "2023-12-19T16:27:20.586Z",
      updated_at: "2023-12-19T16:27:20.586Z",
    },
  },
  {
    id: "125",
    type: "brand",
    attributes: {
      id: 125,
      name: "hardware brand",
      created_at: "2023-12-19T16:29:09.741Z",
      updated_at: "2023-12-19T16:29:09.741Z",
    },
  },

  {
    id: "135",
    type: "brand",
    attributes: {
      id: 135,
      name: "amile",
      created_at: "2023-12-26T10:01:56.191Z",
      updated_at: "2023-12-26T10:01:56.191Z",
    },
  },
];
export const chooseMock = [
  {
    id: "1319",
    type: "catalogue",
    attributes: {
      category: {
        id: 6,
        name: "Product",
        created_at: "2023-09-05T13:00:19.380Z",
        updated_at: "2024-02-29T08:43:42.123Z",
        admin_user_id: null,
        rank: null,
        light_icon: {
          url:
            "https://minio.b123437.dev.eastus.az.svc.builder.cafe/sbucket/uploads/bx_block_categories/category/light_icon/6/liquor.png",
        },
        light_icon_active: {
          url: null,
        },
        light_icon_inactive: {
          url: null,
        },
        dark_icon: {
          url:
            "https://minio.b123437.dev.eastus.az.svc.builder.cafe/sbucket/uploads/bx_block_categories/category/dark_icon/6/liquor.png",
        },
        dark_icon_active: {
          url: null,
        },
        dark_icon_inactive: {
          url: null,
        },
        identifier: null,
        inventory_id: null,
        static_id: 1,
      },
      sub_category: {
        id: 38,
        name: "Wine",
        created_at: "2023-09-05T13:00:19.411Z",
        updated_at: "2024-02-29T08:43:42.220Z",
        parent_id: 6,
        rank: null,
        static_id: 1,
      },
      brand: null,
      target: null,
      tags: [],
      reviews: [],
      name: "dsfd",
      sku: null,
      description: null,
      manufacture_date: null,
      length: null,
      breadth: null,
      height: null,
      stock_qty: null,
      availability: null,
      weight: null,
      price: null,
      recommended: null,
      on_sale: null,
      sale_price: null,
      discount: null,
      meta_data: {
        sku: "",
        gtin: "",
        brand: "",
        color: "",
        dosage: "",
        alcohol: "",
        producer: "sdcfsfd",
        quantity: "",
        allergens: "",
        aging_process: "",
        grape_variety: "",
        aging_potential: "",
        name_complement: "cxdsd",
        short_description: "",
        production_process: "",
        container_with_volume: "",
        grape_variety_percent: "",
      },
      tasting_notes: {
        eye: "",
        nose: "",
        mouth: "",
        comment: "",
        flavours: "",
        main_aromas: "",
        temperature: "",
        food_pairing: "",
        opening_time: "",
      },
      images: null,
      is_available: null,
      average_rating: 0,
      catalogue_variants: [],
      allergens: [],
      location: {
        id: 639,
        appellation_type: "",
        appellation: "",
        country: "",
        region: "",
        sub_region: "",
        style: null,
        era: null,
        string: null,
        region_of_origin: null,
        catalogue_id: 1319,
        created_at: "2024-04-29T07:25:19.495Z",
        updated_at: "2024-04-29T07:25:19.495Z",
        sub_appellation: null,
      },
      certificates: [],
      trophies: [],
      prices: [
        {
          id: 1485,
          title: "Retail Price",
          amount: 4,
          tax_included: false,
        },
      ],
      selected_price: [],
      producer: null,
    },
  },
];
export const colorData = [
  {
    id: "34",
    type: "brand",
    attributes: {
      id: 34,
      name: "dummy data",
      created_at: "2023-12-05T05:33:11.268Z",
      updated_at: "2023-12-05T05:33:11.268Z",
    },
  },
  {
    id: "35",
    type: "brand",
    attributes: {
      id: 35,
      name: "dummy data",
      created_at: "2023-12-05T10:09:15.106Z",
      updated_at: "2023-12-05T10:09:15.106Z",
    },
  },
  {
    id: "36",
    type: "brand",
    attributes: {
      id: 36,
      name: "wine brand",
      created_at: "2023-12-05T11:05:57.326Z",
      updated_at: "2023-12-05T11:05:57.326Z",
    },
  },

  {
    id: "87",
    type: "brand",
    attributes: {
      id: 87,
      name: "dummy data",
      created_at: "2023-12-07T10:01:35.988Z",
      updated_at: "2023-12-07T10:01:35.988Z",
    },
  },
  {
    id: "88",
    type: "brand",
    attributes: {
      id: 88,
      name: "dummy data",
      created_at: "2023-12-07T14:40:47.448Z",
      updated_at: "2023-12-07T14:40:47.448Z",
    },
  },
  {
    id: "89",
    type: "brand",
    attributes: {
      id: 89,
      name: "dummy data",
      created_at: "2023-12-07T15:17:32.157Z",
      updated_at: "2023-12-07T15:17:32.157Z",
    },
  },

  {
    id: "112",
    type: "brand",
    attributes: {
      id: 112,
      name: "machinery",
      created_at: "2023-12-18T05:47:12.538Z",
      updated_at: "2023-12-18T05:47:12.538Z",
    },
  },
  {
    id: "113",
    type: "brand",
    attributes: {
      id: 113,
      name: "test machinery",
      created_at: "2023-12-19T10:37:55.189Z",
      updated_at: "2023-12-19T10:37:55.189Z",
    },
  },
  {
    id: "124",
    type: "brand",
    attributes: {
      id: 124,
      name: "machine brand",
      created_at: "2023-12-19T16:27:20.586Z",
      updated_at: "2023-12-19T16:27:20.586Z",
    },
  },
  {
    id: "125",
    type: "brand",
    attributes: {
      id: 125,
      name: "hardware brand",
      created_at: "2023-12-19T16:29:09.741Z",
      updated_at: "2023-12-19T16:29:09.741Z",
    },
  },

  {
    id: "135",
    type: "brand",
    attributes: {
      id: 135,
      name: "amile",
      created_at: "2023-12-26T10:01:56.191Z",
      updated_at: "2023-12-26T10:01:56.191Z",
    },
  },
  {
    id: "136",
    type: "brand",
    attributes: {
      id: 35,
      name: "Color black",
      created_at: "2023-12-05T10:09:15.106Z",
      updated_at: "2023-12-05T10:09:15.106Z",
    },
  },
];

export const languages = [
  {
    id: 1,
    name: "English (UK)",
    code: "en",
    created_at: "2024-01-03T08:55:29.315Z",
    updated_at: "2024-01-03T08:55:29.315Z",
  },
  {
    id: 2,
    name: "English (US)",
    code: "ens",
    created_at: "2024-01-03T08:55:29.330Z",
    updated_at: "2024-01-03T08:55:29.330Z",
  },
  {
    id: 3,
    name: "Español",
    code: "es",
    created_at: "2024-01-03T08:55:29.340Z",
    updated_at: "2024-01-03T08:55:29.340Z",
  },
  {
    id: 4,
    name: "Français",
    code: "fr",
    created_at: "2024-01-03T08:55:29.350Z",
    updated_at: "2024-01-03T08:55:29.350Z",
  },
  {
    id: 4,
    name: "Italiano",
    code: "it",
    created_at: "2024-01-03T08:55:29.350Z",
    updated_at: "2024-01-03T08:55:29.350Z",
  },
];
export const chatRoomDummyData = [
  {
    id: "3",
    type: "chat",
    attributes: {
      id: 3,
      name: "Michelle Rivera",
      chat_type: "etoh_shop_support",
      status: "admin",
      unread_count:2,
      last_message:{
        data:null,
      },

    },
    relationships: {
      accounts: {
        data: [
          {
            id: "5679",
            type: "account",
          },
        ],
      },
    },
  },
  {
    id: "4",
    type: "chat",
    attributes: {
      id: 4,
      name: "Mac Rivera",
      chat_type: null,
      status: "admin",
    },
    relationships: {
      accounts: {
        data: [
          {
            id: "5679",
            type: "account",
          },
        ],
      },
    },
  },
  {
    id: "5",
    type: "chat",
    attributes: {
      id: 4,
      name: "Jhon Rivera",
      chat_type: null,
      status: "admin",
    },
    relationships: {
      accounts: {
        data: [
          {
            id: "5679",
            type: "account",
          },
        ],
      },
    },
  },
];

export const subscriptionData = [
  {
    id: "13",
    type: "member",
    attributes: {
      activated: true,
      country_code: null,
      email: "test@yopmail.com",
      first_name: "Jhon",
      full_phone_number: "",
      last_name: "Pena",
      phone_number: null,
      type: "EmailAccount",
      country: null,
      state: null,
      postal_code: null,
      city: null,
      company_name: null,
      company_place_address: null,
      created_at: "2024-05-23T18:29:01.186Z",
      updated_at: "2024-05-23T18:29:01.235Z",
      device_id: null,
      deactivated: false,
      hourly_pay: null,
      unique_auth_id: "M7Fmc3q8KqYQfBZtlMjavgtt",
      job_type: "owner",
      job_role: null,
      original_owner: true,
      profile_created: false,
      can_skip: true,
      team: null,
      company_address: {
        data: null,
      },
    },
  },
  {
    id: "14",
    type: "member",
    attributes: {
      activated: true,
      country_code: null,
      email: "test@yopmail.com",
      first_name: "Jhon",
      full_phone_number: "",
      last_name: "Pena",
      phone_number: null,
      type: "EmailAccount",
      country: null,
      state: null,
      postal_code: null,
      city: null,
      company_name: null,
      company_place_address: null,
      created_at: "2024-05-23T18:29:01.186Z",
      updated_at: "2024-05-23T18:29:01.235Z",
      device_id: null,
      deactivated: false,
      hourly_pay: null,
      unique_auth_id: "M7Fmc3q8KqYQfBZtlMjavgtt",
      job_type: "owner",
      job_role: null,
      original_owner: true,
      profile_created: false,
      can_skip: true,
      team: null,
      company_address: {
        data: null,
      },
    },
  },
  {
    id: "15",
    type: "member",
    attributes: {
      activated: true,
      country_code: null,
      email: "test@yopmail.com",
      first_name: "Jhon",
      full_phone_number: "",
      last_name: "Pena",
      phone_number: null,
      type: "EmailAccount",
      country: null,
      state: null,
      postal_code: null,
      city: null,
      company_name: null,
      company_place_address: null,
      created_at: "2024-05-23T18:29:01.186Z",
      updated_at: "2024-05-23T18:29:01.235Z",
      device_id: null,
      deactivated: false,
      hourly_pay: null,
      unique_auth_id: "M7Fmc3q8KqYQfBZtlMjavgtt",
      job_type: "owner",
      job_role: null,
      original_owner: true,
      profile_created: false,
      can_skip: true,
      team: null,
      company_address: {
        data: null,
      },
    },
  },
];

export const timesheetMembers = [
  {
    id: "1",
    name: "Swelch Fan",
    email: "swelch@fanoodle.info",
    teams: "Team 1",
    task: "10 hours",
    hours: "10:24 hours",
  },
  {
    id: "2",
    name: "Etoh suite",
    email: "swelch2@fanoodle.info",
    teams: "Team 11",
    task: "15 hours",
    hours: "16:24 hours",
  },
  {
    id: "3",
    name: "Tech lead",
    email: "swelch3@fanoodle.info",
    teams: "Team",
    task: "25 hours",
    hours: "116:24 hours",
  },
  {
    id: "4",
    name: "Swelch Fan 3",
    email: "swelchfan5@fanoodle.info",
    teams: "Team 168",
    task: "150 hours",
    hours: "10:24 hours",
  },
  {
    id: "5",
    name: "Swelch Fan 7",
    email: "swelch5@fanoodle.info",
    teams: "Team 5",
    task: "25 hours",
    hours: "14:24 hours",
  },
]