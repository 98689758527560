// Customizable Area Start
import React from "react";
import { Box, Typography, Button, styled } from "@material-ui/core";
import SubscriptionScreenController, {
  Props,
  configJSON
} from "./SubscriptionScreenController.web";
import { memberSuccess } from "../assets";
import AppHeader from "../../../../components/src/AppHeader.web";

export default class SubscriptionPaymentSuccess extends SubscriptionScreenController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <>
        <StyledWrapper>
          <Box className="wrapper membersuccessWrapper">
            <AppHeader />
            <Box className="inner-wrapper">
              <Box className="memberAddedWrapper">
                <Box className="member-added-inner">
                  <Box className="member-added-row">
                    <Box className="member-added-img">
                      <img src={memberSuccess} alt="member-success" />
                    </Box>
                    <Box className="member-added-content">
                      <Typography className="heading" variant="h1">
                        {this.t(`${configJSON.subscriptionsuccesstxt}`)}
                      </Typography>
                      <Box component="p" className="sub-txt">
                        {this.t(`${configJSON.subscriptionSuccesSubTxt}`)}
                      </Box>
                      <Box className="team-button-wrapper">
                        <Button
                          className="secondaryButtons"
                          onClick={this.returnTeam}
                          data-testid="addAnotherMembers"
                        >
                          {this.t(`${configJSON.subscriptionRetuntxt}`)}
                        </Button>
                        <Button
                          className="primaryButtons"
                          onClick={this.addAnotherMember}
                          data-testid="returnToTeamss"
                        >
                          {this.t(`${configJSON.subscriptionFinishtxt}`)}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledWrapper>
      </>
    );
  }
}
export const StyledWrapper = styled(Box)(({ theme }) => ({
  "& .wrapper": {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column"
  },
  "& .innerWrapper": {
    width: "100%"
  },
  "& .membersuccessWrapper": {
    "& .inner-wrapper": {
      margin: "auto"
    }
  },
  "& .memberAddedWrapper": {
    padding: "60px",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      padding: "40px"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "24px 60px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "24px"
    },
    "& .member-added-row": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "1040px",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        maxWidth: "850px"
      },
      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
        maxWidth: "320px"
      },
      "& .member-added-img": {
        maxWidth: "456px",
        width: "100%",
        [theme.breakpoints.down("md")]: {
          maxWidth: "325px"
        },
        [theme.breakpoints.down("sm")]: {
          maxWidth: "290px",
          display: "none"
        },
        [theme.breakpoints.down("xs")]: {
          maxWidth: "235px"
        }
      }
    },
    "& .member-added-content": {
      marginLeft: "168px",
      flex: "1",
      [theme.breakpoints.down("md")]: {
        marginLeft: "100px"
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0",
        maxWidth: "512px",
        width: "100%"
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: "0",
        marginTop: "20px"
      }
    },
    "& .heading": {
      fontSize: "24px",
      lineHeight: "30px",
      margin: "0 0 9px",
      color: "#000000",
      letterSpacing: "0",
      fontFamily: "Expletus Sans",
      fontWeight: 400
    },
    "& .sub-txt": {
      width: "381px",
      fontSize: "16px",
      lineHeight: "22px",
      margin: "0 0 48px",
      color: "#505050",
      fontFamily: "Roboto",
      [theme.breakpoints.down("md")]: {
        margin: "0 0 30px",
        width: "370px"
      }
    },
    "& .team-button-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      "& .MuiButton-root": {
        minHeight: "56px",
        width: "auto",
        minWidth: "156px",
        flex: "0 0 48%",
        maxWidth: "48%",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          marginBottom: "15px",
          flex: "0 0 100%",
          maxWidth: "100%"
        }
      }
    }
  },
  "& .primaryButtons": {
    background: "#4ba3b7",
    border: "1px solid #4ba3b7",
    borderRadius: "7px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#ffffff",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    padding: "12px 10px",
    minHeight: "48px",
    marginBottom: "0",
    transition: "all 0.5s ease-in-out",
    textTransform: "capitalize",
    width: "auto",
    minWidth: "150px",
    "&:hover": {
      background: "#4ba3b7",
      color: "#ffffff"
    }
  },
  "& .secondaryButtons": {
    background: "transparent",
    border: "1px solid #e8ecf2",
    borderRadius: "7px",
    filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#2b2b2b",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    textTransform: "unset",
    maxWidth: "100%",
    width: "150px",
    padding: "12px 10px",
    minHeight: "48px",
    marginBottom: "0",
    transition: "all 0.5s ease-in-out",
    letterSpacing: "0",
    textDecoration: "none",
    "&:hover": {
      background: "transparent",
      color: "#2b2b2b"
    }
  }
}));
// Customizable Area End
