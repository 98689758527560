import React from "react";
import {
    Box,
    Typography,
    Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "./IntegrationStyles.web";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";


import AddIntegrationController, {
    Props,
} from './AddIntegrationController.web'

export class AddIntegration extends AddIntegrationController {

    constructor(props: Props) {
        super(props)
    }

    render() {

        const { classes, state } = this.props;

        return (
            <Box className={classes.addIntegrationWrapper} >
                <Box
                    data-test-id="back-btn"
                    onClick={
                        () => this.props.backToStartIntegration()}
                    sx={{
                        position: 'absolute',
                        left: '30px',
                        top: '30px',
                    }}>
                    <KeyboardBackspaceIcon className="backIcon" />
                </Box>
                <Box sx={{ width: "60%" }}>
                    <Typography className={classes.heading} variant="h4">
                        Integrations
                    </Typography>
                    <Box className={classes.subTxt} component="p">
                        Simply connect to account by logging into any system
                    </Box>
                </Box>
                <Box
                    sx={{
                        border: '1px solid #E5E9F0',
                        height: '60px',
                        borderRadius: '8px',
                        display: 'flex',
                        padding: "50px 25px",
                        width: "60%",
                        justifyContent: "space-between",
                        marginTop: "25px",
                        alignItems: 'center',
                    }} >


                    <Box sx={{
                        display: 'flex',
                        justifyContent: "space-around",
                        alignItems: 'center',

                    }}>
                        <Box width={'115px'}>
                            <img
                                src={require("../assets/meta-logo.svg")}
                                alt="logo" />
                        </Box>
                        <Box sx={{ marginLeft: '20px' }}
                        >
                            <Typography className={classes.headingIcon} variant="h3">
                                Meta
                            </Typography>
                            <Box className={classes.subTxt} component="p" >
                                Simply connect your account here
                            </Box>
                        </Box>
                    </Box>


                    <Box sx={{
                        display: 'flex',
                        justifyContent: "space-around",
                        alignItems: 'center',

                    }}>
                        <span
                            data-testid="not-connected"
                            className={`${classes.errorButton}`}
                            title="Create your own"
                        >
                           {state.isMetaConnected && 'Disconnect'}
                        </span>

                        <Button
                            title="Create your own"
                            className={state.isMetaConnected ? `primary-btn ${classes.primaryButton}` : `secondary-btn ${classes.secondaryButton}`}
                            onClick={this.connectToMeta}
                            data-testid="connectToMeta"
                        >
                            Connect
                        </Button>

                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        width: "60%",
                        border: '1px solid #E5E9F0',
                        height: '60px',
                        marginTop: "25px",
                        justifyContent: "space-between",
                        padding: "50px 25px",
                        alignItems: 'center',
                        borderRadius: '8px',
                    }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: "space-around",
                        alignItems: 'center',

                    }}>
                        <Box>
                            <img src={require("../assets/google-business.png")} alt="logo" />
                        </Box>
                        <Box sx={{ marginLeft: '20px' }} >
                            <Typography className={classes.headingIcon} variant="h3">
                                Google My Business
                            </Typography>
                            <Box component="p" className={classes.subTxt}>
                                Simply connect your account here
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: "space-around",
                        alignItems: 'center',

                    }}>
                        <span
                            data-testid="not-connected"
                            className={`${classes.errorButton}`}
                            title="Create your own"
                        >
                          { state.isGoogleConnected &&  'Disconnect'}
                        </span>

                        <Button
                            onClick={this.connectToGoogle}
                            data-testid="connectToGoogle"
                            className={state.isGoogleConnected ? `primary-btn ${classes.primaryButton}` : `secondary-btn ${classes.secondaryButton}`}
                            title="Create your own"
                        >
                            Connect
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        border: '1px solid #E5E9F0',
                        marginTop: "25px",
                        height: '60px',
                        display: 'flex',
                        width: '60%',
                        justifyContent: "space-between",
                        alignItems: 'center',
                        borderRadius: '8px',
                        padding: "50px 25px"
                    }} >
                    <Box sx={{
                        display: 'flex',
                        justifyContent: "space-around",
                        alignItems: 'center',

                    }}>

                        <Box width={'115px'}>
                            <img
                                src={require("../assets/brevo-logo.png")}
                                alt="logo"
                            />
                        </Box>
                        <Box sx={{ marginLeft: '20px' }}>
                            <Typography variant="h3" className={classes.headingIcon}>
                                Brevo
                            </Typography>
                            <Box component="p" className={classes.subTxt}>
                                Simply connect your account here
                            </Box>
                        </Box>

                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: "space-around",
                        alignItems: 'center',

                    }}>
                        <span
                            data-testid="not-connected"
                            className={`${classes.errorButton}`}
                            title="Create your own"
                        >
                            {state.isSendinBlueConnected && 'Disconnect'}
                        </span>

                        <Button
                            title="Create your own"
                            data-testid="connectToBravo"
                            onClick={this.connectToBravo}
                            className={state.isSendinBlueConnected ? `primary-btn ${classes.primaryButton}` : `secondary-btn ${classes.secondaryButton}`}
                        >
                            Connect
                        </Button>
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default withStyles(customStyles, { withTheme: true })(AddIntegration);



