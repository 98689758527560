import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { DateObject } from "react-multi-date-picker";
import i18n from "../../../web/src/utilities/i18n";
const baseConfig = require("../../../framework/src/config");
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  classes:any;
  activityType:string;
  activityId:string;
  activityTitle:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activityLogListing: any;
  isActiveFilter: boolean;
  logDates: DateObject[];
  assigneeList: any[];
  assigneeSearchText: string;
  logAssignees: any[];
  isLoading:boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ActivityLogControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getActivityLogsAPICallId: string = "";
  memberListActivityApiID:string = "";
  activityLogProjectFilterApiID:string = "";
  userSessionData: any;
  userToken: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activityLogListing: [],
      logDates: [],
      isActiveFilter:false,
      assigneeList: [],
      assigneeSearchText: "",
      logAssignees: [],
      isLoading:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
     // Customizable Area Start
     if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse || !responseJson || !apiRequestCallId) {
        return;
      }
      switch (apiRequestCallId) {
          case this.getActivityLogsAPICallId:
          case this.activityLogProjectFilterApiID:
            this.setState({isLoading:false})
            this.setState({activityLogListing: responseJson.data})
          break;
          case this.memberListActivityApiID:
            this.setState({isLoading:false})
            this.setState({ assigneeList: responseJson.members.data });
            break;

      }
      
     }
    // Customizable Area End
    }
  
   // Customizable Area Start
   async componentDidMount() {
    const lang = localStorage.getItem("lang") ?? "en";
    await (i18n as any).changeLanguage(lang);
    this.getAllActivityLogs();
    this.getAssigneeList();
   }
   t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }

   getAllActivityLogs = () => {
    let endPoint = "";
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    if(this.props.activityType === 'project'){
      endPoint = configJSON.projectLogEndPoint + `project_id=${this.props.activityId}`;
    }else if(this.props.activityType === 'task'){
      endPoint = configJSON.taskLogEndPoint + `task_id=${this.props.activityId}`;
    }else if(this.props.activityType === 'deal'){
      endPoint = configJSON.dealsLogEndPoint + `deal_id=${this.props.activityId}`;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getActivityLogsAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    this.setState({isLoading:true})
    runEngine.sendMessage(requestMessage.id, requestMessage);
   }
   getAssigneeList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.memberListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    this.memberListActivityApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.setState({isLoading:true})
    runEngine.sendMessage(requestMessage.id, requestMessage);      
  };
   filterActivityHandler = () => {
    this.setState({ isActiveFilter: true, logDates: [] });
  };
  
  dateRangeFilter = (date:any) => {
    this.setState({ logDates: date });
  }
  displayAssigneeOptions = () => {
    const assigneeNames = this.state.assigneeList.map(
      (item: {
        id: number;
        attributes: {
          team: any[];
          last_name: string;
          email: string;
          first_name: string;
        };
      }) => ({
        title: `${item.attributes.first_name} ${item.attributes.last_name}`,
        id: item.id,
        email: item.attributes.email,
        initials: `${item.attributes.first_name
          .charAt(0)
          .toUpperCase()}${item.attributes.last_name.charAt(0).toUpperCase()}`,
        team: item.attributes.team
          ? item.attributes.team.map((team) => team.title)
          : [],
      })
    );
    return assigneeNames.filter((obj: { title: string }) =>
      this.containsText(obj.title, this.state.assigneeSearchText)
    );
  };
  containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  getProjectsLogWithFilter = () => {
    this.setState({isActiveFilter: false})
    // if (this.state.logDates.length != 2) {
    //   this.getActivityLog(this.state.currentProject.id);
    //   return;
    // }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.activityLogProjectFilterApiID = requestMessage.messageId;
    let params: string[] = [];
    params.push("start_date=" + this.state.logDates[0].format("YYYY-MM-DD"));
    params.push("end_date=" + this.state.logDates[1].format("YYYY-MM-DD"));
   
   if(this.props.activityType === 'project'){
    params.push(`project_id=` + `${this.props.activityId}`);
  }else if(this.props.activityType === 'task'){
    params.push(`task_id=` + `${this.props.activityId}`);
  }else if(this.props.activityType === 'deal'){
    params.push(`deal_id=` + `${this.props.activityId}`);
  }
    if (this.state.logAssignees.length > 0) {
      params.push(`members=[${this.state.logAssignees}]`);
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activityLogsFilter +
        `${params.length > 0 ? "?" + params.join("&") : ""}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    this.setState({isLoading:true})

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  selectAssignee = (assigneeId: number) => {
    let selectedAssignee = this.state.logAssignees;
    if (selectedAssignee.includes(assigneeId)) {
      selectedAssignee = selectedAssignee.filter((x) => x !== assigneeId);
    } else {
      selectedAssignee.push(assigneeId);
    }
    this.setState({
      logAssignees: selectedAssignee,
    });
  };
  downloadProjectsLog = () => {
    
    let params: string[] = [];
    let filename = `${
      'My title'
    }_Project Activity Log_${moment(new Date()).format("YYYYMMDD")}.csv`;
    if (this.state.logDates.length == 2) {
      params.push("start_date=" + this.state.logDates[0].format("YYYY-MM-DD"));
      params.push("end_date=" + this.state.logDates[1].format("YYYY-MM-DD"));
    }
    let url =
      baseConfig.baseURL +
      configJSON.downloadActivityLogCsv + `?${this.props.activityType}=${this.props.activityId}` +
      `${params.length > 0 ? "?" + params.join("&") : ""}`;
    fetch(url, {
      method: "GET",
      headers: { token: this.userToken.meta.token },
    })
      .then(function (response) {
        return response.blob();
      })
      .then(function (blob) {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        alert("Something wen't wrong. Please try again");
      });
  };
   // Customizable Area End
}
