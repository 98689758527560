import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";

export const customStyles: any = (theme: AugmentedTheme) =>
  createStyles({
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "7px",
      //filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "22px",
      color: "#ffffff",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      width: "auto",
      minWidth: "156px",
      textDecoration: "none",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
      marginLeft: "15px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "0px",
      },
    },
    secondaryButton: {
      background: "transparent",
      border: "1px solid #e5e9f0",
      borderRadius: "7px",
      filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      width: "156px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      // marginRight: "16px",
      transition: "all 0.5s ease-in-out",
      letterSpacing: "0",
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
   
    addIntegrationWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    heading: {
      fontSize: "24px",
      lineHeight: "32px",
      margin: "0 0 6px",
      color: "#2B2B2B",
      letterSpacing: "0",
      fontFamily: "Expletus Sans",
      [theme.breakpoints.down("md")]: {
        margin: "0 0 8px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "22px",
        lineHeight: "26px",
        margin: "0 0 8px",
        color: "#2B2B2B",
      },
    },
    subTxt: {
      fontSize: "14px",
      lineHeight: "26px",
      // color: "#94A3B8",
      color: "#6a6a6a",
      fontFamily: "Roboto",
      margin: "0",
      letterSpacing: "0.105px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
        lineHeight: "17px",
      },
    },
   
    headingIcon: {
      marginBottom: 0,
      fontSize: "13px",
      color: "#2B2B2B",
      fontFamily: "Expletus Sans",
    },
    checklist: {
      '&.Mui-checked': {
        color: "#5097ac"
      }
    }

  });