export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const attachment = require("../assets/attachment.png");
export const calendarRange = require("../assets/calendar-range.png");
export const sortVariant = require("../assets/sort-variant.png");
export const viewColumn = require("../assets/view-column.png");
export const minimizeGray = require("../assets/minimize-gray.png");
export const closeGray = require("../assets/close-gray.png");
export const uploadImg = require("../assets/upload-icon.png");
export const checkBoxEmpty = require("../assets/check-empty.png");
export const checkBoxFilled = require("../assets/check-filled.png");
export const menu = require("../assets/menu.png");
export const checkGreen = require("../assets/check-green.png");
export const minimizeDirectEmail = require("../assets/minimizeDirectEmail.svg");
export const noEmailCampaigns = require("../assets/no-email-campaigns.png");
export const sendOutline = require("../assets/send-outline.svg");
export const fileIcon = require("../assets/file-document-outline.svg");
export const saveDraft = require("../assets/save-draft.svg");
export const checked = require("../assets/ic_check_box_outline_blank (1).png"); 

