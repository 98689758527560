// Customizable Area Start
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./CustomGoogleLogInButton.web";
import { toast } from "react-toastify";
import i18n from "../../../web/src/utilities/i18n";

export const configJSON = require("./config");

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: "select_account",
});

export const auth = getAuth();

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  history?: any;
  id?: string;
  classes: any;
}

interface S {
  googleUser: any;
  isChecked: boolean;
  showModal: boolean;
  checkboxError: {
    message: string;
    visible: boolean;
  };
}
interface SS {
  id: any;
}

export default class GoogleWebControllerBlock extends BlockComponent<
  Props,
  S,
  SS
> {
  socialLoginApiID: string = "";
  welcomeApiID: string = "";
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      googleUser: {},
      isChecked: false,
      showModal: false,
      checkboxError: {
        message: "",
        visible: false,
      },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  createToastNotification = (toastMesssage: string) => {
    toast.success(toastMesssage, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
     
      if (errorReponse || !responseJson || !apiRequestCallId) {
        
        this.createToastNotification("Backend down");
        return;
      }
      switch (apiRequestCallId) {
        case this.socialLoginApiID:
          this.handleSocialLoginAPIResponse(responseJson);
        break;
        case this.welcomeApiID:
            this.welcomeAPIResponse(responseJson);
        break;
        default:
            break;
      }
    }
  }
  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.googleUser !== this.state.googleUser) {
      this.handleSocialLogin(this.state.googleUser, false);
    }
  }
  async componentDidMount() {   
    const lang = localStorage.getItem("lang") ?? "en"; 
    await(i18n as any).changeLanguage(lang);
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  handleModalOpen = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleCheckBox = (e: any) => {
    let checkboxerr = { message: "", visible: false };
    if (e.target.checked === false) {
      checkboxerr = {
        message: "Please accept terms and conditions.",
        visible: true,
      };
    }
    this.setState({
      isChecked: e.target.checked,
      checkboxError: checkboxerr,
    });
  };

  handlePopupLogin = (e: any) => {
    if (this.state.isChecked) {
      this.handleSocialLogin(this.state.googleUser, true);
    } else {
      let checkboxerr = {
        message: "Please accept terms and conditions.",
        visible: true,
      };
      this.setState({
        checkboxError: checkboxerr,
      });
    }
  };

  handleSocialLogin = (user: any, emailChecked: boolean) => {
    let userFirstName = "";
    let userLastName = "";
    if (user.displayName) {
      const userDisplayNameArr = user.displayName.split(" ");
      userFirstName = userDisplayNameArr[0] || "";
      userLastName = userDisplayNameArr[1] || "";
    }  
    const header = {
      "Content-Type": configJSON.socialLoginApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (emailChecked) {
      this.socialLoginApiID = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.socialLoginAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            type: "social_account",
            attributes: {
              first_name: userFirstName,
              last_name: userLastName,
              email: user.email,
              unique_auth_id: user.uid,
            },
          },
        })
      );
    } else {
      console.log("in else",emailChecked)
      this.welcomeApiID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.emailValidateEndpoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          email: user.email,
        })
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.socialLoginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  signInWithGooglePopup = () => signInWithPopup(auth, googleProvider);

  logGoogleUser = async () => {
    const { user } =  await this.signInWithGooglePopup();
    
    this.setState({ googleUser: user.providerData[0] });
  };

  handleGoogleButtonClick = () => {
    this.logGoogleUser().catch((error) => {
      this.createToastNotification("Something went wrong. Please try again later");
    });
  };
  handleSocialLoginAPIResponse = (responseJson:any) => {
      sessionStorage.setItem("userData", JSON.stringify(responseJson));
      localStorage.setItem("userData", JSON.stringify(responseJson));
      this.props.history.push('/tutorial');
      if (
        responseJson.data.attributes.original_owner &&
        responseJson.data.attributes.profile_created === false
      ) {
         this.props.history.push('/tutorial');
      } else {
        this.props.history.push('/dashboard');
      }
  }
  welcomeAPIResponse = (responseJson:any) => {
    if (responseJson.message[0].has_social) {
      this.handleClose();
      this.handleSocialLogin(this.state.googleUser, true);
    } else {
      this.handleModalOpen();
    }
  }
}
// Customizable Area End
