// Customizable Area Start
import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./ContactsListStyles.web";
import { CardMedia,Box } from "@material-ui/core";
import { toast } from "react-toastify";
import { multipleAccount, vector, warningIcon , renamed} from "./assets";
import i18n from '../../../web/src/utilities/i18n';

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation: any;
  id: string;
  classes: any;
  groupDetails: any;
  handleCloseViewContactModal: any;
  isMobile?: boolean;
}

interface S {
  pageLink: string;
  groups: any;
  anchorEl: any;
  floatCreateContact: boolean;
  createContactPopoverShown: boolean;
  groupName: string;
  contactId: string;
  searchKeyword: string;
  showGroupsContact: boolean;
  searchContactKeyword: string;
  showAddGroupToContact: boolean;
  groupAnchor:any;
  isShowDeleteNoteConfirmation: boolean;
  isDeleteGroup: boolean;
  delModalOpen: boolean;
  delGroupDetails: {
    name: string,
    id: string,
  };
  error: {
    groupName: string;  
  };
  deleteGroupId:any;
  groupNameOnDelete:string;
  isRenameGroup:boolean;
  renameGroupId:string;
  groupNameRename:string;
  groupError:any;
  contactIds:any
  contactListData:any;
  isLoading:boolean;
  searcheSortGroupData:any;
  isSearchingSorting:boolean;
  sortValue:string;
  isEditGroup:boolean;
  editGroupId:string;
  editcontactIds:any;
  searchedContacts:any;
  sortedContacts:any;
  sortContactValue:string
  isContactSorting:boolean;
  isContactSearching:boolean;
  createGroupId:string;
  isMenuDrawerOpenedEvent:boolean;
  groupsCount: any;
}

interface SS {
  id: any;
}

export default class GroupListController extends BlockComponent<Props, S, SS> {
  getGroupListRequestId: string = "";
  userSessionData: any;
  userToken: any;
  deleteGroupAPI: string="";
  createGroupRequestId:string="";
  renameGroupRequestId:string="";
  getContactlistApiID:string="";
  getSearchGroupRequestId:string="";
  editGroupRequestId:string="";
  getSearchContcatListRequestId:string="";
  getSortRequestId:string=""
  undoActionAPICallId:string="";
  undoEditActionAPICallId:string="";
  getGroupsCountAPICallId:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      pageLink: "",
      groups: [],
      anchorEl: null,
      floatCreateContact: false,
      createContactPopoverShown: false,
      groupName: "",
      contactId: "",
      searchKeyword: "",
      showGroupsContact: false,
      searchContactKeyword: "",
      showAddGroupToContact: false,
      groupAnchor:null,
      isShowDeleteNoteConfirmation: false,
      isDeleteGroup: false,
      delModalOpen: false,
      delGroupDetails: {
        name: "",
        id: "",
      },
      error: {
        groupName:"", 
      },
      deleteGroupId:"",
      groupNameOnDelete:"",
      isRenameGroup:false,
      renameGroupId:"",
      groupNameRename:"",
      groupError:{
        groupNameRename:"",
      },
      contactIds:[],
      contactListData:[],
      isLoading:false,
      searcheSortGroupData:[],
      isSearchingSorting:false,
      sortValue:"",
      isEditGroup:false,
      editGroupId:"",
      editcontactIds:[],
      searchedContacts:[],
      sortedContacts:[],
      sortContactValue:"",
      isContactSorting:false,
      isContactSearching:false,
      createGroupId:"",
      isMenuDrawerOpenedEvent:false,
      groupsCount: [],
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.setState({ pageLink: window.location.pathname });
    this.getGroupList();
    this.getContactlist();
    this.groupListsCounts();
    this.setState({editcontactIds:this.state.contactIds?.map((id:any) => String(id))})
    const langD = localStorage.getItem("lang") ?? "en"; 
    await i18n.changeLanguage(langD); 
  }
 
  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.clearError(prevState)    
  }

  t(key:any) {
    return i18n.t(key, { ns: "translation" } )
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
        );
      let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson.error) {
        this.createToastNotification("Something went wrong");
      }else if (apiRequestCallId === this.getGroupListRequestId) {
        this.getGroupListResponse(responseJson);
      }else if (apiRequestCallId === this.deleteGroupAPI){
         this.deleteGroupResponse(responseJson);
      }else if (apiRequestCallId === this.createGroupRequestId){
        this.createGroupResponse(responseJson);
      }else if (apiRequestCallId === this.renameGroupRequestId){
        this.createToastNotificationSuccess("Group Renamed",renamed ,'update')
        this.closeRenameGroup();
        this.getGroupList();
      }else if (apiRequestCallId === this.editGroupRequestId){
        this.editResponse(responseJson)
        this.getGroupList();
      }else if (apiRequestCallId === this.getContactlistApiID){
        this.getContactResponse(responseJson);
      }else if(apiRequestCallId === this.getSearchGroupRequestId){
        this.setState({isLoading: false})
        this.setState({searcheSortGroupData:responseJson.data})
      }else if(apiRequestCallId ===this.getSearchContcatListRequestId){
        this.setState({isLoading: false})
        this.setState({searchedContacts:responseJson.data})
      }else if(apiRequestCallId ===this.getSortRequestId){
        this.setState({isLoading: false})
        this.setState({sortedContacts:responseJson.data})
      }else if (apiRequestCallId === this.undoActionAPICallId){
        this.getGroupList();
      }else if(apiRequestCallId === this.undoEditActionAPICallId){
        this.getGroupList();
      }else if(apiRequestCallId === this.getGroupsCountAPICallId){
        this.setState({
          groupsCount: responseJson.marketings
        })
        
      }else {
      this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }
  
  createToastNotificationSuccess = (toastMesssage: string, toastIcon: string, actionType:string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{this.t(`${toastMesssage}`)}</div>{" "}
        <a href="#" data-test-id="undoApi" className="toast-link" onClick={()=>this.undoAPI(actionType)}>
          {this.t("undo")}
        </a>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        icon: () => <img src={toastIcon} alt="emptydata" />,
      }
    );
  };
  
  createGroupResponse = (response: any) => {
    if(response.errors){
      this.setState({ error: { groupName: "Group name already exists" } });
    }
    if(response.data){
      this.setState({createGroupId:response.data.attributes.id,groupName:""})
      this.createToastNotificationSuccess("Group Created",multipleAccount,'create')
      this.handleCloseCreateContact()
      this.getGroupList()
      localStorage.removeItem("contactIds")  
    }
  }
  createToastNotificationSuccessEdit = (toastMesssage: string, toastIcon: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{this.t(`${toastMesssage}`)}</div>{" "}
        <a href="#" data-test-id="undoApi-edit" className="toast-link" onClick={this.undoEditActionAPI}>
          {this.t("undo")}
        </a>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        icon: () => <img src={toastIcon} alt="emptydata" />,
      }
    );
  };
  editResponse=(response:any)=>{
    if(response.data){
      this.createToastNotificationSuccessEdit(configJSON.groupEditedSuccess,renamed)
      this.closeEditGroup();
      this.getGroupList();
      localStorage.removeItem("contactIds")
    }
  }
  getContactResponse = (response: any) => {
    this.setState({contactListData:response.data});
  }

  deleteGroupResponse = (response: any) => {
    this.createToastNotificationSuccess("Group Deleted",vector ,'delete')
    this.closeDeleteModal();
    this.getGroupList()
  }

  deleteGroupEvent = () => {
    this.setState({
      isDeleteGroup: true,
      delGroupDetails: {
        name: this.state.groupNameOnDelete,
        id:this.state.deleteGroupId,
      },
      delModalOpen: true
    });
  };

  openRenameGroup = () => {
    this.setState({isRenameGroup: true});
  };
  openEditGroup = () => {
    this.setState({isEditGroup: true});
  };
  closeRenameGroup = () => {
    this.setState({isRenameGroup: false});
  };
  closeEditGroup = () => {
    this.setState({isEditGroup: false});
    localStorage.removeItem("contactIds")
    this.setState({ searchContactKeyword: "" })
  };
  
  closeDeleteModal = () => {
    this.setState({
      isDeleteGroup: false,
      delModalOpen: false,
      delGroupDetails: {
        name: "",
        id: "",
      },
    });
  }

  getGroupListResponse = (response: any) => {
    this.setState({isLoading : false})
    this.setState({ groups: response.data }); 
    localStorage.setItem("groupCount",this.state.groups.length)
  };

  createToastNotification = (toastMesssage: string) => {
    toast.error(this.t(`${toastMesssage}`), {
      position: toast.POSITION.TOP_CENTER,
      icon: () => (
        <CardMedia component="img" src={warningIcon} alt="emptydata" />
      ),
    });
  };
  handleOpenCreateContact = (event: any) => {
    if(!this.state.floatCreateContact){
     localStorage.removeItem("contactIds");
    }
    
    this.setState({
      anchorEl: event.currentTarget,
      createContactPopoverShown: true,
     // groupName:"",
    });
  };

  handleCloseCreateContact = () => {
    this.setState({ anchorEl: false, floatCreateContact: false, groupName:"", });
    localStorage.removeItem("contactIds");
  };

  closeFloatCreateContact = () => {
    this.setState({ floatCreateContact: false, groupName:"", });
  };

  openFloatCreateContact = () => {
    this.setState({
      floatCreateContact: true,
      createContactPopoverShown: false,
    });
  };
  handleEscCreateGroup = (event:any) => {
    event.stopPropagation();
    if (event.key === "Escape") {
      this.openFloatCreateContact();
    }
    
  }

  openCreateContactPopoverShown = () => {
    this.setState({ createContactPopoverShown: true });
  };

  handleSearch = (text: string) => {
    this.setState({ searchKeyword: text , isSearchingSorting:true }, () => {
      this.getSearchSortGroupList(); 
    });
  };

  closeCreateContactPopoverShown = () => {
    this.setState({ createContactPopoverShown: false });
  };

  handleSearchContact = (text: string) => {
    this.setState({ searchContactKeyword: text, isContactSearching:true  },()=>{
      this.getContactSearchtList()
    });
  };

  handleSortGroup= (text: string) => {
    this.setState({ sortValue: text, isSearchingSorting:true }, () => {
      this.getSearchSortGroupList();
    });
  };
  handleSortContact= (text: string) => {
    this.setState({ sortContactValue: text, isContactSorting:true }, () => {
      this.getSortContact(); 
    });
  };

  handleChangeGroupName = (text: string) => {
    this.setState({ groupName: text });
  };
  handleChangeGroupNameRename = (text: string) => {
    this.setState({ groupNameRename: text });
  };
  
  handleChangeContact = (contactId: string) => {
    this.setState({ contactId });
  };
  
  handleOpenGroupContact = () => {
    this.setState({ showGroupsContact: true });
  };

  handleCloseGroupContact = () => {
    this.setState({ showGroupsContact: false });
  };

  handleOpenGroupPopover = (event: React.MouseEvent<HTMLButtonElement> | null,name:string,id:string,contactIds:any) => {
    this.setState({groupNameOnDelete:name})
    this.setState({deleteGroupId:id})
    this.setState({groupNameRename:name})
    this.setState({renameGroupId:id})
    this.setState({contactIds:contactIds})
    this.setState({editGroupId:id})
    this.setState({editcontactIds:contactIds})
    
    if (event) {
      this.setState({ groupAnchor: event.target });
    }
  };

  handleCloseGroupPopover = () => {
    this.setState({ groupAnchor: false });
  };

  clearError = (prevState: S) => {
    if (prevState.groupName !== this.state.groupName) {
      this.setState((prevState) => ({
        error: {
          ...prevState.error,
          groupName: "", 
        },
      }));
    }
    if (prevState.groupNameRename !== this.state.groupNameRename) {
      this.setState((prevState) => ({
        groupError: {
          ...prevState.groupError,
          groupNameRename: "", 
        },
      }));
    }
  }
  createGroup = () => {
    let error = this.state.error;
    let hasError = false;
    if (this.state.groupName.trim() === "") {
      error.groupName = this.t(`${configJSON.groupNameEmptyErrorMsg}`); 
      this.setState({ error: error });
      hasError = true;
    }

    if (this.state.groupName.length > 50) {
      error.groupName = this.t(`${configJSON.errorMessageChar}`);
      this.setState({ error: error });
      hasError = true;
    }

    if (hasError) {
      return;
    }
    const retrievedArray = JSON.parse(localStorage.getItem("contactIds") ?? '[]');

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const body = {
      group :{
        name:this.state.groupName,
        contact_ids: retrievedArray,
   }
    }
    let endPoint = configJSON.viewGroupPath;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createGroupRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
   
  getGroupList = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = configJSON.viewGroupPath;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupListRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    this.setState({isLoading : true})

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteGroupAction = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    let endpoint = `${configJSON.viewGroupPath}/${this.state.deleteGroupId}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteGroupAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  renameGroupEvent = () => {
    let error = this.state.groupError;
    let hasError = false;
    if (this.state.groupNameRename.trim() === "") {
       error.groupNameRename = this.t(`${configJSON.groupNameEmptyErrorMsg}`);
      this.setState({ error: error });
      hasError = true;
    }
    if (this.state.groupNameRename.length > 50) {
      error.groupNameRename = this.t(`${configJSON.errorMessageChar}`);
      this.setState({ error: error });
      hasError = true;
    }
    if (hasError) {
      return;
    }
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const body = {
      group :{
        name:this.state.groupNameRename,
        contact_ids:this.state.contactIds
   }
    }
    let endPoint = `${configJSON.viewGroupPath}/${this.state.renameGroupId}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.renameGroupRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  editGroupEvent = () => {
    console.log(this.state.editcontactIds)
    const retrievedArray = JSON.parse(localStorage.getItem("contactIds") ?? '[]');

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const body = {
      group :{
        name:this.state.groupNameRename,
        contact_ids: retrievedArray
   }
    }
    let endPoint = `${configJSON.viewGroupPath}/${this.state.editGroupId}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editGroupRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getContactlist = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = `${configJSON.contactsPath}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactlistApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSearchSortGroupList = () => {
    const newValue = this.state.sortValue;

    let processedValue = '';

    switch (newValue) {
      case 'A-Z':
        processedValue = 'az';
        break;
      case 'Z-A':
        processedValue = 'za';
        break;
      case 'Most to least contacts':
        processedValue = 'most_contacts';
        break;
      case 'Least to most contacts':
        processedValue = 'least_contacts';
        break;
    }
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = `${configJSON.viewGroupPath}?query=${this.state.searchKeyword}&option=${processedValue}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchGroupRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    this.setState({isLoading: true});
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

 getContactSearchtList = () => {
  const header = {
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getSearchContcatListRequestId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.searchApiPath}?search_term=${this.state.searchContactKeyword}`,
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodGET
  );
  this.setState({isLoading: true});
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
getSortContact = () => {
  const header = {
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getSortRequestId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.viewContactsPath}?first_name=${this.state.sortContactValue == "A-Z"?"asc":"desc"}`,
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodGET
  );
  this.setState({isLoading: true});
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
undoAPI = (actionType:string) => {
  let id;

  switch (actionType) {
    case "create":
      id = this.state.createGroupId;
      break;
    case "delete":
      id = this.state.deleteGroupId;
      break;
    case "update":
      id = this.state.renameGroupId;
      break;
    default:
      id = null;
  }
  const header = {
    "Content-Type": "application/json",
    token: this.userToken.meta.token,
  };

  const body = {
    "id": id,
    "action_type": actionType
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.undoActionAPICallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.undoActionEndPointGroups
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodPOST
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

}
undoEditActionAPI = () => {
  const header = {
    "Content-Type": "application/json",
    token: this.userToken.meta.token,
  };

 
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.undoEditActionAPICallId = requestMessage.messageId;
 
  let endPoint = `${configJSON.undoActionEditAction}/${this.state.editGroupId}/undo_edit_contacts_from_group?contact_ids=[${this.state.editcontactIds}]`;
 
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodGET
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

}
onOpenViewGroup = (id:any) => {
  localStorage.setItem("viewGroupId",id)
  window.location.href="/group-contacts"
};
toggleMenuDrawerEvent = () => {      
  this.setState({
      isMenuDrawerOpenedEvent: true,
  })        
}
closeMenuDrawerEvent = () => {
    this.setState({
        isMenuDrawerOpenedEvent: false,
    })
}
handleBackBtnEvent = () => {
  window.history.back();
};
groupListsCounts = () => {
  const header = {
    "Content-Type": "application/json",
    token: this.userToken.meta.token,
  };
  const requestMessage: Message = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getGroupsCountAPICallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getCountsTab
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodGET
  );
  //* Making Network Request
  this.setState({ isLoading: true });
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
}
// Customizable Area End
