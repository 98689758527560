// Customizable Area Start
import React from "react";
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "7px",
      fontSize: "14px",
      lineHeight: "22px",
      color: "#ffffff",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      width: "auto",
      minWidth: "156px",
      textDecoration: "none",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    inventoryWrapper: {
      "& .inner-wrapper": {
        width: "100%",
        padding: "69px 0 0",
        [theme.breakpoints.down("sm")]: {
          padding: "20px 0"
        },
      },
      "& .MuiCheckbox-root": {
        color: "#94A3B8",
        "&:hover": {
          backgroundColor: "rgba(54, 145, 166, 0.04)",
        },
        "&.Mui-checked": {
          color: "#2B2B2B",
        },
        "& .MuiSvgIcon-root": {
          width: "24px",
          height: "24px",
          [theme.breakpoints.down("sm")]: {
            width: "20px",
            height: "20px",
          },
        },
      },
    },
    inventoryInnerWrapper: {
      padding: "0 160px",
      [theme.breakpoints.down("md")]: {
        padding: "0 35px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0 24px",
      },
      "& .main-heading": {
        marginBottom: "15px",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "27px"
        },
        [theme.breakpoints.down("xs")]: {
          marginBottom: "17px"
        },
        "& .header-section": {
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          "& .heading-left": {
            [theme.breakpoints.down("xs")]: {
              width: "100%",
            },           
          },
          "& .heading-box": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
            [theme.breakpoints.down("xs")]: {
              marginBottom: "6px",
            },  
            "& .heading": {
              fontWeight: 400,
              fontSize: "24px",
              lineHeight: "32px",
              color: "rgba(43, 43, 43, 0.87)",
              fontFamily: "Expletus Sans",
              letterSpacing: "0",   
              [theme.breakpoints.down("xs")]: {
                color: "#2b2b2b",
              },           
            },                      
          },
          "& .inventory-buttons": {
            display: "flex",
            gap: "16px",
            marginLeft: "10px",
            [theme.breakpoints.down("xs")]: {
              display: "none"
            }
          },
          "& .inventory-subtitle-txt": {
            fontWeight: 400,
            fontSize: "14px",
            color: "#505050",
            fontFamily: "Roboto",
            lineHeight: "24px",
            letterSpacing: "0.25px",
            [theme.breakpoints.down("xs")]: {
              color: "#94A3B8",
              letterSpacing: "0.105px",
              display: "-webkit-box",
              WebkitLineClamp: 1, 
              WebkitBoxOrient: 'vertical',
              overflow: "hidden",
            }
          },  
        },               
        "& .export-btn": {
          color: "#2b2b2b",
          minWidth: "131px",
          border: "1px solid #ECF1F4",
          padding: "12px 8px",
          fontSize: "14px",
          width: "100%",
          background: "transparent",
          minHeight: "48px",
          textAlign: "center",
          transition: "all 0.5s ease-in-out",
          fontFamily: "Expletus Sans",
          fontWeight: 500,
          lineHeight: "18px",
          borderRadius: "6px",
          letterSpacing: "0",
          textTransform: "unset",
          textDecoration: "none",
        },
        "& .mobile-view-buttons": {
          display: "none",
          [theme.breakpoints.down("xs")]: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "29px",
          },
          "& .iconButtonImg": {
            width: "20px",
            "&.exportIcon": {
              width: "16px",
            }
          },
        },                    
      },      
    },
    dropdownStyle: {
      marginTop: "21px",
      maxWidth: "190px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "3px"
      },
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        fontFamily: "Expletus Sans",
        [theme.breakpoints.down("sm")]: {
          marginTop: "3px"
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "24px",
            paddingRight: "24px",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px",
                  opcity:0.6,
                },
              }
            }
          }
        },
        "& .MuiMenuItem-root": {
          color: "#2B2B2B",
          padding: "14px 24px",
          "& .MuiListItemText-root": {
            margin: "0"
          },
          "&.Mui-selected": { 
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
            "& .multiselect-squareCheck": {
              "& .normal-img": {
                display: "none",
              },
              "& .selected-img": {
                display: "block",
              },
            },
          },
          "& .multiselect-check": {
            marginLeft: "5px",
            display: "none",
            maxWidth: "16px",
            flex: "0 0 16px",
          },
          "& .multiselect-squareCheck": {
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
            "& .selected-img": {
              display: "none",
            },
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none"
        }
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        whiteSpace: "normal",
        "& .MuiTypography-root": {
          font: "inherit",
          letterSpacing: "0"
        }
      },
      "& .MuiList-padding": {
        paddingTop: "4px",
        paddingBottom: "8px"
      }
    },
    checked: {
      "&:hover": {
        backgroundColor: "transparent"
      },
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        "&:hover": {
          backgroundColor: "transparent"
        }
      }
    },
    inventoryContentWrapper: {
      "& .filters-section": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "14px 0",
        borderBottom: "1px solid #E8ECF2",
        [theme.breakpoints.down("xs")]: {
          padding: "7px 0",
        },
        "& .filterBox": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "371px",
          maxWidth: "100%",
          padding: "0 23px",
          [theme.breakpoints.down("sm")]: {
            display: "none"
          },
          "& .filterTitle": {
            fontFamily: "Expletus Sans",
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "27px",
            color: "#505050",
            letterSpacing: "0.25px",
          },
          "& .clearLink": {
            // textDecoration: "underline",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontWeight: 500,
            color: "#4BA3B7",
            cursor: "pointer",
            lineHeight: "20px", 
            letterSpacing: "0.1px",
            marginRight:"15px",
          }, 
        },
        "& .filterBoxRight": {
          flex: "1",
          paddingLeft: "24px",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
          [theme.breakpoints.down("sm")]: {
            paddingLeft: "0",
          },
          "& .product-actions": {
            display: "flex",
            alignItems: "center",
            marginLeft: "24px",
            "& .action-link": {
              marginRight: "16px",
              "&:last-child": {
                marginRight: "0"
              }
            }
          },
          "& .productsBox": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            [theme.breakpoints.down("xs")]: {
              display: "none",
            },
          },
          "& .productsCheckbox": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& .productTxt": {
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "24px",
              color: "rgba(0, 0, 0, 0.6)",
              letterSpacing: "0.1px",
              [theme.breakpoints.down("xs")]: {
                fontSize: "12px",
                lineHeight: "16px",
                color: "#2B2B2B",
                letterSpacing: "0",
              },
            },
          },
          "& .search-products": {
            marginRight: "10px",
            "& .MuiInput-root": {
              width: "250px",
              maxWidth: "100%",
              [theme.breakpoints.down("sm")]: {
                width: "234px"
              },
              [theme.breakpoints.down("xs")]: {
                width: "100%"
              }
            },
            "& .MuiInputAdornment-positionStart": {
              marginRight: "13px"
            },
            "& .MuiSvgIcon-root": {
              color: "rgba(43, 43, 43, 1)",
              opacity: 0.7,
              fontSize: "22px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "20px"
              }
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.1px",
              color: "#000000",
              fontWeight: "500",
              fontFamily: "Expletus Sans",
              "&::-webkit-input-placeholder": {
                opacity: "0.369",
                color: "rgba(43, 43, 43, 0.87)"
              },
              "&:-ms-input-placeholder": {
                opacity: "0.369",
                color: "rgba(43, 43, 43, 0.87)"
              },
              "&::placeholder": {
                opacity: "0.369",
                color: "rgba(43, 43, 43, 0.87)"
              }
            }
          }
        },                       
      },  
      "& .mobile-header-section": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          display: "block",   
        },                    
        "& .search-inventory": {
          display: 'flex',
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            flex: 1,
          },
          "& .form-control": {
            marginBottom: "0",
            width: '100%',
            "& .MuiOutlinedInput-input": {
              fontSize: "13px",
              lineHeight: "17px",
              paddingTop: "16px",
              paddingBottom: "16px",
              "&::-webkit-input-placeholder": {
                color: "#94A3B8",
              },
              "&:-ms-input-placeholder": {
                color: "#94A3B8",
              },
              "&::placeholder": {
                color: "#94A3B8",
              },
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              paddingLeft: "16px",
              "& .MuiSvgIcon-root": {
                fill: "#94A3B8",
                width: "20px",
                height: "20px",
              },
            },
          },
        },
        "& .mobile-filter-section":{
          display: 'none',
          [theme.breakpoints.down("xs")]: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "16px",
          },
          "& .filterImages":{
            width: "48px",
            height: "48px",
            padding: "0",
            minWidth: "unset",
          },
        },        
      },
    },
    inventoryContent: {
      display:'flex',
      flexWrap: "wrap",
      "& .filtersGrid": {
        borderRight: "1px solid #E8ECF2",
        width: "371px",
        maxWidth: "100%",
        // overflowY: "auto",
        // position: "sticky",
        // top: "0",
        [theme.breakpoints.down("sm")]: {
          display: "none"
        }
      },
      "& .filtersGridContent": {
        flex: "1",
        paddingTop: "40px",
        paddingLeft: "33px",
        [theme.breakpoints.down("md")]: {
          paddingTop: "36px",
        },
        [theme.breakpoints.down("sm")]: {
          paddingTop: "24px",
          paddingLeft: "0",
        },
        [theme.breakpoints.down("xs")]: {
          paddingTop: "16px",
        }
      },
      "& .filtersFormControl": {
        "& .MuiFormControlLabel-root": {
          marginRight: "0",
          marginLeft: "-7px",
          "& .MuiFormControlLabel-label": {
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.25px",
          }
        },
        "& .MuiCheckbox-root": {
          color: "#94A3B8",
          padding: "7px",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
          },
        },     
      },
      "& .filterCategory-Box": {
        margin: "0",
        boxShadow: "none",
        padding: "24px 43px 24px 17px",
        borderBottom: "1px solid #E8ECF2",
        "&:before": {
          display: "none",
        },
        "& .slider-block": {
          "& .categoryTitle": {
            marginBottom: "24px",
          },        
          "& .slider-input": {
            display: "flex",
            alignItems: "center",
            "& .form-control": {
              marginBottom: 0,
              "& .MuiOutlinedInput-input": {
                padding: "15px 16px",
              }
            },
            "& .slider-middle": {
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "26px",
              letterSpacing: "0.0075em",
              color: "#94A3B8",
              padding: "0 16px",
              textTransform: "lowercase",      
            },    
          }, 
        },
        "& .slider-progress": {
          padding: "25px 16px",   
          "& .MuiSlider-markLabel": {
            top: "28px",
            color: "#94A3B8",
            fontFamily: "Roboto",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "12px",
            textTransform: "uppercase",
            display: "inline-flex",
            alignItems: "center",
            flexDirection: "column",
            "& span": {
              display: "block",
              marginBottom: "3px",
            },
          },
          "& .MuiSlider-mark": {
            width: "1px",
            height: "6px",
            borderRadius: "0",
            backgroundColor: "#94A3B8",
            bottom: "0",
          },
          "& .MuiSlider-root": {
            padding: "0",
            marginBottom: "0",
            color: "#4BA3B7",
          },               
          "& .MuiSlider-valueLabel": {
            color: "#4BA3B7",
            textAlign: "center",
            fontSize: "10px",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            lineHeight: "14px",
            letterSpacing: "0",
            top: "-20px",
            left: "calc(-50% - -4px)",
            "&:after": {
              content: "''",
              width: "0",
              height: "0",
              borderLeft: "3px solid transparent",
              borderRight: "3px solid transparent",
              borderTop: "4px solid #4BA3B7",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            },
            "& >span": {
              width: "21px",
              height: "18px",
              transform: "unset",
              borderRadius: "5px",
              "& >span": {
                color: "#ffffff",
                transform: "unset",
              },
            },
          },
          "& .MuiSlider-rail": {
            height: "6px",
            opacity: "1",
            backgroundColor: "#F2F8FC",
            borderRadius: "21px",
          },   
          "& .MuiSlider-track": {
            height: "6px",
            borderRadius: "21px",
            backgroundColor: "#4BA3B7",
            boxShadow: "none",
          },    
          "& .MuiSlider-thumb": {
            width: "20px",
            height: "20px",
            backgroundColor: "#E8ECF2",
            border: "3px solid #FFFFFF",
            marginLeft: "-8px",
            marginTop: "-8px",
            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
          }, 
        },                
        "& .categoryTitle": {
          fontSize: "14px",
          lineHeight: "20px",
          fontFamily: "Expletus Sans",
          fontWeight: 500,
          color: "rgba(43, 43, 43, 0.87)",
          letterSpacing: "0.1px",
          padding: "0",
          minHeight: "unset",
          "& .MuiAccordionSummary-content": {
            margin: "0",
          },
          "& .MuiIconButton-root": {
            padding: "0",
            color: "#94A3B8",
            marginRight: "0",
            "& .MuiSvgIcon-root": {
              width: "20px",
              height: "20px",
            }
          }
        },
        "& .categoryContentDate": {
          marginTop: "20px",
         "& .MuiAccordionSummary-content": {
          marginTop: "10px",
        },
        },
        "& .MuiAccordionDetails-root": {
          padding: "3px 0 0",
          flexDirection: "column",
        }, 
        "& .expandIcon": {
          color: "#94A3B8"
        },
        "& .category-options-more": {
          fontSize: "14px",
          lineHeight: "18px",
          color: "#4BA3B7",
          fontFamily: "Roboto",
          fontWeight: 400,
          letterSpacing: "0.25px",
          paddingLeft: "27px",
          "&:hover": {        
            cursor: "pointer",
          },
        },
        "& .search-wrapper": {
          borderBottom: "1px solid #E8ECF2",
          marginBottom: "6px",
          "& .MuiInput-root": {
            width: "413px",
            maxWidth: "100%",
            [theme.breakpoints.down("sm")]: {
              width: "234px"
            },
            [theme.breakpoints.down("xs")]: {
              width: "100%"
            }
          },
          "& .MuiInputAdornment-positionStart": {
            marginRight: "6px"
          },
          "& .MuiSvgIcon-root": {
            color: "#94A3B8",
            fontSize: "16px",
          },
          "& .MuiInputBase-input": {
            fontSize: "12px",
            lineHeight: "16px",
            color: "#000000",
            fontWeight: "400",
            fontFamily: "Roboto",
            letterSpacing: "0.4px",
            padding: "11px 0",
            "&::-webkit-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            },
            "&:-ms-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            },
            "&::placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            }
          }
        }
      },                  
    },
    noInventoryBox: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "768px",
      width: "100%",
      margin: "0 auto",
      paddingTop: "210px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
      "& .primary-btn": {
        minWidth: "130px",
        marginTop: "28px",
        [theme.breakpoints.down("xs")]: {
          marginTop: "36px",
          maxWidth: "100%",
          width: "100%",
        },
      },
      "& .noInventoryTxtBox": {
        paddingLeft: "101px",
        flex: "1",
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "0",
          textAlign: "center",
          width: "100%",
        },
        "& .noInventorytxt": {
          fontFamily: "Expletus Sans",
          fontSize: "20px",
          fontWeight: 400,
          lineHeight: "27px",
          color: "rgba(43, 43, 43, 0.87)",
          letterSpacing: "0.25px",
          marginBottom: "12px",
        },
        "& .noInventorySubtxt": {
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "20px",
          color: "rgba(43, 43, 43, 0.67)",
          letterSpacing: "0.25px",
        },
      },
      "& .noInventoryImg": {
        width: "100%",
        maxWidth: "388px",   
        [theme.breakpoints.down("xs")]: {
          marginBottom: "24px",
        },     
      },    
    },
    viewItemContainer:{
      display:'flex', 
      flexWrap:'wrap',
      margin: "0 -12px",
      [theme.breakpoints.down("sm")]: {
        margin: "0 -8px",
      },
      "& .view-item-col": {
        flex: "0 0 33.33%",
        maxWidth: "33.33%",
        padding: "0 12px",
        marginBottom: "24px",
        [theme.breakpoints.down("md")]: {
          flex: "0 0 50%",
          maxWidth: "50%",
        },
        [theme.breakpoints.down("sm")]: {
          flex: "0 0 20%",
          maxWidth: "20%",
          padding: "0 8px",
          marginBottom: "16px",
        },
        [theme.breakpoints.down("xs")]: {
          flex: "0 0 50%",
          maxWidth: "50%",
        },
      },
      "&.inventory-checkbox-wrapper":{
        "& .infoBox":{
          alignItems:"flex-start",
        },
        "& .only-text-block":{
          textAlign:"right",
          marginLeft: "8px",
          "& p":{
            padding:"0",
            fontSize:"13px",
            color:"#2b2b2bde",
            
          },
        },
      },
    },
    viewItemsSection: {
      borderRadius: "12px",
      border: "1px solid #E8ECF2",      
      background: "#FFF",
      boxShadow: "none",
      padding: "24px",
      height: "100%",
      "&.dark-border":{
        border: "1px solid #000000",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "35px 16px 16px",
      },
      "&.inventory-checkbox":{
        "& .info-box-right":{
          display:"flex",
          alignItems:"center",
          marginLeft: "10px",
          [theme.breakpoints.down("sm")]: {
            position: "absolute",
            left: "0",
            right: "0",
            marginLeft: "0",
            justifyContent: "space-between",
            top: "-19px",
          },
          "& .in-stock-badge":{
            marginRight:"12px",
            padding:"2px 8px",
            backgroundColor:"#F2F8FC",
            borderRadius:"6px",
            textTransform:"capitalize",
            color:"rgba(43, 43, 43, 0.87)",
            fontSize:"12px",
            lineHeight: "16px",
            letterSpacing: "0.09px",
            fontFamily: "Roboto",
            [theme.breakpoints.down("sm")]: {
              borderRadius: "31px",
              background: "#F2F8FC",
              fontFamily: "Expletus Sans",
              fontSize: "10px",
              fontWeight: "600",
              lineHeight: "14px",
              marginRight: "0",
              letterSpacing: "0",
              padding: "3px",
              minWidth: "20px",
              height: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            "& .badge-name": {
              [theme.breakpoints.down("sm")]: {
                display: "none",
              }
            }
          },
          "& .MuiCheckbox-root":{
            marginTop:"0",
            padding:"0",
            marginLeft: "auto",
          }
        },
      },
      "& .MuiCardContent-root": {
        padding: "40px",
        borderRadius: "12px",
        border: "1px solid #E8ECF2",
        position: "relative",
        [theme.breakpoints.down("md")]: {
          padding: "26px",
        },
        [theme.breakpoints.down("xs")]: {
          border: "none",
          padding: "0",
          display: "flex",
          alignItems: "center",
        },
        "&:hover": {
          border: "1px solid #12AEF1",
          boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
          cursor: "pointer",
          [theme.breakpoints.down("xs")]: {
            border: "none",
            boxShadow: "unset",
          },
          "& .button-wrapper": {
            display: "block",
            [theme.breakpoints.down("xs")]: {
              display: "none",
            },
          },
        },
        "& .MuiButton-root": {
          marginRight: 0,
          marginBottom: "8px",
          borderRadius: "62px",
          boxShadow: "none",
          width: "100%",
          height: "48px",
          marginLeft: 0,
          "&:last-child": {
            marginBottom: 0,
          },
        },
      },
      "& .itemDesc": {
        display: "flex",
        justifyContent: "space-between",
        color: "rgba(0, 0, 0, 0.38)",
        fontSize: "12px",
        lineHeight: "16px",
        fontFamily: "Roboto",
        fontWeight: 400,
        letterSpacing: "0.4px",
        [theme.breakpoints.down("sm")]: {
          color: "#94A3B8",
          fontSize: "10px",
          lineHeight: "17px",
          letterSpacing: "0.125px",
        },
        "& .desc-left": {
          marginRight: "10px",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        },
        "& .task-about": {
          paddingLeft: "14px",
          marginLeft: "8px",
          position: "relative",
          "& .dot": {
            content: '""',
            width: "6px",
            height: "6px",
            borderRadius: "100%",
            background: "#E8ECF2",
            display: "inline-block",
            position: "absolute",
            left: "0",
            top: "5px",
          },
        },
        "& .timing": {
          color: "#2B2B2B",
          fontFamily: "Roboto",
          fontSize: "10px",
          fontWeight: 400,
          lineHeight: "17px",
          letterSpacing: "0.125px",
        },
      },
      "& .ratingBox":{
        display: 'flex',
        alignItems: 'center',
        marginBottom: "5px",
        "& .ratingText":{
          color: "rgba(0, 0, 0, 0.38)",
          fontSize: "12px",
          lineHeight: "16px",
          fontFamily: "Roboto",
          fontWeight: 400,
          letterSpacing: "0.4px",
          marginRight: "6px",
          [theme.breakpoints.down("sm")]: {
            color: "#94A3B8",
            fontSize: "10px",
            lineHeight: "17px",
            letterSpacing: "0.125px",
          },
        },
        "& .ratingImages":{
          marginRight: "6px",
          width: "6px",
          height: "6px",
          [theme.breakpoints.down("sm")]: {
            width: "4px",
            height: "4px",
          }
        },
        "& .starImg":{
          width: "12px",
          [theme.breakpoints.down("sm")]: {
            width: "10px",
          }
        },
      },        
      "& .infoBox":{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: "24px",
        [theme.breakpoints.down("sm")]:{
          position: "relative",
          marginBottom: "27px",
          flexDirection: "column",
        },         
        "&:last-child": {
          marginBottom: "0",
        },
        "& .infoBoxLeft": {
          [theme.breakpoints.down("sm")]:{
            width: "100%",
            marginBottom: "11px",
          }, 
        },
        "& .infoBoxRight": {
          textAlign: "right",
          marginLeft: "8px",
          [theme.breakpoints.down("sm")]:{
            textAlign: "left",
            marginLeft: "0",
          },
        },
        "& .categoryImg":{
          objectFit:"contain",
          height: '68px',
          maxWidth: "68px",
          width: "100%",
          [theme.breakpoints.down("sm")]:{
            height: '56px',
            maxWidth: "56px",
            margin: "0 auto",
          },
        },
        "& .infoText":{
          fontSize: '12px',
          color:'rgba(0, 0, 0, 0.38)',
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0.4px",
          fontFamily: "Roboto",
          [theme.breakpoints.down("sm")]:{
            fontSize: '10px',
            lineHeight:'17px',
            color:'#94A3B8',
            letterSpacing: "0.125px",
          },          
        },
        "& .addressText":{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth:"100px",
          [theme.breakpoints.down("sm")]:{
            maxWidth:"100%",
          },
        },
        "& .itemTitle":{
          fontSize: '16px',
          lineHeight:'24px',
          fontFamily: "Roboto",
          color:'rgba(43, 43, 43, 0.87)',
          letterSpacing: "0.15px",
          marginBottom: "4px",
          fontWeight: 500,
          [theme.breakpoints.down("sm")]:{
            fontSize: '14px',
            lineHeight:'19px',
            fontFamily: "Expletus Sans",
            color:'#2B2B2B',
            letterSpacing: "0",
            fontWeight: 400,
          },
        },
        "& .itemHeading": {           
          fontSize: '12px',
          color:'rgba(0, 0, 0, 0.38)',
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0.4px",
          fontFamily: "Roboto",
          marginBottom: "4px",
          [theme.breakpoints.down("sm")]:{
            fontSize: '10px',
            lineHeight:'14px',
            color:'#94A3B8',
            letterSpacing: "0",
          },
        },
        "& .itemPrice":{
          fontSize: '16px',
          lineHeight:'24px',
          color:'rgba(43, 43, 43, 0.87)',
          fontFamily: "Roboto",
          fontWeight: 400,
          letterSpacing: "0.15px",
          [theme.breakpoints.down("sm")]:{
            fontSize: "14px",
            lineHeight: "19px",
            letterSpacing: "0",
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
          },
        },
      },
      "& .titleBox":{
        marginBottom: "5px",
      },                     
    },
    viewItemBtn: {
      display: "flex",
      position: "fixed",
      bottom: "65px",
      right: "32px",
      zIndex: 1000,
      padding: "14px 24px",
      borderRadius: "6px",
      backgroundColor: "#4BA3B7",
      boxShadow: "0px 0px 24px 0px rgba(5, 111, 135, 0.47)",
      textTransform: "unset",
      textDecoration: "none",
      color: "#FFFFFF",
      fontFamily: "Expletus Sans",
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: 500,
      [theme.breakpoints.down("sm")]:{
        bottom: "50px",
        right: "24px",
      },
      [theme.breakpoints.down("xs")]:{
        bottom: "48px",
      },
      '&:hover':{
        backgroundColor: "#4BA3B7",
        color: "#FFFFFF",
      },
    },
    dropdown: {
      "& .MuiMenu-paper": {
        minWidth: "156px",
        filter: "drop-shadow(0px 1px 2.5px rgba(0,0,0,0.2))",
        backgroundColor: "#fafafa",
        borderRadius: "8px",
        
        boxShadow: "none",
        "& .MuiMenuItem-root": {
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "0",
          color: "#000000",
          padding: "14px 17px",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          display: "flex",
          justifyContent: "flex-start"
        },
        "& .MuiList-padding": {
          paddingTop: "8px",
          paddingBottom: "8px"
        }
      }
    },
    radioChoose: {
      width: "100%",
      padding: "15px 5px",
      borderBottom: "1px solid #E8ECF2",
      "&.error-show": {
        "& .MuiRadio-colorSecondary ~ span": {
          border: "1px solid #C7263E",
          color: "#C7263E"
        }
      },
      "& .MuiFormControlLabel-root": {
        marginRight: "0",
        marginLeft: "-7px",
      },
      "& .MuiFormControlLabel-label": {
        fontSize: "14px",
        lineHeight: "20px",
        color: "rgba(43, 43, 43, 0.87)",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        letterSpacing: "0.1px",
      },
      "& .MuiRadio-root": {
        "&.Mui-checked": {
          color: "#4ba3b7",
        },
        "& .MuiSvgIcon-root": {
          width: "20px",
          height: "20px",
        }        
      },
      "& .MuiFormHelperText-root": {
        fontSize: "12px",
        letterSpacing: "0.0075em",
        color: "#00BA88",
        fontFamily: "Roboto",
        fontWeight: "400",
        marginLeft: "14px",
        marginRight: "14px",
        "&.Mui-error": {
          color: "#C7263E"
        },
        "&.Mui-default": {
          color: "#2B2B2B"
        }
      }
    },
    tabWrapperSmallDevice: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
      "& .tabBottom":{
        borderBottom: "1px solid #E8ECF2",
        padding: "0 24px",
        [theme.breakpoints.down("xs")]: {
          padding: "0",
        },
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "#4BA3B7"
      },
      "& .MuiTabs-root": {
        borderBottom: "0",
        [theme.breakpoints.down("xs")]: {
          minHeight: "52px",
        },        
      },
      "& .MuiTab-root": {
        fontSize: "14px",
        lineHeight: "20px",
        color: "rgba(43, 43, 43, 0.67)",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        textTransform: "unset",
        letterSpacing: 0,
        [theme.breakpoints.down("sm")]: {
          minWidth: "113px"
        },
        [theme.breakpoints.down("xs")]: {
          minWidth: "109px"
        },
        "&.Mui-selected": {
          color: "2B2B2B",
        }
      },
      "& .MuiTabPanel-root": {
        padding: "0"
      },
      "& .button-wrapper": {
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down("xs")]: {
          justifyContent: "space-between"
        }
      }
    },
    tabViewFilterBox: {
      display: "none",
      alignItems: "center",
      justifyContent: "space-between",
      marginRight: "24px",
      [theme.breakpoints.down("sm")]: {
        display: "flex"
      },
      "& .filterText": {
        fontSize: "14px",
        fontWeight: 500,
        fontFamily: "Expletus Sans",
        lineHeight: "18px",
        color: "rgba(43, 43, 43, 0.67)",
        marginLeft: "6px",
      },
      "& .filter-img":{
        opacity:0.8,
        width: "20px",
      }
    },
    modalDialog: {
      "& .MuiBackdrop-root, & .backdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502) !important",
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "rgba(34, 89, 128, 0.7) !important",
        },
        backdropFilter: "blur(3px)",
      }
    },
    addOptionWrapper: {
      borderRadius: "8px",
      padding: "10px 8px 10px",
      margin: "10px 20px !important",
      border:"1px solid rgba(225, 223, 223, 0.87)",
      "& .MuiOutlinedInput-input": {
        padding: "10px 0",
        letterSpacing: "0px",
        fontSize: "14px",
        color: "#000000",
        lineHeight: "20px",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
      },
      "& .MuiFormLabel-root": {
        transform: "none",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-error": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "& .form-control": {
        marginBottom: "0",
      },
      "& .button-wrapper": {
        borderTop: "1px solid #e8ecf2",
        paddingTop: "16px",
        marginTop: "9px",
        justifyContent: "flex-end",
        display: "flex",
        "& .add-link": {
          fontSize: "14px",
          opacity: "0.671",
          letterSpacing: "0",
          lineHeight: "18px",
          fontWeight: 500,
          color: "#4ba3b7",
          textTransform: "capitalize",
          fontFamily: "Expletus Sans",
          "&.secondary-link": {
            color: "#2b2b2b",
            textTransform: "capitalize",
          },
          "&:hover": {
            textDecoration: "underline",
          },
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
      "& .add-item-title input::placeholder":{
        color:"#2B2B2B"
      }
    },
    rightModalDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("md")]: {
        width: 548,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        maxWidth: "100%",
        top: "197px",
        borderRadius: "24px 24px 0 0",
        height: "auto",
        maxHeight: "100%",
      },
      "& .info-group": {
        padding: "16px 0px",
        width: "100%",
        "& .form-row": {
          display: "flex",
          flexWrap: "wrap",
          margin: "0 -8px",
          "&:last-child": {
            "& .form-control": {
              marginBottom: "0",
            },
          },
          "& .form-col": {
            maxWidth: "100%",
            flex: "0 0 100%",
            padding: "0 8px",
            "&.col6": {
              maxWidth: "50%",
              flex: "0 0 50%",
            },
            "& .form-control": {
              marginBottom: "16px",
            },           
          },
        },
        "& .select-outer": {
          width: "100%",
          // marginTop:'-1.3vw',
          marginBottom: "16px",
          "& .error-select":{
            color:'#C7263E',  
          },
          "& svg":{
           fill:'#94A3B8',  
         },
          "& .Mui-error":{
            "& .MuiOutlinedInput-notchedOutline":{
              borderColor:'#C7263E',  
            }
          },       
          "& .MuiInputLabel-formControl": {
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontWeight: 500,
            letterSpacing: "0.1px",
            lineHeight: "19px",
            color: "#94A3B8",
            width: "calc(100% - 46px)",
            display: "flex",
            "&.MuiFormLabel-filled": {
              width: "auto",
              fontSize: "12px",
              color : "rgba(43,43,43,0.87)",
              lineHeight: "16px",
              fontFamily: "Roboto",
              letterSpacing: "0.0075em",
              fontWeight: "400",
            }
          },
          "& .MuiSelect-select": {
            letterSpacing: "0",
            fontSize: "14px",
            color: "#000000",
            fontWeight: "500",
            opacity: "0.671",
            fontFamily: "Expletus Sans",
            justifyContent:'space-between',
            display:'flex',
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            },
          },
          "& .MuiFormLabel-root": {
            "&.Mui-focused": {
              color : "rgba(43,43,43,0.87)",
              fontSize: "12px",
              width: "auto",
              letterSpacing: "0.0075em",
              lineHeight: "16px",
              fontWeight: "400",
              fontFamily: "Roboto",
            },
            "& .MuiInputLabel-asterisk": {
              lineHeight: "23px",
              marginLeft: "auto",
              color: "#e86577",
              fontSize: "18px",
            }
          }
        },
        "& .group-title": {
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "19px",
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          marginBottom: "16px",
          letterSpacing: "0",
        },
        "& .shipping-address-chkbox": {
          color: "#4BA3B7",
        },
        "& .shipping-address-txt": {
          marginBottom: "16px",
        },
        "& .group-sub-title": {
          color: "var(--text-secondary, #94A3B8)",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "26px",
          letterSpacing: "0.105px",
          marginBottom: "16px",
          display:"flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        "& .group-caption": {
          color: "var(--text-secondary, #94A3B8)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.09px",
        },
      },
      "& .switch-wrapper": {
        marginBottom: "12px",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "30px",
        },
        "&:last-child": {
          marginBottom: "0",
        },
        "& .MuiFormControlLabel-root": {
          width: "100%",
          justifyContent: "space-between",
          marginLeft: "0",
          opacity: "1",
          color: "#2b2b2b",
        }
      },
      "&.filter-dialogbox": {
        "& .modal-heading": {
          marginBottom: "42px",
          "& .filter-heading": {
            display: "flex",
            alignItems: "center",
            "& .auth-back": {
              cursor: "pointer",
            },
            "& .modal-title ": {
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "19px",
              fontFamily: "Expletus Sans",
              marginLeft: "14px",
              color: "#2B2B2B",
            },
          },
          "& .right-block": {
            display: "flex",
            alignItems: "center",
            gap: "24px",
          },
          "& .filter-link": {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "19px",
            color: "#4BA3B7",
            fontFamily: "Expletus Sans",
            textDecoration: "none",
          },
        },
        "& .heading-block": {
          marginBottom: "46px",
          "& .primary-txt": {
            fontSize: "24px",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Expletus Sans",
            lineHeight: "32px",
            fontWeight: 400,
            marginBottom: "6px",
          },
          "& .sub-txt": {
            fontSize: "14px",
            color: "#94A3B8",
            lineHeight: "26px",
            marginBottom: "0",
            letterSpacing: "0.105px",
          },
        },
        "& .modal-form": {
          marginTop: "0",
          "& .form-info-wrapper": {
            marginBottom: "30px",
            "&:last-child": {
              marginBottom: "0",
            },
            "& .form-heading": {
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              marginBottom: "16px",
              letterSpacing: "0",
            },
            "& .form-control": {
              "& .MuiInputBase-input": {
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-root": {
                "&.MuiOutlinedInput-adornedEnd": {
                  paddingRight: "24px",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  width: "20px",
                  height: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                padding: "20px 24px",
                "& .MuiOutlinedInput-inputAdornedEnd": {
                  paddingRight: "0",
                },
              },
            },                     
          },
          "& .switch-wrapper": {
            marginBottom: "12px",
            [theme.breakpoints.down("xs")]: {
              marginBottom: "30px",
            },
            "&:last-child": {
              marginBottom: "0",
            },
            "& .MuiFormControlLabel-root": {
              width: "100%",
              justifyContent: "space-between",
              marginLeft: "0",
              opacity: "1",
              color: "#2b2b2b",
            }
          }
        },
        "& .modal-description": {
          marginBottom: "44px",
        },
        "& .modal-footer": {
          "&.full-btn": {
            "& .MuiButton-root": {
              width: "100%",
            },
          },
        },
      },
      "&.favourite-product-dialog": {
        "& .modal-dialoginner": {
          flexDirection: "unset",
          flexWrap: "wrap",
          alignItems: "flex-start",
        },
        "& .modal-heading": {
          width: "100%",
          "& .close-icon": {
            marginLeft: "auto",
          },          
        },
        "& .modal-description": {
          marginBottom: "0",
          width: "100%",
        },
        "& .button-wrapper": {
          marginTop: "0",
        },
        "& .modal-footer": {
          marginTop: "auto",
          "& .MuiButton-root": {
            "&:last-child": {
              marginBottom: "0",
            },
          },
          "& .secondary-btn": {
            [theme.breakpoints.down("xs")]: {
              borderColor: "#4BA3B7",
              color: "#4BA3B7",
            },
          },
        },
      },
      "& .modal-dialoginner": {
        padding: "82px 60px",
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        [theme.breakpoints.down("md")]: {
          padding: "45px 60px 29px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "30px 24px",
        },
        "& .MuiCheckbox-root": {
          color: "#94A3B8",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
        "& .MuiRadio-root": {
          color: "#94A3B8",
          padding: "0",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "6px",
      },
      "& .modal-title": {
        fontSize: "24px",
        lineHeight: "32px",
        color: "rgba(43, 43, 43, 0.87)",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
      },
      "& .modal-description": {
        marginBottom: "20px",
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "26px",
        color: "#2B2B2B",
        letterSpacing: "0.0075em",
        fontWeight: 400,
        fontFamily: "Roboto",
        marginTop: "0",
        marginBottom: "48px",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "16px",
        },  
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#2B2B2B",
        fontWeight: "500",
        "& .modal-link": {
          marginLeft: "3px",
        },
      },      
      "& .button-wrapper": {
        marginTop: "auto",
        "&.full-btn": {
          "& .MuiButton-root": {
            width: "100%",
          },
        },
      },
      "& .select-control": {
        "&.multi-select": {
          "&.outline-control": {
            width: "100%",
            marginTop: "0",
            "& .multiselect-label": {
              display: "block",
              position: "static",
              transform: "none",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              marginBottom: "16px",
              letterSpacing: "0",
            },
            "& .MuiSelect-select": {
              fontFamily: "Roboto",
              fontWeight: 400,
              color: "#2B2B2B",
              padding: "18px 40px 18px 24px",
              lineHeight: "20px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
              },
            },
            "& .multiple-clear": {
              position: "absolute",
              fontSize: "15px",
              zIndex: 1,
              color: "#94A3B8",
              top: "calc(50% + 17px)",
              right: "27px",
              transform: "translateY(-50%)",
            },
          },
          "& .multiselect-label": {
            display: "none",
          },
          "& .MuiInput-formControl": {
            marginTop: "0",
          },
          "& .MuiSelect-select": {
            paddingRight: "40px",
            display: "flex",
            alignItems: "center",
            "& .select-icon": {
              marginRight: "6px",
            },
          },
          "& .multiple-clear": {
            position: "absolute",
            fontSize: "15px",
            right: "18px",
            top: "3px",
            zIndex: 1,
            color: "#94A3B8",
          },
        },
        "&.status-control": {
          borderRadius: "12px",
          background: "rgba(18, 174, 241, 0.07)",
          padding: "6px 12px",
          "& .down-arrow": {
            width: "16px",
            height: "16px",
          },
          "& .MuiSelect-select": {
            color: "#12AEF1",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.09px",
            opacity: "1",
            paddingRight: "5px",
          },
        },
        "& .select-icon": {
          position: "relative",
          marginRight: "6px",
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          color: "rgba(43, 43, 43, 0.87)",
          opacity: "0.671",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          paddingBottom: "0",
          paddingTop: "0",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .clear-icon": {
            width: "50px",
            height: "50px",
            background: "red",
          },
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
      },
      "& .modal-footer": {
        marginTop: "auto",
        display: "flex",
        justifyContent: "space-between",
        "& .half-btn": {
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          "& .MuiButton-root": {
            width: "auto",
            marginBottom: "0",
            marginRight: "10px",
            "&:last-child": {
              marginRight: "0",
            },
            "&.primary-btn": {
              padding: "12px 38px",
            },
            "&.secondary-btn": {
              minWidth: "140px",
            },
          },
        },
        "& .MuiButton-root": {
          marginRight: "24px",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
      "& .primary-btn": {
        minHeight: "56px",
        fontSize: "14px",
        lineHeight: "19px",
        minWidth: "156px",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px",
        }
      },
      "& .secondary-btn": {
        minHeight: "48px",
        fontSize: "14px",
        lineHeight: "19px",
        minWidth: "156px",
      },
      "& .template-list": {
        padding: "0",        
        "& .MuiListSubheader-gutters": {
          paddingLeft: "0",
          paddingRight: "0",
          position: "static",
          "& .form-control": {
            marginBottom: "0",
            "& .MuiInputAdornment-root": {
              "& .MuiSvgIcon-root": {
                fill: "#225980",
                fontSize: "20px",
              },
            },
            "& .MuiOutlinedInput-adornedStart": {
              paddingLeft: "16px",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              minHeight: "56px",
            },
            "& .MuiOutlinedInput-input": {
              fontSize: "13px",
              lineHeight: "17px",
              letterSpacing: "0",
              padding: "17px 16px 16px",
              "&::-webkit-input-placeholder": {
                color: "#94A3B8",
              },
              "&:-ms-input-placeholder": {
                color: "#94A3B8",
              },
              "&::placeholder": {
                color: "#94A3B8",
              },
              "&.MuiOutlinedInput-inputAdornedStart": {
                paddingLeft: "7px",
              },
            },
          },
        },
        "& .multiselect-membres": {
          display: "flex",
          alignItems: "center",
          width: "auto",
          maxWidth: "unset",
          justifyContent: "space-between",
          padding: "16px 0",
          margin: "0",
          borderBottom: "1px solid #ECF1F4",
          [theme.breakpoints.down("xs")]: {
            padding: "15px 0",
            margin: "0",
          },
          "& .total-member": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "19px",
            opacity: "0.67",
          },
          "& .select-control": {
            opacity: "0.67",
            "& .MuiSelect-select": {
              color: "#2b2b2b",
              opacity: "1",
              fontSize: "12px",
              letterSpacing: "0",
              paddingRight: "26px",              
            },
            "& .down-arrow": {
              color: "rgba(43, 43, 43, 0.67)",
            },
            "& .MuiSelect-icon": {
              top: "calc(50% - 11px)",
              color: "#2b2b2b",
              fontSize: "20px",
            },
          },
        },        
        "& .template-li": {
          display: "flex",
          justifyContent: "space-between",
          background: "transparent",
          position: "relative",
          padding: "21px 0",
          borderBottom: "1px solid #ECF1F4",
          cursor: "pointer",
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
          },
          "& .template-left": {
            "& .name": {
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
              marginBottom: "4px",
            },
            "& .sub-txt": {
              fontSize: "12px",
              lineHeight: "16px",
              color: "#94A3B8",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
              letterSpacing: "0",
              marginBottom: "0",
            },
          },
          "& .template-icon": {
            minWidth: "unset",
            marginLeft: "10px",
            "& .MuiCheckbox-root": {
              padding: "0",
            },
            "& .MuiIconButton-colorSecondary": {
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          },
        },
      },
      "& .tasklist-member": {
        padding: "0",
        "& .tasklist-li": {
          display: "flex",
          background: "transparent",
          justifyContent: "space-between",
          position: "relative",
          padding: "26px 24px 18px",
          borderBottom: "1px solid #ECF1F4",
          "& .tasklist-left": {
            display: "flex",
            alignItems: "center",
            "& .assignee-info": {
              marginLeft: "12px",
            },
            "& .assignee-profile": {
              display: "flex",
              width: "38px",
              height: "38px",
              background: "#ECF1F4",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Roboto",
              borderRadius: "100%",
              color: "rgba(43, 43, 43, 0.87)",
              fontSize: "15.485px",
              fontWeight: 600,
              letterSpacing: "-0.395px",
              lineHeight: "21.679px",
            },
            "& .assignee-name": {
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
            },
            "& .assignee-email": {
              color: "#94A3B8",
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: 500,
              fontFamily: "Expletus Sans",
            },
          },
          "& .tasklist-icon": {
            minWidth: "unset",
          },
        },
      },
      "& .assignee-multiSelect-list": {
        padding: "0",
        marginBottom: "15px",
        "&.selected-list": {
          "& .MuiListItem-root": {
            background: "#F2F8FC",
          },
        },
        "& .search-filter": {
          display: "flex",
          alignItems: "center",
          gap: "24px",
          paddingBottom: "22px",
          borderBottom: "1px solid #ECF1F4",
          [theme.breakpoints.down("xs")]: {
            gap: "16px",
            paddingBottom: "0",
            borderBottom: "0",
          },
          "& .form-control": {
            width: "100%",
            marginBottom: "0",
            "& .MuiInputAdornment-root": {
              "& .MuiSvgIcon-root": {
                fill: "rgba(0,0,0,0.67)",
              }
            },
            "& .MuiOutlinedInput-root": {
              [theme.breakpoints.down("xs")]: {
                borderRadius: "12px",
              },
            }  
          },
          "& .filter-group": {
            width: "56px",
            height: "56px",
            minWidth: "56px",
            [theme.breakpoints.down("xs")]: {
              width: "48px",
              height: "48px",
              minWidth: "48px",
            },
            "& img": {
              width: "100%",
              height: "100%",
            }
          }
        },
        "& .MuiListSubheader-gutters": {
          position: "static",
          paddingRight: "0",
          paddingLeft: "0",
          [theme.breakpoints.down("xs")]: {
            paddingLeft: "24px",
            paddingRight: "24px",
            margin: "0 -24px",
          },
          "& .form-control": {
            marginBottom: "12px",
            "& .MuiOutlinedInput-root": {
              minHeight: "56px",
              [theme.breakpoints.down("xs")]: {
                minHeight: "48px",
              },
            },
            "& .MuiOutlinedInput-input": {
              padding: "16px",
              lineHeight: "17px",
              letterSpacing: "0",
              fontSize: "13px",
              "&.MuiOutlinedInput-inputAdornedStart": {
                paddingLeft: "0",
              },
            },
            "& .MuiInputAdornment-positionStart": {
              marginRight: "16px",
            },
            "& .MuiInputAdornment-root": {
              "& .MuiSvgIcon-root": {
                fontSize: "20px",
                fill: "#225980",
              },
            },
          },
          "&.multiselect-subheader": {
            color: "#2B2B2B",
            marginBottom: "0",
            borderBottom: "1px solid #ECF1F4",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "14px",
            fontFamily: "Expletus Sans",
            fontWeight: "500",
            lineHeight: "19px",
            display: "flex",
            padding: "19px 24px",
            [theme.breakpoints.down("xs")]: {
              margin: "0 -24px",
              width: "auto",
            },
            "& .tasklist-link": {
              textDecoration: "none",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "19px",
              whiteSpace: "nowrap",
              marginLeft: "10px",
              color: "#C7263E",
              fontFamily: "Expletus Sans",
            },
          },
          "&.multiselect-subtxt": {
            borderBottom: "1px solid #ECF1F4",
            textTransform: "uppercase",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "normal",
            fontFamily: "Roboto",
            padding: "10px 20px",
            color: "#94A3B8",
            [theme.breakpoints.down("xs")]: {
              margin: "0 -24px",
              width: "auto",
            },
          },
        },
        "& .multiselect-membres": {
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          padding: "16px 20px",
          borderBottom: "1px solid #ECF1F4",
          margin: "0",
          [theme.breakpoints.down("xs")]: {
            width: "auto",
            margin: "0 -24px",
          },
          "& .total-member": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "0.1px",
          },
          "& .select-control": {
            "&.sortby-control": {
              "& .MuiSelect-select": {
                paddingRight: "26px",
              },
              "& .MuiSelect-icon": {
                top: "calc(50% - 11px)",
                color: "#2b2b2b",
                fontSize: "20px",
              },
            },
            "& .MuiSelect-select": {
              color: "#2b2b2b",
              opacity: "1",
              fontSize: "14px",
              letterSpacing: "0",
            },
          },
        },
        "& .MuiListItem-root": {
          borderBottom: "1px solid #ECF1F4",
          cursor: "pointer",
          padding: "8px 20px 6px",
          minHeight: "80px",
          [theme.breakpoints.down("xs")]: {
            margin: "0 -24px",
            padding: "8px 24px 6px",
            width: "auto",
          },
        },
        "& .assignee-detail": {
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          display: "flex",
          "& .assignee-left": {
            display: "flex",
            flex: "1",
            alignItems: "center",
            "& .assignee-info": {
              marginLeft: "13px",
            },
            "& .assignee-email": {
              fontWeight: 500,
              lineHeight: "16px",
              fontFamily: "Expletus Sans",
              color: "#94A3B8",
              fontSize: "12px",
            },
            "& .assignee-name": {
              marginBottom: "2px",
              lineHeight: "19px",
              fontWeight: 500,
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
            },
            "& .assignee-profile": {
              letterSpacing: "-0.395px",
              fontWeight: 600,
              display: "flex",
              color: "rgba(43, 43, 43, 0.87)",
              justifyContent: "center",
              background: "#ECF1F4",
              alignItems: "center",
              borderRadius: "100%",
              fontFamily: "Roboto",
              height: "48px",
              fontSize: "15.485px",
              lineHeight: "21.679px",
              minWidth: "48px",
            },
            "& .chip-wrapper": {
              marginTop: "3px",
              "& .MuiChip-root": {
                height: "24px",
                marginBottom: "2px",
              },
            },
          },
          "& .assignee-right": {
            marginLeft: "10px",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            "& .hours-details": {
              color: "#000",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 600,
              lineHeight: "16px",
              letterSpacing: "0.09px",
              marginRight: "16px",
              textAlign: "right",
              [theme.breakpoints.down("md")]: {
                marginRight: "8px",
              },
              "& .gray-txt": {
                color: "#94A3B8",
                fontWeight: 400,
                marginTop: "4px",
              },
            },
            "& .assinee-selected": {
              width: "20px",
              height: "20px",
              "& .normal-img": {
                display: "none",
              },
              "& .selected-img": {
                display: "block",
              },
            },
            "& .assinee-unselected": {
              width: "20px",
              height: "20px",
              "& .normal-img": {
                display: "block",
              },
              "& .selected-img": {
                display: "none",
              },
            },
          },
        },
      },
      "& .favourite-product-wrapper": {
        textAlign: "center",
        "& .project-img": {
          marginBottom: "40px",
          [theme.breakpoints.down("xs")]: {
            maxWidth: "289px",
            margin: "0 auto 32px",
          },
        },
      }
    },
    modalDialogBox: {
      position: "absolute",
      left: "50%",
      top:"50%",
      transform: "translate(-50%,-50%)",
      maxWidth:"1075px",
      width: "100%",
      backgroundColor: "#ffffff",
      borderRadius: "12px",
      boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
      overflowY: "auto",
      height:"500px",
      // overflowX:"auto",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "90%",
      },
      [theme.breakpoints.down("xs")]: {
        // maxWidth: "90%",
      },
      "&.show-more-dialog": {
        left: "160px",
        transform: "translateY(-50%)",
        maxWidth:"1075px",
        [theme.breakpoints.down("md")]: {      
          left: "35px",
        },
        [theme.breakpoints.down("sm")]: {
          left: "50%",
          maxWidth: "90%",
          transform: "translate(-50%, -50%)",
        },
      },
      "& .link-title-outer": {
        "& .MuiList-root":{
          display:"flex",
          padding:"0",
          "& .MuiListItem-root":{
            display:"block",
            padding:"0",
            width:"auto",            
            "& + .MuiListItem-root":{
              paddingLeft:"10px",
            },
            "& a":{
              textDecoration:"none",
              color:"#94A3B8",
              fontFamily: "Roboto",
              fontWeight:"400",
              fontSize:"14px",
            },
            "& .title-enabled":{
              color:"#2B2B2B !important",
            }
          }
        }
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding:"14px 24px",
        borderBottom:"1px solid #E8ECF2",
        "& .search-products": {
          "& .MuiInputAdornment-positionStart": {
            marginRight: "6px"
          },
          "& .MuiSvgIcon-root": {
            color: "#94A3B8",
            fontSize: "18px",
          },
          "& .MuiInputBase-input": {
            fontSize: "14px",
            lineHeight: "20px",
            color: "#000000",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            letterSpacing: "0.1px",
            padding: "11px 0",
            "&::-webkit-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            },
            "&:-ms-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            },
            "&::placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            }
          }
        }
      },
      "& .modal-description":{
        padding:"25px",
        "& .link-title-outer": {
          "& .MuiList-root":{
            display: "flex",
            overflowX: "auto",
            flexWrap: "wrap",
            flexDirection: "column",
            height: "400px",
            whiteSpace: "nowrap",
            margin:"0 -20px -12px",
            "& .MuiListItem-root":{
              padding:"0 20px 12px !important",
              "& p":{
                color:"000000",
                fontWeight:"500",
                fontSize:"14px",
                fontFamily: "Roboto",
              },
              "& + .MuiListItem-root":{
                  paddingLeft:"0"
              }
            }
          }
        }
      },
      "& .modal-title": {
        fontWeight: 400,
        fontSize: "24px",
        lineHeight: "28px",
        fontFamily: "Expletus Sans",
        color: "rgba(43, 43, 43, 0.87)",
      },
      "& .heading-right": {
        display: "flex",
        alignItems: "center",
        gap: 16,
      },
      "& .clearLink": {
        fontFamily: "Expletus Sans",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "18px",
        color: "#4BA3B7",
        cursor: "pointer",
      },
      "& .close-icon": {
        color: "#94A3B8",
        lineHeight: "0"
      },
      "& .filterCategory-Box": {
        margin: "0",
        boxShadow: "none",
        padding: "24px 0",
        borderBottom: "1px solid #E8ECF2",
        "&:before": {
          display: "none",
        },
        "& .slider-block": {
          "& .categoryTitle": {
            marginBottom: "24px",
          },
          "& .slider-input": {
            display: "flex",
            alignItems: "center",
            "& .form-control": {
              marginBottom: 0,
              "& .MuiOutlinedInput-input": {
                padding: "15px 16px",
              }
            },
            "& .slider-middle": {
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "26px",
              letterSpacing: "0.0075em",
              color: "#94A3B8",
              padding: "0 16px",
              textTransform: "lowercase",      
            },    
          }, 
        },
        "& .slider-progress": {
          padding: "25px 16px",   
          "& .MuiSlider-markLabel": {
            top: "28px",
            color: "#94A3B8",
            fontFamily: "Roboto",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "12px",
            textTransform: "uppercase",
            display: "inline-flex",
            alignItems: "center",
            flexDirection: "column",
            "& span": {
              display: "block",
              marginBottom: "3px",
            },
          },
          "& .MuiSlider-mark": {
            width: "1px",
            height: "6px",
            borderRadius: "0",
            backgroundColor: "#94A3B8",
            bottom: "0",
          },
          "& .MuiSlider-root": {
            padding: "0",
            marginBottom: "0",
            color: "#4BA3B7",
          },               
          "& .MuiSlider-valueLabel": {
            color: "#4BA3B7",
            textAlign: "center",
            fontSize: "10px",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            lineHeight: "14px",
            letterSpacing: "0",
            top: "-20px",
            left: "calc(-50% - -4px)",
            "&:after": {
              content: "''",
              width: "0",
              height: "0",
              borderLeft: "3px solid transparent",
              borderRight: "3px solid transparent",
              borderTop: "4px solid #4BA3B7",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            },
            "& >span": {
              width: "21px",
              height: "18px",
              transform: "unset",
              borderRadius: "5px",
              "& >span": {
                color: "#ffffff",
                transform: "unset",
              },
            },
          },
          "& .MuiSlider-rail": {
            height: "6px",
            opacity: "1",
            backgroundColor: "#F2F8FC",
            borderRadius: "21px",
          },   
          "& .MuiSlider-track": {
            height: "6px",
            borderRadius: "21px",
            backgroundColor: "#4BA3B7",
            boxShadow: "none",
          },    
          "& .MuiSlider-thumb": {
            width: "20px",
            height: "20px",
            backgroundColor: "#E8ECF2",
            border: "3px solid #FFFFFF",
            marginLeft: "-8px",
            marginTop: "-8px",
            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
          }, 
        },
        "& .categoryContentDate": {
          marginTop: "20px",
         "& .MuiAccordionSummary-content": {
          marginTop: "10px",
         },
        },   
        "& .categoryTitle": {
          fontSize: "14px",
          lineHeight: "20px",
          fontFamily: "Expletus Sans",
          fontWeight: 500,
          color: "rgba(43, 43, 43, 0.87)",
          letterSpacing: "0.1px",
          padding: "0",
          minHeight: "unset",
          "& .MuiAccordionSummary-content": {
            margin: "0",
          },
          "& .MuiIconButton-root": {
            padding: "0",
            color: "#94A3B8",
            marginRight: "0",
            "& .MuiSvgIcon-root": {
              width: "20px",
              height: "20px",
            }
          },          
        },
                          
        "& .MuiAccordionDetails-root": {
          padding: "3px 0 0",
          flexDirection: "column",
        }, 
        "& .expandIcon": {
          color: "#94A3B8"
        },
        "& .category-options-more": {
          fontSize: "14px",
          lineHeight: "18px",
          color: "#4BA3B7",
          fontFamily: "Roboto",
          fontWeight: 400,
          letterSpacing: "0.25px",
          paddingLeft: "27px",
          "&:hover": {        
            cursor: "pointer",
          },
        },
        "& .search-wrapper": {
          borderBottom: "1px solid #E8ECF2",
          marginBottom: "6px",         
          "& .MuiInput-root": {
            width: "413px",
            maxWidth: "100%",
            [theme.breakpoints.down("sm")]: {
              width: "234px"
            },
            [theme.breakpoints.down("xs")]: {
              width: "100%"
            }
          },
          "& .MuiInputAdornment-positionStart": {
            marginRight: "6px"
          },
          "& .MuiSvgIcon-root": {
            color: "#94A3B8",
            fontSize: "16px",
          },
          "& .MuiInputBase-input": {
            fontSize: "12px",
            lineHeight: "16px",
            color: "#000000",
            fontWeight: "400",
            fontFamily: "Roboto",
            letterSpacing: "0.4px",
            padding: "11px 0",
            "&::-webkit-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            },
            "&:-ms-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            },
            "&::placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            }
          }
        }
      },
      "& .filtersFormControl": {
        "&.disabled": {
          opacity: "0.37",
          pointerEvents: "none",
        },
        "& + .filtersFormControl":{
          marginLeft:"0",
        },
        "& .MuiFormControlLabel-root": {
          marginRight: "0",
          marginLeft: "-7px",
          "& .MuiFormControlLabel-label": {
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.25px",
          }
        },
        "& .MuiCheckbox-root": {
          color: "#94A3B8",
          padding: "7px",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
          },
        },     
      },
    },
    wrapper: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column"
    },
    selectFormControl : {
      marginRight: "25px",
      "&:last-child": {
        marginRight: "0",
      },
      "& .MuiSelect-select": {
        fontSize: "14px",
        letterSpacing: "0.1px",
        color: "rgba(43, 43, 43, 0.87)",
        opacity: "0.671",
        fontWeight: "500",
        fontFamily: "Expletus Sans !important",
        paddingBottom: "0",
        paddingTop: "0",
        "&:focus": {
          backgroundColor: "transparent"
        },        
      },
      "& .MuiSelect-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        opacity: "0.671",
      }
    },
    itemsFormControl : {
      display:"block",
      marginBottom: "4px",
      [theme.breakpoints.down("sm")]:{
        marginBottom: "2px",
      },
      "& .MuiSelect-select": {
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.4px",
        color: "rgba(0, 0, 0, 0.38)",
        fontWeight: 400,
        fontFamily: "Roboto",
        paddingTop: "0",
        paddingBottom: "0",
        [theme.breakpoints.down("sm")]:{
          fontSize: "10px",
          lineHeight: "14px",
          letterSpacing: "0",
          color: "#94A3B8",
        },
        "&:focus": {
          backgroundColor: "transparent"
        },        
      },
      "& .MuiSelect-icon": {
        color: "rgba(0, 0, 0, 0.38)",
        width: "20px",
      }
    },
    switchWrapper: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px"
      },
      "& .MuiFormControl-root": {
        width: "100%"
      },
      "& .MuiFormControlLabel-root": {
        opacity: "0.671",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0px",
        color: "rgba(0, 0, 0, 0.87)",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          width: "100%",
          justifyContent: "space-between",
          opacity: "1",
          color: "#2B2B2B"
        },
        "& .MuiFormControlLabel-label": {
          font: "inherit"
        }
      },
      "& .MuiSwitch-track": {
        backgroundColor: "#d3d9e1",
        opacity: 1,
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "#94A3B8"
        }
      },
      "& .MuiSwitch-root": {
        [theme.breakpoints.down("xs")]: {
          width: "33px",
          height: "20px",
          padding: "5px"
        },
        "& .MuiIconButton-root": {
          [theme.breakpoints.down("xs")]: {
            padding: "6px"
          }
        }
      },
      "& .MuiSwitch-switchBase": {
        color: "#fafafa",
        [theme.breakpoints.down("xs")]: {
          color: "#ffffff"
        },
        "&.Mui-checked": {
          color: "#3691a6",
          [theme.breakpoints.down("xs")]: {
            transform: "translateX(12px)"
          },
          "&+ .MuiSwitch-track": {
            backgroundColor: "#3691a6"
          }
        }
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.2))",
        [theme.breakpoints.down("xs")]: {
          width: "8px",
          height: "8px"
        }
      }
    },
});
// Customizable Area End
