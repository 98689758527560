// Customizable Area Start
import React from "react";
import clsx from "clsx";
import { lighten, makeStyles, createTheme } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 1025,
  lg: 1281,
  xl: 1920,
};

const breakpointsFull = {
  breakpoints: createBreakpoints({
    values: BREAKPOINTS,
  }),
};

const customTheme = createTheme(
  {
    palette: {
      primary: {
        main: "#FF66CC",
      },
    },
  },
  breakpointsFull
);
export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiCheckbox-root": {
      color: "#94A3B8",
      "&:hover": {
        backgroundColor: "rgba(54, 145, 166, 0.04)",
      },
      "&.Mui-checked": {
        color: "#4ba3b7",
      },
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
      },
    },
  },
  table: {
    minWidth: 1250,
    [theme.breakpoints.down("md")]: {
      minWidth: 1050,
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 950,
    },
    "&.view-teammember": {
      "& .MuiTableCell-body": {
        "&.name-cell": {
          width: "19%",
          [theme.breakpoints.down("md")]: {
            width: "22.3%",
          },
          [theme.breakpoints.down("sm")]: {
            width: "24.7%",
          },
        },
        "&.email-cell": {
          width: "20.4%",
          [theme.breakpoints.down("md")]: {
            width: "30%",
          },
          [theme.breakpoints.down("sm")]: {
            width: "24.5%",
          },
          "& .table-link": {
            marginLeft: "0",
          },
        },
        "&.joindate-cell": {
          width: "45%",
          [theme.breakpoints.down("md")]: {
            width: "18%",
          },
          [theme.breakpoints.down("sm")]: {
            width: "21%",
          },
        },
        "&.action-link": {
          width: "14.5%",
          [theme.breakpoints.down("md")]: {
            width: "22.4%",
          },
          [theme.breakpoints.down("sm")]: {
            width: "21.43%",
          },
        },
      },
    },
    "&.viewTeamList": {
      "& .MuiTableCell-body": {
        "&.name-cell": {
          width: "21%",
          [theme.breakpoints.down("md")]: {
            width: "24%",
          },
          [theme.breakpoints.down("sm")]: {
            width: "28.5%",
          },
        },
        "&.email-cell": {
          width: "59%",
          [theme.breakpoints.down("md")]: {
            width: "54%",
          },
          [theme.breakpoints.down("sm")]: {
            width: "42.5%",
          },
          "& .table-link": {
            marginLeft: "0",
          },
        },
        "&.team-cell": {
          width: "44%",
          [theme.breakpoints.down("md")]: {
            width: "27%",
          },
          [theme.breakpoints.down("sm")]: {
            width: "29%",
          },
        },
        "&.action-link": {
          width: "15.5%",
          [theme.breakpoints.down("md")]: {
            width: "20.5%",
            paddingRight: "0",
          },
          [theme.breakpoints.down("sm")]: {
            width: "23.5%",
          },
        },
      },
    },
    "&.view-memberList": {
      [theme.breakpoints.down("sm")]: {
        minWidth: "1232px",
      },
      "& .MuiTableCell-body": {
        "&.name-cell": {
          width: "18.5%",
          [theme.breakpoints.down("md")]: {
            width: "15.4%",
          },
        },
        "&.email-cell": {
          width: "22%",
          [theme.breakpoints.down("md")]: {
            width: "17.8%",
          },
          "& .table-link": {
            marginLeft: "0",
          },
        },
        "&.team-cell": {
          width: "25.9%",
          [theme.breakpoints.down("md")]: {
            width: "27.8%",
          },
        },
        "&.joindate-cell": {
          width: "14.5%",
          [theme.breakpoints.down("md")]: {
            width: "10.5%",
          },
        },
        "&.action-link": {
          width: "14.5%",
          [theme.breakpoints.down("md")]: {
            width: "23.2%",
          },
        },
      },
    },
    "&.team-list-employee": {
      "& .MuiTableCell-body": {
        "&.MuiTableCell-root": {
          "&:last-child": {
            width: "auto",
          }
        }
      }
    },
    "&.mem-list-employee": {
      "& .MuiTableCell-body": {
        "&.MuiTableCell-root": {
          "&:last-child": {
            width: "auto",
          }
        }
      }
    },
    "&.mem-list-manager": {
      "& .MuiTableCell-body": {
        "&.MuiTableCell-root": {
          "&:last-child": {
            width: "auto",
          }
        }
      }
    },
    "&.bulk-upload-confirm": {
      "& .MuiTableCell-head": {
        "&.MuiTableCell-paddingNone": {
          padding: "14px",
        },
      },
      "& .MuiTableCell-body": {
        "&.MuiTableCell-paddingNone": {
          padding: "14px",
        },
        "&.name-cell": {
          width: "25.3%",
        },
        "&.email-cell": {
          width: "74.8%",
        },
        // "&.team-cell": {
        //   width: "33.5%",
        // },
        // "&.joindate-cell": {
        //   width: "14%",
        // },
      },
    },
    "& .MuiTableRow-root": {
      "&.Mui-selected": {
        background: "#fafcfe",
        "&:hover": {
          background: "#fafcfe",
        },
        "& .MuiTableCell-root": {
          "& .profile-name": {
            backgroundColor: "#eaeff2",
          },
        },
      },
      "&.disable-member-row": {
        "&:hover": {
          background: "#ffffff",
        },
      },
    },
    "& .MuiTableCell-head": {
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      color: "rgba(43, 43, 43, 0.369)",
      fontWeight: "500",
      fontFamily: "Expletus Sans",
      padding: "19px 16px",
      cursor: "default",
      "& .MuiTableSortLabel-root":{
        cursor: "default",
      },
      [theme.breakpoints.down("md")]: {
        padding: "19px 10px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "17px 10px",
      },
      "&.MuiTableCell-paddingNone": {
        padding: "0",
      },
      "& .MuiTableSortLabel-iconDirectionAsc":{
        display: "none",
      },
      "&.MuiTableCell-paddingCheckbox": {
        padding: "0 40px 0 18px",
      },
    },
    "& .disable-member-row": {
      "& .MuiTableCell-body": {
        "&:not(.action-link)": {
          userSelect: "none",
          pointerEvents: "none",
          opacity: "0.569",
        },

        "&.action-link": {
          "& .table-link": {
            userSelect: "none",
            pointerEvents: "none",
            opacity: "0.569",
            "&.reactivate": {
              userSelect: "unset",
              pointerEvents: "unset",
              opacity: "1",
            },
          },
        },
      },
    },
    "& .MuiTableCell-body": {
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      color: "#2b2b2b",
      fontWeight: "500",
      fontFamily: "Expletus Sans",
      padding: "26px 16px",
      [theme.breakpoints.down("md")]: {
        padding: "26px 10px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "25px 10px",
      },
      "&.MuiTableCell-paddingNone": {
        padding: "0",
      },
      "&.MuiTableCell-paddingCheckbox": {
        padding: "0 40px 0 18px",
      },
      "& .MuiLink-root": {
        color: "inherit",
      },
      "&.action-link": {
        whiteSpace: "noWrap",
        "& .table-link": {
          marginLeft: "0",
          whiteSpace: "noWrap",
        },
      },
      "& .member-team-link": {
        cursor: "pointer",
        color: "#4ba3b7",
        marginLeft: "10px",
        "&:hover": {
          textDecoration: "underline",
        },
      },
      "& .show-me-link": {
        marginLeft: "5px",
        background: "#4ba3b7",
        borderRadius: "6px",
        color: "#FFFFFF",
        textAlign: "center",
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.0075em",
        padding: "2px 8px",
        textTransform: "capitalize",
      },
      "& .table-menu": {
        display: "inline-block",
        "& .table-link": {
          padding: "0",
          textTransform: "none",
          font: "inherit",
          "& .MuiButton-endIcon": {
            marginLeft: "2px",
          },
        },
      },
      "& .table-link": {
        color: "#2b2b2b",
        textDecoration: "none",
        marginLeft: "10px",
        "&.delete-action-link":{
          "&:hover": {
            textDecoration: "underline",
          },
        },
        
      },
      "&.name-cell": {
        width: "18.6%",
        [customTheme.breakpoints.down("md")]: {
          width: "16.6%",
        },
      },
      "&.email-cell": {
        width: "22%",
        [customTheme.breakpoints.down("md")]: {
          width: "19%",
        },
      },
      "&.team-cell": {
        width: "26%",
        [customTheme.breakpoints.down("md")]: {
          width: "24%",
        },
      },
      "&.joindate-cell": {
        width: "14.4%",
        [customTheme.breakpoints.down("md")]: {
          width: "13.4%",
        },
      },
      "& .name-col": {
        display: "flex",
        alignItems: "center",
        "& .table-link": {
          marginLeft: "0",
        },
      },
      "& .profile-name": {
        width: "48px",
        height: "48px",
        borderRadius: "24px",
        backgroundColor: "#fafcfe",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textTransform: "uppercase",
        fontWeight: "700",
        marginRight: "12px",
      },
      "& .select-control": {
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          color: "#2b2b2b",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          paddingBottom: "0",
          paddingTop: "0",
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
      },
    },

    "& .action-link": {
      width: "14.6%",
      [customTheme.breakpoints.down("md")]: {
        width: "21.6%",
      },
      "&> a": {
        marginRight: "25px",
        "&:last-child": {
          marginRight: "0",
        },
      },
    },
  },
  dropDropdown: {
    "& .MuiMenu-paper": {
      minWidth: "156px",
      filter: "drop-shadow(0px 1px 2.5px rgba(0,0,0,0.2))",
      backgroundColor: "#fafafa",
      borderRadius: "8px",
      marginTop: "7px",
      boxShadow: "none",
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
      },
      "& .MuiList-padding": {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
  },
  dropdownStyle: {
    marginTop: "21px",
    maxWidth: "190px",
    width: "100%",
    "& .MuiMenuItem-root": {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0",
      color: "#000000",
      padding: "14px 17px",
      fontWeight: "500",
      fontFamily: "Expletus Sans",
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      whiteSpace: "normal",
    },
    "& .MuiList-padding": {
      paddingTop: "4px",
      paddingBottom: "8px",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  paginationToolbar: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #e8ecf2",
    padding: "4px 0px 5px 16px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 23px 7px 14px",
    },
    "& .member-count": {
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      color: "rgba(43,43,43,0.87)",
      fontWeight: "500",
      fontFamily: "Expletus Sans",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
        letterSpacing: "0",
        lineHeight: "16px",
        color: "#2B2B2B",
      },
      "& .delete-icon": {
        marginLeft: "23px",
      },
    },
  },
  paginationTable: {
    "& .MuiTablePagination-toolbar": {
      minHeight: "unset",
    },
    "& .MuiTablePagination-caption": {
      opacity: "0.671",
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      color: "#2b2b2b",
      fontWeight: "500",
      fontFamily: "Expletus Sans",
    },
    "& .MuiTablePagination-actions": {
      "& .MuiIconButton-root": {
        color: "#2b2b2b",
        padding: "6px",
        "&.Mui-disabled": {
          opacity: "0.369",
        },
      },
    },
    "& .MuiSelect-select": {
      fontFamily: "Expletus Sans",
    },
  },
}));

export interface ContactsData {
  memId: number;
  name: string;
  email: string;
  phone: string;
  group: any;
  company_name: string;
  business_category: string;
  actions: any;
}

export function createData(
  memId: number,
  name: string,
  email: string,
  phone: string,
  group: any,
  company_name: string,
  business_category: string,
  actions: any
): ContactsData {
  return {
    memId,
    name,
    email,
    phone,
    group,
    company_name,
    business_category,
    actions,
  };
}

export function formatDate(dateString: any) {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface GroupContactHeadProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order?: string;
  orderBy?: string;
  rowCount: number;
  hideActionColumn?: boolean;
  userRole: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof ContactsData;
  label: string;
  numeric: boolean;
}

export function GroupContactHead(props: GroupContactHeadProps) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    hideActionColumn,
    userRole,
  } = props;

  let headCells;
  headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Name" },
    { id: "email", numeric: false, disablePadding: false, label: "Email" },
    { id: "phone", numeric: false, disablePadding: false, label: "Phone" },
    {
      id: "group",
      numeric: false,
      disablePadding: false,
      label: "Group",
    },
    { id: "company_name", numeric: false, disablePadding: false, label: "Company Name" },
    { id: "buisness_category", numeric: false, disablePadding: false, label: "Business Category" },
  ];
  if (!hideActionColumn) {
    if (userRole !== "employee") {
      headCells.push({
        id: "actions",
        numeric: false,
        disablePadding: false,
        label: "Actions",
      });
    }
  }
  return (
    <TableHead>
      <TableRow>
        {!hideActionColumn && (
          <TableCell padding="checkbox">
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

interface TeamMemberToolbarProps {
  numSelected: number;
}

export const TeamMemberToolbar = (props: TeamMemberToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};
// Customizable Area End