// Customizable Area Start
import React from "react";
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    modalDialog: {
      "& .MuiBackdrop-root, & .backdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502) !important",
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "rgba(34, 89, 128, 0.7) !important",
        },
      }
    },
    rightModalDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("md")]: {
        width: 548,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        maxWidth: "100%",
        top: "197px",
        borderRadius: "24px 24px 0 0",
        height: "auto",
        maxHeight: "100%",
      },
      "&.filter-dialogbox": {
        "& .modal-heading": {
          marginBottom: "42px",
          "& .filter-heading": {
            display: "flex",
            alignItems: "center",
            "& .auth-back": {
              cursor: "pointer",
            },
            "& .modal-title ": {
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "19px",
              fontFamily: "Expletus Sans",
              marginLeft: "14px",
              color: "#2B2B2B",
            },
          },
          "& .right-block": {
            display: "flex",
            alignItems: "center",
            gap: "24px",
          },
          "& .filter-link": {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "19px",
            color: "#4BA3B7",
            fontFamily: "Expletus Sans",
            textDecoration: "none",
          },
        },
        "& .heading-block": {
          marginBottom: "46px",
          "& .primary-txt": {
            fontSize: "24px",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Expletus Sans",
            lineHeight: "32px",
            fontWeight: 400,
            marginBottom: "6px",
          },
          "& .sub-txt": {
            fontSize: "14px",
            color: "#94A3B8",
            lineHeight: "26px",
            marginBottom: "0",
            letterSpacing: "0.105px",
          },
        },
        "& .modal-form": {
          marginTop: "0",
          "& .form-info-wrapper": {
            marginBottom: "30px",
            "&:last-child": {
              marginBottom: "0",
            },
            "& .form-heading": {
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              marginBottom: "16px",
              letterSpacing: "0",
            },
            "& .form-control": {
              "& .MuiInputBase-input": {
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-root": {
                "&.MuiOutlinedInput-adornedEnd": {
                  paddingRight: "24px",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  width: "20px",
                  height: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                padding: "20px 24px",
                "& .MuiOutlinedInput-inputAdornedEnd": {
                  paddingRight: "0",
                },
              },
            },            
          },
          "& .switch-wrapper": {
            marginBottom: "12px",
            [theme.breakpoints.down("xs")]: {
              marginBottom: "30px",
            },
            "&:last-child": {
              marginBottom: "0",
            },
            "& .MuiFormControlLabel-root": {
              width: "100%",
              justifyContent: "space-between",
              marginLeft: "0",
              opacity: "1",
              color: "#2b2b2b",
            }
          }
        },
        "& .modal-description": {
          marginBottom: "44px",
        },
        "& .modal-footer": {
          "&.full-btn": {
            "& .MuiButton-root": {
              width: "100%",
            },
          },
        },
      },
      "&.favourite-product-dialog": {
        "& .modal-dialoginner": {
          flexDirection: "unset",
          flexWrap: "wrap",
          alignItems: "flex-start",
        },
        "& .modal-heading": {
          width: "100%",
          "& .close-icon": {
            marginLeft: "auto",
          },          
        },
        "& .modal-description": {
          marginBottom: "0",
          width: "100%",
        },
        "& .button-wrapper": {
          marginTop: "0",
        },
        "& .modal-footer": {
          marginTop: "auto",
          "& .MuiButton-root": {
            "&:last-child": {
              marginBottom: "0",
            },
          },
          "& .secondary-btn": {
            [theme.breakpoints.down("xs")]: {
              borderColor: "#4BA3B7",
              color: "#4BA3B7",
            },
          },
        },
      },
      "& .modal-dialoginner": {
        padding: "82px 60px",
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        [theme.breakpoints.down("md")]: {
          padding: "45px 60px 29px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "30px 24px",
        },
        "& .MuiCheckbox-root": {
          color: "#94A3B8",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
        "& .MuiRadio-root": {
          color: "#94A3B8",
          padding: "0",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "6px",
      },
      "& .modal-title": {
        fontSize: "24px",
        lineHeight: "32px",
        color: "rgba(43, 43, 43, 0.87)",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
      },
      "& .modal-description": {
        marginBottom: "20px",
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "26px",
        color: "#2B2B2B",
        letterSpacing: "0.0075em",
        fontWeight: 400,
        fontFamily: "Roboto",
        marginTop: "0",
        marginBottom: "48px",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "16px",
        },  
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#2B2B2B",
        fontWeight: "500",
        "& .modal-link": {
          marginLeft: "3px",
        },
      },      
      "& .button-wrapper": {
        marginTop: "auto",
        "&.full-btn": {
          "& .MuiButton-root": {
            width: "100%",
          },
        },
      },
      "& .select-control": {
        "&.multi-select": {
          "&.outline-control": {
            width: "100%",
            marginTop: "0",
            "& .multiselect-label": {
              display: "block",
              position: "static",
              transform: "none",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              marginBottom: "16px",
              letterSpacing: "0",
            },
            "& .MuiSelect-select": {
              fontFamily: "Roboto",
              fontWeight: 400,
              color: "#2B2B2B",
              padding: "18px 40px 18px 24px",
              lineHeight: "20px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
              },
            },
            "& .multiple-clear": {
              position: "absolute",
              fontSize: "15px",
              zIndex: 1,
              color: "#94A3B8",
              top: "calc(50% + 17px)",
              right: "27px",
              transform: "translateY(-50%)",
            },
          },
          "& .multiselect-label": {
            display: "none",
          },
          "& .MuiInput-formControl": {
            marginTop: "0",
          },
          "& .MuiSelect-select": {
            paddingRight: "40px",
            display: "flex",
            alignItems: "center",
            "& .select-icon": {
              marginRight: "6px",
            },
          },
          "& .multiple-clear": {
            position: "absolute",
            fontSize: "15px",
            right: "18px",
            top: "3px",
            zIndex: 1,
            color: "#94A3B8",
          },
        },
        "&.status-control": {
          borderRadius: "12px",
          background: "rgba(18, 174, 241, 0.07)",
          padding: "6px 12px",
          "& .down-arrow": {
            width: "16px",
            height: "16px",
          },
          "& .MuiSelect-select": {
            color: "#12AEF1",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.09px",
            opacity: "1",
            paddingRight: "5px",
          },
        },
        "& .select-icon": {
          position: "relative",
          marginRight: "6px",
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          color: "rgba(43, 43, 43, 0.87)",
          opacity: "0.671",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          paddingBottom: "0",
          paddingTop: "0",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .clear-icon": {
            width: "50px",
            height: "50px",
            background: "red",
          },
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
      },
      "& .modal-footer": {
        marginTop: "auto",
        display: "flex",
        justifyContent: "space-between",
        "& .half-btn": {
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          "& .MuiButton-root": {
            width: "auto",
            marginBottom: "0",
            marginRight: "10px",
            "&:last-child": {
              marginRight: "0",
            },
            "&.primary-btn": {
              padding: "12px 38px",
            },
            "&.secondary-btn": {
              minWidth: "140px",
            },
          },
        },
        "& .MuiButton-root": {
          marginRight: "24px",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
      "& .primary-btn": {
        minHeight: "56px",
        fontSize: "14px",
        lineHeight: "19px",
        minWidth: "156px",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px",
        }
      },
      "& .secondary-btn": {
        minHeight: "48px",
        fontSize: "14px",
        lineHeight: "19px",
        minWidth: "156px",
      },
      "& .template-list": {
        padding: "0",        
        "& .MuiListSubheader-gutters": {
          paddingLeft: "0",
          paddingRight: "0",
          position: "static",
          "& .form-control": {
            marginBottom: "0",
            "& .MuiInputAdornment-root": {
              "& .MuiSvgIcon-root": {
                fill: "#225980",
                fontSize: "20px",
              },
            },
            "& .MuiOutlinedInput-adornedStart": {
              paddingLeft: "16px",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              minHeight: "56px",
            },
            "& .MuiOutlinedInput-input": {
              fontSize: "13px",
              lineHeight: "17px",
              letterSpacing: "0",
              padding: "17px 16px 16px",
              "&::-webkit-input-placeholder": {
                color: "#94A3B8",
              },
              "&:-ms-input-placeholder": {
                color: "#94A3B8",
              },
              "&::placeholder": {
                color: "#94A3B8",
              },
              "&.MuiOutlinedInput-inputAdornedStart": {
                paddingLeft: "7px",
              },
            },
          },
        },
        "& .multiselect-membres": {
          display: "flex",
          alignItems: "center",
          width: "auto",
          maxWidth: "unset",
          justifyContent: "space-between",
          padding: "16px 0",
          margin: "0",
          borderBottom: "1px solid #ECF1F4",
          [theme.breakpoints.down("xs")]: {
            padding: "15px 0",
            margin: "0",
          },
          "& .total-member": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "19px",
            opacity: "0.67",
          },
          "& .select-control": {
            opacity: "0.67",
            "& .MuiSelect-select": {
              color: "#2b2b2b",
              opacity: "1",
              fontSize: "12px",
              letterSpacing: "0",
              paddingRight: "26px",              
            },
            "& .down-arrow": {
              color: "rgba(43, 43, 43, 0.67)",
            },
            "& .MuiSelect-icon": {
              top: "calc(50% - 11px)",
              color: "#2b2b2b",
              fontSize: "20px",
            },
          },
        },        
        "& .template-li": {
          display: "flex",
          justifyContent: "space-between",
          background: "transparent",
          position: "relative",
          padding: "21px 0",
          borderBottom: "1px solid #ECF1F4",
          cursor: "pointer",
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
          },
          "& .template-left": {
            "& .name": {
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
              marginBottom: "4px",
            },
            "& .sub-txt": {
              fontSize: "12px",
              lineHeight: "16px",
              color: "#94A3B8",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
              letterSpacing: "0",
              marginBottom: "0",
            },
          },
          "& .template-icon": {
            minWidth: "unset",
            marginLeft: "10px",
            "& .MuiCheckbox-root": {
              padding: "0",
            },
            "& .MuiIconButton-colorSecondary": {
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          },
        },
      },
      "& .tasklist-member": {
        padding: "0",
        "& .tasklist-li": {
          display: "flex",
          background: "transparent",
          justifyContent: "space-between",
          position: "relative",
          padding: "26px 24px 18px",
          borderBottom: "1px solid #ECF1F4",
          "& .tasklist-left": {
            display: "flex",
            alignItems: "center",
            "& .assignee-info": {
              marginLeft: "12px",
            },
            "& .assignee-profile": {
              display: "flex",
              width: "38px",
              height: "38px",
              background: "#ECF1F4",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Roboto",
              borderRadius: "100%",
              color: "rgba(43, 43, 43, 0.87)",
              fontSize: "15.485px",
              fontWeight: 600,
              letterSpacing: "-0.395px",
              lineHeight: "21.679px",
            },
            "& .assignee-name": {
              fontSize: "14px",
              lineHeight: "19px",
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
            },
            "& .assignee-email": {
              color: "#94A3B8",
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: 500,
              fontFamily: "Expletus Sans",
            },
          },
          "& .tasklist-icon": {
            minWidth: "unset",
          },
        },
      },
      "& .assignee-multiSelect-list": {
        padding: "0",
        marginBottom: "15px",
        "&.selected-list": {
          "& .MuiListItem-root": {
            background: "#F2F8FC",
          },
        },
        "& .search-filter": {
          display: "flex",
          alignItems: "center",
          gap: "24px",
          paddingBottom: "22px",
          borderBottom: "1px solid #ECF1F4",
          [theme.breakpoints.down("xs")]: {
            gap: "16px",
            paddingBottom: "0",
            borderBottom: "0",
          },
          "& .form-control": {
            width: "100%",
            marginBottom: "0",
            "& .MuiInputAdornment-root": {
              "& .MuiSvgIcon-root": {
                fill: "rgba(0,0,0,0.67)",
              }
            },
            "& .MuiOutlinedInput-root": {
              [theme.breakpoints.down("xs")]: {
                borderRadius: "12px",
              },
            }  
          },
          "& .filter-group": {
            width: "56px",
            height: "56px",
            minWidth: "56px",
            [theme.breakpoints.down("xs")]: {
              width: "48px",
              height: "48px",
              minWidth: "48px",
            },
            "& img": {
              width: "100%",
              height: "100%",
            }
          }
        },
        "& .MuiListSubheader-gutters": {
          position: "static",
          paddingRight: "0",
          paddingLeft: "0",
          [theme.breakpoints.down("xs")]: {
            paddingLeft: "24px",
            paddingRight: "24px",
            margin: "0 -24px",
          },
          "& .form-control": {
            marginBottom: "12px",
            "& .MuiOutlinedInput-root": {
              minHeight: "56px",
              [theme.breakpoints.down("xs")]: {
                minHeight: "48px",
              },
            },
            "& .MuiOutlinedInput-input": {
              padding: "16px",
              lineHeight: "17px",
              letterSpacing: "0",
              fontSize: "13px",
              "&.MuiOutlinedInput-inputAdornedStart": {
                paddingLeft: "0",
              },
            },
            "& .MuiInputAdornment-positionStart": {
              marginRight: "16px",
            },
            "& .MuiInputAdornment-root": {
              "& .MuiSvgIcon-root": {
                fontSize: "20px",
                fill: "#225980",
              },
            },
          },
          "&.multiselect-subheader": {
            color: "#2B2B2B",
            marginBottom: "0",
            borderBottom: "1px solid #ECF1F4",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "14px",
            fontFamily: "Expletus Sans",
            fontWeight: "500",
            lineHeight: "19px",
            display: "flex",
            padding: "19px 24px",
            [theme.breakpoints.down("xs")]: {
              margin: "0 -24px",
              width: "auto",
            },
            "& .tasklist-link": {
              textDecoration: "none",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "19px",
              whiteSpace: "nowrap",
              marginLeft: "10px",
              color: "#C7263E",
              fontFamily: "Expletus Sans",
            },
          },
          "&.multiselect-subtxt": {
            borderBottom: "1px solid #ECF1F4",
            textTransform: "uppercase",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "normal",
            fontFamily: "Roboto",
            padding: "10px 20px",
            color: "#94A3B8",
            [theme.breakpoints.down("xs")]: {
              margin: "0 -24px",
              width: "auto",
            },
          },
        },
        "& .multiselect-membres": {
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          padding: "16px 20px",
          borderBottom: "1px solid #ECF1F4",
          margin: "0",
          [theme.breakpoints.down("xs")]: {
            width: "auto",
            margin: "0 -24px",
          },
          "& .total-member": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "0.1px",
          },
          "& .select-control": {
            "&.sortby-control": {
              "& .MuiSelect-select": {
                paddingRight: "26px",
              },
              "& .MuiSelect-icon": {
                top: "calc(50% - 11px)",
                color: "#2b2b2b",
                fontSize: "20px",
              },
            },
            "& .MuiSelect-select": {
              color: "#2b2b2b",
              opacity: "1",
              fontSize: "14px",
              letterSpacing: "0",
            },
          },
        },
        "& .MuiListItem-root": {
          borderBottom: "1px solid #ECF1F4",
          cursor: "pointer",
          padding: "8px 20px 6px",
          minHeight: "80px",
          [theme.breakpoints.down("xs")]: {
            margin: "0 -24px",
            padding: "8px 24px 6px",
            width: "auto",
          },
        },
        "& .assignee-detail": {
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          display: "flex",
          "& .assignee-left": {
            display: "flex",
            flex: "1",
            alignItems: "center",
            "& .assignee-info": {
              marginLeft: "13px",
            },
            "& .assignee-email": {
              fontWeight: 500,
              lineHeight: "16px",
              fontFamily: "Expletus Sans",
              color: "#94A3B8",
              fontSize: "12px",
            },
            "& .assignee-name": {
              marginBottom: "2px",
              lineHeight: "19px",
              fontWeight: 500,
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
            },
            "& .assignee-profile": {
              letterSpacing: "-0.395px",
              fontWeight: 600,
              display: "flex",
              color: "rgba(43, 43, 43, 0.87)",
              justifyContent: "center",
              background: "#ECF1F4",
              alignItems: "center",
              borderRadius: "100%",
              fontFamily: "Roboto",
              height: "48px",
              fontSize: "15.485px",
              lineHeight: "21.679px",
              minWidth: "48px",
            },
            "& .chip-wrapper": {
              marginTop: "3px",
              "& .MuiChip-root": {
                height: "24px",
                marginBottom: "2px",
              },
            },
          },
          "& .assignee-right": {
            marginLeft: "10px",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            "& .hours-details": {
              color: "#000",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 600,
              lineHeight: "16px",
              letterSpacing: "0.09px",
              marginRight: "16px",
              textAlign: "right",
              [theme.breakpoints.down("md")]: {
                marginRight: "8px",
              },
              "& .gray-txt": {
                color: "#94A3B8",
                fontWeight: 400,
                marginTop: "4px",
              },
            },
            "& .assinee-selected": {
              width: "20px",
              height: "20px",
              "& .normal-img": {
                display: "none",
              },
              "& .selected-img": {
                display: "block",
              },
            },
            "& .assinee-unselected": {
              width: "20px",
              height: "20px",
              "& .normal-img": {
                display: "block",
              },
              "& .selected-img": {
                display: "none",
              },
            },
          },
        },
      },
      "& .favourite-product-wrapper": {
        textAlign: "center",
        "& .project-img": {
          marginBottom: "40px",
          [theme.breakpoints.down("xs")]: {
            maxWidth: "289px",
            margin: "0 auto 32px",
          },
        },
      }
    },
    modalDialogBox: {
      position: "absolute",
      left: "50%",
      top:"50%",
      transform: "translate(-50%,-50%)",
      maxWidth:"1075px",
      width: "100%",
      backgroundColor: "#ffffff",
      borderRadius: "12px",
      boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
      overflowY: "auto",
      height:"500px",
      // overflowX:"auto",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "90%",
      },
      [theme.breakpoints.down("xs")]: {
        // maxWidth: "90%",
      },
      "&.show-more-dialog": {
        left: "160px",
        transform: "translateY(-50%)",
        maxWidth:"1075px",
        [theme.breakpoints.down("md")]: {      
          left: "35px",
        },
        [theme.breakpoints.down("sm")]: {
          left: "50%",
          maxWidth: "90%",
          transform: "translate(-50%, -50%)",
        },
      },
      "& .link-title-outer": {
        "& .MuiList-root":{
          display:"flex",
          padding:"0",
          "& .MuiListItem-root":{
            display:"block",
            padding:"0",
            width:"auto",            
            "& + .MuiListItem-root":{
              paddingLeft:"10px",
            },
            "& a":{
              textDecoration:"none",
              color:"#94A3B8",
              fontFamily: "Roboto",
              fontWeight:"400",
              fontSize:"14px",
            },
            "& .title-enabled":{
              color:"#2B2B2B !important",
            }
          }
        }
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding:"14px 24px",
        borderBottom:"1px solid #E8ECF2",
        "& .search-products": {
          "& .MuiInputAdornment-positionStart": {
            marginRight: "6px"
          },
          "& .MuiSvgIcon-root": {
            color: "#94A3B8",
            fontSize: "18px",
          },
          "& .MuiInputBase-input": {
            fontSize: "14px",
            lineHeight: "20px",
            color: "#000000",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            letterSpacing: "0.1px",
            padding: "11px 0",
            "&::-webkit-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            },
            "&:-ms-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            },
            "&::placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            }
          }
        }
      },
      "& .modal-description":{
        padding:"25px",
        "& .link-title-outer": {
          "& .MuiList-root":{
            display: "flex",
            overflowX: "auto",
            flexWrap: "wrap",
            flexDirection: "column",
            height: "400px",
            whiteSpace: "nowrap",
            margin:"0 -20px -12px",
            "& .MuiListItem-root":{
              padding:"0 20px 12px !important",
              "& p":{
                color:"000000",
                fontWeight:"500",
                fontSize:"14px",
                fontFamily: "Roboto",
              },
              "& + .MuiListItem-root":{
                  paddingLeft:"0"
              }
            }
          }
        }
      },
      "& .modal-title": {
        fontWeight: 400,
        fontSize: "24px",
        lineHeight: "28px",
        fontFamily: "Expletus Sans",
        color: "rgba(43, 43, 43, 0.87)",
      },
      "& .heading-right": {
        display: "flex",
        alignItems: "center",
        gap: 16,
      },
      "& .clearLink": {
        fontFamily: "Expletus Sans",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "18px",
        color: "#4BA3B7",
        cursor: "pointer",
      },
      "& .close-icon": {
        color: "#94A3B8",
        lineHeight: "0"
      },
      "& .filterCategory-Box": {
        margin: "0",
        boxShadow: "none",
        padding: "24px 0",
        borderBottom: "1px solid #E8ECF2",
        "&:before": {
          display: "none",
        },
        "& .slider-block": {
          "& .categoryTitle": {
            marginBottom: "24px",
          },
          "& .slider-input": {
            display: "flex",
            alignItems: "center",
            "& .form-control": {
              marginBottom: 0,
              "& .MuiOutlinedInput-input": {
                padding: "15px 16px",
              }
            },
            "& .slider-middle": {
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "26px",
              letterSpacing: "0.0075em",
              color: "#94A3B8",
              padding: "0 16px",
              textTransform: "lowercase",      
            },    
          }, 
        },
        "& .slider-progress": {
          padding: "25px 16px",   
          "& .MuiSlider-markLabel": {
            top: "28px",
            color: "#94A3B8",
            fontFamily: "Roboto",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "12px",
            textTransform: "uppercase",
            display: "inline-flex",
            alignItems: "center",
            flexDirection: "column",
            "& span": {
              display: "block",
              marginBottom: "3px",
            },
          },
          "& .MuiSlider-mark": {
            width: "1px",
            height: "6px",
            borderRadius: "0",
            backgroundColor: "#94A3B8",
            bottom: "0",
          },
          "& .MuiSlider-root": {
            padding: "0",
            marginBottom: "0",
            color: "#4BA3B7",
          },               
          "& .MuiSlider-valueLabel": {
            color: "#4BA3B7",
            textAlign: "center",
            fontSize: "10px",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            lineHeight: "14px",
            letterSpacing: "0",
            top: "-20px",
            left: "calc(-50% - -4px)",
            "&:after": {
              content: "''",
              width: "0",
              height: "0",
              borderLeft: "3px solid transparent",
              borderRight: "3px solid transparent",
              borderTop: "4px solid #4BA3B7",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            },
            "& >span": {
              width: "21px",
              height: "18px",
              transform: "unset",
              borderRadius: "5px",
              "& >span": {
                color: "#ffffff",
                transform: "unset",
              },
            },
          },
          "& .MuiSlider-rail": {
            height: "6px",
            opacity: "1",
            backgroundColor: "#F2F8FC",
            borderRadius: "21px",
          },   
          "& .MuiSlider-track": {
            height: "6px",
            borderRadius: "21px",
            backgroundColor: "#4BA3B7",
            boxShadow: "none",
          },    
          "& .MuiSlider-thumb": {
            width: "20px",
            height: "20px",
            backgroundColor: "#E8ECF2",
            border: "3px solid #FFFFFF",
            marginLeft: "-8px",
            marginTop: "-8px",
            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
          }, 
        },   
        "& .categoryTitle": {
          fontSize: "14px",
          lineHeight: "20px",
          fontFamily: "Expletus Sans",
          fontWeight: 500,
          color: "rgba(43, 43, 43, 0.87)",
          letterSpacing: "0.1px",
          padding: "0",
          minHeight: "unset",
          "& .MuiAccordionSummary-content": {
            margin: "0",
          },
          "& .MuiIconButton-root": {
            padding: "0",
            color: "#94A3B8",
            marginRight: "0",
            "& .MuiSvgIcon-root": {
              width: "20px",
              height: "20px",
            }
          },          
        },
                          
        "& .MuiAccordionDetails-root": {
          padding: "3px 0 0",
          flexDirection: "column",
        }, 
        "& .expandIcon": {
          color: "#94A3B8"
        },
        "& .category-options-more": {
          fontSize: "14px",
          lineHeight: "18px",
          color: "#4BA3B7",
          fontFamily: "Roboto",
          fontWeight: 400,
          letterSpacing: "0.25px",
          paddingLeft: "27px",
          "&:hover": {        
            cursor: "pointer",
          },
        },
        "& .search-wrapper": {
          borderBottom: "1px solid #E8ECF2",
          marginBottom: "6px",
          "& .MuiInput-root": {
            width: "413px",
            maxWidth: "100%",
            [theme.breakpoints.down("sm")]: {
              width: "234px"
            },
            [theme.breakpoints.down("xs")]: {
              width: "100%"
            }
          },
          "& .MuiInputAdornment-positionStart": {
            marginRight: "6px"
          },
          "& .MuiSvgIcon-root": {
            color: "#94A3B8",
            fontSize: "16px",
          },
          "& .MuiInputBase-input": {
            fontSize: "12px",
            lineHeight: "16px",
            color: "#000000",
            fontWeight: "400",
            fontFamily: "Roboto",
            letterSpacing: "0.4px",
            padding: "11px 0",
            "&::-webkit-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            },
            "&:-ms-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            },
            "&::placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)"
            }
          }
        }
      },
      "& .filtersFormControl": {
        "&.disabled": {
          opacity: "0.37",
          pointerEvents: "none",
        },
        "& + .filtersFormControl":{
          marginLeft:"0",
        },
        "& .MuiFormControlLabel-root": {
          marginRight: "0",
          marginLeft: "-7px",
          "& .MuiFormControlLabel-label": {
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.25px",
          }
        },
        "& .MuiCheckbox-root": {
          color: "#94A3B8",
          padding: "7px",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
          },
        },     
      },
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "6px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#f2f8fc",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      maxWidth: "100%",
      minWidth: "170px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    secondaryButton: {
      background: "transparent",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      minWidth: "198px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    headerSection:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        padding:'16px 24px',
        "&.fixed-header": {
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          backgroundColor: "#FFF",
        },
        "& .backStyle":{
            display:'flex',
            alignItems:'center',
            fontSize: "16px",
            lineHeight: "24px",
            fontFamily: "Expletus Sans",
            cursor:'pointer',
            letterSpacing: "0.15px",
            color: "rgba(43, 43, 43, 0.87)",
        },
        "& .backIcon":{
            marginRight:'16px'
        },
        "& .editBtn": {
            borderRadius: "6px",
            border: "1px solid #ECF1F4",
            minHeight: "48px",
            fontSize: "14px",
            lineHeight: "19px",
            padding: "10px 16px",
            backgroundColor: "#fff",
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            textTransform: "capitalize",            
            fontWeight: 500,
            letterSpacing: "0",
            minWidth: "120px",
        },
    },
    heading: {
      fontWeight: 400,
      fontSize: "34px",
      lineHeight: "46px",
      color: "rgba(43, 43, 43, 1)",
      fontFamily: "Expletus Sans",
      letterSpacing: "0.25px",
      marginBottom: "24px",
    },
    itemImage:{
        marginLeft:'10vw',
    },
    priceTitle:{
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "28px",
      color: "#2B2B2B",
      fontFamily: "Expletus Sans",
      marginBottom:'48px',
      letterSpacing: "0",
    },
    box:{
        display:'flex',
        alignItems:'center',
        gap:'4vw',
        "& .featureText":{
            fontWeight: 500,
            fontSize: "0.6vw",
            lineHeight: "0.8vw",
            color: "rgba(43, 43, 43, 1)",
            opacity:'0.5',
            fontFamily: "Roboto",
            letterSpacing:'0.01vw',
        },
        "& .featureValue":{
            fontWeight: 400,
            fontSize: "0.7vw",
            lineHeight: "1vw",
            color: "rbga(43, 43, 43, 1)",
            fontFamily: "Roboto",
            marginRight:'1vw',
        }
    },
    stockBox:{
       border: '1px dashed #E8ECF2',
       borderRadius:'8px',
       padding:'5px 12px', 
       marginBottom: "28px",
       display: "inline-block",
       [theme.breakpoints.down("sm")]: {
        marginBottom: "36px",
      },
       "& .stockText":{
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "20px",
        color: "#94A3B8",
        fontFamily: "Roboto",
        letterSpacing: "0.0075em",
       },
       "& .valueBox":{
        display:'flex',
        alignItems:'center',
       },
       "& .valueText":{
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "19px",
        color: "#00BA88",
        fontFamily: "Expletus Sans",
        letterSpacing: "0",
        marginRight: '10px',
       },
       "& .editIcon":{
            width:"14px",
            height: "14px",
            color: "#94A3B8",
            marginLeft: 'auto',
            cursor:'pointer',
       },
    },
    buttonBox:{
      display:'flex',
      alignItems:'center',
      gap:'24px',  
      marginBottom: "48px",
      [theme.breakpoints.down("md")]: {
        marginBottom: "38px",
      },
      [theme.breakpoints.down("sm")]: {
        gap:'16px',  
      },
      "& .primary-btn": {
        minWidth: "300px",
        [theme.breakpoints.down("sm")]: {
          minWidth: "unset",
          flex: "1", 
        },
      },
      "& .secondary-btn": {
        minWidth: "164px",
        [theme.breakpoints.down("sm")]: {
          minWidth: "unset",
          flex: "1", 
        },
      }
    },
    infoBox:{
        width:'62%',
        margin:'2vw 0',
        padding: '1vw 2vw',
        borderRadius:'8px',
        border: '1px solid #E8ECF2',
        "& .title":{
            fontWeight:500,
            fontSize: "1vw",
            lineHeight: "1.4vw",
            padding: "0.8vw 0",
            color: "rgba(43, 43, 43, 1)",
            fontFamily: "Expletus Sans",
            textTransform: "capitalize",
        }
    },
    accordionBlock: {
        boxShadow: "none",
        borderRadius: "12px",
        border: "1px solid #E8ECF2",
        minHeight: "unset",
        padding: "23px 24px",    
        [theme.breakpoints.down("xs")]: {
          border: "0",
          borderBottom: "1px solid #E8ECF2",
          padding: "0 0 8px",
          borderRadius: '0',
        },    
        "&.MuiAccordion-root": {
          margin:'0 0 24px',
          [theme.breakpoints.down("xs")]: {
            margin:'0 0 16px',
          },
        },
        "&:before": {
          display: "none",
        },
        "& .accordion-heading": {
          minHeight: "unset",
          padding: "0",
          "& .title":{
            fontWeight:500,
            fontSize: "14px",
            lineHeight: "20px",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Expletus Sans",
            textTransform: "capitalize",
            letterSpacing: "0.1px",
          },
          "& .MuiAccordionSummary-content": {
            margin: "0",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
          },
          "& .MuiIconButton-root": {
            padding: "0",
            margin: "0",
            "& .MuiSvgIcon-root": {
              fontSize: "20px",
              color: "#2b2b2b",
            },
            "& .MuiTouchRipple-root": {
              display: "none",
            },
          },
        },
        "& .trophy-details-row": {
          display:'flex',
          alignItems: "center",
          gap:'12px',
          marginBottom:"16px",
          "& .trophy-img": {
            width: "187px",
            maxWidth: "100%",
            maxHeight: "229px",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            [theme.breakpoints.down("md")]: {
              width: "170px",
              maxHeight: "212px",
            },
            [theme.breakpoints.down("sm")]: {
              width: "136px",
              maxHeight: "185px",
            },
            [theme.breakpoints.down("xs")]: {
              width: "96px",
              maxHeight: "125px",
            },
            "& img": {
              maxHeight: "inherit",
            }
          },
          "& .trophy-details": {
            flex: "1",
            "& .infoDetailsBox": {
              [theme.breakpoints.down("xs")]: {
                flexWrap: "wrap",
              },
              "& .infoDetailcol": {
                [theme.breakpoints.down("xs")]: {
                  flex: "0 0 100%",
                  maxWidth: "100%",
                }
              }
            }
          }
        },
        "& .trophy-list-wrapper": {
          display: "flex",
          justifyContent: "center",
          margin:"0 -6px 24px",
          flexWrap: "wrap",
          "& .trophy-list-col": {
            padding: "0 6px",
            flex: "0 0 113px",
            maxWidth: "113px",
            "& .trophy-list-inner": {
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              borderRadius: "8px",
              overflow: "hidden",
              transition: "all 0.3s ease-in-out",
              "&:hover, &.active": {
                boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
              }
            },
            "& .trophy-img": {
              "& img": {
                height: "100%",
              }
            }
          }
        },      
        "& .certification-lists": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          "& .certification-icon": {
            width: "32px",
            marginRight: "7px",
            "& :last-child": {
              marginRight: "0",
            }
          }
        },
        "& .accordion-details": {
          padding: "0",
          marginTop: "16px",
          display:'block',
          [theme.breakpoints.down("xs")]: {
            marginTop: "21px",
          },
          "& .trophy-details": {
            display:'flex',
            flexWrap: "wrap",
            "& .infoDetailsBox": {
              width: "100%",
            }
          },
          "& .infoDetailsBox":{
            display:'flex',
            marginBottom:'16px',
            gap:'15px',
            [theme.breakpoints.down("xs")]: {
              gap:'8px',
            },
            "& .infoDetailcol": {
              flex: "0 0 33.33%",
              maxWidth: "33.33%",
              "&.col-full": {
                flex: "0 0 100%",
                maxWidth: "100%",
              },
              "&.col-sm2": {
                [theme.breakpoints.down("sm")]: {
                  flex: "0 0 50%",
                  maxWidth: "50%",
                },                
              }
            },
            "& .featureText":{
              fontWeight: 400,
              fontSize: "10px",
              lineHeight: "12px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              letterSpacing:'0',
              marginBottom:'8px',
              textTransform:'uppercase', 
            },
            "& .featureValue":{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              color: "#2b2b2b",
              fontFamily: "Roboto",
              letterSpacing: "0.25px",
            },
            "& .featureDesc":{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "17px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              letterSpacing: "0.0075em",
              marginTop: "8px",
            }
          },
        },
    },
    stockPopover: {
      borderRadius: "8px",
      background: "#fafafa",
      filter: "drop-shadow(0px 1px 2.5px rgba(0,0,0,0.2))",
      margin: "5px 0",
      padding: "19px 22px 22px",
      maxWidth: "327px",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "270px",
      },
    },
    popupBody:{
      "& .button-box":{
        display: "flex",
        borderTop: "1px solid #e8ecf2",
        marginTop: "9px",
        paddingTop: "16px",
        justifyContent: "flex-end",
      },
      "& .add-link":{
        color: "#4ba3b7",
        opacity: "0.671",
        fontSize: "14px",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        lineHeight: "18px",
        marginRight: "28px",
        letterSpacing: "0",
        textDecoration: "none",
        "&:last-child": {
          marginRight: "0",
        },
        "&.secondary-link": {
          color: "#2b2b2b",
        }
      },
      "& .stock-feild": {
         "&:before": {
            position:"absolute",
            content:" ",
            top:"0",
            left:"0",
            width:"1px",
            height:"5px",
            backgroundColor:"#000",
          },
          [theme.breakpoints.down("xs")]: {
            width: "100%"
          },
          "& .MuiOutlinedInput-input": {
            padding: "10px 0",
            fontSize: "14px",
            letterSpacing: "0px",
            lineHeight: "20px",
            color: "#000000",
            fontWeight: 500,
            fontFamily: "Expletus Sans",
          },
          "& .MuiFormLabel-root": {
            transform: "none",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-error": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
          "& .form-control": {
            marginBottom: "0",
          },
          "& .button-wrapper": {
            borderTop: "1px solid #e8ecf2",
            marginTop: "9px",
            paddingTop: "16px",
            justifyContent: "flex-end",
            "& .add-link": {
              marginRight: "28px",
              opacity: "0.671",
              fontSize: "14px",
              lineHeight: "18px",
              letterSpacing: "0",
              color: "#4ba3b7",
              fontWeight: 500,
              fontFamily: "Expletus Sans",
              textDecoration: "none",
              "&.secondary-link": {
                color: "#2b2b2b",
              },
              "&:last-child": {
                marginRight: "0",
              },
              "&:hover": {
                textDecoration: "underline",
              },
            },
          },
          "& .stock-qty input::placeholder":{
            color:"#2B2B2B",
            opacity:"1"
          }
      },
    },
    chemicalProductOuter: {
      maxWidth: "1232px",
      width: "100%",
      margin: "0 auto",
      padding: "64px 15px 50px",
      [theme.breakpoints.down("md")]: {
        maxWidth: "1181px",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "926px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "64px 24px 50px",
      },
      "& h1": {
        fontSize: "34px",
      },
      "& .chemical-product-main": {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexWrap: "wrap",
        [theme.breakpoints.down("xs")]: {
          maxWidth: "750px",
          margin: "0 auto",
        },

        "& .chemical-image-block": {
          flex: "0 0 43.4%",
          maxWidth: "43.4%",
          [theme.breakpoints.down("md")]: {
            flex: "0 0 53.4%",
            maxWidth: "53.4%",
          },
          [theme.breakpoints.down("sm")]: {
            flex: "0 0 42%",
            maxWidth: "42%",
          },
          [theme.breakpoints.down("xs")]: {
            flex: "0 0 100%",
            maxWidth: "100%",
            paddingBottom: "50px",
          },
          "& .image-wrapper": {
            maxWidth: "477px",
            marginBottom: "24px",
            "&:last-child": {
              marginBottom: "0",
            },
          },
        },
        "& .chemical-content-block": {
          flex: "0 0 56.6%",
          maxWidth: "56.6%",
          paddingLeft: "106px",
          position: "sticky",
          top: "81px",
          [theme.breakpoints.down("md")]: {
            flex: "0 0 46.6%",
            maxWidth: "46.6%",
            paddingLeft: "48px",
          },
          [theme.breakpoints.down("sm")]: {
            flex: "0 0 58%",
            maxWidth: "58%",
            paddingLeft: "32px",
          },
          [theme.breakpoints.down("xs")]: {
            flex: "0 0 100%",
            maxWidth: "100%",
            paddingLeft: "0",
            position: "static",
          },
          "& .place-name": {
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "26px",
            letterSpacing: "0.105px",
            marginBottom: "16px",
          },
          "& .ViewItem-stockBox-7": {
            width: "123px",
            padding: "8px",
            margin: "15px 0 20px",
            [theme.breakpoints.down("xs")]: {
              width: "100%",
              padding: "12px",
            },
            "& .stockText": {
              fontSize: "12px",
              lineHeight: "1",
            },
            "& .price-text": {
              fontSize: "18px",
              lineHeight: "1",
            },
            "& .valueBox": {
              justifyContent: "space-between",
              marginTop: "3px",
              [theme.breakpoints.down("xs")]: {
                marginTop: "8px",
              },
              "& p": {
                fontSize: "12px",
                lineHeight: "1",
              },
            },
          },
          "& .accordion-block": {
            boxShadow: "none",
            borderRadius: "12px",
            border: "1px solid #E8ECF2",
            minHeight: "unset",
            padding: "24px",
            margin: "0 0 24px 0",
            [theme.breakpoints.down("xs")]: {
              padding: "15",
            },
            "&:last-child": {
              margin: "0",
            },
            "&:before": {
              display: "none",
            },
            "& .accordion-heading": {
              minHeight: "unset",
              padding: "0",
              alignItems: "flex-start",
              "& .heading": {
                width: "100%",
                color: "#2B2B2B",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                lineHeight: "19px",
                fontWeight: 500,
                marginBottom: "0",
              },
              "& .sub-txt": {
                marginBottom: "0",
                color: "#94A3B8",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0.09px",
              },
              "& .MuiAccordionSummary-content": {
                margin: "0",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "flex-start",
              },
              "& .MuiIconButton-root": {
                padding: "0",
                margin: "0",
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  color: "#94A3B8",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              },
            },
            "& .accordion-details": {
              padding: "0",
              marginTop: "15px",
              "& .main-text": {
                color: "#2B2B2B",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "24px",
                wordWrap: "break-word",
                whiteSpace: "normal",  
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
              "& .table-heading": {
                color: "#94A3B8",
                fontFamily: "Roboto",
                fontSize: "10px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0.09px",
                padding: "0",
                textTransform: "uppercase",
                marginBottom: "6px",
                [theme.breakpoints.down("xs")]: {
                  marginBottom: "2px",
                },
                "&.last": {
                  padding: "15px 0 0",
                },
              },
              "& .content-main": {
                width: "100%",
                "& .content-grid": {
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "0 -15px -24px",
                  [theme.breakpoints.down("xs")]: {
                    margin: "0 -7px -7px",
                  },
                  "& .content-item": {
                    flex: "0 0 100%",
                    maxWidth: "100%",
                    padding: "0 12px 24px",
                    [theme.breakpoints.down("xs")]: {
                      padding: "0 7px 7px",
                    },
                    "&.three-col": {
                      flex: "0 0 33.33%",
                      maxWidth: "33.33%",
                    },
                    "&.two-col": {
                      flex: "0 0 50%",
                      maxWidth: "50%",
                    },
                  },
                },
              },
              
            },
          },
        },
      },
    },
    trophyTitle:{
      fontSize:'14px',
      fontFamily:"Expletus Sans",
      fontWeight:500,
      lineHeight: '20px',
      marginBottom: '16px',
      letterSpacing: '0.1px',
      color: 'rgba(43, 43, 43, 0.87)',
    }
});
// Customizable Area End