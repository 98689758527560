// Customizable Area Start
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Link from "@material-ui/core/Link";
import CloseIcon from "@material-ui/icons/Close";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { playStore, appStore, modalImg } from "./assets";
interface CustomInputProps {
  modalOpen: any;
  modalClose: any;
  modalType: any;
  modalHeading: string;
  termsPrivacyData?: string;
  setPrivacyNull?: ()=>void;
}

const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const breakpointsFull = {
  breakpoints: createBreakpoints({
    values: BREAKPOINTS,
  }),
};
const useStyles = makeStyles((theme) => ({
  downloadModal: {
    flex: "0 0 51%",
    maxWidth: "51%",
    padding: "0 15px",
    "& .auth-logo": {
      maxWidth: "162px",
      marginBottom: "90px",
    },
    "& .auth-inner": {
      paddingTop: "129px",
      "& .auth-left-row": {
        display: "flex",
        alignItems: "flex-end",
        flexWrap: "wrap",
        margin: "0 -10px",
        "& .col": {
          padding: "0 10px",
        },
        "& .col-left": {
          flex: "0 0 54%",
          maxWidth: "54%",
        },
        "& .col-right": {
          flex: "0 0 46%",
          maxWidth: "46%",
          paddingRight: "206px",
          "@media (max-width: 1536px)": {
            paddingRight: "95px",
          },
        },
      },
    },
    "& .auth-desc": {
      fontSize: "24px",
      lineHeight: "28px",
      color: "#000000",
      fontWeight: 400,
      marginBottom: "19px",
    },
    "& .auth-name": {
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0",
      color: "#94a3b8",
      fontWeight: 400,
    },
    "& .auth-img": {
      margin: "61px auto 0",
      maxWidth: "380px",
      width: "100%",
      height: "630px",
      overflow: "hidden",
      position: "relative",
      marginBottom: "-50px",
    },
    "& .copyright-txt": {
      fontSize: "12px",
      letterSpacing: "0",
      color: "#94a3b8",
      fontWeight: 400,
      fontFamily: "Expletus Sans",
      textAlign: "right",
      "& span": {
        color: "#2b2b2b",
      },
    },
  },
  primaryButton: {
    background: "#4ba3b7",
    border: "1px solid #4ba3b7",
    borderRadius: "7px",
    filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
    fontSize: "19px",
    lineHeight: "23px",
    color: "#ffffff",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    textTransform: "unset",
    width: "100%",
    padding: "12px 10px",
    minHeight: "56px",
    marginBottom: "30px",
    transition: "all 0.5s ease-in-out",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "20px",
      padding: "8px 6px",
      minHeight: "46px",
    },
    "&:hover": {
      background: "#4ba3b7",
      color: "#ffffff",
    },
  },

  modalDialogBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 580,
    backgroundColor: "#ffffff",
    padding: "49px 49px 25px",
    borderRadius: "24px",
    [theme.breakpoints.down("md")]: {
      padding: "35px 35px 20px",
      width: 520,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
      padding: "25px 25px 20px",
    },
    "&.privacyPolicyDialog": {
      width: 1023,
      overflowY: "auto",
      maxHeight: "90vh",
      "& .modal-heading": {
        marginBottom: "40px",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "28px",
        }
      },
      "& .modal-title": {
        fontSize: "24px",
        lineHeight: "28px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "20px",
          lineHeight: "24px",
        }
      },
      "& .modal-h3": {
        color: "#000000",
        fontSize: "16px",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        lineHeight: "22px",
        letterSpacing: "0",
        marginBottom: "8px",
      },
      "& .para": {
        color: "#000000",
        fontSize: "14px",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0",
        marginBottom: "26px",
      }
    },
    "& .modal-heading": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "34px",
      [theme.breakpoints.down("md")]: {
        marginBottom: "28px",
      },
    },
    "& .modal-title": {
      fontSize: "20px",
      lineHeight: "26px",
      color: "#000000",
      letterSpacing: "0",
      fontFamily: "Expletus Sans",
      fontWeight: 500,
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
        lineHeight: "24px",
      },
    },
    "& .close-icon": {
      color: "rgba(43, 43, 43, 0.87)",
      lineHeight: "0",
      marginLeft: "10px",
    },
    "& .modal-image": {
      marginBottom: "23px",
      lineHeight: "0",
      textAlign: "center",
      "& img": {
        [theme.breakpoints.down("md")]: {
          width: "260px",
        },
      },
    },
  },
  modalDialog: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(57,57,57,0.502)",
    },
  },
  downloadBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .store-btn": {
      lineHeight: "0",
      width: "50%",
      marginRight: "25px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "15px",
      },
      "&:last-child": {
        marginRight: "0",
      },
      "& img": {
        width: "100%",
      },
    },
  },
}));

const ModalWeb = (props: CustomInputProps) => {
  const { modalOpen, modalClose, modalType, modalHeading, termsPrivacyData,setPrivacyNull} = props;

  useEffect(()=>{
    return setPrivacyNull
  },[setPrivacyNull])
  const classes = useStyles();
  return (
    <Box className={classes.downloadModal}>
      <Modal
        open={modalOpen}
        onClose={modalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
      >        
        <Box className={`${modalType === "privacyPolicy" ? "privacyPolicyDialog" : ""} ${classes.modalDialogBox}`}>
          <Box className="modal-heading">
            <Typography className="modal-title" component="h2">
              {modalHeading}
            </Typography>
            <Link href="#" className="close-icon" onClick={modalClose}>
              <CloseIcon />
            </Link>
          </Box>
          {modalType === "privacyPolicy" && termsPrivacyData &&  <div dangerouslySetInnerHTML={{ __html: termsPrivacyData }} />}
          {modalType === "downloadApp" && <DownloadModalWeb />}
        </Box>
      </Modal>
    </Box>
  );
};
const DownloadModalWeb = () => {
  const classes = useStyles();
  return (
    <Box className="modal-description">
      <Box className="modal-image">
        <img src={modalImg} alt="modalImg" />
      </Box>
      <Box className={classes.downloadBtn}>
        <Link href="#" className="store-btn">
          <img src={appStore} alt="appStore" />
        </Link>
        <Link href="#" className="store-btn">
          <img src={playStore} alt="playStore" />
        </Link>
      </Box>
    </Box>
  );
};

export const PrivacyPolicy = () => {
  return (
    <Box className="modal-description">
      <Typography className="modal-h3" component="h3">
        What is a privacy policy?
      </Typography>
      <Typography className="para" component="p">
        A privacy policy is a legal document where you disclose what data you collect from users, how you manage the collected data and how you use that
        data. The important objective of a privacy policy is to inform users how
        you collect, use and manage the collected.
      </Typography>
      <Typography className="modal-h3" component="h3">
        Is the privacy policy generator free to use?
      </Typography>
      <Typography className="para" component="p">
      The Privacy Policy Generator (privacypolicygenerator.info) is a free
      generator of privacy policies for websites, apps & Facebook pages/app. You
      can use our free generator to create the privacy policy for your business.
      </Typography>
      <Typography className="modal-h3" component="h3">
        Why is a privacy policy important?
      </Typography>
      <Typography className="para" component="p">
      The most important thing to remember is that a privacy policy is required
      by law if you collect data from users, either directly or indirectly. For
      example, if you have a contact form on your website you need a privacy
      policy. But you will also need a privacy policy if you use analytics tools
      such as Google Analytics.
      </Typography>
      <Typography className="modal-h3" component="h3">
        Where do I put my privacy policy?
      </Typography>
      <Typography className="para" component="p">
      Usually, you can find privacy policies in the footer of a website. We
      recommend that you place your privacy policy in easy to find locations on
      your website.
      </Typography>
    </Box>
  );
};

export default ModalWeb;
// Customizable Area End
