import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../framework/src/Message";
// Customizable Area Start
import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./TeamsStyles.web";
import { toast } from "react-toastify";
import { memberMoved } from "../assets";
import i18n from "../../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation?: any;
  location?: any;
  id: string;
  classes:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  modalOpen: boolean;
  excludeMembersList: any;
  teamsList: any;
  isAddingToTeam: any;
  isMultipleSelected: boolean;
  isFiltering: boolean;
  isDeactivatedActive: boolean;
  selectedMemberIds: number[];
  selectedTeamVal: [];
  selectedRoleVal: [];
  selectedTitleVal: any[];
  filteredData: any;
  selected: number[];
  anchorEl: any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AddMembersTeamControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  fetchTeamApiCallId: string = "";
  excludeTeamMemberApiCallId: string = "";
  addMemberTeamAPICallId: string = "";
  filterMemberAPICallId: string="";
  userSessionData: any;
  userToken: any;
  userRole: any;
  labelwebTeamsTitle: string;
  labelwebTeamsSubTitle: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    // Customizable Area Start
    this.arrayholder = [];
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.userRole = this.userToken.data.attributes.job_type;
    this.labelwebTeamsTitle = configJSON.webTeamsTitle;
    this.labelwebTeamsSubTitle = configJSON.webTeamsSubTitle;
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      modalOpen: false,
      excludeMembersList: [],
      teamsList: [],
      isAddingToTeam: false,
      isMultipleSelected: false,
      isFiltering: false,
      isDeactivatedActive: false,
      selected: [],
      selectedMemberIds: [],
      selectedTeamVal: [],
      selectedRoleVal: [],
      selectedTitleVal: [],
      filteredData: [],
      anchorEl: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.fetchTeamApiCallId: {
            this.handleCreatedTeamResponse(responseJson);
            break;
          }
          case this.excludeTeamMemberApiCallId: {
            this.handleExcludeTeamResponse(responseJson);
            break;
          }
          case this.addMemberTeamAPICallId: {
            this.handleAddTeamResponse(responseJson);
            break;
          }
          case this.filterMemberAPICallId: {
            this.handleFilterMmemberResponse(responseJson);
            break;
          }
          default: {
            //statements;
            break;
          }
        }
      } else {
        console.log(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.createdTeamList();
    const langT = localStorage.getItem("lang") ?? "en"; 
    await (i18n as any).changeLanguage(langT);
    if (this.props.navigation.getParam("teamID") !== undefined) {
      this.excludeTeamList();
    }
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  handle_modalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handle_modalClose = () => {
    this.setState({ modalOpen: false });
    this.setState({ isAddingToTeam: false });
  };

  saveSelectedOption = (event: { target: { value: string } }, key: any) => {
    const newState = { [key]: event.target.value } as Pick<S, keyof S>;
    this.setState(newState, () => {
        this.filterMembersAPI();
    });
  };

  handleClearSelection = (key: any) => {
    const newVal:any[]=[]
    const newState = { [key]: newVal } as Pick<S, keyof S>;
    this.setState(newState, () => {
     this.filterMembersAPI();
    });
     
  };

  handledeActivateSwitch = () => {
    this.setState(
      { isDeactivatedActive: !this.state.isDeactivatedActive },
      () => {
        this.filterMembersAPI();
      }
    );
  };

  /*Network request functions*/
  createdTeamList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchTeamApiCallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webTeamsGetEndPoint
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodGet
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  excludeTeamList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.excludeTeamMemberApiCallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webExcludeTeamMemberEndPoint +
        this.props.navigation.getParam("teamID")
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodGet
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  filterMembersAPI = () => {
    const {
      selectedTeamVal,
      selectedRoleVal,
      selectedTitleVal,
     isDeactivatedActive,
    } = this.state;

    this.setState({ isFiltering: true });
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const baseURL = configJSON.webFilterMembers;
    const job_roleString = selectedRoleVal.map(role => `"${role}"`).join(',');
    const job_Type : any = [];
    const deActivateVal = isDeactivatedActive ? isDeactivatedActive : []
    if(selectedTitleVal.includes( "owner" )){
      job_Type.push(0)
    }
    if(selectedTitleVal.includes( "manager" )){
      job_Type.push(1)
    }
    if(selectedTitleVal.includes( "employee" )){
      job_Type.push(2)
    }
   
    let url = `${baseURL}&deactivated=${deActivateVal}`;
    if(selectedTeamVal.length>0){
      url+=`&choose_team=[${selectedTeamVal}]`
    }
    if(job_roleString != ""){
      url+=`&job_role=[${job_roleString}]`
    }
    if(job_Type.length>0){
      url+=`&job_type=[${job_Type}]`
    }

    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.filterMemberAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addSingleMemberToTeam = (memberId: any) => {
    this.setState({ isAddingToTeam: true });
   
      this.setState({ selectedMemberIds: memberId });  
    
    this.handle_modalOpen();
  }

  addMultipleMembersToTeam = () => {
    if (this.state.selectedMemberIds.length === 0) {
      this.createToastNotificationError("Select members to add to this team");
      return;
    }
    this.setState({ isAddingToTeam: true });
    this.handle_modalOpen();
  };

  multiSelectMembers = (selected: []) => {
    this.setState({ isMultipleSelected: true });
    this.setState({ selectedMemberIds: selected });
  };

  getSelectedRecords = (selectedRecords: number[]) => {
    this.setState({
      selectedMemberIds: selectedRecords,
    });
  };

  createToastNotification = (toastMesssage: string, toastIcon: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMesssage}</div>{" "}
        <a href="#" className="toast-link">
          undo
        </a>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        icon: () => <img src={toastIcon} alt="emptydata" />,
      }
    );
  };

  createToastNotificationError = (toastMesssage: string) => {
    toast.error(toastMesssage, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  addToTeamNetworkCall = () => {
    let membersSelectedIds;

    if (Array.isArray(this.state.selectedMemberIds)) {
        membersSelectedIds = this.state.selectedMemberIds?.map((id) => ({ id }));
    } else {
        membersSelectedIds = [{ id: this.state.selectedMemberIds }];
    }
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    
    const httpBody = {
      account: membersSelectedIds,
      team_id: this.props.navigation.getParam("teamID"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addMemberTeamAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webAddMemberTeamEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleExcludeTeamResponse = (responseJson: { errors: {}; data: {} }) => {
    if (responseJson.errors) {
      return;
    }

    this.setState({ excludeMembersList: responseJson.data });
  };

  handleCreatedTeamResponse = (responseJson: { errors: []; data: [] }) => {
    const { errors, data } = responseJson;
    if (errors) {
      this.createToastNotificationError(
        "An error has occured. Please try again later."
      );
      return;
    }
    this.setState({ teamsList: data });
  };

  handleAddTeamResponse = (responseJson: {
    error: { message: string };
    data: [];
  }) => {
    const { error, data } = responseJson;
    const membersLength = data.length;
    if (error) {
      this.createToastNotificationError(responseJson.error.message);
      return;
    }

    this.createToastNotification(`${membersLength} members added`, memberMoved);
    this.handle_modalClose();
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AccountCreation");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
    this.excludeTeamList();
    
  };

  handleFilterMmemberResponse = (responseJson: {
    errors: [];
    user: { data: [] };
    members: { data: [] };
  }) => {
    
    let emptyUser: any[] = [];
    let user: any | undefined  = responseJson?.user?.data;
    let members: any[] | undefined = responseJson?.members?.data;
    if (user!== undefined) {
      emptyUser.push(user);
    }
    
    if (members!== undefined && members.length > 0) {
      emptyUser.push(...members);
    }
    this.setState({ filteredData: emptyUser });
  }
  // Customizable Area End
}
