// Customizable Area Start
import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { WithStyles } from "@material-ui/core/styles";
import { CardMedia } from "@material-ui/core";
import { customStyles } from "./ContactsStyles.web";
import { toast } from "react-toastify";
import { Box } from "@material-ui/core/";
import i18n from "../../../web/src/utilities/i18n";
import { warningIcon , bottle, deleteOutline, noteActionIcon} from "./assets";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  isMobile?: boolean;
  classes: any;
  contactDetails: any;
  isShowViewContactModal: boolean;
  handleOpenAddGroupToContact?:()=> void;
  handleMergeContactOpen?:()=> void;
  handleCloseViewContactModal:()=> void;
  handleSelectContactsOpen:() => void;
  getLatestDetails: (contactId:string) => void;
}

interface S {
  newStatus: any;
  checked: number;
  chooseGroupChecked: any[];
  
  showMoreGroups: boolean;
  noteMenuAnchor: any;
  isShowEditContact: boolean;
  noteUpdateId: string;
  isShowAddNote: boolean;
  selectedNote: string;
  isShowEditNote: boolean;
  isShowDeleteNoteConfirmation: boolean;
  delModalOpen: boolean;
  isDeleteContact: boolean;
  delContactDetails: {
    lastName: string,
    firstName: string,
    id: string,
  };
  contactDetails:any,
  viewEditDetails:any;
  isMergeContact: boolean;
  notes:string;
  isAddContactGroup: boolean;
  noteViewId:string
  notesData:any;
  isLoading: boolean;
  viewContactTabsValue: number;
  sortValueDeals: string;
  sortValueNotes: string;
  sortValueFavPRoduct: string;
  isActiveChooseStage: boolean;
  currentDealStage: {
    currentdealId: string;
    currentdealStage: number | undefined;
  };
  addDealNote: string;
  undoFavProductId:any;
  favProduct: boolean;
  createDealPopoverShown:boolean;
  createCampaignPopover: boolean;
  anchorElDeal: any;
  anchorElCampaign:any;
  selectedProductIds: any;
  prevFavProducts: any;
}
interface SS {
  id: any;
}

export default class ViewContactController extends BlockComponent<
  Props,
  S,
  SS
> {
  deleteContactAPI: string = "";
  fileRef: React.RefObject<HTMLInputElement>;
  formRef: React.RefObject<HTMLFormElement>;
  userToken: any;
  userId:any;
  userSessionData: any;
  userRole: any;
  getAllNotesApiIDAction:string="";
  getContactlistDataApiIDAction:string="";
  getEditContactAPICallId:string="";
  createNotesApiIDActionAction:string="";
  updateNoteAPIAction:string="";
  deleteNoteAPIAction:string="";
  updateDealStatusRequestId:string="";
  deleteContactFavProductAPIID:string="";
  addAsFavProductApiRequestId:string="";
  undoEditContactAPICallId:string="";
  undoNotesContactAPICallId:string="";
  undoADdFavProductAPICallId:string="";
  undoRemoveFavProductAPICallId:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.fileRef = React.createRef();
    this.formRef = React.createRef();

    this.state = {
      checked: 1,
      viewContactTabsValue: 0,
      showMoreGroups: false,
      newStatus: null,
      chooseGroupChecked: [0],
      noteMenuAnchor: null,
      noteUpdateId: "",
      isShowAddNote: false,
      isShowEditContact: false,
      isShowEditNote: false,
      isShowDeleteNoteConfirmation: false,
      selectedNote: "",
      isDeleteContact: false,
      delModalOpen: false,
      delContactDetails: {
        lastName: "",
        firstName: "",
        id: "",
      },
      isMergeContact: false,
      isAddContactGroup: false,
      notes:"",
      contactDetails:{},
      viewEditDetails:{},
      noteViewId:"",
      notesData: [],
      isLoading: false,
      sortValueDeals: "",
      sortValueNotes: "",
      sortValueFavPRoduct: "",
      isActiveChooseStage: false,
      currentDealStage: {
        currentdealId: "",
        currentdealStage: 0,
      },
      addDealNote: "",
      undoFavProductId:"",
      favProduct: false,
      createDealPopoverShown:false,
      createCampaignPopover: false,
      anchorElDeal: null,
      anchorElCampaign: null,
      selectedProductIds: [],
      prevFavProducts: this.props.contactDetails,
		}
    this.userSessionData = sessionStorage.getItem("userData");
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.userToken = JSON.parse(this.userSessionData);
    this.userRole = this.userToken.data.attributes.job_type;
    this.userId = this.userToken.data.id;
	}
  async componentDidMount() {
    this.getAllNotes();
    this.getContactDetails();
    const lang = localStorage.getItem("lang") ?? "en";
    await i18n.changeLanguage(lang);
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }

  componentDidUpdate(prevProps:any) {
    // Update state if the prop changes and the state is outdated
    //console.log(prevProps.contactDetails)
    if (
      prevProps.contactDetails.attributes.contact_products !==
      this.props.contactDetails.attributes.contact_products
    ) {
      const contactProducts = prevProps.contactDetails.attributes.contact_products;
      const productIds = contactProducts.map((item:any) => item.product.data.id);
     this.setState({ prevFavProducts: productIds });
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.error) {
          console.log("error")
      }else {
        switch (apiRequestCallId) {
          case this.deleteContactAPI:
            this.deleteContactResponse(responseJson);
            break;
        
          case this.getContactlistDataApiIDAction:
            this.viewContactResponse(responseJson);
            break;
        
          case this.getEditContactAPICallId:
            this.viewEditResponse(responseJson);
            break;
        
          case this.getAllNotesApiIDAction:
            this.setNotesList(responseJson.data);
            break;
        
          case this.createNotesApiIDActionAction:
            console.log(responseJson.data.id)
            this.handleHideAddNote();
            this.toastMessageNotes(configJSON.noteCreated, noteActionIcon,"create", responseJson.data.id )
            this.setState({ notes: "" });
            this.getAllNotes();
            break;
        
          case this.deleteNoteAPIAction:
            this.onHideDeleteNoteConfirmation();
            this.toastMessageNotes(configJSON.noteDeleted, noteActionIcon,"delete",this.state.noteViewId)
            this.getAllNotes();
            break;
        
          case this.updateNoteAPIAction:
            this.toastMessageNotes(configJSON.noteEdited, noteActionIcon,"update",this.state.noteUpdateId)
            this.onHideEditNote();
            this.getAllNotes();
            break;
        
          case this.updateDealStatusRequestId:
            this.setState({ isLoading: false });
            this.chooseStageModalCloseEvent();
            this.getContactDetails();
            this.props.getLatestDetails(this.props.contactDetails.id)  
            break;
          case this.deleteContactFavProductAPIID:
            this.props.getLatestDetails(this.props.contactDetails.id)  
            this.getContactDetails()
            this.toastMessageUndo("Favorite Product Removed",bottle, this.undoRemoveFavProducts)
            this.setState({ isLoading: false });
          break;
          case this.addAsFavProductApiRequestId:
            this.toastMessageUndo(responseJson.message, bottle, this.undoAddFavProducts); 
            this.props.getLatestDetails(this.props.contactDetails.id)       
            this.getContactDetails()  
            this.setState({ isLoading: false });
            break;
          case this.undoEditContactAPICallId:
            case this.undoADdFavProductAPICallId:
            case this.undoRemoveFavProductAPICallId:
            this.getContactDetails();
            this.props.getLatestDetails(this.props.contactDetails.id)  
            this.setState({ isLoading: false });
            break;
          case this.undoNotesContactAPICallId:
            this.getAllNotes();
            this.setState({ isLoading: false, noteUpdateId: "" });
            break;
          default:
            // Handle unknown apiRequestCallId cases, if needed
            break;
        }
        
      }
      
    }
  }
  
  viewContactResponse = (response: any) => {
    this.setState({isLoading : false})
    this.setState({contactDetails:response.data})
  }
  viewEditResponse = (response:any) => {
    this.setState({ isShowEditContact: true })
    this.setState({isLoading : false})
    this.setState({viewEditDetails:response.data})
    
  }

  setNotesList = (response:any)=>{
    this.setState({notesData: response})
  }

  createToastNotification = (toastMesssage: string) => {
    toast.error(toastMesssage, {
      position: toast.POSITION.TOP_CENTER,
      icon: () => (
        <CardMedia component="img" src={warningIcon} alt="emptydata" />
      ),
    });
  };

  toastMessageUndo = (toastMesssage: string, toastIcon: string, undoHandler:any) => {
    toast.success(
      <Box className="toast-notification">
        <Box className="notification-txt">{toastMesssage}</Box>
        <a href="#" data-test-id="undoApi" className="toast-link" onClick={undoHandler}>
          {this.t("undo")}
        </a>
      </Box>,
      {
        position: toast.POSITION.TOP_CENTER,
        icon: () => (
          <CardMedia component="img" src={toastIcon} alt="emptydata" />
        ),
      }
    );
  }
  toastMessageNotes = (toastMesssage: string, toastIcon: string,actionType:string, id:string) => {
    toast.success(
      <Box className="toast-notification">
        <Box className="notification-txt">{toastMesssage}</Box>
        <a href="#" data-test-id="undoApi" className="toast-link" onClick={() => {this.undoNotesAction(actionType, id)}}>
          {this.t("undo")}
        </a>
      </Box>,
      {
        position: toast.POSITION.TOP_CENTER,
        icon: () => (
          <CardMedia component="img" src={toastIcon} alt="emptydata" />
        ),
      }
    );
  }
  createToastNotificationSuccess = (toastMesssage: string, toastIcon: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMesssage}</div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        icon: () => <img src={toastIcon} alt={toastIcon} />,
      }
    );
  };

  setViewContactTabsValue = (obj: any, val: any) => {
    this.setState({
      viewContactTabsValue: val,
    });
  };

  toggleShowGroups = () => {
    this.setState({ showMoreGroups: !this.state.showMoreGroups });
  };

  stageStatusClassMaping = (dealStageStatus: string) => {
    if (dealStageStatus === "Initiated") {
      return <Box className="status sky-blue"></Box>;
    } else if (dealStageStatus === "sent proposal") {
      return <Box className="status orange"></Box>;
    } else if (dealStageStatus === "follow up") {
      return <Box className="status yellow"></Box>;
    } else if (dealStageStatus === "won") {
      return <Box className="status green"></Box>;
    } else if (dealStageStatus === "lost") {
      return <Box className="status black"></Box>;
    }
  };

  formatCreatedDate = (inputDate: any): React.ReactNode => {
		const date = new Date(inputDate);
		
		// Define month names
		const months = [
			"Jan", "Feb", "Mar", "Apr",
			"May", "Jun", "Jul", "Aug",
			"Sep", "Oct", "Nov", "Dec"
		];
		
		// Extract date components
		const day = date.getDate();
		const month = months[date.getMonth()];
		const year = date.getFullYear();
		
		// Format the date as "Month Day, Year"
		const formattedDate = `${month} ${day}, ${year}`;
		
		return formattedDate;
	}

  formatNoteDate = (dateString: any): React.ReactNode => {
    const date = new Date(dateString);
    const months = [
			"Jan", "Feb", "Mar", "Apr",
			"May", "Jun", "Jul", "Aug",
			"Sep", "Oct", "Nov", "Dec"
		];
    const day = date.getDate();
		const month = months[date.getMonth()];

    const timePart = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
    }).format(date);
    
    return `${day} ${month} ${date.getFullYear()} ${timePart.toLowerCase()}`;
  };

  deleteContactResponse = (response: any) => {
    this.createToastNotificationSuccess(this.t(configJSON.deleteContactSuccessMsg), deleteOutline);
    this.closeDeleteModalAction();
    this.props.handleCloseViewContactModal();
    
  }

  handleOpenDeal = (
    event: React.MouseEvent<HTMLButtonElement> | null
  ) => {
    localStorage.removeItem("isInteraction");
    if (this.state.createDealPopoverShown) {
      return;
    }
    if (event) {
      this.setState({
        anchorElDeal: event.currentTarget,
        createDealPopoverShown: true,
      });
    }
  };

  openCreateCampaigns = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    if (event) {
      this.setState({
        anchorElCampaign: event.currentTarget,
        createCampaignPopover: true,
      });
    }
  }

  closeCreateCampaigns = () => {
    this.setState({
      anchorElCampaign: null,
      createCampaignPopover: false,
    });
  }

  handleCloseDeal = () => {
    this.setState({ anchorElDeal: null,  createDealPopoverShown: false});
    const isInteraction= localStorage.getItem("isInteraction");
    if(isInteraction==="true"){
      this.getContactDetails()
    }
  };

  onEditContact = () => {
    this.viewEditData()
  };

  onCloseEditContact = () => {
    this.setState({ isShowEditContact: false });
    this.getContactDetails();
  };
  handleSortChangeNotes = (event: any) => {
    this.setState({sortValueNotes:event.target.value as string});
  };
  handleOpenNoteMenu = (event: React.MouseEvent<HTMLButtonElement>,id:string,content:string) => {
    this.setState({selectedNote:content})
    this.setState({noteViewId:id})
    this.setState({noteUpdateId:id})
    this.setState({ noteMenuAnchor: event.currentTarget });
  };

  handleCloseNoteMenu = () => {
    this.setState({ noteMenuAnchor: null, noteUpdateId: "" });
  };

  handleShowAddNote = () => {
    this.setState({ isShowAddNote: true });
  };

  handleHideAddNote = () => {
    this.setState({notes:""})
    this.setState({ isShowAddNote: false });
  };

  onShowEditNote = (noteId: string, content:string) => {
    this.setState({ noteMenuAnchor: null,selectedNote: content, isShowEditNote: true, noteUpdateId: noteId });
  };

  onHideEditNote = () => {
    this.setState({ isShowEditNote: false, noteMenuAnchor: null, noteUpdateId: "" });
  };

  handleCloseMenuAnchor = () => {
    this.setState({ noteMenuAnchor: null, noteUpdateId: "" });
  }

  onUpdateNote = (note: string) => {
    this.setState({ selectedNote: note});
  };

  onShowDeleteNoteConfirmation = () => {
    this.setState({ isShowDeleteNoteConfirmation: true, noteMenuAnchor: null });
  };

  onHideDeleteNoteConfirmation = () => {
    this.setState({ isShowDeleteNoteConfirmation: false });
  };
  
    handleStageChangeEvent = (event: any) => {
      if (event.target.value != undefined) {
        this.setState({ newStatus: event.target.value });
      }
    };

  handleInputChange = (event:any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  chooseStageHandlerEvent = (dealId: string, dealStage: string, dealNote: string) => {
    this.setState({ isActiveChooseStage: true });
    let dealStausValue;
    if (dealStage === "Initiated") {
      dealStausValue = 0;
    } else if (dealStage === "sent proposal") {
      dealStausValue = 1;
    } else if (dealStage === "follow up") {
      dealStausValue = 2;
    } else if (dealStage === "won") {
      dealStausValue = 3;
    } else if (dealStage === "lost") {
      dealStausValue = 4;
    }   
    this.setState({
      currentDealStage: {
        currentdealStage: dealStausValue,
        currentdealId: dealId,
      },
      addDealNote: dealNote,
      newStatus : dealStausValue,
    
    });
  };

  chooseStageModalCloseEvent = () => {
    this.setState({ isActiveChooseStage: false });
  };

  dealNoteHandlerEvent = (dealNote: string) => {
    this.setState({ addDealNote: dealNote });
  };
  

	formatCreatedDatee = (inputDate: Date) => {
		const date = new Date(inputDate);
		
		// Define month names
		const months = [
			"Jan", "Feb", "Mar", "Apr",
			"May", "Jun", "Jul", "Aug",
			"Sep", "Oct", "Nov", "Dec"
		];
		
		// Extract date components
		const day = date.getDate();
		const month = months[date.getMonth()];
		const year = date.getFullYear();
		
		// Format the date as "Month Day, Year"
		const formattedDate = `${month} ${day}, ${year}`;
		
		return formattedDate;
	}
  formatNoteDatee = (dateString: any) => {
    const date = new Date(dateString);
    const months = [
			"Jan", "Feb", "Mar", "Apr",
			"May", "Jun", "Jul", "Aug",
			"Sep", "Oct", "Nov", "Dec"
		];
    const day = date.getDate();
		const month = months[date.getMonth()];

    const timePart = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
    }).format(date);
    
    return `${day} ${month} ${date.getFullYear()} ${timePart.toLowerCase()}`;
  };
  deleteContactEventAction = () => {
    this.setState({
      isDeleteContact: true,
      delContactDetails: {
        lastName: this.props.contactDetails.last_name,
        firstName: this.props.contactDetails.first_name,
        id: this.props.contactDetails.id,
      },
      delModalOpen: true
    });
  };

  closeDeleteModalAction = () => {
    this.setState({
      delModalOpen: false,
      isDeleteContact: false,
      delContactDetails: {
        firstName: "",
        lastName: "",
        id: "",
      },
    });
  }

  deleteContactAction = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    let endpoint = `${configJSON.viewContactsPath}/${this.props.contactDetails.id}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteContactAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  viewEditData = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = `${configJSON.contactsPath}/${this.props.contactDetails.id}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEditContactAPICallId = requestMessage.messageId;
    

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getContactDetails = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = `${configJSON.contactsPath}/${this.props.contactDetails.id}?token=${this.userToken.meta.token}`;
    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactlistDataApiIDAction = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    this.setState({isLoading: true})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAllNotes = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllNotesApiIDAction = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notesEndpoint}?contact_id=${this.props.contactDetails.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createNotess = () => {
    const header = {
      token: this.userToken.meta.token,
      'Content-Type': 'application/json',
    };
    const body={
      contact_id: this.props.contactDetails.id,
      note: {
          content: this.state.notes
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNotesApiIDActionAction = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notesEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateNoteActionn = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    let endpoint = `${configJSON.notesEndpoint}/${this.state.noteUpdateId}`;
    const body ={
      "contact_id": this.props.contactDetails.id,
      "note": {
          "content": this.state.selectedNote
      }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateNoteAPIAction = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteNoteActionn = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    let endpoint = `${configJSON.notesEndpoint}/${this.state.noteViewId}?contact_id=${this.props.contactDetails.id}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteNoteAPIAction = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateDealStageEvent = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const { newStatus, currentDealStage, addDealNote } = this.state;

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateDealStatusRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateDealStatus +
        `id=${currentDealStage.currentdealId}&stage=${newStatus}&note=${addDealNote}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPATCH
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleRemoveProduct = (id:any) => {
    this.setState({undoFavProductId:id})
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body={
      contact_id: this.state.contactDetails.id,
      product_id: id
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteContactFavProductAPIID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeFavProductContactsPath}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOpenFavProduct=()=>{
    this.setState({
      favProduct: true,
    });
  }

  handleCloseFavPRoduct = () => {
    this.setState({
      favProduct: false,
    });
  }

  addAsFavProduct = (productsSelected:any,productQuantities:any) => {
    this.setState({
      selectedProductIds : productsSelected,
    })
   
    const formattedProducts = productsSelected.map((productId:any, index:any) => {
      const quantity = productQuantities[productId];
  
      return {
          id: productId , 
          quantity: quantity ?? 1,
          price: {
            price_id: 1,
            amount: 0
          }
      };
  });

    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json",
    };
    const body={
      "contact": {
        
          "contact_products": {
              "products": formattedProducts,
          }
      }
   }

   

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addAsFavProductApiRequestId = requestMessage.messageId;  

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addAsFavPRoductAPiEndpoint}/${this.props.contactDetails.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  undoEditAction = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
  
    
    const body = {
      "id": this.props.contactDetails.id,
      "action_type": "update"
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.undoEditContactAPICallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.undoEditContact
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    this.setState({isLoading:true})

    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 
  undoNotesAction = (actionType:string, id:string) => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
  
    
    const body = {
      "action_type" : actionType,
      "id": id
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.undoNotesContactAPICallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.undoNotes
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    this.setState({isLoading:true})

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  undoAddFavProducts = () => {
    const contactProducts = this.props.contactDetails.attributes.contact_products;
    const productIds = contactProducts.map((item:any) => item.product.data.id);
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
  
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.undoADdFavProductAPICallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.undoAddFavProducts}/${this.props.contactDetails.id}/undo_added_fav_products?product_ids=[${this.state.prevFavProducts}]`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    this.setState({isLoading:true})

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  undoRemoveFavProducts = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const body = {
      "action_type" : "delete",
    "product_id": this.state.undoFavProductId
    }
  
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.undoRemoveFavProductAPICallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.undoRemoveFavProducts}/${this.props.contactDetails.id}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    this.setState({isLoading:true})

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
// Customizable Area End
