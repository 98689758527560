// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Button,
  InputAdornment,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import { close} from "../assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import { configJSON } from "../GroupListController.web";

interface GroupContactsProps {
  classes: any;
  modalOpen: boolean;
  modalClose: () => void;
  searchContactKeyword: string;
  handleSearchContact: (text: string) => void;
  contacts:any;
  title?:string;
  subTitle?:string;
  groupSingleData?:any;
  editGroupEvent:()=>void;
  contactIds?:any;
  isSearching?:boolean;
  searchedContacts:any;
  handleSortContact:(e:any)=>void;
  isSorting?:boolean;
  sortedContacts:any;
  t:any;
  actionType?:string;
}

const GroupContacts = (props: GroupContactsProps) => {
  const {
    classes,
    modalOpen,
    modalClose,
    searchContactKeyword,
    handleSearchContact,
    contacts,
    title,
    subTitle,
    editGroupEvent,
    contactIds,
    isSearching,
    searchedContacts,
    handleSortContact,
    isSorting,
    sortedContacts,
    t,
    actionType,
  } = props;

  const [selectedItemIds, setSelectedItemIds] = useState<any[]>(contactIds?.map((id:any) => String(id)));
  const [selectedItemData, setSelectedItemData] = useState([]);
  const [contactData, setContactData] = useState(contacts);
  const retrievedArrayLength : any = JSON.parse(localStorage.getItem("contactIds") ?? '[]').length;
  // localStorage.setItem("existingcontactIds",contactIds?.map((id:any) => String(id)));
  if(actionType === "create"){

  useEffect(() => {
    if (retrievedArrayLength === 0) {
      setSelectedItemData([]); 
      setSelectedItemIds([])
    }
  }, [retrievedArrayLength]);
}

  useEffect(() => {
    if (isSearching) {
      const filteredContacts = searchedContacts.filter((contact:{id:string}) => !selectedItemIds?.includes(contact.id));
      setContactData(filteredContacts);
    }else{
      setContactData(contacts);
    }
   },[searchedContacts,contacts,isSearching]);

   useEffect(() => {
    if(isSorting){
      const filteredContacts = sortedContacts.filter((contact:{id:string}) => !selectedItemIds?.includes(contact.id));
      setContactData(filteredContacts);
    }else{
      setContactData(contacts);
    }
   },[contacts,sortedContacts,isSorting]);

  const handleToggleSelection = (itemId:any) => {  
    setSelectedItemIds((contactIds || []).map((id:any) => (id !== null ? `${id}` : id))) 
    const updatedSelectedItems = selectedItemIds ? [...selectedItemIds] : [];

    const selectedIndex = updatedSelectedItems.indexOf(itemId);
    setSelectedItemIds(selectedIndex === -1 ? [...updatedSelectedItems, itemId] : updatedSelectedItems.filter(id => id !== itemId));
       
    if (selectedIndex === -1) {
      updatedSelectedItems.push(itemId);
    } else {
      updatedSelectedItems.splice(selectedIndex, 1);
    }
    setSelectedItemIds(updatedSelectedItems);
    
    const selectedContacts = contacts.filter((contact: any) => updatedSelectedItems.includes(contact.id));
    setSelectedItemData(selectedContacts)
    localStorage.setItem("contactIds",JSON.stringify(updatedSelectedItems));
  };

  const handleRemoveAll=()=>{
    setSelectedItemIds([]);
    setSelectedItemData([]);
    localStorage.removeItem("contactIds");
  }

  useEffect(() => {
    if (selectedItemIds && selectedItemIds.length > 0) {
      const selectedContacts = contacts.filter((contact: any) => selectedItemIds.includes(contact.id));
      setSelectedItemData(selectedContacts);

      const unselectedContacts = contacts.filter((contact: any) => !selectedItemIds.includes(contact.id));
      setContactData(unselectedContacts);      
    } else {
        setContactData(contacts);
    }
   }, [selectedItemIds, contacts]);

   let message;

   if (title) {
     message = title;
   } else {
     const selectedItemCount = selectedItemData.length;
     const messageSuffix = selectedItemCount === 1
       ? "1 Contact Selected"
       : `${selectedItemCount} Contacts Selected`; 
   
     message = selectedItemCount === 0
       ? "Choose Contact"
       : messageSuffix;
   }
   const contactCount = contactData?.length || 0;
    const contactLabel = contactCount > 1 ? t(configJSON.contacts) : t(configJSON.contact);
    const messageContact = `${contactCount} ${contactLabel}`;
  return (
    <Modal
      open={modalOpen}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalBoxCenter}
    >
      <Box className={classes.contactGroupsModal}>
        <Box className="modal-heading">
          <Box className="heading-left">
            <Typography className="modal-title" component="h2">
            {message}
            </Typography>
            <Box component="p" className="sub-txt">
             {subTitle}
            </Box>
          </Box>
          <Box className="heading-right">
            <Link to="#" className="heading-icon" onClick={modalClose}>
              <img src={close} alt="closeGray" />
            </Link>
          </Box>
        </Box>
        <Box className="search-box">
          <CustomInputWeb
            type="select"
            data-test-id="select"
            label=""
            value={searchContactKeyword}
            placeholder="Search contacts"
            onChange={(event: any) => handleSearchContact(event.target.value)}
            errors={""}
            rows={4}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon style={{color:"#94A3B8"}}/>
              </InputAdornment>
            }
          />
        </Box>
        <Box className="assign-contact">
          <Box className="selected-contact-header">
            <Typography className="selected-contact-txt" component="h2">              
              {t(`${configJSON.selectContacts}`)}
            </Typography>
            <Button
             className="remove-contact-txt"
             onClick={handleRemoveAll}
             data-test-id="handleRemoveAll"
            >{t(`${configJSON.removeAllContacts}`)}</Button>
          </Box>
          
          <Box className="selected-contact">
            <Box className="select-contact-item">
              {selectedItemData.map((item:any)=>{
              const filteredGroups = item.attributes.contact_groups.filter((group:any )=> group !== null);
              return<Box className="select-contact-item-block" key={item.id}>
              <Box className="avatar">
                <Typography className="short-name">
                {item.attributes.first_name.charAt(0).toUpperCase()}{item.attributes.last_name.charAt(0).toUpperCase()}
                </Typography>
              </Box>
              <Box className="contact-info">
                <Typography className="name">{item.attributes.first_name} {item.attributes.last_name}</Typography>
                <Typography className="email">{item.attributes.email}</Typography>
                <Box className="departments">
              {filteredGroups?.map((group:any)=>{
              return <Box className="dep-item" key={group?.id}>
                  <Typography className="dep-name">
                    {group?.name}
                  </Typography>
                </Box>               
   
              })}
          <Box data-test-id="handleToggleSelection1" onClick={() => handleToggleSelection(item.id)}>
            {selectedItemIds && item.id !== null && item.id !== undefined && selectedItemIds.includes(item.id) ? (
            <CheckCircleIcon className="checkedCirlce" />
          ) : (
            <RadioButtonUnchecked className="circleCheck" />
          )}
          </Box>
              </Box>
              </Box>
              </Box>
            })}
            </Box>
          </Box>
            <Typography className="top-title">{t(`${configJSON.unassigned}`)}</Typography>
            <Box className="top-list">
              <Typography className="count-contact"> {messageContact}</Typography>
              <FormControl className="select-control">
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  onChange={(e:any) => handleSortContact(e.target.value)}
                  displayEmpty
                  defaultValue="Sort by"
                  IconComponent={() => (
                    <ExpandMoreIcon style={{color:"#94A3B8"}}/>
                  )}
                  data-test-id="select2"
                >
                  <MenuItem value="Sort by">Sort by</MenuItem>
                  <MenuItem value="A-Z">A-Z</MenuItem>
                  <MenuItem value="Z-A">Z-A</MenuItem>
                </Select>
              </FormControl>
            </Box>
          <Box className="un-assign-contact">
            <div className="line"></div>
            <Box className="un-assign-list">
            {contactData.map((contact:any)=>{
            let filteredGroupsSelected;
            if (Array.isArray(contact.attributes.contact_groups)) {
              filteredGroupsSelected = contact.attributes.contact_groups.filter((group:any )=> group !== null);
            } 
            return <Box className="contact-item" key={contact.id}>
            <Box className="avatar">
              <Typography className="short-name">
                {contact.attributes.first_name.charAt(0).toUpperCase()}{contact.attributes.last_name.charAt(0).toUpperCase()}
                </Typography>
            </Box>
            <Box className="contact-info">
              <Typography className="name"> {contact.attributes.first_name} {contact.attributes.last_name}</Typography>
              <Typography className="email">
               {contact.attributes.email}
              </Typography>
              <Box className="departments">
              {filteredGroupsSelected?.map((group:any)=>{                
              return <Box className="dep-item" key={group?.id}>
                  <Typography className="dep-name">
                    {group?.name}
                  </Typography>
                </Box>               
              })}
            <Box data-test-id="handleToggleSelection" onClick={() => handleToggleSelection(contact.id)}>
            {selectedItemIds && contact.id !== null && contact.id !== undefined && selectedItemIds.includes(contact.id) ? (
            <CheckCircleIcon className="checkedCirlceContact" />
          ) : (
            <RadioButtonUnchecked className="circleCheck" />
          )}
            </Box>
            </Box>
          </Box>
          </Box>
          })}

            </Box>
          {title?<Button onClick={()=>editGroupEvent()} data-test-id="editGroupEvent" className="save-group-button">{t(`${configJSON.saveGroup}`)}</Button>:""}
          </Box>
          
        </Box>
      </Box>
    </Modal>
  );
};

export default GroupContacts;
// Customizable Area End
