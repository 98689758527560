// Customizable Area Start
import React from "react";
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";

export const liveChatCustomStyles = (theme: AugmentedTheme) =>
  createStyles({
    profileWrapper: {
      "& .inner-wrapper": {
        width: "100%",
        padding: "73px 0px 0px",
        [theme.breakpoints.down("sm")]: {
          padding: "21px 0 129px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "24px 0",
        },
      },
    },
    quickChatHeader: {
      borderBottom: "1px solid #E8ECF2",
      height: "66px",
      marginBottom: "20px",
      width: "100%",
      background: "#fff",
      position: "sticky",
      top: 0,
      backgroundColor: " #fff",
      zIndex: 1000,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px",     
      "& .etoh-header-quick-chat": {
        display: "flex",
        alignItems: "center",
        margin: "20px",
        "& .etoh-name": {
          width: "38px",
          height: "38px",
          display: "flex",
          background: "#ECF1F4",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "Roboto",
          borderRadius: "100%",
          color: "rgba(43, 43, 43, 0.87)",
          fontSize: "15.485px",
          fontWeight: 600,
          letterSpacing: "-0.395px",
          lineHeight: "21.679px",
        },
        "& .user-name": {
          fontFamily: "Expletus Sans",
          color: "#2B2B2B",
          fontSize: "14px",
          fontWeight: 500,
          marginLeft: "10px",
        },
      },
      "& .single-chat-icons": {
        display: "flex",
        alignItems: "center",
        margin: "20px",
        "& .close-icon": {
          width: "18px",
          marginLeft: "10px",
        },
      },
    },
    sinlgeChatModal: {
      borderRadius: "12px",
      background: "#FFF",
      boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
      maxWidth: "591px",
      width: "336",
      marginLeft: "auto",     
      display: "inline-block",
      top: "auto !important",
      bottom: "0",
      right: "32px",
      position: "fixed",     
      "& .profileQuickChatBlockOuter": {
        width: "100%",
        "& .chat-block-wrapper": {
          position: "relative",
          width: "100%",
          padding: "0px 30px",
        },
      },
      "& .date-message":{
        border: "1px solid #E8ECF2",
        padding: "8px",
        borderRadius: "12px",
        fontWeight: 400,
        fontSize: "12px",
        color: "#000000DE",
        fontFamily: "Roboto",
        marginBottom: "10px",
        background:"#F2F8FC",
        width:"85px",
        textAlign:"center",
        margin:"0 auto"
      },
      "& .chat-text-content-reciever": {
        "& .right-content-quick": {
          display: "flex",
          flexDirection: "column",
          marginBottom:"10px",
          alignItems: "start",
        "& .help-message-quick": {
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "90%",
          border: "1px solid #E8ECF2",
          padding: "12px",
          borderRadius: "12px 12px 12px 0px",
          fontWeight: 400,
          fontSize: "12px",
          color: "#000000DE",
          fontFamily: "Roboto",
          marginBottom: "10px",
        },
        "& .ss-image": {
          borderRadius: "12px",
          marginBottom: "6px",
          maxWidth: "100%",         
          display: "flex",
          marginTop:"6px"
        },
        "& .tick-time-quick": {
          fontWeight: 400,
          fontSize: "10px",
          color: "#000000DE",
          fontFamily: "Roboto",
          marginBottom:"10px",          
          "& .img-quick": {
            width: "16px",
            marginRight: "6px",
          },
        },
      }
      },
      "& .show-input-message": {
        background: "#fff",
        border: "1px solid #E8ECF2",
        borderRadius: "24px 24px 0 0",
        margin: "12px",
        marginBottom:"0",
        padding: "15px",
        borderBottom: "none",
        "& .quick-msg-display": {
          fontWeight: 500,
          fontSize: "14px",
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
        },
      },
      "& .send-quick-messge-area": {
        position: "sticky",
        bottom: 0,
        backgroundColor: "#fff",
        zIndex: 1000,
        padding: "10px",
        // display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .image-video-audio-preview":{
          background:"#ECF1F4",
          padding:"20px",
          "& .preview-image":{
            objectFit: "contain",
            maxHeight: "400px",
            maxWidth:"250px",
          },
          "& .close-icon":{
            textAlign:"end"
          }
        },
        "& .attachment-img": {
          width: "16px",
          height:"16px",
          cursor: "pointer",
        },
        "& .send-img": {
          width: "20px",
          marginLeft: "15px",
          cursor: "pointer",
        },
      },
      "& .chat-text-content-sender": {
        position: "relative",
        "& .right-content-quick": {
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          marginBottom: "10px",
          "& .help-message-quick": {
            whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "90%",
            border: "1px solid #E8ECF2",
            background: "#4BA3B7",
            padding: "12px",
            borderRadius: "12px 12px 0px 12px",
            fontWeight: 400,
            fontSize: "12px",
            color: "#FFFFFF",
            fontFamily: "Roboto",
            marginBottom: "10px",
            textAlign: "end",
          },
          "& .ss-image": {
            borderRadius: "12px",
            marginBottom: "6px",
            maxWidth: "100%",            
            display: "flex",
            marginTop:"6px"
          },
          "& .tick-time-quick": {
            fontWeight: 400,
            fontSize: "10px",
            color: "#000000DE",
            fontFamily: "Roboto",
            textAlign: "end",
            "& .img-quick": {
              width: "16px",
              marginRight: "6px",
            },
          },
        },
      },
    },
    quickChatBox: {
      marginTop: "9px",
      "& .quick-chat-container": {
        display: "flex",
        flexDirection: "row",
        paddingLeft: "24px",
        paddingTop: "24px",
        paddingRight: "24px",
        paddingBottom: "15px",
        justifyContent: "space-between",
        "& .title": {
          color: "#000000",
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: "400",
          fontFamily: "Expletus Sans",
        },
        "& .sub-title": {
          fontSize: "12px",
          marginTop: "6px",
          color: "#2B2B2B",
          fontFamily: "Roboto",
        },
        "& .icon": {
          width: "16px",
          marginLeft: "6px",
          marginBottom: "-3px",
        },
      },
      "& .quickChatParentBox": {
        maxHeight: 284,
        padding: "0 24px",
        overflow: "auto",
        "& .drawerContent": {
          flex: 1,
        },
        "& .profileBlockOuter": {
          width: "100%",        
          "& .chat-block-wrapper": {
            position: "relative",
            width: "100%",           
            "& .quick-chat-header": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              "& .etoh-header-chat": {
                "& .etoh-name": {
                  width: "38px",
                  height: "38px",
                  display: "flex",
                  background: "#ECF1F4",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Roboto",
                  borderRadius: "100%",
                  color: "rgba(43, 43, 43, 0.87)",
                  fontSize: "15.485px",
                  fontWeight: 600,
                  letterSpacing: "-0.395px",
                  lineHeight: "21.679px",
                },
              },
            },
            "& .etoh-header": {
              display: "flex",
              alignItems: "center",
              padding: "20px",
              background: "#F2F8FC",
              borderTop: "1px solid #E8ECF2",
              borderBottom: "1px solid #E8ECF2",
              "& .etoh-img": {
                width: "38px",
                height: "38px",
              }, 
              "& .etoh-name": {
                width: "38px",
                height: "38px",
                display: "flex",
                background: "#ECF1F4",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Roboto",
                borderRadius: "100%",
                color: "rgba(43, 43, 43, 0.87)",
                fontSize: "15.485px",
                fontWeight: 600,
                letterSpacing: "-0.395px",
                lineHeight: "21.679px",
              },
              "& .etoh-content": {
                marginLeft: "20px",
                "& .support-txt": {
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "#000000",
                  fontFamily: "Expletus Sans",
                },
                "& .support-sub-txt": {
                  fontWeight: 500,
                  fontSize: "12px",
                  color: "#94A3B8",
                  fontFamily: "Expletus Sans",
                },
              },
            },
            "& .etoh-header-chat": {
              display: "flex",
              alignItems: "center",
              padding: "20px 0",
              position: "relative",
              borderBottom: "1px solid #E8ECF2",
              "& .etoh-name": {
                width: "38px",
                height: "38px",
                display: "flex",
                background: "#ECF1F4",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Roboto",
                borderRadius: "100%",
                color: "rgba(43, 43, 43, 0.87)",
                fontSize: "15.485px",
                fontWeight: 600,
                letterSpacing: "-0.395px",
                lineHeight: "21.679px",
              },
              "& .etoh-content": {
                marginLeft: "20px",
                "& .support-txt": {
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "#000000",
                  fontFamily: "Expletus Sans",
                },
                "& .support-sub-txt": {
                  fontWeight: 500,
                  fontSize: "12px",
                  color: "#94A3B8",
                  fontFamily: "Expletus Sans",
                  width: "250px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              },
              "& .time-content": {
                position: "absolute",
                right: "6px",
                textAlign: "-moz-right",
                "& .chat-time": {
                  fontWeight: 500,
                  fontSize: "12px",
                  color: "#94A3B8",
                  fontFamily: "Expletus Sans",
                },
                "& .chat-double-tick": {
                  width: "16px",                  
                  marginTop: "6px",
                  position: "absolute",
                  right: 0,
                },
                "& .chat-count-position": {             
                  color: "#FFFFFF",                  
                  fontSize: "12px",                   
                  fontWeight: 600,
                  fontFamily: "Roboto", 
                  marginRight: "0.4px",            
                },
    
                "& .chat-count": {  
                  width: "17px",
                  height: "17px",
                  display: "flex",
                  background: "#C7263E",
                  alignItems: "center",
                  justifyContent: "center",                 
                  borderRadius: "100%", 
                  marginTop: "6px",
                  position: "absolute",
                  right: 0,  
                },
    
              },
            },
          },
        },
      },
      "&.display-center": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      "& .default-chat": {
        marginBottom: "40px",
        textAlign: "center",
        "& .title": {
          color: "#000000",
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: "400",
          fontFamily: "Expletus Sans",
        },
        "& .sub-title": {
          fontSize: "12px",
          marginTop: "6px",
          color: "#2B2B2B",
          fontFamily: "Roboto",
        },
        "& .image":{
          width:"200px",
        },
      },
      "& .MuiButton-label": {
        textTransform: "initial",
        color: "#4BA3B7",
        fontFamily: "Expletus Sans",
      },
      "&.btn-new-chat": {
        textTransform: "initial",
        color: "#4BA3B7",
        fontSize: "14px",
      },
    },
    profileBlockOuter: {
      width: "100%",
      height: "100vh",
      "& .support-sub-txt": {
        fontWeight: 500,
        fontSize: "16px",
        color: "#94A3B8",
        fontFamily: "Expletus Sans",
      },
      "&.mvc-profile-outer": {
        display: "flex",
        // justifyContent: "center",
        flexDirection: "column",
        flexWrap: "wrap",
        [theme.breakpoints.down("md")]: {
          // minHeight: "49vh",
        },
        [theme.breakpoints.down("sm")]: {
          // minHeight: "66.5vh",
        },
      },
      "& .profile-block-wrapper": {
        display: "flex",
        alignItems: "center",
        padding: "23px 40px",
        margin: "70px auto",
        "& .container-2": {
          marginLeft: "80px",
          "& .primary-btn": {
            width: "223px",
            fontSize: "14px",
            fontFamily: "Expletus Sans",
            fontWeight: "500",
            fontStyle: "normal",
            marginBottom: "16px",
          },
          "& .heading": {
            fontWeight: 400,
            fontSize: "20px",
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            marginBottom: "12px",
          },
          "& .sub-heading": {
            fontWeight: 400,
            fontSize: "14px",
            color: "#94A3B8",
            fontFamily: "Roboto",
            marginBottom: "48px",
            width: "375px",
          },
        },
        "& .default-image": {
          width: "327px",
        },
        [theme.breakpoints.down("md")]: {
          marginBottom: "16px",
        },
        [theme.breakpoints.down("xs")]: {
          border: "0",
          borderRadius: "0",
          padding: "0 0 32px",
          borderBottom: "1px solid #E8ECF2",
          marginBottom: "32px",
        },
      },
      "& .chat-block-wrapper": {
        position: "relative",
        borderRight: "1px solid #E8ECF2",
        width: "50%",
        paddingLeft: "50px",
        minHeight: "100vh",
        "& .sort-container": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
          "& .conversation-container": {
            display: "flex",
            alignItems: "center",
          },
          "& .conversion-count-text": {
            marginLeft: "20px",
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "30px",
            color: "#625F5F",
            fontFamily: "Expletus Sans",
          },
          "& .conversion-count-text1": { 
            marginLeft: "3px",          
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "30px",
            color: "#625F5F",
            fontFamily: "Expletus Sans",
          },
          "& .select-control": {
            "&.multi-select": {
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                position: "absolute",
                right: "18px",
                fontSize: "15px",
                zIndex: 1,
                top: "3px",
                color: "#94A3B8",
              },
            },
            "& .MuiSelect-select": {
              letterSpacing: "0.1px",
              fontSize: "14px",
              color: "#2b2b2b",
              opacity: "0.671",
              fontFamily: "Expletus Sans",
              paddingBottom: "0",
              fontWeight: "500",
              paddingTop: "0",
              paddingRight: "2px",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                height: "50px",
                background: "red",
                width: "50px",
              },
              "& .MuiBox-root": {
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
          },
        },
        "& .search-wrapper": {
          position: "relative",
          "& .filter-image": {
            cursor: "pointer",
          },
          "& .search-box": {
            width: "85%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              marginLeft: "-4px",
            },
            "& .MuiOutlinedInput-input": {
              marginLeft: "6px",
            },
          },
          "& .sort-by": {
            position: "absolute",
            right: 0,
            top: "0px",
            borderRadius: "12px",
            border: "1px solid var(--Stroke, #E8ECF2)",
            padding: "16px",
          },
        },
        "& .etoh-header": {
          display: "flex",
          alignItems: "center",
          padding: "20px",
          background: "#F2F8FC",
          borderTop: "1px solid #E8ECF2",
          borderBottom: "1px solid #E8ECF2",
          "& .etoh-img": {
            width: "38px",
            height: "38px",
          },
          "& .etoh-name": {
            width: "38px",
            height: "38px",
            display: "flex",
            background: "#ECF1F4",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "Roboto",
            borderRadius: "100%",
            color: "rgba(43, 43, 43, 0.87)",
            fontSize: "15.485px",
            fontWeight: 600,
            letterSpacing: "-0.395px",
            lineHeight: "21.679px",
          },
          "& .etoh-content": {
            marginLeft: "20px",
            "& .support-txt": {
              fontWeight: 500,
              fontSize: "14px",
              color: "#000000",
              fontFamily: "Expletus Sans",
            },
            "& .support-sub-txt": {
              fontWeight: 500,
              fontSize: "12px",
              color: "#94A3B8",
              fontFamily: "Expletus Sans",
            },
          },
        },
        "& .etoh-header-chat": {
          display: "flex",
          alignItems: "center",
          padding: "20px",
          position: "relative",
          borderBottom: "1px solid #E8ECF2",
          "& .etoh-name": {
            width: "38px",
            height: "38px",
            display: "flex",
            background: "#ECF1F4",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "Roboto",
            borderRadius: "100%",
            color: "rgba(43, 43, 43, 0.87)",
            fontSize: "15.485px",
            fontWeight: 600,
            letterSpacing: "-0.395px",
            lineHeight: "21.679px",
          },
          "& .etoh-content": {
            marginLeft: "20px",
          
            "& .support-txt": {
              fontWeight: 500,
              fontSize: "14px",
              color: "#000000",
              fontFamily: "Expletus Sans",
              width: "250px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",              
            },
           
            "& .support-sub-txt": {             
              width: "250px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: 500,
              fontSize: "12px",
              color: "#94A3B8",
              fontFamily: "Expletus Sans",
            },
          },
      
          "& .time-content": {
            position: "absolute",
            right: "6px",
            textAlign: "-moz-right",
            "& .chat-time": {
              fontWeight: 500,
              fontSize: "12px",
              color: "#94A3B8",
              fontFamily: "Expletus Sans",
            },
            "& .chat-double-tick": {
              width: "16px",
              marginTop: "6px",
              position: "absolute",
              right: 0,
            },
            "& .chat-count-position": {             
              color: "#FFFFFF",                  
              fontSize: "12px",                   
              fontWeight: 600,
              fontFamily: "Roboto", 
              marginRight: "0.8px",            
            },

            "& .chat-count": {  
              width: "17px",
              height: "17px",
              display: "flex",
              background: "#C7263E",
              alignItems: "center",
              justifyContent: "center",                 
              borderRadius: "100%", 
              marginTop: "6px",
              position: "absolute",
              right: 0,
            },

          },
        },
        "& .etoh-header-chat-active": {
          display: "flex",
          alignItems: "center",
          padding: "20px",
          position: "relative",
          background: "#F2F8FC",
          borderTop: "1px solid #E8ECF2",
          borderBottom: "1px solid #E8ECF2",
          "& .etoh-name": {
            width: "38px",
            height: "38px",
            display: "flex",
            background: "#ECF1F4",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "Roboto",
            borderRadius: "100%",
            color: "rgba(43, 43, 43, 0.87)",
            fontSize: "15.485px",
            fontWeight: 600,
            letterSpacing: "-0.395px",
            lineHeight: "21.679px",
          },
          "& .etoh-content": {
            marginLeft: "20px",
            "& .support-txt": {
              fontWeight: 500,
              fontSize: "14px",
              color: "#000000",
              fontFamily: "Expletus Sans",
            },
            "& .support-sub-txt": {
              width: "250px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis", 
              fontWeight: 500,
              fontSize: "12px",
              color: "#94A3B8",
              fontFamily: "Expletus Sans",
            },
          },
          "& .time-content": {
            position: "absolute",
            right: "6px",
            textAlign: "-moz-right",
            "& .chat-time": {
              fontWeight: 500,
              fontSize: "12px",
              color: "#94A3B8",
              fontFamily: "Expletus Sans",
            },
            "& .chat-double-tick": {
              width: "16px",
              marginTop: "6px",
              position: "absolute",
              right: 0,
            },
            "& .chat-count": {
              width: "16px",
              height: "16px",
              display: "flex",
              background: "#C7263E",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Roboto",
              borderRadius: "100%",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: 600,
              marginTop: "6px",
              position: "absolute",
              right: 0,
            },
          },
        },
        "& .new-chat-wrapper": {
          "& .btn-new-chat": {
            position: "absolute",
            top: "16",
            right: "12px",
            minWidth: "30px",
            height: "30px",
            borderRadius: "20px",
            background: "#4BA3B7",
            color: "#ffffff",
            textTransform: "none",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            zIndex: 1,
          },
        },
        "& .conversations": {
          fontWeight: 400,
          fontSize: "20px",
          lineHeight: "30px",
          color: "#2b2b2b",
          fontFamily: "Expletus Sans",
          marginBottom: "8px",
        },
        "& .conversations-sub": {
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "30px",
          color: "#2b2b2b",
          fontFamily: "Roboto",
          marginBottom: "10px",
        },
      },
      "& .chat-container": {
        width: "50%",
        position: "relative",
        paddingLeft: "30px",
        paddingBottom: "100px",
        // height:"100%",
        "& .loader":{
          fontWeight: 500,
          fontSize: "14px",
          color: "#000000",
          fontFamily: "Expletus Sans",
        },
        "& .default-chat-content": {
          "& .support-txt-default": {
            fontWeight: 600,
            fontSize: "40px",
            color: "#000000",
            fontFamily: "Expletus Sans",
            display: "none",
            justifyContent: "center",
          },
        },
        "& .etoh-header": {
          display: "flex",
          alignItems: "center",
          padding: "20px",
          marginBottom: "20px",
          "& .etoh-img": {
            width: "38px",
            height: "38px",
          },
          "& .etoh-name": {
            width: "38px",
            height: "38px",
            display: "flex",
            background: "#ECF1F4",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "Roboto",
            borderRadius: "100%",
            color: "rgba(43, 43, 43, 0.87)",
            fontSize: "15.485px",
            fontWeight: 600,
            letterSpacing: "-0.395px",
            lineHeight: "21.679px",
          },
          "& .etoh-content": {
            marginLeft: "20px",
            "& .support-txt": {
              fontWeight: 500,
              fontSize: "14px",
              color: "#000000",
              fontFamily: "Expletus Sans",
            },
            "& .support-sub-txt": {              
              fontWeight: 500,
              fontSize: "12px",
              color: "#94A3B8",
              fontFamily: "Expletus Sans",
            },
          },
          "& .info-content": {
            display: "none",
            position: "absolute",
            right: "6px",
            textAlign: "-moz-right",

            "& .info-content-img": {
              width: "16px",
              height: "16px",
              marginTop: "6px",
            },
          },
        },
        "& .submit":{
          display:"none"
         },
        "& .date-message":{
          border: "1px solid #E8ECF2",
          padding: "8px",
          borderRadius: "12px",
          fontWeight: 400,
          fontSize: "12px",
          color: "#000000DE",
          fontFamily: "Roboto",
          marginBottom: "10px",
          background:"#F2F8FC",
          width:"85px",
          textAlign:"center",
          margin:"0 auto"
        },
        "& .chat-text-content-reciever": {
          "& .right-content": {
            display: "flex",
            flexDirection: "column",
            marginBottom:"10px",
            alignItems: "start",
          "& .help-message": {
            // width: "70%",
            whiteSpace: "normal",
            wordWrap: "break-word",
            maxWidth: "90%",
            border: "1px solid #E8ECF2",
            padding: "12px",
            borderRadius: "12px 12px 12px 0px",
            fontWeight: 400,
            fontSize: "12px",
            color: "#000000",
            fontFamily: "Roboto",
            marginBottom: "10px",
            background: "#ffffff", 
          },
          "& .tick-time": {
            fontWeight: 400,
            fontSize: "10px",
            color: "#000000DE",
            fontFamily: "Roboto",
            marginBottom:"10px",                     
            "& .img": {
              width: "16px",
              marginRight: "6px",
            },
          },
        }
        },
        "& .chat-text-content-sender": {
          position: "relative",
          paddingRight:"20px",
          "& .right-content": {
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            marginBottom: "10px",
            "& .help-message": {
              whiteSpace: "normal",
              wordWrap: "break-word",
              maxWidth: "90%",
              border: "1px solid #E8ECF2",
              background: "#4BA3B7",
              padding: "12px",
              borderRadius: "12px 12px 0px 12px",
              fontWeight: 400,
              fontSize: "12px",
              color: "#FFFFFF",
              fontFamily: "Roboto",
              marginBottom: "10px",
              textAlign: "end",
            },
            "& .ss-image": {
              borderRadius: "12px",
              marginBottom: "6px",
              width: "300px",             
              display: "flex",
              marginTop:"6px"
            },
            "& .tick-time": {
              fontWeight: 400,
              fontSize: "10px",
              color: "#000000DE",
              fontFamily: "Roboto",
              textAlign: "end",
              "& .img": {
                width: "16px",
                marginRight: "6px",
              },
            },
          },
        },
        "& .send-messge-area": {
          // width: "100%",
          marginTop: "40px",
          position:" absolute",
          bottom: "0",
          width: "90%",
          background:"#ffff",
          "& .image-video-audio-preview":{
            background:"#ECF1F4",
            padding:"20px",
            "& .preview-image":{
              objectFit: "contain",
              maxHeight: "400px",
              maxWidth:" 300px",
            },
            "& .close-icon":{
              textAlign:"end"
            }
          },
          "& .attachment-img": {
            width: "14px",
            cursor: "pointer",
          },
          "& .send-img": {
            width: "20px",
            marginLeft: "15px",
            cursor: "pointer",
          },
        },
      },
    },
    dropdownStyle: {
      maxWidth: "100px",
      width: "100%",
      marginTop: "6px",
      // borderRadius: "12px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "26px",
      },
      "& .MuiPopover-paper ": {
        borderRadius: "12px",
        boxShadow: "0px 5px 12px 0px rgb(191 186 186 / 22%)",
      },
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingRight: "24px",
            paddingLeft: "24px",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  fill: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                letterSpacing: "0",
                lineHeight: "17px",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          padding: "14px 24px",
          color: "#2B2B2B",
          "& .MuiListItemText-root": {
            margin: "0",
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
            "& .multiselect-squareCheck": {
              "& .normal-img": {
                display: "none",
              },
              "& .selected-img": {
                display: "block",
              },
            },
          },
          "& .multiselect-check": {
            marginLeft: "5px",
            display: "none",
            maxWidth: "16px",
            flex: "0 0 16px",
          },
          "& .multiselect-squareCheck": {
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
            "& .selected-img": {
              display: "none",
            },
          },
        },
        "& .new-group": {
          color: "#4BA3B7",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          textTransform: "capitalize",
          alignItems: "center",
          textAlign: "center",
          marginLeft: "12px",
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        letterSpacing: "0",
        lineHeight: "20px",
        color: "#000000",
        padding: "14px 17px",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        whiteSpace: "normal",
        "& .MuiTypography-root": {
          letterSpacing: "0",
          font: "inherit",
        },
      },
      "& .MuiList-padding": {
        paddingBottom: "8px",
        paddingTop: "4px",
      },
    },
    primaryButton: {
      borderRadius: "12px",
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      fontSize: "24px",
      lineHeight: "18px",
      color: "#FFFFFF",
      textAlign: "center",
      fontWeight: 500,
      maxWidth: "100%",
      fontFamily: "Expletus Sans",
      padding: "12px 10px",
      minHeight: "48px",
      transition: "all 0.5s ease-in-out",
      marginBottom: "0",
      textTransform: "capitalize",
      cursor: "pointer",
      "&:hover": {
        color: "#ffffff",
        background: "#4ba3b7",
      },
    },
    diabledPrimaryButton: {
      borderRadius: "12px",
      background: "#92C7D3",
      border: "1px solid #92C7D3",
      fontSize: "24px",
      lineHeight: "18px",
      color: "#F2F8FC !important",
      textAlign: "center",
      fontWeight: 500,
      maxWidth: "100%",
      fontFamily: "Expletus Sans",
      padding: "12px 10px",
      minHeight: "48px",
      transition: "all 0.5s ease-in-out",
      marginBottom: "0",
      textTransform: "capitalize",
      cursor: "pointer",
      "&:hover": {
        color: "#F2F8FC !important",
        background: "#92C7D3",
      },
    },
    secondaryButton: {
      background: "transparent",
      borderRadius: "12px",
      fontSize: "24px",
      border: "1px solid #4BA3B7",
      color: "#4BA3B7",
      fontWeight: 500,
      lineHeight: "18px",
      textTransform: "unset",
      fontFamily: "Expletus Sans",
      textAlign: "center",
      maxWidth: "100%",
      // minWidth: "48px",
      padding: "12px 10px",
      transition: "all 0.5s ease-in-out",
      minHeight: "48px",
      marginBottom: "0",
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#4BA3B7",
      },
    },
    modalDialogBox: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 580,
      backgroundColor: "#ffffff",
      padding: "48px",
      borderRadius: "24px",
      [theme.breakpoints.down("xs")]: {
        padding: "24px",
        maxWidth: "327px",
        width: "90%",
        borderRadius: "12px",
        backgroundColor: "#F2F8FC",
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
      },
      "& .modal-heading-invite": {
        display: "flex",
        justifyContent: "space-between",
      },   
      "& .invite-member-img":{
        height: "250px",
        margin: "0 auto",
        marginBottom:"20px",
      },
      "& .member-criteria":{
        fontSize: "24px",
        lineHeight: "30px",
        color: "#2B2B2B",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        marginBottom:"8px",
      },
        "& .confirm-text":{
          display:"flex",
          alignItems:"center",
          fontSize: "14px",
          lineHeight: "24px",
          color: "#2B2B2B",
          letterSpacing: "0",
          fontFamily: "Roboto",
          fontWeight: 400,
          marginBottom:"8px",
        },
        "& .confirm-text-1":{
          display:"flex",
          alignItems:"center",
          fontSize: "14px",
          lineHeight: "24px",
          color: "#2B2B2B",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: 500,
          marginBottom:"5px",
        },
        "& .points":{
          width: "3px",
          height: "3px",
          color: "#2B2B2B",
          background: "#2B2B2B",
          borderRadius: "100px",
          marginRight:"6px"
        },
      "& .modal-title": {
        fontSize: "24px",
        lineHeight: "30px",
        color: "#000000",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
      },
      "& .modal-sub-title": {
        fontSize: "14px",
        letterSpacing: "0.0075em",
        lineHeight: "26px",
        color: "#2b2b2b",
        fontWeight: 400,
        fontFamily: "Roboto",
        marginBottom: "20px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "13px",
          lineHeight: "17px",
        },
      },

      "& .modal-footer": {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "10px",
        "& .MuiButton-root": {
          marginRight: "24px",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
      "& .primary-btn": {
        minHeight: "48px",
        width: "100%",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "0",
        marginTop: "10px",
        [theme.breakpoints.down("xs")]: {
          minHeight: "30px",
        },
      },
      "& .secondary-btn": {
        minHeight: "48px",
        width: "100%",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "0",
        marginTop: "10px",
        [theme.breakpoints.down("xs")]: {
          minHeight: "30px",

          color: "#4BA3B7",
        },
      },
    },
    externalChatModal: {
      flex: "0 0 51%",
      maxWidth: "51%",
      padding: "0 15px",
    },
    modalDialogChat: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
    },

    secondaryHeader: {
      padding: "25px 0",
      borderBottom: "1px solid #E8ECF2",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      "& .container": {
        maxWidth: "100%",
        padding: "0 25px",
        margin: "0 auto",
      },
      "& .image-wrapper": {
        "& .back-img":{
         marginRight:"16px",
        },
        "& a": {
          display: "inline-block",
          color: "#000000de",
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: 1.5,
          textDecoration: "none",
          "& em": {
            marginRight: "16px",
          },
        },
      },
    },
    drawerWrapper: {
      display: "flex",
      flexWrap: "wrap",
    },
    chatBox:{
     height:"100vh !important",
     paddingBottom:"20%"
    },
    drawerRootWrapper: {
      "& .MuiBackdrop-root": {
        [theme.breakpoints.down("xs")]: {
          background: "rgba(34, 89, 128, 0.70)",
        },
      },
      "& .drawer-inner": {
        [theme.breakpoints.down("xs")]: {
          padding: "56px 24px",
        },
      },
    },
    drawer: {
      minWidth: 302,
      [theme.breakpoints.down("md")]: {
        minWidth: 123,
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: "100%",
      position: "static",
      borderRight: "0",
      [theme.breakpoints.down("xs")]: {
        width: "80%",
        position: "fixed",
      },
      "& .sidebar-heading": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "48px",
          display: "flex",
          alignItems: "center",
        },
        "& .back-btn-wrap": {
          [theme.breakpoints.down("xs")]: {
            marginRight: "16px",
          },
        },
        "& .heading": {
          [theme.breakpoints.down("xs")]: {
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "30px",
            color: "#2b2b2b",
            fontFamily: "Expletus Sans",
          },
        },
      },
    },
    drawerContent: {
      flex: 1,
    },
    profileInnerWrapper: {
      textAlign: "left",
      maxWidth: "100%",
      width: "100%",
      padding: "0 160px",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        padding: "0 116px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0 24px",
      },
      "& .main-heading": {
        paddingBottom: "48px",
        marginBottom: "48px",
        borderBottom: "1px solid #E8ECF2",
        [theme.breakpoints.down("sm")]: {
          paddingBottom: "32px",
          marginBottom: "44px",
        },
        [theme.breakpoints.down("xs")]: {
          paddingBottom: "0",
          marginBottom: "48px",
          borderBottom: "0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        "& .profile-menu": {
          display: "none",
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .back-btn-wrap": {
          display: "none",
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .heading": {
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "42px",
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          letterSpacing: "0",
          [theme.breakpoints.down("xs")]: {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
            margin: "0 20px",
          },
        },
      },
      "& .profile-team": {
        marginBottom: "24px",
        "& .team-label": {
          fontWeight: 400,
          fontSize: "10px",
          lineHeight: "12px",
          textTransform: "uppercase",
          color: "#94A3B8",
          marginBottom: "8px",
          fontFamily: "Roboto",
        },
      },
    },
    modalFilter: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 580,
      backgroundColor: "#ffffff",
      padding: "0",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
     
      "&.view-modal-filter": {
        "& .filter-content": {
          position: "relative",
          padding: "80px 60px 24px 60px",
          "& .filter-heading": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          },
          "& .button-filter": {
            width: "100%",            
            background: "#4BA3B7",
            color: "#FAFCFE",
            padding: "18.5px 0 18.5px 0",
            marginTop: "98px",
            fontSize: "14px",
            lineHeight: "18.75px",         
            fontFamily: "Expletus Sans",
            fontWieght: 500,
            textTransform: "capitalize"

         },
         "& .heading-center": {
          display: "flex",
          alignItems: "center",
         },
         "& .back-button": {
           marginRight: "8px",
         },
         "& .filter-concersation": {
          fontSize: "14px",
          lineHeight: "18.75px",
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          fontWieght: 500,
         },
         "& .filters": {
          fontSize: "24px",
          lineHeight: "32.14px",
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          fontWieght: 400,
          marginTop: "44px",

         },
         "& .filters-activity": {
          fontSize: "14px",
          lineHeight: "1.85px",
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontWieght: 400,
          marginTop: "12px",
          

         },
        "& .filters-assigned": {
          fontSize: "16px",
          lineHeight: "1.13px",
          color: "#2B2B2B",
          fontFamily: "Roboto",
          fontWieght: 400,
          marginTop: "46px",
          marginBottom: "16px"
         },
         "& .filters-team": {
          fontSize: "16px",
          lineHeight: "1.13px",
          color: "#2B2B2B",
          fontFamily: "Roboto",
          fontWieght: 400,
          marginTop: "46px",
          marginBottom: "16px"
         },
         "& .filter-reset": {
          fontSize: "14px",
          lineHeight: "18.75px",
          color: "#4BA3B7",
          fontFamily: "Expletus Sans",
          fontWieght: 500,
          marginRight: "24px"
         },
         "& .atatchments-container": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
         },
         "& .atatchments": {
          fontSize: "14px",
          lineHeight: "18.75px",
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          fontWieght: 500,
         },
        },   
      },
    },
    modalCreateDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 580,
      backgroundColor: "#ffffff",
      padding: "0",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        width: 570,
      },
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },

      "&.view-modal-dialog": {
        "& .header-content": {
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          padding: "0 24px",
          "& .live-chat-box": {
            display: "flex",
            alignItems: "center",
            justifyContent: " center",
            marginTop: " 24px",
            cursor: "pointer",
            "& .live-btn-txt": {
              color: "var(--Task-Completed-Color, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              marginLeft: "8px",
            },
            "& .chat-icon": {
              color: "#2B2B2B",
              width: "16px",
              heigth: "16px",
            },
          },
          "& .back-btn-content": {
            display: "flex",
            alignItems: "center",
            justifyContent: " center",
            marginTop: " 24px",
            cursor: "pointer",
            "& .back-txt": {
              color: "var(--Task-Completed-Color, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              marginLeft: "8px",
            },
          },
        },
        "& .main-container": {
          paddingBottom: "24px",
          paddingTop: "24px",
          background: "#FFF",
          padding: "40px",
          marginTop: "24px",
          "& .title": {
            color: "rgba(0, 0, 0, 0.87)",
            fontFamily: "Expletus Sans",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "0.25px",
          },
          "& .content": {
            color: "#797878",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.25px",
            marginTop: "8px",
          },
          "& .button-action": {
            display: "flex",
            justifyContent: "space-between",
            marginTop: "80px",
            "& .primary-btn, & .secondary-btn ": {
              width: "auto",
              minWidth: "268px",
              [theme.breakpoints.down("xs")]: {
                minWidth: "150px",
                padding: "4px 6px",
              },
            },
          },
        },
        "& .user-name": {
          display: "flex",
          justifyContent: "space-between",
          "& .avatar": {
            display: "flex",
            alignItems: "center",
          },
          "& .name": {
            color: "#797878",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.25px",
            marginTop: "20px",
            marginLeft: "12px",
          },
          "& .technical-team": {
            display: "flex",
            alignItems: "center",
            "& .name": {
              color: "#797878",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0.25px",
              marginTop: "20px",
              marginLeft: "12px",
            },
            "& .attachment-icon": {
              height: "16px",
            },
          },
          "& .short-name": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontSize: "10px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            lineHeight: "21.679px",
            fontWeight: "400",
            letterSpacing: "-0.395px",
            width: "24px",
            height: "24px",
            borderRadius: "19.5px",
            backgroundColor: "var(--background-background-medium, #ECF1F4)",
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
        "& .main-content-txt": {
          display: "flex",
          "& .content-sub-txt": {
            fontSize: "12px",
            lineHeight: "normal",
            color: "#797878",
            fontFamily: "Roboto",
            fontWeight: 400,
            letterSpacing: "0.4px",
            marginTop: "4px",
            marginBottom: "24px",
            "&.content-dots": {
              color: "#797878",
              marginLeft: "12px",
              "&:before": {
                content: '""',
                width: "8px",
                height: "8px",
                borderRadius: "100%",
                background: "#ECF1F4",
                display: "inline-block",
                marginRight: "8px",
              },
            },
          },
        },
        "& .main-content-info": {
          display: "flex",
          justifyContent: "space-between",

          "& .ticket-system-status": {
            color: "#4BA3B7",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            letterSpacing: "0.4px",
            display: "flex",
            padding: "7px 12px",
            alignItems: "center",
            borderRadius: "6px",
            height: "30px",
            // background:"rgba(75, 163, 183, 0.08)",
            "&.open": {
              color: "#4BA3B7",
              background: "rgba(75, 163, 183, 0.08)",
              "&:before": {
                background: "#4BA3B7",
              },
            },
            "&.progress": {
              color: "#F6A319",
              background: "#fdf6e9",
              "&:before": {
                background: "#F6A319",
              },
            },
            "&.resolved": {
              color: "#00BA88",
              background: "#eaf9f5",
              "&:before": {
                background: "#00BA88",
              },
            },
            "&.cancelled": {
              "&:before": {
                background: "#F7E223",
              },
            },
            "&.offtrack": {
              "&:before": {
                background: "#C7263E",
              },
            },
            "&:before": {
              content: '""',
              width: "8px",
              height: "8px",
              borderRadius: "100%",
              background: "#2B2B2B",
              display: "inline-block",
              marginRight: "8px",
            },
          },
        },
        "& .modal-content": {
          padding: "60px",
          "& .sort-container": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "20px",
            marginTop: "20px",
            "& .conversion-count-text": {
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "30px",
              color: "#625F5F",
              fontFamily: "Expletus Sans",
            },
            "& .select-control": {
              "&.multi-select": {
                "& .MuiInput-formControl": {
                  marginTop: "0",
                },
                "& .multiselect-label": {
                  display: "none",
                },
                "& .MuiSelect-select": {
                  paddingRight: "40px",
                },
                "& .multiple-clear": {
                  position: "absolute",
                  right: "18px",
                  fontSize: "15px",
                  zIndex: 1,
                  top: "3px",
                  color: "#94A3B8",
                },
              },
              "& .MuiSelect-select": {
                letterSpacing: "0.1px",
                fontSize: "14px",
                color: "#2b2b2b",
                opacity: "0.671",
                fontFamily: "Expletus Sans",
                paddingBottom: "0",
                fontWeight: "500",
                paddingTop: "0",
                paddingRight: "2px",
                "&:focus": {
                  backgroundColor: "transparent",
                },
                "& .clear-icon": {
                  height: "50px",
                  background: "red",
                  width: "50px",
                },
                "& .MuiBox-root": {
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  "& .select-icon": {
                    marginRight: "6px",
                  },
                },
              },
              "& .MuiInput-underline:after": {
                display: "none",
              },
              "& .MuiInput-underline:before": {
                display: "none",
              },
            },
          },
          "& .search-wrapper": {
            position: "relative",
            "& .filter-image": {
              cursor: "pointer",
            },
            "& .search-box": {
              width: "85%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
                marginLeft: "-4px",
              },
              "& .MuiOutlinedInput-input": {
                marginLeft: "6px",
              },
            },
            "& .sort-by": {
              position: "absolute",
              right: 0,
              top: "0px",
              borderRadius: "12px",
              border: "1px solid var(--Stroke, #E8ECF2)",
              padding: "16px",
            },
          },
          "& .etoh-header": {
            display: "flex",
            alignItems: "center",
            padding: "20px",
            borderBottom: "1px solid #E8ECF2",
            "& .etoh-img": {
              width: "38px",
              height: "38px",
            },
            "& .etoh-name": {
              width: "38px",
              height: "38px",
              display: "flex",
              background: "#ECF1F4",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Roboto",
              borderRadius: "100%",
              color: "rgba(43, 43, 43, 0.87)",
              fontSize: "15.485px",
              fontWeight: 600,
              letterSpacing: "-0.395px",
              lineHeight: "21.679px",
            },
            "& .etoh-content": {
              marginLeft: "20px",
              "& .support-txt": {
                fontWeight: 500,
                fontSize: "14px",
                color: "#000000",
                fontFamily: "Expletus Sans",
              },
              "& .support-sub-txt": {
                fontWeight: 500,
                fontSize: "12px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
              },
            },
          },
          "& .etoh-header-chat": {
            display: "flex",
            alignItems: "center",
            padding: "20px",
            position: "relative",
            borderBottom: "1px solid #E8ECF2",
            "& .etoh-name": {
              width: "38px",
              height: "38px",
              display: "flex",
              background: "#ECF1F4",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Roboto",
              borderRadius: "100%",
              color: "rgba(43, 43, 43, 0.87)",
              fontSize: "15.485px",
              fontWeight: 600,
              letterSpacing: "-0.395px",
              lineHeight: "21.679px",
            },
            "& .etoh-content": {
              marginLeft: "20px",
              "& .support-txt": {
                fontWeight: 500,
                fontSize: "14px",
                color: "#000000",
                fontFamily: "Expletus Sans",
              },
              "& .support-sub-txt": {
                fontWeight: 500,
                fontSize: "12px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                marginBottom:"6px",
              },
            },
          },
          "& .select-outer": {
            width: "100%",
            // marginTop:'-1.3vw',
            marginBottom: "16px",
            "& .error-select": {
              color: "#C7263E",
            },
            "& svg": {
              fill: "#94A3B8",
            },
            "& .Mui-error": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#C7263E",
              },
            },
            "& .MuiInputLabel-formControl": {
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: 500,
              letterSpacing: "0.1px",
              lineHeight: "19px",
              color: "#94A3B8",
              width: "calc(100% - 46px)",
              display: "flex",
              "&.MuiFormLabel-filled": {
                width: "auto",
                fontSize: "12px",
                color: "rgba(43,43,43,0.87)",
                lineHeight: "16px",
                fontFamily: "Roboto",
                letterSpacing: "0.0075em",
                fontWeight: "400",
              },
            },
            "& .MuiSelect-select": {
              letterSpacing: "0",
              fontSize: "14px",
              color: "#000000",
              fontWeight: "500",
              opacity: "0.671",
              fontFamily: "Expletus Sans",
              justifyContent: "space-between",
              display: "flex",
              "&:focus": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
              },
            },
            "& .MuiFormLabel-root": {
              "&.Mui-focused": {
                color: "rgba(43,43,43,0.87)",
                fontSize: "12px",
                width: "auto",
                letterSpacing: "0.0075em",
                lineHeight: "16px",
                fontWeight: "400",
                fontFamily: "Roboto",
              },
              "& .MuiInputLabel-asterisk": {
                lineHeight: "23px",
                marginLeft: "auto",
                color: "#e86577",
                fontSize: "18px",
              },
            },
          },
          "& .primary-btn": {
            width: "100% !important",
            marginTop: "45px !important",
          },
          "& .desc": {
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.25px",
            marginBottom: "20px",
          },
          "& .modal-wrapper": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& .modal-headding": {
              color: "#000000DE",
              fontFamily: "Expletus Sans",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              letterSpacing: "0.25px",
            },
            "& .close-icon": {
              cursor: "pointer",
            },
          },
        },
      },
    },
    chipButton: {
      background: "#ECF1F4",
      fontWeight: 400,
      borderRadius: "6px",
      lineHeight: "12px",
      fontSize: "10px",
      padding: "5px 6px",
      color: "#2B2B2B",
      cursor: "pointer",
      marginBottom: "8px",
      marginRight: "8px",
      height: "auto",
      minHeight: "26px",
      border: "1px solid transparent",
      fontFamily: "Roboto",
      "&.last-child": {
        marginRight: "0",
      },
      "&.secondary-chip": {
        borderColor: "#4BA3B7",
        color: "#4BA3B7",
        backgroundColor: "transparent",
      },
      "&.primary-chip": {
        color: "#FFFFFF",
        backgroundColor: "#4BA3B7",
      },
      "& .MuiChip-avatar": {
        width: "14px",
        height: "14px",
        marginLeft: "0",
        marginRight: "5px",
      },
      "& .MuiChip-label": {
        padding: "0",
      },
    },
    modalCreateDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
    },
  });
// Customizable Area End
