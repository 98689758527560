// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  CardMedia,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";

import { editContactAvatarRound } from "../../blocks/ContactsList/src/assets";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from '@material-ui/core/styles';
import i18n from "../../web/src/utilities/i18n";
import { configJSON } from "../../blocks/ContactsList/src/GroupListController.web";

interface ContactDetails {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  created_at: string;
  business_info: {
      data: {
          id: string;
          type: string;
          attributes: {
              id: string;
              company_name: string;
              VAT_number: string;
              business_category_name: string | null;
          };
      };
  };
  billing_address: {
      data: {
          id: string;
          type: string;
          attributes: {
              id: string;
              country: string;
              city: string;
              pin_code: string;
              address1: string;
              address2: string;
          };
      };
  };
  shipping_address: {
      data: {
          id: string;
          type: string;
          attributes: {
              id: string;
              country: string;
              city: string;
              pin_code: string;
              address1: string;
              address2: string;
          };
      };
  };
  note: {
      data: {
          id: string;
          type: string;
          attributes: {
              id: string;
              content: string;
          };
      };
  };
  contact_groups: Array<{
      id: string;
      name: string;
      created_at: string;
      updated_at: string;
      account_id: string | null;
      deleted_at: string | null;
  }>;
  contact_products: any[]; 
}

interface MergeContactsProps {
  classes: any;
  isSelectMergeInfo:boolean;
  handleMergeInfoSelectionClose:()=>void;
  contactMergeDetails:any;
  mergeContact:(data:any,id:any)=>void
}
const useStyles = makeStyles((theme:any) => ({
  formControl: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiRadio-colorSecondary.Mui-checked":{
      color:"#4BA3B7",
    },
    "& .MuiRadio-root":{
      color:"#E8ECF2"
    },
  }, 
}));

const MergeContactsInfoModal = (props: MergeContactsProps) => {
  const classess = useStyles();
  const {
    classes,
    isSelectMergeInfo,
    handleMergeInfoSelectionClose,
    contactMergeDetails,
    mergeContact
  } = props;

const [defaultContactData, setDefaultContactData] = useState<ContactDetails[]>([]);
const [selectedValues, setSelectedValues] = useState({
  firstName:'',
  lastName: '',
  phone: '',
  email: '',
  companyName: '',
  billingAddress:'',
  shippingAddress:'',
  vatNumber:'',
  businessCategory:''
});
const t=(key:any, variables?: Record<string, any>) => {
  return (i18n as any).t(key, { ns: "translation" , ...variables } )
}
useEffect(() => {
  const storedContactDetailsString = localStorage.getItem("contactDefaultDetails");
  if (storedContactDetailsString) {
      const storedContactDetails = JSON.parse(storedContactDetailsString);
      setDefaultContactData([storedContactDetails])
    } 
  }, []);

  const handleRadioChange = (fieldName:any, value:any) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };
  const mergeBillingAddress = contactMergeDetails?.billing_address?.data?.attributes;
  const defaultBillingAddress = defaultContactData[0]?.billing_address?.data?.attributes;
  const mergeShippingAddress = contactMergeDetails?.shipping_address?.data?.attributes;
  const defaultShippingAddress = defaultContactData[0]?.shipping_address?.data?.attributes;
  const addressString = selectedValues.billingAddress
  const addressParts = addressString.split(" ");

  const billing_address = {
    address1: addressParts[0],     
    address2: addressParts[1], 
    city: addressParts[2],        
    pin_code: addressParts[3], 
    country:defaultBillingAddress?.country,
    id:defaultBillingAddress?.id
  };
  const addressStringShipping = selectedValues.shippingAddress;
  const addressShipingParts = addressStringShipping.split(" ");
  const shipping_address = {
    address1: addressShipingParts[0],     
    address2: addressShipingParts[1], 
    city: addressShipingParts[2],        
    pin_code: addressShipingParts[3], 
    country:defaultShippingAddress?.country,
    id:defaultShippingAddress?.id
  };

  const groupIds = defaultContactData[0]?.contact_groups
  ?.map((group:any )=> group?.id);

  const businessCategoryString = selectedValues.businessCategory
  const businessCategoryPart = businessCategoryString.split(" ");
  const business_info = {
    "company_name": selectedValues.companyName,
    "business_category_id": businessCategoryPart[0],
    "VAT_number": selectedValues.vatNumber,
    "id": businessCategoryPart[1],    
  };

  const mergedContactData = {
    "contact": {
      "first_name":  selectedValues.firstName,
      "last_name":  selectedValues.lastName,
      "email": selectedValues.email,
      "phone": selectedValues.phone,
      business_info,
      billing_address,
      shipping_address,
      "note": {
        "id":defaultContactData[0]?.note?.data?.attributes?.id,
        "content": defaultContactData[0]?.note?.data?.attributes?.content
      },
      "contact_groups": {
        "group_ids": groupIds
      },
      "merge_contact_id":defaultContactData[0]?.id
    }
  };

  return (
    <Modal
    open={isSelectMergeInfo}
    onClose={handleMergeInfoSelectionClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className={classes.modalBoxCenter}
  >
    <Box className={classes.mergeInfoGroupsModal}>
         <Box className="heading-bar">           
            <CloseIcon
              className="close-icon"
              onClick={handleMergeInfoSelectionClose}
            />
          </Box>
          <div className="line"></div>
      <Box className="merge-wrapper">
       <Box className="heading-avatar">
            <Box className="wrap-avatar">
              <CardMedia
                component="img"
                className="avatar-bg"
                src={editContactAvatarRound}
              />
              <Typography className="shortcut-name">
              {defaultContactData[0]?.first_name?.charAt(0).toUpperCase()}
              {defaultContactData[0]?.last_name?.charAt(0).toUpperCase()}
              </Typography>
            </Box>
       </Box>
       <Box className="profile-name">
             {defaultContactData[0]?.first_name} {defaultContactData[0]?.last_name}
       </Box>
       <Box className="headding">
           {t(`${configJSON.mergeHeadingTxt}`)}
       </Box>
       <Box className="selection-wrapper">
       <Typography className="heading-title">{t(`${configJSON.firstName}`)}</Typography>
       <Box className="headding-wrapper">
       <Box className="checkbox-selected">
        <RadioGroup
         aria-label="select" 
         name="select"
         value={selectedValues.firstName}
         onChange={(event) =>
           handleRadioChange('firstName', event.target.value)
         }
        >
          <FormControlLabel
            className={`${classess.formControl} heading-title-sub`}
            value={contactMergeDetails?.first_name} 
            control={<Radio />} 
            label={contactMergeDetails?.first_name} 
            labelPlacement="start" />
          <FormControlLabel
            className={`${classess.formControl} heading-title-sub`}
            value={defaultContactData[0]?.first_name}
            control={<Radio />}
            label={defaultContactData[0]?.first_name} 
            labelPlacement="start" />
        </RadioGroup>
        </Box>        
       </Box>
       <div className="line"></div>
       </Box>
       <Box className="selection-wrapper">
       <Typography className="heading-title">{t(`${configJSON.lastName}`)}</Typography>
       <Box className="headding-wrapper">
       <Box className="checkbox-selected">
        <RadioGroup 
        aria-label="select" 
        name="select" 
        value={selectedValues.lastName}
        onChange={(event) =>
          handleRadioChange('lastName', event.target.value)
        }
        >
          <FormControlLabel 
          className={`${classess.formControl} heading-title-sub`}
          value={contactMergeDetails?.last_name} 
          control={<Radio   />} 
          label={contactMergeDetails?.last_name} 
          labelPlacement="start" />
          <FormControlLabel 
          className={`${classess.formControl} heading-title-sub`}
          value={defaultContactData[0]?.last_name} 
          control={<Radio   />} 
          label={defaultContactData[0]?.last_name} 
          labelPlacement="start" />
        </RadioGroup>
        </Box>        
       </Box>
       <div className="line"></div>
       </Box>
       <Box className="selection-wrapper">
       <Typography className="heading-title">{t(`${configJSON.phone}`)}</Typography>
       <Box className="headding-wrapper">
       <Box className="checkbox-selected">
        <RadioGroup 
        aria-label="select" 
        name="phone" 
        value={selectedValues.phone}
        onChange={(event) =>
          handleRadioChange('phone', event.target.value)
        }
        >
          <FormControlLabel 
          className={`${classess.formControl} heading-title-sub`}
          value={contactMergeDetails?.phone} 
          control={<Radio   />} 
          label={contactMergeDetails?.phone || 'None'} 
          labelPlacement="start" />
          <FormControlLabel 
          className={`${classess.formControl} heading-title-sub`} 
          value={defaultContactData[0]?.phone} 
          control={<Radio   />} 
          label={defaultContactData[0]?.phone  || 'None'} 
          labelPlacement="start" />
        </RadioGroup>
        </Box>        
       </Box>
       <div className="line"></div>
       </Box>
       <Box className="selection-wrapper">
       <Typography className="heading-title">{t(`${configJSON.email}`)} </Typography>
       <Box className="headding-wrapper">
       <Box className="checkbox-selected">
        <RadioGroup
         aria-label="select" 
         name="email"
         value={selectedValues.email}
          onChange={(event) =>
            handleRadioChange('email', event.target.value)
          }
         >
          <FormControlLabel 
          className={`${classess.formControl} heading-title-sub`}
          value={contactMergeDetails?.email} 
          control={<Radio   />} 
          label={contactMergeDetails?.email || 'None'} 
          labelPlacement="start" />
          <FormControlLabel 
          className={`${classess.formControl} heading-title-sub`} 
          value={defaultContactData[0]?.email} 
          control={<Radio   />} 
          label={defaultContactData[0]?.email || 'None'} 
          labelPlacement="start" />
        </RadioGroup>
        </Box>        
       </Box> 
       <div className="line"></div>
       </Box>
       <Box className="selection-wrapper">
       <Typography className="heading-title">{t(`${configJSON.companyName}`)}</Typography>
       <Box className="headding-wrapper">
       <Box className="checkbox-selected">
        <RadioGroup
         aria-label="select" 
         name="companyName" 
         value={selectedValues.companyName}
         onChange={(event) =>{
         
           handleRadioChange('companyName', event.target.value)
         }
         }
         >
          
          <FormControlLabel
           className={`${classess.formControl} heading-title-sub`} 
           value={
            contactMergeDetails?.business_info?.data?.attributes?.company_name 
              ? `${contactMergeDetails?.business_info?.data?.attributes?.company_name}`
              : " "  // Empty string when company name is not present
          } 
           control={<Radio   />} 
           label={
            contactMergeDetails?.business_info?.data?.attributes?.company_name 
              ? `${contactMergeDetails?.business_info?.data?.attributes?.company_name}`
              : 'None'  // 'None' as fallback when company name is missing
          }
           labelPlacement="start" />
          <FormControlLabel 
           className={`${classess.formControl} heading-title-sub`} 
           value={`${defaultContactData[0]?.business_info?.data?.attributes?.company_name}`}
           control={<Radio   />} 
           label={
            defaultContactData[0]?.business_info?.data?.attributes?.company_name 
              ? `${defaultContactData[0]?.business_info?.data?.attributes?.company_name}`
              : 'None'  // 'None' as fallback when company name is missing
          }
           labelPlacement="start" />
        </RadioGroup>
        </Box>  
        </Box>     
       <div className="line"></div>
       </Box>
       <Box className="selection-wrapper">
       <Typography className="heading-title">{t(`${configJSON.billingAddressTxt}`)}</Typography>
       <Box className="headding-wrapper">
       <RadioGroup 
       aria-label="select" 
       name="billingAddress" 
       value={selectedValues.billingAddress}
       onChange={(event) =>
           handleRadioChange('billingAddress', event.target.value)
       }
       >
        <FormControlLabel 
        className={`${classess.formControl} heading-title-sub`} 
        value={
          mergeBillingAddress?.address1 || mergeBillingAddress?.address2 || mergeBillingAddress?.city || mergeBillingAddress?.pin_code 
            ? `${mergeBillingAddress?.address1} ${mergeBillingAddress?.address2} ${mergeBillingAddress?.city} ${mergeBillingAddress?.pin_code}`
            : ''
        }
        control={<Radio   />} 
        label={
          mergeBillingAddress?.address1 || mergeBillingAddress?.address2 || mergeBillingAddress?.city || mergeBillingAddress?.pin_code 
            ? `${mergeBillingAddress?.address1} ${mergeBillingAddress?.address2} ${mergeBillingAddress?.city} ${mergeBillingAddress?.pin_code}`
            : 'None'
        }
        labelPlacement="start" />
      <FormControlLabel 
        className={`${classess.formControl} heading-title-sub`} 
        value={`${defaultBillingAddress?.address1} ${defaultBillingAddress?.address2} ${defaultBillingAddress?.city} ${defaultBillingAddress?.pin_code}`} 
        control={<Radio   />} 
        label={
          mergeBillingAddress?.address1 || mergeBillingAddress?.address2 || mergeBillingAddress?.city || mergeBillingAddress?.pin_code 
            ? `${mergeBillingAddress?.address1} ${mergeBillingAddress?.address2} ${mergeBillingAddress?.city} ${mergeBillingAddress?.pin_code}`
            : 'None'
        }
        labelPlacement="start"
      />
        </RadioGroup>     
             
       </Box>
      
       <div className="line"></div>
       </Box>
       <Box className="selection-wrapper">
       <Typography className="heading-title">{t(`${configJSON.shippingAddress}`)}</Typography>
       <Box className="headding-wrapper">
       <RadioGroup 
       aria-label="select" 
       name="shippingAddress" 
       value={selectedValues.shippingAddress}
       onChange={(event) =>{
           handleRadioChange('shippingAddress', event.target.value)
       }}
       >
          <FormControlLabel 
            className={`${classess.formControl} heading-title-sub`}
            value={
              mergeShippingAddress?.address1 || mergeShippingAddress?.address2 || mergeShippingAddress?.city || mergeShippingAddress?.pin_code 
                ? `${mergeShippingAddress?.address1} ${mergeShippingAddress?.address2} ${mergeShippingAddress?.city} ${mergeShippingAddress?.pin_code}`
                : ''
            }    
            control={<Radio />} 
            label={
              mergeShippingAddress?.address1 || mergeShippingAddress?.address2 || mergeShippingAddress?.city || mergeShippingAddress?.pin_code 
                ? `${mergeShippingAddress?.address1} ${mergeShippingAddress?.address2} ${mergeShippingAddress?.city} ${mergeShippingAddress?.pin_code}`
                : 'None'
            }
            labelPlacement="start" />
          <FormControlLabel 
            className={`${classess.formControl} heading-title-sub`} 
            value={`${defaultShippingAddress?.address1} ${defaultShippingAddress?.address2} ${defaultShippingAddress?.city} ${defaultShippingAddress?.pin_code}`} 
            control={<Radio />} 
            label={
              defaultShippingAddress?.address1 || defaultShippingAddress?.address2 || defaultShippingAddress?.city || defaultShippingAddress?.pin_code 
                ? `${defaultShippingAddress?.address1} ${defaultShippingAddress?.address2} ${defaultShippingAddress?.city} ${defaultShippingAddress?.pin_code}`
                : 'None'
            }
            labelPlacement="start"
          />
        </RadioGroup>     
       </Box>
       <div className="line"></div>
       </Box>
       <Box className="selection-wrapper">
       <Typography className="heading-title">{t(`${configJSON.vatNumber}`)}</Typography>
       <Box className="headding-wrapper">
       <RadioGroup 
       aria-label="select" 
       name="vatNumber" 
       value={selectedValues.vatNumber}
       onChange={(event) =>
           handleRadioChange('vatNumber', event.target.value)
       }
       >
          <FormControlLabel className={`${classess.formControl} heading-title-sub`}
           value={
            contactMergeDetails?.business_info?.data?.attributes?.VAT_number 
              ? `${contactMergeDetails?.business_info?.data?.attributes?.VAT_number}`
              : " " 
          } 
           control={<Radio   />} 
           label={
            contactMergeDetails?.business_info?.data?.attributes?.VAT_number 
              ? `${contactMergeDetails?.business_info?.data?.attributes?.VAT_number}`
              : 'None'
          }
           labelPlacement="start" />
          <FormControlLabel
           className={`${classess.formControl} heading-title-sub`} 
           value={`${defaultContactData[0]?.business_info?.data?.attributes?.VAT_number}`} 
           control={<Radio   />} 
           label={
            defaultContactData[0]?.business_info?.data?.attributes?.VAT_number 
              ? `${defaultContactData[0]?.business_info?.data?.attributes?.VAT_number}`
              : 'None'
          }
           labelPlacement="start" />
        </RadioGroup>   
            
       </Box>
      
       <div className="line"></div>
       </Box>
       <Box className="selection-wrapper">
       <Typography className="heading-title">{t(`${configJSON.businessCategorytxt}`)}</Typography>
       <Box className="headding-wrapper">
          <RadioGroup 
          aria-label="select" 
          name="businessCategory"
          value={selectedValues.businessCategory}
          onChange={(event) =>
              handleRadioChange('businessCategory', event.target.value)
          }
         >
          <FormControlLabel className={`${classess.formControl} heading-title-sub`}
           value={contactMergeDetails?.business_info?.data?.attributes?.business_category_name ? `${contactMergeDetails?.business_info?.data?.attributes?.business_category_name}` : ""}
           control={<Radio   />} 
           label={contactMergeDetails?.business_info?.data?.attributes?.business_category_name ? `${contactMergeDetails?.business_info?.data?.attributes?.business_category_name}` :  'None'}
           labelPlacement="start" />
          <FormControlLabel
           className={`${classess.formControl} heading-title-sub`} 
           value={`${defaultContactData[0]?.business_info?.data?.attributes?.business_category_name}`}
           control={<Radio   />} 
           label={defaultContactData[0]?.business_info?.data?.attributes?.business_category_name ? `${defaultContactData[0]?.business_info?.data?.attributes?.business_category_name}` : 'None'}
           labelPlacement="start" />          
         </RadioGroup>
        </Box>      
       <div className="line"></div>
       </Box>
       <Button data-test-id="mergeContactBtn" className="btn-merge" onClick={()=>mergeContact(mergedContactData,contactMergeDetails?.id)}>{t(`${configJSON.mergeContactBtnTxt}`)}</Button>
     </Box>
     </Box>
    </Modal>
  );
};

export default MergeContactsInfoModal;

// Customizable Area End
