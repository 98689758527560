// Customizable Area Start
import React from "react";
import { Box, Typography, Button , InputAdornment} from "@material-ui/core";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CustomInputWeb from "./CustomInput.web";
import { configJSON } from "../../blocks/ContactsList/src/GroupListController.web";
import { new_page ,close } from "../../blocks/ContactsList/src/assets";

interface CreateGroupProps {
  classes: any;
  handleCloseDialog: any;
  handleMinimizeCreateGroup: () => void;
  groupError:string;
  groupName: string;
  handleChangeGroupName: (text: string) => void;
  contactId?: string;
  handleChangeContact?: (id: string) => void;
  openGroupsContact: () => void;
  createGroup:() => void;
  t:any;
}

const CreateGroup = (props: CreateGroupProps) => {
  const {
    classes,
    handleCloseDialog,
    handleMinimizeCreateGroup,
    groupName,
    handleChangeGroupName,
    openGroupsContact,
    groupError,
    createGroup,
    t
  } = props;
  const retrievedArrayLength = JSON.parse(localStorage.getItem("contactIds") ?? '[]').length;

  let message;
  if (retrievedArrayLength === 0) {
    message = "";
  } else {
    const contactText = retrievedArrayLength !== 1 ? t(`${configJSON.contactsSelected}`) : t(`${configJSON.contactSelected}`);
    message = contactText.replace('{{count}}', retrievedArrayLength);
  }
  
  return (
    <Box className={classes.createContactBox}>
      <Box className="contact-heading">
        <Box className="heading-left">
          <Typography className="modal-title" component="h2">
            {t(`${configJSON.createGroup}`)}
          </Typography>
          <Box component="p" className="sub-txt">
            {t(`${configJSON.letsCreateGroupTxt}`)}
          </Box>
        </Box>
        <Box className="heading-right">
          <Link
            to="#"
            className="heading-icon"
            onClick={handleMinimizeCreateGroup}
          >
            <img src={new_page} alt="minimizeGray" />
          </Link>
          <Link to="#" className="heading-icon" onClick={handleCloseDialog}>
            <img src={close} alt="closeGray" />
          </Link>
        </Box>
      </Box>
      <Box className="contact-content-group"> 
      <Box className="info-group">
        <Box className="group-form" component="form">
          <Box className="form-info-wrapper">
            <Box className="form-row">
              <Box className="form-col">
                <CustomInputWeb
                  type="text"
                  label={t(`${configJSON.groupName}`)}
                  value={groupName}
                  name="Group Name"
                  onChange={(event: any) =>
                    handleChangeGroupName(event.target.value)
                  }
                  isRequired={true}
                  errors={groupError}
                  rows={4}
                  data-test-id="groupname"
                />
              </Box>
            </Box>
            <Box className="form-row">
            <Box className="form-col">
                <CustomInputWeb
                  type="text"
                  disabled={false}
                  customClass="has-modal"
                  onClick={openGroupsContact}
                  label={t(`${configJSON.chooseContact}`)}
                  value={message}                        
                  endAdornment={
                    <InputAdornment position="end"  onClick={openGroupsContact}>
                      <KeyboardArrowDownIcon />
                    </InputAdornment>
                  }
                  data-test-id="choose-contact"
                />
              </Box>              
            </Box>
          </Box>
        </Box>
      </Box>
      </Box>
      <Box className="create-bottom">
         <Box className="full-btn">
         <Button
          data-test-id="createContact"
          onClick={createGroup}
          className={`create-contact-btn ${classes.primaryButton}`}
        >
         + {t(`${configJSON.createGroup}`)}
        </Button>
        </Box>
        </Box>
      {/* <Button className="create-contact-btn" onClick={createGroup}>+ {t(`${configJSON.createGroup}`)}</Button> */}
    </Box>
  );
};

export default CreateGroup;

// Customizable Area End