import React from 'react';
import {
    Box,
    Typography,
    Button,
    Card,
    CardMedia,
    CardContent
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "./MetaStyles.web";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from '@material-ui/core/Grid'

import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import { useTheme } from '@material-ui/styles';


import ChooseMetricsController, {
    Props,
    configJSON
} from './ChooseMetricsController.web'


export class ChooseMetrics extends ChooseMetricsController {

    constructor(props: Props) {
        super(props)
    }

    render() {

        const { classes } = this.props;

        return (
            <Box className={classes.addIntegrationWrapper} >
                <Box
                    data-testid="back-btn"
                    onClick={() => this.props.backToStartIntegration()}
                    sx={{
                        position: 'absolute',
                        top: '30px',
                        left: '30px',
                    }} >
                    <KeyboardBackspaceIcon className="backIcon" />
                </Box>
                <Box sx={{ width: "50%" }}>
                    <Box pb={2}>
                        <Typography className={classes.heading} variant="h4">
                            Choose metrics to connect
                        </Typography>
                        <Box component="p"
                            paddingBottom={1}
                            className={classes.subTxt}>
                            Confirm which metrics you want in app
                        </Box>
                    </Box>
                    <Grid container spacing={2} >
                        <Grid item xs={6}>
                            <Box
                                sx={{
                                    border: '1px solid #E5E9F0',
                                    height: '60px',
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignItems: 'center',
                                    borderRadius: '8px',
                                    padding: "0px 2px 0px 10px"
                                }}
                            >
                                <Typography className={classes.headingIcon} variant="h3">
                                    Page Insights Metrics
                                </Typography>
                                <Checkbox
                                    size='small'
                                    key={63}
                                    className={classes.checklist}
                                    tabIndex={-1}
                                    disableRipple
                                    checked={this.state.pageInsideMetrics}
                                    data-test-id="pim-checkbox"
                                    onChange={(e) => this.handleChangePIM(e)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box
                                sx={{
                                    border: '1px solid #E5E9F0',
                                    height: '60px',
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignItems: 'center',
                                    borderRadius: '8px',
                                    padding: "0px 2px 0px 10px"
                                }}
                            >
                                <Typography className={classes.headingIcon} variant="h3">
                                    Page Insights Page Impressions
                                </Typography>
                                <Checkbox
                                    size='small'
                                    key={63}
                                    className={classes.checklist}
                                    tabIndex={-1}
                                    disableRipple
                                    checked={this.state.pageInsidePI}
                                    data-test-id="pipi-checkbox"
                                    onChange={(e) => this.handleChangPI(e)}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box
                                sx={{
                                    border: '1px solid #E5E9F0',
                                    height: '60px',
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignItems: 'center',
                                    borderRadius: '8px',
                                    padding: "0px 2px 0px 10px"
                                }}
                            >
                                <Typography className={classes.headingIcon} variant="h3">
                                    Page Insights Page Content
                                </Typography>
                                <Checkbox
                                    key={63}
                                    size='small'
                                    tabIndex={-1}
                                    disableRipple
                                    className={classes.checklist}
                                    checked={this.state.pageInsidePC}
                                    data-test-id="pipc-checkbox"
                                    onChange={(e) => this.handleChangPIPC(e)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box
                                sx={{

                                    border: '1px solid #E5E9F0',
                                    height: '60px',
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignItems: 'center',
                                    borderRadius: '8px',

                                    padding: "0px 2px 0px 10px"
                                }}
                            >
                                <Typography className={classes.headingIcon} variant="h3">
                                    Page Insights Page Posts
                                </Typography>
                                <Checkbox
                                    size='small'
                                    key={63}
                                    className={classes.checklist}
                                    tabIndex={-1}
                                    disableRipple
                                    data-test-id="pipp"
                                    checked={this.state.pageInsidePP}
                                    onChange={(e) => this.handleChangPIPP(e)}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box
                                sx={{
                                    border: '1px solid #E5E9F0',
                                    height: '60px',
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignItems: 'center',
                                    borderRadius: '8px',
                                    padding: "0px 2px 0px 10px"
                                }}
                            >
                                <Typography className={classes.headingIcon} variant="h3">
                                    Page Insights Page CTA clicks
                                </Typography>

                                <Checkbox
                                    size='small'
                                    key={63}
                                    className={classes.checklist}
                                    tabIndex={-1}
                                    disableRipple
                                    data-test-id="pipcc-checkbox"
                                    checked={this.state.pageInsideCTA}
                                    onChange={(e) => this.handleChangeCTA(e)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box
                                sx={{
                                    border: '1px solid #E5E9F0',
                                    height: '60px',
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignItems: 'center',
                                    borderRadius: '8px',
                                    padding: "0px 2px 0px 10px"
                                }}
                            >
                                <Typography className={classes.headingIcon} variant="h3">
                                    Page Insights Page Post Impressions
                                </Typography>
                                <Checkbox
                                    size='small'
                                    key={63}
                                    className={classes.checklist}
                                    tabIndex={-1}
                                    disableRipple
                                    data-test-id="pippi-checkbox"
                                    checked={this.state.pageInsidePPI}
                                    onChange={(e) => this.handleChangPPI(e)}
                                />

                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box
                                sx={{
                                    border: '1px solid #E5E9F0',
                                    height: '60px',
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignItems: 'center',
                                    borderRadius: '8px',
                                    padding: "0px 2px 0px 10px"
                                }}
                            >
                                <Typography className={classes.headingIcon} variant="h3">
                                    Page Insights Page Post Engagements
                                </Typography>

                                <Checkbox
                                    size='small'
                                    key={63}
                                    className={classes.checklist}
                                    tabIndex={-1}
                                    disableRipple
                                    data-test-id="pippe-checkbox"
                                    checked={this.state.pageInsidePPE}
                                    onChange={(e) => this.handleChangPPE(e)}
                                />

                            </Box>
                        </Grid>
                        <Box sx={{ padding: 8, width: "100%", marginY: 5, display: "flex", justifyContent: "space-between" }}>
                            <Button
                                className={`secondary-btn ${classes.secondaryButton}`}
                                onClick={this.cancel}
                                data-testid="cancel-btn"
                            >
                                Cancel
                            </Button>
                            <Button
                                className={`primary-btn ${classes.primaryButton}`}
                                onClick={this.addMetrics}
                                data-testid="add-btn"
                            >
                                Add Matrics
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            </Box>
        );
    }
}

export default withStyles(customStyles, { withTheme: true })(ChooseMetrics);
