import React from "react";
// Customizable Area Start
import { Box, Typography, Menu, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import AppHeader from "../../../../components/src/AppHeader.web";
import MembersListTable from "../../../../components/src/MembersListTable.web";
import { customStyles } from "./TeamsStyles.web";
import TeamMembersSort from "./TeamMembersSort.web";
import ConfirmActionModal from "./ConfirmActionModal.web";
// Customizable Area End
import AddMembersTeamControllerWeb, {
  Props,
  configJSON,
  // Customizable Area Start
  // Customizable Area End
} from "./AddMembersTeamController.web";
export class AddMembersTeam extends AddMembersTeamControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  dropMenuClick = (e: any) => {this.setState({ anchorEl: e.currentTarget });};

  dropMenuClose = () => {this.setState({ anchorEl: null });};
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const memberTeamsData = () => {
      if (this.state.excludeMembersList.length === 0) {
        return null;
      }
      const membersListProps = {
        renderMembers: [],
        addMembertoTeam: this.addSingleMemberToTeam,
        addMultipleMemberTeam: this.addMultipleMembersToTeam,
        showEditOption: false,
        getSelectedRecords: this.getSelectedRecords,
        showDeactivateOption: false,
        userRole: this.userRole
      };
      if (this.state.isFiltering) {
        if (this.state.filteredData.length > 0) {
          membersListProps.renderMembers = this.state.filteredData;
        } else {
          return <Box className="no-member">No members found</Box>;
        }
      } else {
        membersListProps.renderMembers = this.state.excludeMembersList;
      }
      return <MembersListTable {...membersListProps} />;
    };
    return (
      <Box>
        <Box className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper}`}>
          <AppHeader
            openModal={this.handle_modalOpen}
            buttonTxt={this.t(`${configJSON.addToTeam}`)}
            clickHandler={this.addMultipleMembersToTeam}
            data-test-id="app-header-btn"
          />
          <Box className={classes.innerWrapper}>
            <Box className={classes.teamModuleWrapper}>
              <Box className="heading-wrapper">
                <Box className="heading-left">
                  <Typography className="heading" variant="h1">
                    {this.t(`${configJSON.selectMembersTxt}`)}
                  </Typography>
                  <Box component="p" className="sub-txt">{this.t(`${configJSON.txtAddMemberTitle}`)}</Box>
                </Box>
                {this.userRole === "owner" && (
                  <Box className="heading-right">
                    <Box component="span" className="members-count">
                      {this.state.excludeMembersList.length}{" "}
                      {this.t(`${configJSON.membersTxt}`)}
                    </Box>

                    <Box className={classes.dropMenu}>
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        variant="contained"
                        onClick={this.dropMenuClick}
                        className="dropmenu-btn"
                      >
                        + {this.t(`${configJSON.inviteMemberTxt}`)}
                      </Button>

                      <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        getContentAnchorEl={null}
                        onClose={() => {
                          this.setState({ anchorEl: null });
                        }}
                        className={classes.dropDropdown}
                      >
                        <MenuItem onClick={this.dropMenuClose}>
                          {this.t(`${configJSON.addManuallyTxt}`)}{" "}
                        </MenuItem>
                        <MenuItem onClick={this.dropMenuClose}>
                          {this.t(`${configJSON.bulkUploadTxt}`)}{" "}
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box className={classes.tabWrapper}>
                <Box sx={{ width: "100%" }}>
                  <Box className="table-top">
                    <Box className="top-left">
                      <Box className="search-wrapper">
                        <Input
                          placeholder={this.t(`${configJSON.searchTxtType}`)}
                          type="search"
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                        />
                      </Box>
                      <TeamMembersSort
                        selectTeamOptions={this.state.teamsList}
                        selectJobOptions={this.state.excludeMembersList}
                        classes={classes}
                        onSelectedOptions={this.saveSelectedOption}
                        selectedTeam={this.state.selectedTeamVal}
                        selectedRole={this.state.selectedRoleVal}
                        selectedTitle={this.state.selectedTitleVal}
                        clearAction={this.handleClearSelection}
                        deActivated={this.state.isDeactivatedActive}
                        ondeActivatedChange={this.handledeActivateSwitch}
                      />
                    </Box>
                    <Box className="top-right">
                      <FormControl className="select-control">
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: { paper: classes.dropdownStyle }
                          }}
                          defaultValue={this.t(`${configJSON.sortByTxt}`)}
                        >
                          <MenuItem value={this.t(`${configJSON.sortByTxt}`)}>
                            {this.t(`${configJSON.sortByTxt}`)}
                          </MenuItem>
                          <MenuItem value="A-Z">A-Z</MenuItem>
                          <MenuItem value="Z-A">Z-A</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
                {memberTeamsData()}
                {this.state.isAddingToTeam && (
                  <ConfirmActionModal
                    isOpen={this.state.isAddingToTeam}
                    modalHeading={this.t(`${configJSON.addMemberToTeamTxt}`)}
                    modalMessage={this.t(`${configJSON.confirmationTxt}`)}
                    handleClose={this.handle_modalClose}
                    modalConfirmAction={this.addToTeamNetworkCall}
                    confirmBtnTxt={this.t(`${configJSON.addToTeam}`)}
                    cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
                    data-testid="confirmDeleteTeam"
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(AddMembersTeam);
// Customizable Area End
