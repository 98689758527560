import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Switch,
  Button,
  Modal,
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme
} from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from "@material-ui/icons/Close";
import CustomInput from "../../../components/src/CustomInput.web";

import AddPriceController, {
  Props,
  configJSON
} from "./AddPriceController.web";
import { customStyles } from "../../../components/src/AddItemStyles.web";
import FreeSoloCreateOption from "../../../components/src/FreeSoloCreateOption.web";


// Customizable Area End

export class AddPrice extends AddPriceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes,onclose, openPriceModal} = this.props;
    return (
        <Box>
            <Modal
                open={openPriceModal}
                onClose={onclose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={classes.modalDialog}
                data-testId={"modalComponent"}
                BackdropProps={{ className: "backdrop-root" }} 
            >
                 <Box className={`${classes.modalDialogBox} price-modal-dialogbox`}>
                    <Box className="modal-heading">
                      <Typography className="modal-title">
                        {this.t(`${configJSON.addPrice}`)}
                      </Typography>
                      <CloseIcon
                        className="close-icon"
                        onClick={onclose}
                        data-testId="closeIcon"
                      />
                    </Box>
                    <Box className="modal-description">
                        <Grid item md={12} lg={12} className={classes.rowSpacing}>
                             <FreeSoloCreateOption
                                options={this.state.pricelabelList.map((val) => {
                                  return {
                                    id: val.id,
                                    title: val.title,
                                  };
                                })}
                                addEditCallback={this.addPriceLabel}
                                addLinkTitle={this.t(`${configJSON.ShowAsOption4}`)}
                                noOptionTitle={this.t(`${configJSON.noPriceFountTxt}`)}
                                label={this.t(`${configJSON.priceLabelTxt}`)}
                                id="Price"
                                onChange={this.handlePriceLabel}
                                value={this.state.pricelabel}
                                errorMsgNoRecord={this.t(`${configJSON.noPriceLabelTxt}`)}
                                data-testId="priceLabelFeild"
                                notRequired={true}
                                placeHolder={this.t(`${configJSON.searchPriceTxt}`)}
                                hideOtherOptions={true}
                                disabled={this.state.priceId>0} 
                                isinventoryPricing={true}
                                />
                        </Grid>
                        <Grid item md={12} lg={12}>
                            <CustomInput
                                value={this.state.itemPrice}
                                onChange={(event)=>this.handlePrice(event)}
                                placeholder={this.t(`${configJSON.enterPriceTxt}`)}
                                data-testId={"priceTextFeild"}
                                errors={this.state.error.price}
                            />
                        </Grid>
                        <Box className="taxBox">
                            <Switch
                            checked={this.state.isTaxExcluded}
                            onChange={event => this.handleTax(event)}
                            className="switchSecondaryStyle"
                            data-testId={"taxSwitch"}
                            />
                            <Typography className="tax-text">{this.t(`${configJSON.taxtext}`)}</Typography>
                        </Box>
                        <Box className="btn-box">
                            <Button data-testId={"submit-prices"} className="primary-btn"
                            onClick={this.addPrice}
                            >
                            {this.t(`${configJSON.submitButton}`)}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
    }
}

// Customizable Area Start


export default withStyles(customStyles, { withTheme: true })(AddPrice);
// Customizable Area End