// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Box, CardMedia } from "@material-ui/core";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./ContactsListStyles.web";
import { toast } from "react-toastify";
import { warningIcon } from "./assets";
import i18n from "../../../web/src/utilities/i18n";
import { DateObject } from "react-multi-date-picker";

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id?: string;
  classes: any;
  handleCloseDialog: any;
  handleMinimizeDealContact?: () => void;
  handleSelectContactsOpen: () => void;
  contactDetails: any;
}

interface S {
  dealCloseDate: DateObject[];
  dealName: string;
  dealDescription: string;
  dealFiles: any;
  dealStage: string;
  dealOwner: string;
  dealProduct: any;
  dealContact: string;
  filesUploaded: File | null;
  isLoading: boolean;
  selectedOwner: any;
  selectedContacts: any;
  isActiveChooseDeal: boolean;
  dealOwners: any;
  dealsListing: any;
  chosenDealProductIds: any;
  createDealError: {
    dealOwnerError: string;
    dealNameError: string;
    dealContactError: string;
  };
  openProductsModalInteractions: boolean;
  chosenProductsData: [];
  productAmount: number | null,
  viewSelectedProducts: boolean;
}

interface SS {
  id: any;
}

export default class CreateDealController extends BlockComponent<Props, S, SS> {
  userSessionData: any;
  userToken: any;
  fileRef: React.RefObject<HTMLInputElement>;
  formRef: React.RefObject<HTMLFormElement>;
  createDealRequestAPICallId: string = "";
  getDealOwnersRequestID: string = "";
  getAllDealsRequestId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.fileRef = React.createRef();
    this.formRef = React.createRef();

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];
    this.state = {
      dealName: "",
      dealDescription: "",
      dealCloseDate: [],
      dealStage: "0",
      dealOwner: "",
      dealFiles: "",
      dealContact: "",
      filesUploaded: null,
      dealProduct: "",
      isLoading: false,
      selectedOwner: [],
      selectedContacts: [],
      isActiveChooseDeal: false,
      dealOwners: [],
      dealsListing: [],
      createDealError: {
        dealOwnerError: "",
        dealNameError: "",
        dealContactError: "",
      },
      chosenDealProductIds: [],
      openProductsModalInteractions: false,
      chosenProductsData:[],
      productAmount: 0,
      viewSelectedProducts:false,
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.dealName !== this.state.dealName) {
      this.setState({
        createDealError: {
          ...this.state.createDealError,
          dealNameError: "",
        },
      });
    }
  }

  async componentDidMount() {
    this.getAllDealOwnersEvent();
    this.getAllDeals();
    this.displayContactOptionss();
    const langChange = localStorage.getItem("lang") ?? "en"; 
    await i18n.changeLanguage(langChange); 
  }
  
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.error) {
        this.createToastNotification("Something went wrong");
      }
      switch (apiRequestCallId) {
        case this.getDealOwnersRequestID:
          this.setState({ isLoading: false });
          this.setState({ dealOwners: responseJson.members.data }, () =>
            this.getCurrentDealOwner(responseJson.members.data)
          );
          break;
        case this.getAllDealsRequestId:
          this.setState({ isLoading: false });
          this.setState({ dealsListing: responseJson.data });
          break;
        case this.createDealRequestAPICallId:
          this.setState({isLoading:false})
          this.getDealsResponse(responseJson);
          break;
        default:
          this.parseApiCatchErrorResponse(errorReponse);
          break;
      }
    }
  }
  getDealsResponse = (response: any) => {
    if (response.data) {
      localStorage.setItem("isInteraction", true.toString());
      localStorage.removeItem("contactIds")
      this.setState({ isLoading: false });
      this.props.handleCloseDialog();
      this.createToastNotificationSuccess(response.message);
    }
  };
  createToastNotification = (toastMesssage: string) => {
    toast.error(this.t(`${toastMesssage}`), {
      position: toast.POSITION.TOP_CENTER,
      icon: () => (
        <CardMedia component="img" src={warningIcon} alt="emptydata" />
      ),
    });
  };

  createToastNotificationSuccess = (toastMessage: string) => {
    toast.success(
      <Box className="toast-notification">
        <Box className="notification-txt">{this.t(`${toastMessage}`)}</Box>
      </Box>,
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };
  t(key:any) {
    return i18n.t(key, { ns: "translation" } )
  }
  handleAllInputChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  handleCloseDateChange = (date: any) => {
    this.setState({
      dealCloseDate: date,
    });
  };
  onBrowse = () => {
    if (this.fileRef.current !== null) {
      this.fileRef.current.click();
    }
  };

  handleOnDrop = (files: FileList | null, event?: any) => {
    if (files) {
      this.setState({
        filesUploaded: files[0],
      });
    }
  };
  openProductsModalInteractions = () => {
    this.setState({ openProductsModalInteractions: true });
  }
  closeProductsModalInteractions = () => {
    this.setState({ openProductsModalInteractions: false });
  }
  openViewSelectedProductsss= () => {
    this.setState({viewSelectedProducts : true});
  }
  closeViewSelectedProductsss = () => {
    this.setState({viewSelectedProducts : false});
  }
  selectedDealProducts=(productsSelected:string[],totalAmount:number|null,selectedProductsData:[])=>{
    console.log(selectedProductsData)
      this.setState({chosenDealProductIds:productsSelected,chosenProductsData: selectedProductsData,productAmount: totalAmount,  }, ()=> {this.closeProductsModalInteractions()})
    
  }
  createDealHandler = () => {
    const retrievedArray = JSON.parse(localStorage.getItem("contactIds") ?? "[]");
    const contactIdsString = JSON.stringify(retrievedArray);
    const {
      dealName,
      dealStage,
      dealDescription,
      dealCloseDate,
      selectedOwner,
      filesUploaded,
      selectedContacts,
    } = this.state;

    let error = this.state.createDealError;
    let hasError = false;
    if (this.state.dealName.trim() === "") {
      error.dealNameError = "Please enter deal name";
      this.setState({ createDealError: error });
      hasError = true;
    }
   
    if (hasError) {
      return;
    }
    let startDate = "";
    let endDate = "";
    const formData = new FormData();
    formData.append("data[name]", dealName);
    formData.append("stage", dealStage);
    if (dealCloseDate.length === 2) {
      startDate = dealCloseDate[0].format("YYYY-MM-DD");
      endDate = dealCloseDate[1].format("YYYY-MM-DD");
      formData.append("data[start_date]",startDate);
      formData.append("data[close_date]", endDate);
    }
    
    formData.append("data[description]", dealDescription);
    formData.append("data[deal_owner_id]",selectedOwner.id)
    if(this.state.chosenDealProductIds.length > 0){
      const convertProductIds = [this.state.chosenDealProductIds.map(Number)];
      formData.append("products", `[${convertProductIds}]`);
      if(this.state.productAmount !==null){
        formData.append("data[total_amount]", this.state.productAmount.toString())
      }
      
    }
    if (filesUploaded) {
      formData.append("files", filesUploaded.name);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      token: this.userToken.meta.token,
    };

    this.createDealRequestAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createDealsEndpoint +
        `?contact_ids=[${selectedContacts.id}]`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCurrentDealOwner = (ownersData: any) => {
    let selected = ownersData.find(
      (ownerd: any) => ownerd.id === this.userToken.data.id
    );
    this.setState({ selectedOwner: selected });
  };

  getAllDealOwnersEvent = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDealOwnersRequestID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dealOwnersPath
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  openChooseDealModal = () => {
    this.setState({ isActiveChooseDeal: true });
  };

  closeChooseDealOwner = () => {
    this.setState({ isActiveChooseDeal: false });
  };
  handleOwnerClick = (ownerId: string) => {
    const { dealOwners } = this.state;
    let updateSeleected = dealOwners.find((owner: any) => owner.id === ownerId);
    this.setState({ selectedOwner: updateSeleected });
  };
  getAllDeals = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllDealsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewDealsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  displayContactOptionss = () => {
    const contact = this.props.contactDetails; // Assuming this.props.contactDetails is the contact object
    const contactNames = [{
      id: contact.id,
      title: `${contact.attributes.first_name} ${contact.attributes.last_name}`,
      email: contact.attributes.email,
      initials: `${contact.attributes.first_name
        .charAt(0)
        .toUpperCase()}${contact.attributes.last_name
        .charAt(0)
        .toUpperCase()}`,
      phone: contact.attributes.phone,
      groups: contact.attributes.contact_groups.map((group: { name: string }) => group.name), // Extracting group names
      interactions: contact.attributes.contact_interactions.data.map(
        (interaction: { attributes: { name: string; stage: string } }) => ({
          name: interaction.attributes.name,
          stage: interaction.attributes.stage,
        })
      ), // Extracting interaction names and stages
    }];
    this.setState({
      selectedContacts: contactNames,
    });
  
    return contactNames;
  };
  
}
// Customizable Area End
