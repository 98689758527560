import React from "react";
// Customizable Area Start
import { Typography, Button, Grid } from "@material-ui/core";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import { withStyles } from "@material-ui/core/styles";
import ShoppingCartOrdersController, {
  Props,
  configJSON,
} from "./ShoppingCartOrdersController.web";
import { etohShopStyle } from "../../../components/src/EtohShopStyleHelper.web";
import { successFrame } from "./assets";
import BackArrow from "@material-ui/icons/ArrowBack";
// Customizable Area End

export class PaymentSuccess extends ShoppingCartOrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { cartData } = this.state;   
    const showAdditionalDetailsButton = cartData.some(
      (item) =>
        item &&
        item.attributes &&
        item.attributes.bx_block_catalogue_service &&
        item.attributes.bx_block_catalogue_service.data &&
        item.attributes.bx_block_catalogue_service.data.attributes &&
        item.attributes.bx_block_catalogue_service.data.attributes.buyers_additional_details === true
    );     
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <div
          style={{
            color: "#2B2B2B",
            display: "flex",
            alignItems: "center",
            marginTop: "24px",
            marginLeft: "12px",
          }}
          onClick={this.handleViewShop}
        >
          <BackArrow style={{ color: "#2B2B2B", marginRight: "4px" }} />
          {this.translationShopEvent(`${configJSON.backTxt}`)}
        </div>
        <Grid container spacing={3} className={classes.mainSuccessContainer}>
          <Grid item xs={6} className="image-container">
            <img className="image" src={successFrame} alt="success-image"></img>
          </Grid>
          <Grid item xs={6}>
            <Typography className="success-headding">
              {this.translationShopEvent(`${configJSON.successPaymentTxt}`)}
            </Typography>
            <Typography className="success-sub-txt">
              {this.translationShopEvent(`${configJSON.successPaymentSubTxt}`)}
            </Typography>
            <div className="button-container">
              <Button
                className={`secondary-btn ${classes.secondaryButton}`}
                onClick={this.handleViewShop}
                data-test-id="handleViewShop"
              >
                {this.translationShopEvent(`${configJSON.returnToShop}`)}
              </Button>
              {showAdditionalDetailsButton && (
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  onClick={this.handleFillDetailsOpen}
                  data-test-id="handleFillDetailsOpen"
                >
                  {this.translationShopEvent(`${configJSON.viewAdditionalDetails}`)}
                </Button>
              )}
              <Button
                style={{display:"none"}}
                onClick={this.checkPaymentSuccess}
                data-test-id="create-order"
              >
                {this.translationShopEvent(`${configJSON.viewAdditionalDetails}`)}
              </Button>
            </div>
          </Grid>
        </Grid>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(etohShopStyle, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(PaymentSuccess)
);
// Customizable Area End
